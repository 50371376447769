import PageLayout from "pages/layout/PageLayout"
import { useCallback, useEffect, useState } from "react"
import { AddressBookQueryState, AddressBookResponse } from "./address-book.type"
import {
  deleteAddressBookMiddleware,
  getAddressBookMiddleware,
} from "./address-book.api"
import SimpleTable, { SimpleTableProps } from "components/Table/SimpleTable"
import { useBoolean, useNumber, useString, useWindowSize } from "helpers/hooks"
import { TippyCustomzie } from "components/TippyCustomzie"
import { ReactComponent as IconActionThreeDot } from "assets/images/icons/icon-three-dots.svg"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import { FormAddressBookModal } from "./Modal/FormAddressBookModal"
import { cloneDeep, debounce } from "lodash"
import ActionItem from "pages/projects/molecules/ActionItem"
import { configureStore } from "stores/configureStore"
import { closeModal, openModal } from "reducers/modal"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { Pagination } from "components/Pagination/Pagination"
import { ReactComponent as IconSearchNormal } from "assets/images/icons/icon-search-normal.svg"
import InputDefault from "components/Input/InputDefault"
import { ReactComponent as IconClearSearch } from "assets/images/icons/icon-clear-search.svg"

export const AddressBookPage = () => {
  const { isMobile } = useWindowSize()
  const [addressBooks, setAddressBooks] = useState<AddressBookResponse[]>([])
  const [showTippy, setShowTippy] = useState([] as string[])
  const openModalForm = useBoolean()
  const [dataSelected, setDataSelected] = useState<AddressBookResponse>({
    id: "",
    name: "",
    email: "",
  })

  const [queryState, setQueryState] = useState<AddressBookQueryState>({
    page: 1,
    pageSize: 25,
    search: "",
  })
  const totalPage = useNumber(0)
  const search = useString()
  useEffect(() => {
    getAddressBook(queryState)
  }, [queryState.pageSize, queryState.page, queryState.search])

  const getAddressBook = async (newQueyState: AddressBookQueryState) => {
    const response = await getAddressBookMiddleware(newQueyState)
    setAddressBooks(response.data)
    totalPage.setValue(response.total)
  }

  const handleClickTippy = (value) => {
    if (showTippy.includes(value)) {
      setShowTippy(showTippy.filter((item) => item !== value))
    } else setShowTippy(showTippy.concat([value]))
  }

  const handleWhenSubmit = (response: AddressBookResponse) => {
    openModalForm.setValue(false)
    setDataSelected({
      id: "",
      name: "",
      email: "",
    })
    if (dataSelected.id) {
      const newAddressBooks = cloneDeep(addressBooks)
      const index = newAddressBooks.findIndex((item) => item.id === response.id)
      if (index > -1) {
        newAddressBooks[index] = response
      }
      setAddressBooks(newAddressBooks)
    } else {
      if (queryState.page === 1) {
        getAddressBook(queryState)
      } else {
        setQueryState({
          ...queryState,
          page: 1,
        })
      }
    }
  }

  const handleChangePage = (newPage: number, newLimit: number) => {
    setQueryState({
      ...queryState,
      page: newPage,
      pageSize: newLimit,
    })
  }
  const onPreventBubble = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const debounceSearch = useCallback(
    debounce((newSearch: string, newQueyState: AddressBookQueryState) => {
      setQueryState({
        ...newQueyState,
        search: newSearch.trim(),
        page: 1,
      })
    }, 500),
    []
  )

  const columns: SimpleTableProps["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      render: (columnData: AddressBookResponse) => (
        <p className="pl-[20px]">{columnData.name}</p>
      ),
      width: `45%`,
    },

    {
      title: "Email",
      dataIndex: "email",
      width: isMobile ? "55%" : "50%",
      render: (columnData: AddressBookResponse) => columnData.email,
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: isMobile ? "0%" : "5%",
      render: (columnData: AddressBookResponse) => {
        if (isMobile) {
          return null
        }
        return (
          <TippyCustomzie
            containerClass="component-card-menu"
            placement="bottom-end"
            interactive
            arrow={false}
            animation="scale"
            visible={showTippy.includes(columnData.id)}
            onClickOutside={() =>
              setShowTippy(showTippy.filter((item) => item !== columnData.id))
            }
            content={
              <div
                style={{
                  background: "#222222",
                  borderRadius: 4,
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 120,
                }}
              >
                <ActionItem
                  title="Edit"
                  onClick={() => {
                    setDataSelected(columnData)
                    openModalForm.setValue(true)
                    handleClickTippy(columnData.id)
                  }}
                />
                <ActionItem
                  title="Delete"
                  onClick={() => {
                    handleClickTippy(columnData.id)

                    configureStore.dispatch(
                      openModal({
                        type: "Delete",
                        props: {
                          deleteModal: {
                            title: `When action is confirmed, contact be removed. This action can not undo.`,
                            label: `Warning`,
                            content: `Press "Delete" to process`,
                            styleTitle: { textAlign: "center" },
                            titleButton: "Delete",
                            onSubmit: async () => {
                              try {
                                await deleteAddressBookMiddleware(columnData.id)
                                if (queryState.page === 1) {
                                  getAddressBook(queryState)
                                } else {
                                  setQueryState({
                                    ...queryState,
                                    page: 1,
                                  })
                                }
                                toast(
                                  <LabelNotificationPage
                                    messenger={
                                      MESSENGER_NOTIFICATION.DELETE_ADDRESS_BOOK_SUCCESS
                                    }
                                    type="success"
                                  />
                                )
                                closeModal()
                              } catch (error: any) {
                                closeModal()
                                toast(
                                  <LabelNotificationPage
                                    messenger={
                                      error.response?.data?.message ??
                                      MESSENGER_NOTIFICATION.DELETE_ADDRESS_BOOK_ERROR
                                    }
                                    type="error"
                                  />
                                )
                              }
                            },
                          },
                        },
                      })
                    )
                  }}
                />
              </div>
            }
            allowHTML
          >
            <div
              className="flex items-center justify-end cursor-pointer"
              onClick={() => {
                handleClickTippy(columnData.id)
              }}
            >
              <IconActionThreeDot />
            </div>
          </TippyCustomzie>
        )
      },
    },
  ]

  return (
    <PageLayout
      childrenHeader={
        <div className="flex items-center justify-between h-full px-[24px]">
          <h2 className="text-[16px] font-semibold lh-26 text-black">
            Address book
          </h2>
          <div className="flex items-center gap-[12px]">
            <div
              className=" bg-grayWhite2 p-2 rounded-full flex items-center w-[320px]"
              onClick={onPreventBubble}
            >
              <IconSearchNormal
                style={{
                  width: 20,
                }}
                values={search.value}
                onClick={onPreventBubble}
                onChange={(e) => {
                  onPreventBubble(e)
                }}
              />
              <InputDefault
                value={search.value}
                placeholder="Search by either name or email"
                style={{
                  border: "none",
                  boxShadow: "none",
                  height: 30,
                  fontWeight: 400,
                }}
                classCustom="h-11 border rounded bg-grayWhite2 pl-3 pr-3 text-sm font-light text-[#111111] focus:outline-none"
                onClick={onPreventBubble}
                onChange={(e) => {
                  onPreventBubble(e)
                  search.setValue(e.target.value)
                  debounceSearch(e.target.value, cloneDeep(queryState))
                }}
              />
              {search.value ? (
                <div
                  onClick={(e) => {
                    onPreventBubble(e)
                    search.setValue("")
                    setQueryState({
                      ...queryState,
                      search: "",
                    })
                  }}
                  className="h-5 w-5 flex items-center justify-center custom-icon-clear-search cursor-pointer"
                >
                  <IconClearSearch />
                </div>
              ) : null}
            </div>
            {!isMobile ? (
              <ButtonHasIcon
                title="New contact"
                widthButton={130}
                onClick={() => {
                  openModalForm.setValue(true)
                  setDataSelected({
                    id: "",
                    name: "",
                    email: "",
                  })
                }}
              />
            ) : null}
          </div>
        </div>
      }
    >
      <div className="flex flex-col p-6">
        <div className="text-black bg-white border border-border-gray rounded-md overflow-auto">
          <SimpleTable
            customClass="storage-usage-table"
            columns={columns}
            dataSource={addressBooks || []}
          />
        </div>
        {totalPage.value ? (
          <Pagination
            total={totalPage.value}
            queryState={{
              page: queryState.page,
              limit: queryState.pageSize,
            }}
            handleChangePage={handleChangePage}
          />
        ) : null}
      </div>
      {openModalForm.value ? (
        <FormAddressBookModal
          openModal={openModalForm.value}
          onCloseModal={() => {
            openModalForm.setValue(false)
            setDataSelected({
              id: "",
              name: "",
              email: "",
            })
          }}
          dataSelected={dataSelected}
          handleWhenSubmit={handleWhenSubmit}
        />
      ) : null}
    </PageLayout>
  )
}
