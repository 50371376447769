/* eslint-disable react-hooks/exhaustive-deps */
import { STATUS_BUILD } from "components/Status/types"
import { TippyCustomzie } from "components/TippyCustomzie"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import {
  checkPermissionPage,
  formatDate,
  customLocalStorageHandler,
} from "helpers/utils"
import LabelComponentBuildCard from "../molecules/LabelComponentBuildCard"
import LabelStatusBuild from "../molecules/LabelStatusBuild"
import { ProjectBuildDetail } from "../types"
import { ReactComponent as IconAction } from "assets/images/icons/icon-action-project.svg"
import React, { useContext, useEffect, useState } from "react"
import ActionItem from "pages/projects/molecules/ActionItem"
import { cloneDeep, includes, isUndefined } from "lodash"
import {
  archiveBuildMiddleware,
  deleteProjectBuildMiddleware,
  duplicateBuildMiddleware,
  deleteBuildAttachment,
  getProjectBuildAdditionalInfoMiddleware,
} from "pages/project-build/api.services"
import { KeyEncryptionType, STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { PermissionProjectPage } from "../contexts/PermissionProjectPage.context"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { useAppSelector } from "hooks/useApp"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { createEncryptionKeysMiddleware } from "pages/projects/services/api"
import { v4 as uuid } from "uuid"
interface Props {
  listBuilds: ProjectBuildDetail[]
  handleUpdateDataWhenChangeTableBuild?: (
    data: ProjectBuildDetail,
    key: "duplicate" | "archive" | "delete" | "delete_attachment"
  ) => void
  customHeaders?: HeaderTableBuild[]
  customStyleFieldStatus?: React.CSSProperties
  canDeleteAttachments?: boolean
}

export interface HeaderTableBuild {
  name: string
  field: string
  style?: React.CSSProperties
}
const TableBuildCard = (props: Props) => {
  const {
    listBuilds,
    customHeaders,
    handleUpdateDataWhenChangeTableBuild,
    customStyleFieldStatus,
    canDeleteAttachments,
  } = props
  const { archiveProject, viewOnlyShare } = useContext(PermissionProjectPage)
  const user = useAppSelector((state) => state.userInfo)
  const [showTippy, setShowTippy] = useState<boolean[]>([])
  const { handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.BUILD
  )
  const [headers, setHeaders] = useState<HeaderTableBuild[]>([
    {
      name: "#",
      field: "index",
      style: {
        width: 50,
        minWidth: 50,
      },
    },
    {
      name: "ID",
      field: "code",
      style: {
        width: 115,
        minWidth: 115,
      },
    },
    {
      name: "Build name",
      field: "name",
      style: {
        width: 250,
        minWidth: 250,
      },
    },
    {
      name: "Creation period",
      field: "created_at",
      style: {
        width: 180,
        minWidth: 180,
      },
    },
    {
      name: "Last update",
      field: "updated_at",
      style: {
        width: 180,
        minWidth: 180,
      },
    },
    {
      name: "Components",
      field: "components",
      style: {
        width: "auto",
      },
    },
    {
      name: "Status",
      field: "status",
      style: {
        width: 165,
        minWidth: 165,
      },
    },
    {
      name: "",
      field: "action",
      style: {
        width: 50,
        minWidth: 50,
        paddingRight: 12,
      },
    },
  ])

  useEffect(() => {
    setShowTippy(listBuilds.map(() => false))
  }, [JSON.stringify(listBuilds)])

  useEffect(() => {
    if (isUndefined(customHeaders) || !customHeaders.length) {
      return
    }
    setHeaders(customHeaders)
  }, [customHeaders])

  const onClickAction =
    (
      key: "duplicate" | "archive" | "delete" | "delete_attachment",
      oldItem: ProjectBuildDetail
    ) =>
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      setShowTippy(listBuilds.map(() => false))
      switch (key) {
        case "duplicate":
          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: `Are you sure to duplicate this build?`,
                  label: `Confirmation`,
                  onSubmit: onSubmitDuplicate(oldItem),
                  titleButton: `Duplicate`,
                  colorYellowButton: true,
                },
              },
            })
          )
          break
        case "archive":
          if (oldItem.status === STATUS_BUILD.IN_PRODUCTION) {
            toast(
              <LabelNotificationPage
                messenger=" There's an active build. Please consider to update the build status before archiving"
                type="warning"
              />
            )
            return
          }

          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: `Are you sure to ${
                    oldItem.is_archived === 0 ? "archive" : "Unarchive"
                  } this build?`,
                  label: `Confirmation`,
                  onSubmit: onSubmitArchive(oldItem),
                  content: "",
                  titleButton: `${
                    oldItem.is_archived === 0 ? "Archive" : "Unarchive"
                  }`,
                },
              },
            })
          )

          break
        case "delete":
          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: `Are you sure to delete ${oldItem.name}?`,
                  label: `Confirmation`,
                  onSubmit: onSubmitDelete(oldItem),
                  titleButton: "Delete",
                },
              },
            })
          )
          break
        case "delete_attachment":
          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: `When action is confirmed, all attachment files also be removed. This action can not undo.`,
                  label: `Warning`,
                  content: `Press "Delete" to process`,
                  onSubmit: onSubmitDeleteAttachment(oldItem),
                  styleTitle: { textAlign: "center" },
                  titleButton: "Delete",
                },
              },
            })
          )
          break
      }
    }

  const handleClickTippy = (oldIndex: number, value: boolean) => (event) => {
    if (
      checkPermissionPage({
        project: archiveProject,
        viewShare: viewOnlyShare,
        notificationProject: true,
        notificationViewShare: true,
      })
    ) {
      return
    }
    if (value) {
      event.stopPropagation()
      event.preventDefault()
    }
    const newShowTippy = cloneDeep(showTippy)
    newShowTippy[oldIndex] = value
    setShowTippy(newShowTippy)
  }

  const renderTableBody = (
    header: HeaderTableBuild,
    value: any,
    index: number
  ) => {
    switch (header.field) {
      case "index":
        return index + 1
      case "updated_at":
      case "created_at":
        return (
          <p
            className="font-normal"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: Boolean(value.is_archived) ? " #C7C7C7" : "#7A7A7A",
            }}
          >
            {value[header.field]
              ? formatDate(value[header.field], "DD MMM yyyy - HH:mm A")
              : "No update"}
          </p>
        )
      case "status":
        return (
          <div
            className="flex justify-end"
            style={{
              ...customStyleFieldStatus,
            }}
          >
            <LabelStatusBuild
              status={value[header.field]}
              isArchive={Boolean(value.is_archived)}
            />
          </div>
        )
      case "components":
        if (isUndefined(value[header.field]) || !value[header.field].length) {
          return null
        }
        return (
          <LabelComponentBuildCard
            components={value[header.field]}
            isArchive={Boolean(value.is_archived)}
            isDeleted={value.status === STATUS_BUILD.DELETED}
          />
        )
      case "code":
        const isDeleted = value.status === STATUS_BUILD.DELETED
        return (
          <p
            className={`font-normal underline ${
              isDeleted ? "" : "hover-text-code-build cursor-pointer"
            }`}
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: Boolean(value.is_archived) ? " #C7C7C7" : "#0A5AF5",
            }}
          >
            {value[header.field]}
          </p>
        )
      case "action":
        return renderActionTableBody(index, value)
      default:
        return value[header.field]
    }
  }

  const renderActionTableBody = (oldIndex: number, oldValue: any) => {
    if (oldValue.status === STATUS_BUILD.DELETED) {
      return null
    }
    return (
      <TippyCustomzie
        containerClass="component-card-menu"
        placement="bottom-end"
        interactive
        arrow={false}
        animation="scale"
        visible={showTippy[oldIndex]}
        onClickOutside={handleClickTippy(oldIndex, false)}
        content={
          <div
            style={{
              background: "#222222",
              borderRadius: 4,
              paddingTop: 8,
              paddingBottom: 8,
              width: 165,
            }}
          >
            {oldValue.is_archived === 0 ? (
              <React.Fragment>
                <ActionItem
                  title="Duplicate"
                  onClick={onClickAction("duplicate", oldValue)}
                />
              </React.Fragment>
            ) : null}

            <ActionItem
              title={oldValue.is_archived === 0 ? "Archive" : "Unarchive"}
              onClick={onClickAction("archive", oldValue)}
            />
            {oldValue.status === STATUS_BUILD.DRAFT ? (
              <ActionItem
                title="Delete Draft"
                onClick={onClickAction("delete", oldValue)}
              />
            ) : canDeleteAttachments ? (
              <ActionItem
                className="hidden md:flex"
                title="Delete"
                onClick={onClickAction("delete_attachment", oldValue)}
              />
            ) : null}
          </div>
        }
        allowHTML
      >
        <div
          className="flex items-center justify-center w-[30px] h-[30px] cursor-pointer"
          onClick={handleClickTippy(oldIndex, !showTippy[oldIndex])}
        >
          <IconAction
            className={`${
              checkPermissionPage({
                project: archiveProject,
                viewShare: viewOnlyShare,
              })
                ? "disabled-icon-button-create-fill"
                : ""
            }`}
          />
        </div>
      </TippyCustomzie>
    )
  }

  const changeRouter =
    (oldItem: ProjectBuildDetail, header: HeaderTableBuild) => () => {
      if (!includes(["code"], header.field)) {
        return
      }
      handleSetLocalStorage({})
      pushTo(
        PATH.buildOverview,
        {
          status: oldItem.status,
          idProject: oldItem.project_id,
          idProjectBuild: oldItem.id,
        },
        {
          selectArchivedFilter:
            oldItem.is_archived || archiveProject ? true : false,
        },
        {
          tab: oldItem.created_by === user.id ? "my-builds" : "others",
        }
      )
    }

  const onSubmitDuplicate = (buildDetail: ProjectBuildDetail) => async () => {
    if (!buildDetail.id) {
      return
    }
    let additionalInfos = await getProjectBuildAdditionalInfoMiddleware(
      buildDetail.id
    )
    additionalInfos = await Promise.all(
      additionalInfos.map(async (item) => {
        let newDescription = item.originalDescription
        const newFiles: {
          oldId: string
          newId: string
          file: string
          is_note_file: number
        }[] = []
        if (!isUndefined(item.files) && item.files.length) {
          item.files.forEach((file) => {
            const newFileId = uuid()
            newFiles.push({
              oldId: file.id,
              newId: newFileId,
              file: file.file,
              is_note_file: file.is_note_file,
            })
            newDescription = newDescription.replace(file.id, newFileId)
          })
        }

        newDescription = await encryptionController().encrypt(
          item.description,
          {
            dataType: "string",
            type: "build_additional_info",
            relationId: buildDetail.id,
          }
        )

        return {
          id: item.id,
          created_by: item.created_by,
          description: newDescription,
          newFiles,
        }
      })
    )
    duplicateBuildMiddleware(
      buildDetail.id,
      additionalInfos,
      async (
        type: STATUS_RESPONSE,
        messenger: string,
        data?: ProjectBuildDetail
      ) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && data) {
          closeModal()
          if (handleUpdateDataWhenChangeTableBuild) {
            const currentProjectEncryptionKey = localStorage.getItem(
              EncryptionKeys.currentProjectEncryptionKey
            ) as string
            const buildAdditionalInfoEncryptionKeys = JSON.parse(
              localStorage.getItem(
                EncryptionKeys.buildAdditionalInfoEncryptionKeys
              ) || "{}"
            )
            const buildAdEncryptionKeyItem =
              buildAdditionalInfoEncryptionKeys[buildDetail.id]
            const buildAdditionEncryptedKey = encryptionHelper.encrypt(
              currentProjectEncryptionKey,
              buildAdEncryptionKeyItem
            )
            await createEncryptionKeysMiddleware([
              {
                encrypted_key: buildAdditionEncryptedKey,
                model: "build_additional_infos",
                relation_id: data.id,
                type: KeyEncryptionType.ModuleKey,
                user_id: user.id,
                additional_id: data.project_id,
              },
            ])
            handleUpdateDataWhenChangeTableBuild(data, "duplicate")
          }
        }
      }
    )
  }

  const onSubmitArchive = (buildDetail: ProjectBuildDetail) => () => {
    if (!buildDetail.id) {
      return
    }
    archiveBuildMiddleware(
      buildDetail.id,
      buildDetail.is_archived === 0 ? false : true,
      (type: STATUS_RESPONSE, messenger: string, data?: ProjectBuildDetail) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && data) {
          closeModal()
          if (handleUpdateDataWhenChangeTableBuild) {
            handleUpdateDataWhenChangeTableBuild(data, "archive")
          }
        }
      }
    )
  }

  const onSubmitDelete = (buildDetail: ProjectBuildDetail) => () => {
    if (!buildDetail.id) {
      return
    }
    deleteProjectBuildMiddleware(
      buildDetail.id,
      "build",
      buildDetail.code,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          closeModal()
          if (handleUpdateDataWhenChangeTableBuild) {
            handleUpdateDataWhenChangeTableBuild(buildDetail, "delete")
          }
        }
      }
    )
  }

  const onSubmitDeleteAttachment = (buildDetail: ProjectBuildDetail) => () => {
    if (buildDetail.status === STATUS_BUILD.DELETED) {
      return
    }
    deleteBuildAttachment(buildDetail.id)
      .then(() => {
        if (handleUpdateDataWhenChangeTableBuild) {
          handleUpdateDataWhenChangeTableBuild(buildDetail, "delete")
        }
        toast(
          <LabelNotificationPage
            messenger={"Delete build successfully!"}
            type="success"
          />
        )
        closeModal()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message || "Delete build failed!"}
            type="error"
          />
        )
        closeModal()
      })
  }

  const renderBackgroundColorTable = (
    isArchived: boolean,
    oldIndex: number
  ) => {
    if (isArchived) {
      return "neutral3"
    }
    if (oldIndex % 2 === 0) {
      return "grayWhite"
    }
    return "white"
  }

  return (
    <div
      className="bg-white flex w-full mr-6 overflow-auto"
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 6,
        paddingBottom: 2,
      }}
    >
      <p className="bg-grayWhite hidden"></p>
      <table className="w-full">
        <thead>
          <tr>
            {headers.map((header: HeaderTableBuild, idx: number) => (
              <th
                className="pl-3 font-semibold min-h-48-custom h-12"
                style={{
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "#111111",
                  textAlign: "left",
                  ...header.style,
                }}
                key={idx}
              >
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listBuilds.length ? (
            listBuilds.map((el: ProjectBuildDetail, index: number) => (
              <tr
                key={index}
                className={`${
                  el.status === STATUS_BUILD.DELETED
                    ? "bg-table-row-delete-element bg-neutral3"
                    : `bg-${renderBackgroundColorTable(
                        Boolean(el.is_archived),
                        index
                      )}`
                }`}
              >
                {headers.map((header: HeaderTableBuild, idx: number) => (
                  <td
                    className="pl-3 py-1 font-normal min-h-48-custom h-12"
                    style={{
                      fontSize: 13,
                      lineHeight: "24px",
                      color: Boolean(el.is_archived) ? "#C7C7C7" : "#111111",
                      ...header.style,
                    }}
                    key={idx}
                    onClick={
                      el.status === STATUS_BUILD.DELETED
                        ? undefined
                        : changeRouter(el, header)
                    }
                  >
                    {renderTableBody(header, el, index)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="px-3 font-normal min-h-48-custom"
                style={{
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "#111111",
                  textAlign: "center",
                }}
                colSpan={headers.length}
              >
                No data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
export default TableBuildCard
