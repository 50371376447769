import Button from "components/Button/Button"
import PageLayout from "pages/layout/PageLayout"
import FormInputCard from "./molecules/FormInputCard"
import InputPhone from "components/Input/InputPhone"
import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import InputDefault from "components/Input/InputDefault"
import { useChangeLabelStatusInputPasswordAndConfirm } from "./auth.hook"
import { STATUS_INPUT } from "components/Input/types"
import { useState } from "react"
import { useTimeOutResend } from "hooks/useTimeOutResend"
import { twMerge } from "tailwind-merge"
import { isValidPhone } from "helpers/utils"
import {
  getUserMiddleware,
  postChangePhoneNumberMiddleware,
  postSendSMSOTPMiddleware,
} from "./services/api"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { encryptionController } from "controllers/EncryptionController"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useLocation } from "react-router-dom"

export const ChangePhoneNumber = () => {
  const location = useLocation() as any
  const locationStateTab = location.state?.tab || 0
  const { countTimeResend, disableResend, timeOutResend } = useTimeOutResend()
  const phone = useString("")
  const password = useString("")
  const [otp, setOtp] = useState({
    status: STATUS_INPUT.ERROR,
    label: "Verification code required",
    value: "",
  })
  const isResend = useBoolean()
  const { isMobile } = useWindowSize()
  const showToolTip = useBoolean()
  const { statusInputPassword, getStatusInputPassword } =
    useChangeLabelStatusInputPasswordAndConfirm()

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }
  const handleChangePhoneNumber = (value) => {
    phone.setValue(value)
  }

  const handleChangeInput = (event) => {
    password.setValue(event.target.value)
    getStatusInputPassword(event.target.value)
  }

  const handleChangeOtp = (event: any) => {
    const value = event.target.value
    let status = STATUS_INPUT.DEFAULT
    let label = ""
    if (value.length < 6) {
      label = "Verification code must be a 6 digit number"
      status = STATUS_INPUT.ERROR
    }
    if (value.length === 0) {
      label = "Verification code required"
    }
    setOtp({ value, status, label })
  }

  const handleSendSMSOTP = () => {
    if (disableResend.value || !isValidPhoneNumber) {
      return
    }
    postSendSMSOTPMiddleware(phone.value)
      .then(() => {
        countTimeResend(30)
        timeOutResend.setValue(30)
        disableResend.setValue(true)
        isResend.setValue(true)
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }

  const onClickButtonSave = async () => {
    const { generatePasswordHashed } = encryptionController()

    const request = {
      phoneNumber: phone.value,
      password: await generatePasswordHashed(password.value),
      code: "",
    }
    postChangePhoneNumberMiddleware(request)
      .then(() => {
        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.CHANGE_PHONE_NUMBER_SUCCESS}
            type="success"
          />
        )
        getUserMiddleware()
        pushTo(PATH.general, undefined, { tab: locationStateTab })
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }

  const isValidPhoneNumber = isValidPhone(phone.value)

  const handleDisableButton = () => {
    if (
      isValidPhoneNumber &&
      // otp.value.length === 6 &&
      statusInputPassword.status === STATUS_INPUT.VALID
    ) {
      return false
    }
    return true
  }

  return (
    <PageLayout heightHeader={0} minWidth="min-w-[500px]">
      <div className="flex items-center justify-center h-full">
        <div
          className="w-[440px] bg-white p-[24px] gap-[24px] flex flex-col"
          style={{
            border: "1px solid #E4E4E4",
            borderRadius: 6,
          }}
        >
          <p className="text-[#111111] text-[20px] font-bold leading-8">
            Change phone number
          </p>
          <div className="flex flex-col">
            <FormInputCard title="New phone number">
              <InputPhone
                value={phone.value}
                setValue={handleChangePhoneNumber}
                onEnterKeyPress={onKeyPress}
              />
              {phone.value ? (
                <div className="flex mt-1">
                  <p
                    className="font-semibold"
                    style={{
                      color: isValidPhoneNumber ? "#56A012" : "#EA4545",
                      fontSize: 10,
                      lineHeight: "14px",
                    }}
                  >
                    {isValidPhoneNumber
                      ? "Valid phone number format!"
                      : "Invalid phone number format!"}
                  </p>
                </div>
              ) : null}
            </FormInputCard>
            {/* <FormInputCard title="SMS OTP" customClass="mb-3 relative">
              <InputDefault
                type="text"
                value={otp.value}
                onChange={handleChangeOtp}
                status={otp.status}
                labelStatus={otp.label}
                placeholder="Enter Verification code"
                pattern="\d+"
              />
              <p
                className={twMerge(
                  "absolute text-[13px] leading-[22px] right-0 top-[-2px]",
                  disableResend.value || !isValidPhoneNumber
                    ? `cursor-not-allowed`
                    : `cursor-pointer hover:underline`
                )}
                style={{
                  color:
                    disableResend.value || !isValidPhoneNumber
                      ? "#7A7A7A"
                      : "#0a5af5",
                }}
                onClick={handleSendSMSOTP}
              >
                {`${isResend.value ? "Resend" : "Send"} OTP ${
                  disableResend.value ? `in (${timeOutResend.value}s)` : ""
                }`}
              </p>
            </FormInputCard> */}
            <FormInputCard
              placement="bottom"
              offset={[60, 10]}
              title="Password"
              isTippy
              label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
              showToolTip={showToolTip}
              visible={isMobile ? showToolTip.value : undefined}
              onClickOutside={() =>
                isMobile ? showToolTip.setValue(false) : undefined
              }
              customClass=""
            >
              <InputDefault
                type="password"
                isPassword
                value={password.value}
                onChange={handleChangeInput}
                onKeyPress={onKeyPress}
                status={statusInputPassword.status}
                labelStatus={statusInputPassword.label}
              />
            </FormInputCard>
          </div>
          <Button
            title="Save"
            widthBtn={"100%"}
            onClick={onClickButtonSave}
            disabled={handleDisableButton()}
            isDisabledBtn
          />
        </div>
      </div>
    </PageLayout>
  )
}
