import { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import CustomCollapse from "pages/project-component-detail/molecules/CustomCollapse"
import GerberViewerCard from "pages/project-component-detail/organisms/GerberViewerCard"
import SpecificationViewerCard from "pages/project-component-detail/organisms/SpecificationViewerCard"
import {
  getIsReadBuildComponentMiddleware,
  getPCBLayerAssignmentsMiddleware,
  getPCBMiddleware,
  getPCBSubBOMMiddleware,
  getHistoriesBuildComponentConvoMiddleware,
} from "pages/project-component-detail/services"
import {
  defaultProjectComponentDetail,
  ProjectComponentDetail,
} from "pages/project-component/types"
import {
  emptyInfoFileDetail,
  PCBDetail,
  ExtendedViewType,
  ComponentType,
  BOMDetail,
  BuildHistoryDetail,
  defaultBuildHistoryDetail,
  PartsSpecificationDetail,
  LayerRender,
} from "pages/project-component-detail/types"
import ListFileInviteeComponentPCBCard from "./ListFileInviteeComponentPCBCard"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { useAppSelector } from "hooks/useApp"
import {
  getConversationMessagesMiddleware,
  getConversationRoleMiddleWare,
} from "pages/conversations/conversations.api"
import { useInviteeComponent } from "./invitees-component.hook"
import { useGerberPCBComponent } from "pages/project-component-detail/project-component-detail.hook"
import {
  acceptedSharingConversationMiddleware,
  getProjectDetailMiddleware,
} from "pages/projects/services/api"
import PageInviteeComponent from "./PageInviteeComponent"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import { SpecificationHeader } from "components/specification/SpecificationHeader"
import { SectionCustom } from "components/Section/SectionCustom"
import {
  converSpecificationsRequice,
  findHistoryTreeDefault,
  getFileContentFromEnrypted,
  getPrivateAssetURI,
} from "helpers/utils"
import { cloneDeep, includes, remove } from "lodash"
import { STATUS_TYPE_FILE_BUILD } from "pages/project-component-detail/build-history.constant"
import { CONVERSATION_ROLE } from "pages/conversations/conversations.type"
import InviteeBOMDetailCard from "./InviteeBOMDetailCard"
import { closeLoading } from "reducers/loading"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { EncryptedDataWarning } from "components/EncryptedDataWarning"

const InviteePCBComponent = () => {
  const params = useParams<{
    idProjectComponent: string
    idProjectBuildComponent: string
    idProjectComponentHistory: string
    idConversation: string
  }>()
  const locationInviteeComponentPCB = useLocation<any>()
  const userInfo = useAppSelector((state) => state.userInfo)

  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const versionHistory = useString("")
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])

  const [detailViewType, setDetailViewType] = useState<ExtendedViewType>(
    ExtendedViewType.HideAll
  )
  const [bomDetail, setBomDetail] = useState<BOMDetail>()
  const updatedAtComponent = useString()
  const [specificationRequired, setSpecificationRequired] = useState<string[]>(
    []
  )
  const [partSpecificationRequice, setPartSpecificationRequice] = useState<
    PartsSpecificationDetail[]
  >([])
  const [layerFiles, setLayerFiles] = useState<LayerRender[]>([])
  const loadedLayers = useBoolean(false)
  const isUpdatingBom = useBoolean()
  const {
    infoFileAssembly,
    infoFileBOM,
    infoFileGerber,
    infoFileStackup,
    isToggleGerber,
    isToggleSpecification,
    layerAssignment,
    layersPCB,
    specifications,
    setLayerAssignment,
    setLayersPCB,
    setInfoFileAssembly,
    setInfoFileBOM,
    setInfoFileGerber,
    setInfoFileStackup,
    setSpecifications,
    canViewGerber,
    setListFileUrl,
    listFileUrl,
  } = useGerberPCBComponent()

  const getLayerData = async (layers: LayerRender[]) => {
    toast(
      <LabelNotificationPage
        messenger="System busy. Please wait.."
        type="warning"
      />
    )
    const layerContents = await Promise.all(
      layers.map(async (layer) => {
        const fileUrl = getPrivateAssetURI(layer.file, {
          conversation_id: params.idConversation,
        })
        const content = (await getFileContentFromEnrypted(
          fileUrl,
          layer.file_name,
          true,
          undefined,
          undefined,
          historyDetail.decryptedShareKey
        )) as any
        const decoder = new TextDecoder("utf-8")
        const svgContent = decoder.decode(content)
        const svgBlob = new Blob([svgContent], { type: "image/svg+xml" })
        return {
          ...layer,
          file: URL.createObjectURL(svgBlob),
        }
      })
    )
    loadedLayers.setValue(true)
    closeLoading()
    setLayersPCB(layerContents)
  }
  useEffect(() => {
    if (isToggleGerber.value && layerFiles && !loadedLayers.value) {
      getLayerData(layerFiles)
    }
  }, [isToggleGerber.value])

  const updatedAtProjectComponent = useString("")
  const {
    conversationMessages,
    idConversationParam,
    idProjectBuildComponentParam,
    idProjectComponentParam,
    isReadComponent,
    nameBOM,
    projectBuild,
    projectDetail,
    setProjectDetail,
    setConversationMessages,
    setProjectBuild,
    idComponentHistory,
    conversationRole,
    setConversationRole,
  } = useInviteeComponent(params)
  useEffect(() => {
    loadedLayers.setValue(false)
  }, [idComponentHistory.value])

  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail?.build_status === BuildStatusNumber.DELETED_ATTACHMENT

  useEffect(() => {
    if (
      !idProjectComponentParam ||
      // !idProjectComponentHistoryParam ||
      !idProjectBuildComponentParam ||
      !idConversationParam
    ) {
      pushTo(PATH.conversations)
      return
    }
    getProjectComponentDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponentParam,
    idProjectBuildComponentParam,
    idConversationParam,
  ])

  const getSubBomDetail = async (
    idHistory: string,
    decryptedShareKey?: string
  ) => {
    const newDecryptedShareKey =
      decryptedShareKey || historyDetail.decryptedShareKey
    isUpdatingBom.setValue(false)
    await getPCBSubBOMMiddleware(
      idHistory,
      idConversationParam,
      newDecryptedShareKey
    )
      .then((res) => {
        setBomDetail(res)
      })
      .catch(() => {
        setBomDetail(undefined)
      })
  }

  const getProjectComponentDetail = async () => {
    isLoading.setValue(true)
    try {
      await getConversationMessages(idConversationParam)
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponentParam,
        idConversationParam
      )
      setComponentDetail(dataComponentRes)
      await getProjectBuildComponent(idProjectBuildComponentParam)
      if (dataComponentRes.type.key !== TYPE_PROJECT_COMPONENT.PCB) {
        pushTo(PATH.conversations)
        return
      }
      await getConversationRole()
      updatedAtProjectComponent.setValue(dataComponentRes.updated_at)
      await getIsReadProjectBuildComponent(idProjectBuildComponentParam)
      setTimeout(async () => {
        if (!isDeleted) {
          await getHistories(dataComponentRes.project_id)
          await getPCBLayerAssignments()
        }
      }, 200)
      nameBOM.setValue(dataComponentRes.name.replace(" ", "_"))
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.conversations)
    }
  }
  const getConversationRole = async () => {
    const role = await getConversationRoleMiddleWare(
      idProjectBuildComponentParam,
      idConversationParam
    )
    setConversationRole(role.role)
  }

  const getDataHistoryDetail = async (
    newDataRes: BuildHistoryDetail[],
    findItemCommit = true
  ) => {
    const { newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationInviteeComponentPCB
    )
    if (newHistoryDetail.code) {
      versionHistory.setValue(newHistoryDetail.code)
    }
    idComponentHistory.setValue(newHistoryDetail.id)
    setHistoryDetail(newHistoryDetail)
    await getSubBomDetail(
      newHistoryDetail.id,
      newHistoryDetail.decryptedShareKey
    )
    await getPCB(newHistoryDetail.id)
  }
  const getProjectBuildComponent = async (idProjectBuildComponent: string) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      idProjectBuildComponent,
      idConversationParam
    )
    setProjectBuild(dataRes)
    if (
      locationInviteeComponentPCB.state &&
      Boolean(locationInviteeComponentPCB?.state?.breadcrumpProject)
    ) {
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataRes.project_id,
        idConversationParam
      )
      setProjectDetail(dataProjectDetailRes)
    }
  }
  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent,
      idConversationParam
    )
    isReadComponent.setValue(dataRes.is_read_invitee_comment)
  }

  const getPCB = useCallback(
    async (oldIdComponentHistory: string, loading = false) => {
      try {
        const dataPCB = await getPCBMiddleware(
          oldIdComponentHistory,
          idConversationParam
        )
        setLayerFiles(dataPCB.tempLayers)
        convertDataPCB(dataPCB)
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        setInfoFileGerber(emptyInfoFileDetail)
        setInfoFileBOM(emptyInfoFileDetail)
        setInfoFileStackup(emptyInfoFileDetail)
        setInfoFileAssembly(emptyInfoFileDetail)
        setLayersPCB([])
        setListFileUrl([])
        setSpecifications([])
        canViewGerber.setValue(true)
        if (loading) {
          isLoading.setValue(false)
        }
      }
    },
    [
      setInfoFileGerber,
      setInfoFileBOM,
      setInfoFileStackup,
      setInfoFileAssembly,
      setListFileUrl,
      setLayersPCB,
      setSpecifications,
      canViewGerber,
      isLoading,
    ]
  )
  const getHistories = async (
    projectId: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getHistoriesBuildComponentConvoMiddleware(
        projectId,
        idProjectBuildComponentParam,
        idConversationParam
      )
      setHistories(dataRes)
      if (dataRes.length) {
        await getDataHistoryDetail(dataRes, findItemCommit)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }

  const convertDataPCB = (dataPCB: PCBDetail) => {
    setInfoFileGerber({
      file: dataPCB.zip_file,
      name: dataPCB.file_name,
    })
    setInfoFileBOM({
      file: dataPCB.bom_file,
      name: dataPCB.bom_file_name,
    })
    setInfoFileStackup({
      file: dataPCB.stackup_file,
      name: dataPCB.stackup_file_name,
    })
    setInfoFileAssembly({
      file: dataPCB.assembly_file,
      name: dataPCB.assembly_file_name,
    })
    setListFileUrl(dataPCB.files)
    // setLayersPCB(dataPCB.layers ?? [])
    setSpecifications(dataPCB.specifications ?? [])
    canViewGerber.setValue(dataPCB.can_view_gerber)
    if (dataPCB.specifications.length) {
      const dataPartsRequice = dataPCB.specifications[0].parts.filter((part) =>
        Boolean(part.required)
      )
      setPartSpecificationRequice(dataPartsRequice)
      setSpecificationRequired(converSpecificationsRequice(dataPartsRequice))
    }
  }
  const getConversationMessages = async (
    conversationId: string,
    loading = false
  ) => {
    const dataRes = await getConversationMessagesMiddleware(conversationId)
    if (dataRes.is_conversation_un_active) {
      pushTo(PATH.notFound)
      return
    }
    setConversationMessages(dataRes)
    if (!dataRes.invitee.accepted && !dataRes.is_owner) {
      await acceptedSharingConversationMiddleware(conversationId)
    }
    if (loading) {
      isLoading.setValue(false)
    }
  }

  const getPCBLayerAssignments = async () => {
    try {
      const dataRes = await getPCBLayerAssignmentsMiddleware(
        idConversationParam
      )
      setLayerAssignment(
        dataRes.map((el) => {
          return {
            ...el,
            label: el.key,
          }
        })
      )
    } catch (error) {
      setLayerAssignment([])
    }
  }
  const updatedAtComponentDetail = (updatedAt: string) => {
    updatedAtComponent.setValue(updatedAt)
  }
  // updatedAtComponentDetail(new Date().toISOString())

  const handleUpdateDataPCB = (
    oldIdComponentHistory: string,
    type?: string
  ) => {
    isLoading.setValue(true)
    getPCB(oldIdComponentHistory, true)
    if (type === STATUS_TYPE_FILE_BUILD.GERBER) {
      isToggleGerber.setValue(false)
      isToggleSpecification.setValue(false)
    }
    updatedAtComponentDetail(new Date().toISOString())
  }

  const handleDeleteAttachment = (oldIdFile: string) => {
    const newFileURls = cloneDeep(listFileUrl)
    remove(newFileURls, (el) => el.id === oldIdFile)
    setListFileUrl(newFileURls)
    updatedAtComponentDetail(new Date().toISOString())
  }
  const viewOnly =
    includes(
      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED, STATUS_BUILD.SAVE],
      historyDetail.status
    ) || conversationRole !== CONVERSATION_ROLE.INVITEE
  const handleUpdateDataWhenChangeHistory = (getFirst = true) => {
    isLoading.setValue(true)
    getHistories(componentDetail.project_id, true, getFirst)
    updatedAtComponentDetail(new Date().toISOString())
  }
  const updateDataWhenChangeColumn = async (callback: () => void) => {
    isLoading.setValue(true)
    await getSubBomDetail(
      idComponentHistory.value,
      historyDetail.decryptedShareKey
    )
    updatedAtProjectComponent.setValue(new Date().toISOString())
    callback()
  }
  const renderLayout = () => {
    if (!historyDetail.id) {
      return null
    }
    if (!historyDetail.decryptedShareKey) {
      return (
        <EncryptedDataWarning
          type="component"
          name={`ID: ${componentDetail.code}`}
          id={`${componentDetail.id} in Conversation ID: ${conversationMessages.conversation_code}`}
          isEncrypted={true}
        />
      )
    }
    return (
      <SectionCustom>
        {/* {histories.length !== 0 && (
          <HistoryTreeCard<EncryptedDataWarning
            histories={histories}
            historyDetail={historyDetail}
            handleChangeHistoryDetail={handleChangeHistoryDetail}
            typeComponent={componentDetail.type.key}
            titlePage={"build-component"}
          />
        )} */}
        <div className="mt-1 pb-2 md:pb-0 mr-6 md:mr-0 ">
          <ListFileInviteeComponentPCBCard
            idComponentHistory={idComponentHistory.value}
            infoFileAssembly={infoFileAssembly}
            listFileUrl={listFileUrl}
            infoFileBOM={{
              ...infoFileBOM,
              // downloadUri: `${API_URL}/api/pcb/${historyDetail.id}/${params.idConversation}/export-bom`,
            }}
            infoFileGerber={infoFileGerber}
            infoFileStackup={infoFileStackup}
            detailViewType={detailViewType}
            setDetailViewType={setDetailViewType}
            isDeleted={isDeleted}
            conversationId={idConversationParam}
            handleUpdateBomFile={getSubBomDetail}
            handleDeleteAttachment={handleDeleteAttachment}
            handleUpdateDataPCB={handleUpdateDataPCB}
            viewOnly={viewOnly}
            decryptedShareKey={historyDetail.decryptedShareKey}
          />
        </div>

        <div
          className={`flex flex-col mt-6 mr-6 ${
            detailViewType !== ExtendedViewType.Gerber ? "hidden" : ""
          }`}
        >
          <CustomCollapse
            changeHeaderBorder={false}
            buttonClass="border-0"
            isToggle={isToggleGerber.value}
            isDisableToggle={infoFileGerber.file ? false : true}
            handleChangeToggle={(newToggle: boolean) =>
              isToggleGerber.setValue(newToggle)
            }
            header={
              <SpecificationHeader
                title={"Gerber viewer"}
                isExpanded={isToggleGerber.value}
              />
            }
            childrenBody={
              <GerberViewerCard
                canViewGerber={canViewGerber.value}
                layersPCB={layersPCB}
                layerAssignment={layerAssignment}
                isToggleGerber={isToggleGerber.value}
                viewOnly={viewOnly}
                isInvitee={true}
                conversationId={idConversationParam}
                isEncrypted={projectDetail.is_encrypted}
              />
            }
          />
          <div className="mt-6">
            <CustomCollapse
              changeHeaderBorder={false}
              buttonClass="border-0"
              isToggle={isToggleSpecification.value}
              isDisableToggle={!specifications.length}
              handleChangeToggle={(newToggle: boolean) =>
                isToggleSpecification.setValue(newToggle)
              }
              childrenBody={
                <SpecificationViewerCard
                  partSpecificationRequice={partSpecificationRequice}
                  setPartSpecificationRequice={setPartSpecificationRequice}
                  setSpecificationRequired={setSpecificationRequired}
                  specifications={specifications}
                  idComponentHistory={idComponentHistory.value}
                  viewOnly={viewOnly}
                  isToggleSpecification={isToggleSpecification.value}
                  conversationId={idConversationParam}
                  handleUpdateSpecifications={handleUpdateDataPCB}
                  isInvitee={true}
                  decryptedShareKey={historyDetail.decryptedShareKey || ""}
                  isUploadSpecification={includes(
                    [STATUS_BUILD.DRAFT],
                    historyDetail.status
                  )}
                  isShowErrorSpecification={false}
                />
              }
              header={
                <SpecificationHeader
                  title={"Specification"}
                  isExpanded={isToggleGerber.value}
                />
              }
            />
          </div>
        </div>
        <div
          className={`flex flex-col mt-6 mr-6 ${
            !bomDetail || detailViewType !== ExtendedViewType.Bom
              ? "hidden"
              : ""
          }`}
        >
          <InviteeBOMDetailCard
            BOMJson={bomDetail?.bom_json || []}
            projectComponentHistory={historyDetail}
            updatedAtBOM={bomDetail?.updated_at || ""}
            isInvitee={true}
            isCloseConversation={false}
            updateDataWhenChangeColumn={updateDataWhenChangeColumn}
            nameBOM={infoFileBOM.name}
            additionalJson={bomDetail?.additional_json || []}
            inviteeBoms={[]}
            componentType={ComponentType.PCB}
            isAddColumn={isUpdatingBom}
            conversationId={idConversationParam}
            conversationRole={conversationRole}
          />
        </div>
      </SectionCustom>
    )
  }
  return (
    <PageInviteeComponent
      componentDetail={componentDetail}
      updatedAtProjectComponent={updatedAtProjectComponent.value}
      projectBuild={projectBuild}
      idConversationParam={idConversationParam}
      idProjectBuildComponentParam={idProjectBuildComponentParam}
      conversationMessages={conversationMessages}
      projectDetail={projectDetail}
      idProjectComponentHistoryParam={historyDetail.id}
      nameBOM={nameBOM.value}
      userInfo={userInfo}
      isReadComponent={isReadComponent}
      isLoading={isLoading.value}
      histories={histories}
      versionHistory={historyDetail}
      setHistoryDetail={setHistoryDetail}
      isDeleted={isDeleted}
      handleUpdateDataWhenChangeHistory={handleUpdateDataWhenChangeHistory}
      isUpdatingBom={isUpdatingBom.value}
      commitButtonStage={{
        isActive:
          infoFileGerber.file &&
          infoFileGerber.name &&
          historyDetail.status === STATUS_BUILD.DRAFT
            ? true
            : false,
        tooltipHelper: "Please upload gerber",
      }}
      specificationRequired={specificationRequired}
      conversationRole={conversationRole}
    >
      {renderLayout()}
    </PageInviteeComponent>
  )
}

export default InviteePCBComponent
