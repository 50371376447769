import { STATUS_INPUT } from "components/Input/types"
import { useBoolean, useNumber } from "helpers/hooks"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { MFAMethod } from "../types"
import { resendCodeTwoFactor, verifyTwoFactor } from "../services/api"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { toast } from "react-toastify"
import Header from "../atoms/Header"
import InputDefault from "components/Input/InputDefault"
import Button from "components/Button/Button"
import { includes } from "lodash"

interface Props {
  oneTimeToken: string
  setOneTimeToken: (data: string) => void
  onVerifySuccess?: () => void
  mfaMethods: number[]
  labelMfa: {
    maskEmail: string
    maskPhone: string
  }
  isResetPassword?: boolean
  originMFAMethod: MFAMethod
}

export const TwoFactorForm = (props: Props) => {
  const {
    oneTimeToken,
    setOneTimeToken,
    mfaMethods,
    isResetPassword,
    onVerifySuccess,
    labelMfa,
    originMFAMethod,
  } = props
  const location = useLocation<any>()
  const [input, setInput] = useState({
    status: STATUS_INPUT.ERROR,
    label: "Verification code required",
    value: "",
  })
  const currentMFAMethod = useNumber(MFAMethod.authenticatorApp)
  const disableResend = useBoolean(false)
  const timeOutResend = useNumber(30)
  const timeResendInterval = useRef<any>(null)
  useEffect(() => {
    if (mfaMethods.length) {
      let newMethod: MFAMethod = MFAMethod.email

      if (originMFAMethod) {
        newMethod = originMFAMethod
      } else {
        if (mfaMethods.includes(MFAMethod.authenticatorApp)) {
          newMethod = MFAMethod.authenticatorApp
        } else if (mfaMethods.includes(MFAMethod.sms)) {
          newMethod = MFAMethod.sms
        }
      }

      currentMFAMethod.setValue(newMethod)

      if (includes([MFAMethod.email, MFAMethod.sms], newMethod)) {
        disableResend.setValue(true)
        countTimeResend(30)
      }
    }
  }, [JSON.stringify(mfaMethods), originMFAMethod])

  const onSubmit = async () => {
    if (isResetPassword) {
      verifyTwoFactor(
        input.value,
        oneTimeToken,
        currentMFAMethod.value,
        isResetPassword
      )
        .then(() => {
          if (onVerifySuccess) {
            onVerifySuccess()
          }
        })
        .catch((error) => {
          const message =
            error.response?.data?.message ?? "Something went wrong!"
          toast(<LabelNotificationPage messenger={message} type="error" />)
        })
      return
    }
    verifyTwoFactor(
      input.value,
      oneTimeToken,
      currentMFAMethod.value,
      isResetPassword
    )
      .then((response) => {
        localStorage.setItem("access_token", response.token)
        if (location.state?.redirectUrl) {
          pushTo(location.state.redirectUrl)
        } else {
          pushTo(PATH.projects)
        }
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }

  const handleChangeInput = (event: any) => {
    const value = event.target.value
    let status = STATUS_INPUT.DEFAULT
    let label = ""
    if (value.length < 6) {
      label = "Verification code must be a 6 digit number"
      status = STATUS_INPUT.ERROR
    }
    if (value.length === 0) {
      label = "Verification code required"
    }
    setInput({ value, status, label })
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onSubmit()
    }
  }

  const handleDisableButton = () => {
    /// 6-digit code
    if (input.value.length !== 6) {
      return true
    }
    return false
  }

  const countTimeResend = (time: number) => {
    if (timeResendInterval.current) {
      clearInterval(timeResendInterval.current)
    }
    let timeString = time
    timeResendInterval.current = setInterval(() => {
      const currentTime = timeString - 1
      if (currentTime === 0) {
        disableResend.setValue(false)
        clearInterval(timeResendInterval.current)
        return
      }
      timeString = currentTime
      timeOutResend.setValue(timeString)
    }, 1000)
  }

  const onResendCode = (newMethod: MFAMethod) => {
    resendCodeTwoFactor(oneTimeToken, newMethod)
      .then((response) => {
        setOneTimeToken(response.token)
        countTimeResend(30)
        timeOutResend.setValue(30)
        disableResend.setValue(true)
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }

  const methodOptions = [
    {
      title: "Email OTP",
      value: MFAMethod.email,
    },
    {
      title: "Google Authenticator",
      value: MFAMethod.authenticatorApp,
    },

    {
      title: "SMS OTP",
      value: MFAMethod.sms,
    },
  ]
  const filteredMethodOptions = methodOptions.filter((el) =>
    includes(mfaMethods, el.value)
  )

  const renderLabel = () => {
    const classLabel = "text-sm font-normal leading-[24px] text-[#111111]"
    switch (currentMFAMethod.value) {
      case MFAMethod.authenticatorApp:
        return (
          <p className={classLabel}>
            Please enter the 6-digit code from your Google Authenticator app to
            log in.
          </p>
        )
      case MFAMethod.sms:
        return (
          <p className={classLabel}>
            Enter the OTP sent to{" "}
            <span className="font-semibold">{labelMfa.maskPhone}</span> via SMS
            to log in. This code will be valid for 5 minutes.
          </p>
        )
      default:
        return (
          <p className={classLabel}>
            Enter the OTP sent to{" "}
            <span className="font-semibold">{labelMfa.maskEmail}</span> to log
            in. This code will be valid for 5 minutes.
          </p>
        )
    }
  }

  return (
    <div className="relative">
      <Header title="Enter authentication code" />
      <div
        className="flex items-center  border-b border-[#E4E4E4] my-[24px]"
        style={{
          justifyContent:
            filteredMethodOptions.length === 3 ? "space-between" : "normal",
        }}
      >
        {filteredMethodOptions.map((el, index) => {
          const active = currentMFAMethod.value === el.value
          return (
            <div
              key={index}
              className={`cursor-pointer whitespace-nowrap min-h-32 border-solid border-b-[2px]  ${
                active ? "border-[#F7AC1B]" : "border-[#fff]"
              }`}
              style={{
                marginRight: filteredMethodOptions.length === 3 ? 0 : 24,
              }}
              onClick={() => {
                if (active) {
                  return
                }
                currentMFAMethod.setValue(el.value)
                setInput({
                  status: STATUS_INPUT.ERROR,
                  label: "Verification code required",
                  value: "",
                })
                if (el.value !== MFAMethod.authenticatorApp) {
                  onResendCode(el.value)
                }
              }}
            >
              <p
                className="font-semibold text-sm leading-[24px]"
                style={{
                  color: active ? "#111111" : "#7A7A7A",
                }}
              >
                {el.title}
              </p>
            </div>
          )
        })}
      </div>
      {renderLabel()}
      <div className="mt-6">
        <div className="flex items-center justify-between mb-1">
          <p className="text-sm font-semibold leading-[24px] text-[#111111]">
            {currentMFAMethod.value === MFAMethod.authenticatorApp
              ? "Authentication code"
              : "OTP"}
          </p>
          {includes(
            [MFAMethod.email, MFAMethod.sms],
            currentMFAMethod.value
          ) ? (
            <p
              className={
                disableResend.value
                  ? `cursor-not-allowed text-[13px] leading-[24px]`
                  : `text-[13px] leading-[24px] cursor-pointer hover:underline`
              }
              style={{
                color: disableResend.value ? "#7A7A7A" : "#0a5af5",
              }}
              onClick={() => {
                if (disableResend.value) {
                  return
                }
                onResendCode(currentMFAMethod.value)
              }}
            >
              {`Resend OTP ${
                disableResend.value ? `in (${timeOutResend.value}s)` : ""
              }`}
            </p>
          ) : null}
        </div>
        <InputDefault
          type="text"
          value={input.value}
          onChange={handleChangeInput}
          status={input.status}
          labelStatus={input.label}
          onKeyPress={onKeyPress}
          placeholder="Enter Verification code"
          pattern="\d+"
        />
      </div>
      <Button
        title="Verify"
        onClick={onSubmit}
        isDisabledBtn
        widthBtn="100%"
        disabled={handleDisableButton()}
        className="mt-6"
      />
    </div>
  )
}
