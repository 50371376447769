import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import {
  BUILD_INVITEE_STATUS,
  emptyInviteeBuildComponentDetail,
  InviteeBuilComponentDetail,
  ProjectBuildInvitessDetail,
  SendComponentInviteeRequest,
} from "../project-build.type"
import { ReactComponent as IconButton } from "assets/images/icons/icon-button-create.svg"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean, useNumber } from "helpers/hooks"
import React, { useContext, useEffect, useRef, useState } from "react"
import { HashLoader } from "react-spinners"
import {
  deleteProjectBuildInviteeAddComponentMiddleware,
  getProjectBuildInviteeComponentMiddleware,
  postProjectBuildInviteeActivateMiddleware,
  postProjectBuildInviteeAddComponentMiddleware,
  postProjectBuildInviteeSyncMiddleware,
  postProjectBuildSendInviteeMiddleware,
  postUploadFileInviteeMiddleware,
} from "../api.services"
import ComponentBuildItemCard from "../molecules/ComponentBuildItemCard"
import { KeyEncryptionType, STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { cloneDeep, includes, isUndefined, remove } from "lodash"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import Button from "components/Button/Button"
import { ProjectComponentStatus } from "components/Status/types"
import { umamiTracking, customLocalStorageHandler } from "helpers/utils"
import { EVENT } from "constants/events"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import { useAppSelector } from "hooks/useApp"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import ActionItem from "pages/projects/molecules/ActionItem"
import { MENU_TAB_CONVERSATION } from "pages/conversations/conversations.type"
import { ReactComponent as IconMessagerDeleteComponent } from "assets/images/icons/icon-messager-delete-component.svg"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { useAddComponentInInvitee } from "hooks/useAddComponentInInvitee"
import { MessageBackupType } from "components/FormBackup/type"
import { FormBackup } from "components/FormBackup/FormBackup"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { FormSendInvitation } from "./FormSendInvitation"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"
import forge from "node-forge"
import { createEncryptionKeysMiddleware } from "pages/projects/services/api"

interface Props {
  originInvites: ProjectBuildInvitessDetail
  sumComponent: number
  onEditInvites: (oldInvite: ProjectBuildInvitessDetail) => () => void
  isViewShare: boolean
  handleChangeData: (newInvitees?: ProjectBuildInvitessDetail) => void
  isDeleted?: boolean
}
const InviteesItemCard = (props: Props) => {
  const {
    originInvites,
    onEditInvites,
    isViewShare,
    handleChangeData,
    sumComponent,
    isDeleted,
  } = props
  const { projectBuild } = useContext(PermissionProjectBuildPage)
  const userInfo = useAppSelector((state) => state.userInfo)
  const showTippyAddComponent = useBoolean(false)
  const refSelectComponent = useRef<HTMLDivElement>(null)
  const isLoadingComponent = useBoolean(false)
  const isLoading = useBoolean(false)
  const [buildComponents, setBuildComponents] = useState<
    InviteeBuilComponentDetail[]
  >([])
  const [inviteBuilComponent, setInviteBuilComponent] = useState<
    InviteeBuilComponentDetail[]
  >([])
  const [oldBuildComponent, setOldBuildComponent] =
    useState<InviteeBuilComponentDetail>(emptyInviteeBuildComponentDetail)
  const isFormDeleteComponent = useBoolean(false)
  const statusInvites = useNumber(BUILD_INVITEE_STATUS.ACTIVATE)
  const isSyncComponent = useBoolean()

  const deactivateTab = statusInvites.value === BUILD_INVITEE_STATUS.DEACTIVATE
  const { handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATIONS
  )
  const {
    createNewComponentHistoryByOwner,
    syncNewVersionComponentInvitee,
    messageBackups,
    currentMessageBackup,
    errorMessageBackup,
    isLoadingBackupInvitee,
    setCurrentMessageBackup,
    setMessageBackups,
  } = useAddComponentInInvitee()

  const [invitation, setInvitation] = useState<{
    invitee: {
      name: string
      email: string
      isSent: boolean
      accepted: boolean
      is_just_sent: boolean
    }
    ccs: {
      email: string
      isSent: boolean
      accepted: boolean
      is_just_sent: boolean
    }[]
  }>({
    invitee: {
      accepted: false,
      name: "",
      email: "",
      isSent: false,
      is_just_sent: false,
    },
    ccs: [],
  })

  const showSendInvitation = useBoolean()
  const hasSend = useBoolean()
  const showTippyAction = useBoolean()
  useEffect(() => {
    setInviteBuilComponent(originInvites.project_build_components)
    statusInvites.setValue(
      originInvites.status === BUILD_INVITEE_STATUS.DEACTIVATE
        ? BUILD_INVITEE_STATUS.DEACTIVATE
        : BUILD_INVITEE_STATUS.ACTIVATE
    )
    setInvitation({
      invitee: {
        name: originInvites.name,
        email: originInvites.email,
        isSent: originInvites.is_sent_invitee || false,
        accepted: originInvites.accepted || false,
        is_just_sent: originInvites.is_invitee_just_sent || false,
      },
      ccs: originInvites.publicKeyCCs?.length
        ? originInvites.publicKeyCCs?.map((el) => ({
            email: el.email,
            isSent: el.is_sent || false,
            accepted: el.accepted || false,
            is_just_sent: el.is_just_sent || false,
          }))
        : [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originInvites])

  const onShowComponent = async () => {
    showTippyAddComponent.setValue(!showTippyAddComponent.value)
    if (!originInvites.id || showTippyAddComponent.value) {
      return
    }
    isLoadingComponent.setValue(true)
    try {
      const dataRes = await getProjectBuildInviteeComponentMiddleware(
        originInvites.id
      )
      setBuildComponents(dataRes)
      isLoadingComponent.setValue(false)
    } catch (error) {
      isLoadingComponent.setValue(false)
    }
  }
  const onClickOutsideAddComponent = () => {
    if (isLoadingComponent.value) {
      return
    }
    showTippyAddComponent.setValue(false)
    setBuildComponents([])
  }

  const onClickAddComponentInInvitess =
    (oldComponent: InviteeBuilComponentDetail) => async () => {
      if (!oldComponent.project_build_component_id || !originInvites.id) {
        return
      }
      errorMessageBackup.setValue(false)
      let dataComponentRequest: SendComponentInviteeRequest = {
        commit: "",
        componentType: oldComponent.type,
      }
      let consoleSharedKey = ""
      isLoadingComponent.setValue(true)
      if (!oldComponent.is_history_from) {
        const messageBackups = [
          {
            content: `Retrieve data from components: ${oldComponent.code}`,
            type: MessageBackupType.RETRIEVE_BACKUP_VERSION,
          },
          {
            content: "Decrypting data context and files",
            type: MessageBackupType.DECRYPTING_BACKUP_VERSION,
          },
          {
            content: "Encrypting data context and files",
            type: MessageBackupType.ENCRYPTING_BACKUP_VERSION,
          },
          {
            content: "Creating share version",
            type: MessageBackupType.CREATING_BACKUP_FILE_VERSION,
          },
          {
            content: "Sync version to invitees",
            type: MessageBackupType.DONE_BACKUP_VERSION,
          },
        ]
        setMessageBackups(messageBackups)
        setCurrentMessageBackup(MessageBackupType.RETRIEVE_BACKUP_VERSION)
        setTimeout(() => {
          setCurrentMessageBackup(MessageBackupType.DECRYPTING_BACKUP_VERSION)
        }, 200)
        isLoadingBackupInvitee.setValue(true)
        const newComponent = await createNewComponentHistoryByOwner(
          projectBuild.project_id,
          oldComponent.type,
          oldComponent.project_component_history_id
        )
        dataComponentRequest = newComponent.dataComponentRequest
        consoleSharedKey = newComponent.sharedKey
      } else {
        if (oldComponent.componentShareKey) {
          const projectKeys = JSON.parse(
            localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
          )
          const projectKey = projectKeys[projectBuild.project_id]
          const sharedKey = await encryptionController().decrypt(
            oldComponent.componentShareKey,
            {
              dataType: "string",
              type: "component-shared",
              encryptionKey: projectKey,
            }
          )
          consoleSharedKey = sharedKey
        }
      }
      setCurrentMessageBackup(MessageBackupType.ENCRYPTING_BACKUP_VERSION)
      setCurrentMessageBackup(MessageBackupType.CREATING_BACKUP_FILE_VERSION)
      console.log("-------START SHARE COMPONENT-----------")
      console.log("Conversation code", originInvites.conversation_code)
      console.log("Conversation ID", originInvites.conversation_id)
      console.log("Share key", consoleSharedKey)
      console.log(
        "Invitee",
        `${originInvites.name} / ${originInvites.email} / ${originInvites.user_id}`
      )
      console.log("CC", originInvites.cc)
      console.log("-------END SHARE COMPONENT-----------")
      postProjectBuildInviteeAddComponentMiddleware(
        originInvites.id,
        oldComponent.project_build_component_id,
        `${oldComponent.code}/${oldComponent.version}`,
        originInvites.conversation_id,
        dataComponentRequest,
        async (
          type: STATUS_RESPONSE,
          messenger: string,
          dataResponse?: InviteeBuilComponentDetail
        ) => {
          isLoadingComponent.setValue(false)
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          if (type === STATUS_RESPONSE.SUCCESS && dataResponse) {
            setCurrentMessageBackup(MessageBackupType.DONE_BACKUP_VERSION)
            setTimeout(() => {
              isLoadingBackupInvitee.setValue(false)
            }, 2000)

            const newBuildComponents = cloneDeep(buildComponents)
            remove(
              newBuildComponents,
              (el) =>
                el.project_build_component_id ===
                oldComponent.project_build_component_id
            )
            handleChangeData()
            setBuildComponents(newBuildComponents)
            if (!newBuildComponents.length) {
              showTippyAddComponent.setValue(false)
            }
            if (
              consoleSharedKey &&
              Boolean(originInvites.is_sent) &&
              dataResponse.project_component_history_share_id
            ) {
              const keys: {
                encryptedKey: string
                user_id: string
              }[] = []
              if (originInvites.publicKey) {
                const publicInviteeKey = forge.pki.publicKeyFromPem(
                  originInvites.publicKey
                )
                const encryptInviteeSharedKey = publicInviteeKey.encrypt(
                  consoleSharedKey,
                  "RSA-OAEP"
                )
                keys.push({
                  encryptedKey: encryptInviteeSharedKey,
                  user_id: originInvites.user_id,
                })
              }
              if (
                originInvites.publicKeyCCs &&
                originInvites.publicKeyCCs.length
              ) {
                originInvites.publicKeyCCs.forEach((cc) => {
                  if (cc.is_sent) {
                    const publicCCKey = forge.pki.publicKeyFromPem(cc.publicKey)
                    const encryptCCSharedKey = publicCCKey.encrypt(
                      consoleSharedKey,
                      "RSA-OAEP"
                    )
                    keys.push({
                      encryptedKey: encryptCCSharedKey,
                      user_id: cc.user_id,
                    })
                  }
                })
              }
              if (keys.length) {
                await createEncryptionKeysMiddleware(
                  keys.map((el) => {
                    return {
                      encrypted_key: el.encryptedKey,
                      model: "project_build_components",
                      relation_id:
                        dataResponse.project_component_history_share_id || "",
                      type: KeyEncryptionType.ComponentShareKey,
                      user_id: el.user_id,
                    }
                  })
                )
              }
            }
          } else {
            isLoadingBackupInvitee.setValue(false)
          }
        }
      )
    }

  const onClickDeleteComponentInInvitess =
    (oldComponent: InviteeBuilComponentDetail) => async () => {
      if (!oldComponent.id) {
        return
      }
      const isSent =
        invitation.invitee.isSent || invitation.ccs.some((cc) => cc.isSent)

      if (Boolean(isSent)) {
        setOldBuildComponent(oldComponent)
        isFormDeleteComponent.setValue(true)
        return
      }
      showTippyAddComponent.setValue(false)
      deleteComponent(oldComponent)
    }
  const onDeleteComponentForm = () => {
    if (!oldBuildComponent.id) {
      return
    }
    deleteComponent(oldBuildComponent)
  }
  const deleteComponent = async (oldComponent: InviteeBuilComponentDetail) => {
    isLoading.setValue(true)
    try {
      await deleteProjectBuildInviteeAddComponentMiddleware(
        oldComponent.id,
        `${oldComponent.code}/${oldComponent.version}`,
        originInvites.conversation_id
      )
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.DELETE_COMPONENT_INVITEE_SUCCESS}
          type="success"
        />
      )
      const newBuildComponents = cloneDeep(buildComponents)
      const newInvitesComponent = cloneDeep(inviteBuilComponent)
      remove(newInvitesComponent, (el) => el.id === oldComponent.id)
      newBuildComponents.push(oldComponent)
      setInviteBuilComponent(newInvitesComponent)
      setBuildComponents(newBuildComponents)
      isFormDeleteComponent.setValue(false)
      setOldBuildComponent(emptyInviteeBuildComponentDetail)
    } catch (error: any) {
      toast(
        <LabelNotificationPage
          messenger={
            error.response?.data?.message ??
            MESSENGER_NOTIFICATION.DELETE_COMPONENT_INVITEE_ERROR
          }
          type="error"
        />
      )
    }
  }

  const openSendInvite = async () => {
    if (!originInvites.id) {
      return
    }

    if (originInvites.email === "") {
      return toast(
        <LabelNotificationPage
          messenger="Please update invitee email!"
          type="warning"
        />
      )
    }
    showSendInvitation.setValue(true)
    hasSend.setValue(false)
  }
  const onSubmitSendInvitation =
    (title: string, emails: string[]) => async () => {
      if (!emails.length) {
        return
      }
      umamiTracking(EVENT.INVITEE.SEND_INVITATION)

      const generateDataKeyPairUsers = await syncNewVersionComponentInvitee(
        inviteBuilComponent,
        projectBuild.project_id,
        originInvites,
        projectBuild.id,
        emails
      )
      showTippyAddComponent.setValue(false)
      isLoading.setValue(true)
      configureStore.dispatch(startLoading())
      setTimeout(() => {
        postProjectBuildSendInviteeMiddleware(
          originInvites.id,
          title,
          (type: STATUS_RESPONSE, messenger: string) => {
            isLoading.setValue(false)
            toast(<LabelNotificationPage messenger={messenger} type={type} />)
            if (type === STATUS_RESPONSE.SUCCESS) {
              setCurrentMessageBackup(MessageBackupType.DONE_BACKUP_VERSION)
              setTimeout(() => {
                isLoadingBackupInvitee.setValue(false)
              }, 2000)
              hasSend.setValue(true)
              const newInvitation = cloneDeep(invitation)
              if (includes(emails, newInvitation.invitee.email)) {
                newInvitation.invitee.accepted =
                  !newInvitation.invitee.isSent &&
                  newInvitation.invitee.accepted
                    ? false
                    : newInvitation.invitee.accepted
                newInvitation.invitee.isSent = true
                newInvitation.invitee.is_just_sent = true
              }
              newInvitation.ccs.forEach((item) => {
                if (includes(emails, item.email)) {
                  item.accepted =
                    !item.isSent && item.accepted ? false : item.accepted

                  item.isSent = true
                  item.is_just_sent = true
                }
              })
              setInvitation(newInvitation)
            }
          },
          emails,
          generateDataKeyPairUsers
        )
      }, 500)
    }
  const onChangeStatusInvitees = async () => {
    if (!originInvites.id) {
      return
    }
    showTippyAction.setValue(false)
    isLoading.setValue(true)
    const newStatus =
      statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE
        ? BUILD_INVITEE_STATUS.DEACTIVATE
        : BUILD_INVITEE_STATUS.ACTIVATE
    let generateDataKeyPairUsers: {
      publicKey: string
      encryptedPrivateKey: string
      userId: string
    }[] = []

    if (newStatus === BUILD_INVITEE_STATUS.ACTIVATE) {
      const emailSendKey: string[] = []
      if (originInvites.is_sent_invitee) {
        emailSendKey.push(originInvites.email)
      }
      originInvites.publicKeyCCs?.forEach((item) => {
        if (item.is_sent) {
          emailSendKey.push(item.email)
        }
      })
      generateDataKeyPairUsers = await syncNewVersionComponentInvitee(
        inviteBuilComponent,
        projectBuild.project_id,
        originInvites,
        projectBuild.id,
        emailSendKey
      )
    }
    postProjectBuildInviteeActivateMiddleware(
      originInvites.id,
      statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE
        ? "Deactivate"
        : "Activate",

      async (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          statusInvites.setValue(newStatus)
          handleChangeData()
        }
      },
      originInvites.email,
      generateDataKeyPairUsers
    )
  }
  const onRedirectConversationDetail = () => {
    if (!originInvites.project_build_id || !originInvites.conversation_id) {
      return
    }
    //reset filter on conversation overview page to default
    handleSetLocalStorage({
      tab:
        projectBuild.created_by === userInfo.id
          ? MENU_TAB_CONVERSATION.FROM_ME
          : MENU_TAB_CONVERSATION.OTHERS,
    })
    pushTo(PATH.conversations, undefined, undefined, {
      conversationId: originInvites.conversation_id,
    })
  }
  const onSynComponentInvitee = () => {
    if (!originInvites.id) {
      return
    }
    isLoading.setValue(true)
    postProjectBuildInviteeSyncMiddleware(
      originInvites.id,
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isSyncComponent.setValue(false)
          handleChangeData()
        }
      }
    )
  }

  const renderButtonActivate = () => {
    const nameButton = deactivateTab ? "Activate" : "Deactivate"
    return (
      <Button
        className="hidden md:block"
        title={nameButton}
        sizeBtn="small"
        colorBtn="white"
        onClick={onChangeStatusInvitees}
        styleButton={{
          marginLeft: 8,
        }}
      />
    )
  }

  const renderDotsIconConversation = () => {
    if (isUndefined(originInvites.is_read) || originInvites.is_read) {
      return
    }
    return (
      <div
        className="absolute"
        style={{
          height: 11,
          width: 11,
          background: "#EA4545",
          border: "3px solid #FFFFFF",
          borderRadius: "50%",
          top: 1,
          right: 1,
        }}
      ></div>
    )
  }
  const renderIconShowComponent = () => {
    if (
      !isViewShare &&
      Boolean(statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE) &&
      sumComponent - inviteBuilComponent.length > 0
    ) {
      return (
        <div
          className="h-7 flex items-center cursor-pointer"
          onClick={onShowComponent}
        >
          <IconButton />
        </div>
      )
    }
    return null
  }
  const renderButtonSend = () => {
    if (isViewShare || !originInvites.email) {
      return null
    }
    const emailSend = invitation.ccs
      .filter((item) => item.isSent === false)
      .map((item) => item.email)
      .concat(invitation.invitee.isSent ? [] : [invitation.invitee.email])

    const title = Boolean(emailSend.length) ? "Send invite" : "Resend"

    return (
      <Button
        className="mt-2"
        title={title}
        sizeBtn="small"
        colorBtn="black"
        onClick={openSendInvite}
        disabled={deactivateTab}
        isDisabledBtn
      />
    )
  }
  const renderSelectComponent = () => (
    <div
      className="flex items-center justify-center cursor-pointer w-full h-7"
      onClick={
        !isViewShare &&
        Boolean(statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE)
          ? onShowComponent
          : undefined
      }
    >
      {!isViewShare &&
      Boolean(statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE) ? (
        <React.Fragment>
          <IconButton />
          <p
            className="font-normal ml-1"
            style={{
              color: "#0A5AF5",
              lineHeight: "24px",
              fontSize: 14,
            }}
          >
            Select component
          </p>
        </React.Fragment>
      ) : null}
    </div>
  )

  return (
    <div
      className="flex flex-col bg-white px-3 pt-3 mb-6 last:mb-0"
      ref={refSelectComponent}
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 6,
        paddingBottom: 4,
      }}
    >
      <div
        className="flex flex-col pb-3 h-fit w-full gap-1"
        style={{
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-4">
            <p
              className="font-semibold max-w-461 overflow-hidden text-center text-ellipsis "
              style={{
                fontSize: 14,
                lineHeight: "22px",
                color: deactivateTab ? "#C7C7C7" : "#111111",
              }}
            >
              {" "}
              {originInvites.conversation_code}
            </p>
            {originInvites.project_build_components.some(
              (item) => !item.is_read
            ) && (
              <div className="items-center gap-1 hidden md:flex">
                <IconMessagerDeleteComponent className="icon-warning-messager" />
                <p
                  className="font-normal text-13 leading-22"
                  style={{
                    color: "#e87a16",
                  }}
                >
                  {"New version of components is submitted"}
                </p>
              </div>
            )}
          </div>

          <div className="flex items-center justify-between "></div>
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="md:flex items-center overflow-hidden">
            <p
              className="font-semibold max-w-461 overflow-hidden text-ellipsis "
              style={{
                fontSize: 13,
                lineHeight: "22px",
                color: deactivateTab ? "#C7C7C7" : "#111111",
              }}
            >
              {originInvites.name}
            </p>
            <div
              className="hidden md:block h-1 w-1 mx-2"
              style={{
                backgroundColor: deactivateTab ? "#C7C7C7" : "#7A7A7A",
                borderRadius: "50%",
              }}
            />
            <p
              className="font-normal"
              style={{
                fontSize: 13,
                lineHeight: "22px",
                color: deactivateTab ? "#C7C7C7" : "#7A7A7A",
              }}
            >
              {originInvites.email}
            </p>
            {!isViewShare ? renderButtonActivate() : null}
          </div>

          <div className="flex items-center">
            {!isViewShare ? (
              <div>
                <ActionIconHistory
                  classIcon="hidden md:block"
                  icon="edit"
                  tooltip="Edit"
                  styleIcon={{
                    marginLeft: 12,
                    marginRight: 0,
                  }}
                  disabled={isViewShare || deactivateTab}
                  onClick={onEditInvites({
                    ...originInvites,
                    is_sent_invitee: invitation.invitee.isSent || false,
                    status: statusInvites.value,
                  })}
                />
                {/* tippy action on mobile */}
                <TippyCustomzie
                  containerClass="component-card-menu reset-tippy md:hidden"
                  placement="bottom-end"
                  interactive
                  arrow={false}
                  offset={[30, 5]}
                  animation="scale"
                  visible={showTippyAction.value}
                  onClickOutside={() => showTippyAction.setValue(false)}
                  content={
                    <div
                      style={{
                        background: "#222222",
                        borderRadius: 4,
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: 185,
                      }}
                    >
                      {!deactivateTab && (
                        <ActionItem
                          title={"Edit invitee"}
                          onClick={onEditInvites({
                            ...originInvites,
                            is_sent_invitee: invitation.invitee.isSent || false,
                            status: statusInvites.value,
                          })}
                        />
                      )}
                      <ActionItem
                        title={deactivateTab ? "Activate" : "Deactivate"}
                        onClick={onChangeStatusInvitees}
                      />
                    </div>
                  }
                  allowHTML
                >
                  <div
                    className="flex items-center w-[30px] h-[30px] cursor-pointer"
                    onClick={() =>
                      showTippyAction.setValue(!showTippyAction.value)
                    }
                  >
                    <ActionIconHistory
                      icon="three-dots"
                      tooltip="Action"
                      styleIcon={{
                        marginRight: 0,
                      }}
                    />
                  </div>
                </TippyCustomzie>
              </div>
            ) : null}

            <div className="relative">
              <ActionIconHistory
                onClick={onRedirectConversationDetail}
                icon="conversation"
                tooltip={"Conversation"}
                disabled={
                  // !Boolean(isSentInvitee.value) ||
                  !originInvites.conversation_id || deactivateTab
                }
                styleIcon={{
                  marginLeft: 12,
                  marginRight: 0,
                }}
              />
              {renderDotsIconConversation()}
            </div>
          </div>
        </div>
        {originInvites.project_build_components.some(
          (item) => !item.is_read
        ) && (
          <div className="items-center gap-1 flex md:hidden">
            <IconMessagerDeleteComponent className="icon-warning-messager" />
            <p
              className="font-normal text-13 leading-22"
              style={{
                color: "#e87a16",
              }}
            >
              {"New version of components is submitted"}
            </p>
          </div>
        )}
      </div>

      <div className="flex my-3 w-full">
        <TippyCustomzie
          containerClass={`component-card-menu flex w-full ${
            inviteBuilComponent.length ? "" : "justify-center"
          } reset-tippy`}
          placement={inviteBuilComponent.length ? "bottom-start" : "bottom"}
          interactive
          arrow={false}
          animation="scale"
          visible={showTippyAddComponent.value}
          onClickOutside={onClickOutsideAddComponent}
          offset={[-12, 15]}
          allowHTML
          content={
            <div
              className="flex items-center gap-2 flex-wrap bg-white px-3 py-3 relative"
              style={{
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                borderRadius: 6,
                minHeight: 52,
                width: refSelectComponent.current?.clientWidth,
              }}
            >
              {buildComponents.length
                ? buildComponents.map((component, index) => (
                    <ComponentBuildItemCard
                      key={index}
                      component={component}
                      onClickRoot={onClickAddComponentInInvitess}
                      isDeleted={
                        component.status ===
                          ProjectComponentStatus.DeletedAttachment || isDeleted
                      }
                    />
                  ))
                : null}

              {isLoadingComponent.value ? (
                <div className="w-full flex justify-center items-center absolute top-0 left-0 right-0 bottom-0">
                  <HashLoader color="#F7AC1B" loading={true} size={28} />
                </div>
              ) : null}
            </div>
          }
        >
          <div className="flex items-center w-full">
            {inviteBuilComponent.length ? (
              <div className="flex flex-col w-full">
                <div className="flex justify-between w-full gap-2">
                  <div className="flex items-center flex-wrap gap-2">
                    {inviteBuilComponent.map((buildComponent, index) => (
                      <ComponentBuildItemCard
                        key={index}
                        component={buildComponent}
                        hasDeleteIcon={
                          !isViewShare &&
                          statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE
                        }
                        isDisabled={
                          statusInvites.value ===
                          BUILD_INVITEE_STATUS.DEACTIVATE
                        }
                        onDelete={onClickDeleteComponentInInvitess}
                        isDeleted={
                          buildComponent.status ===
                            ProjectComponentStatus.DeletedAttachment ||
                          isDeleted
                        }
                        tooltip={
                          <span
                            className="font-normal"
                            style={{
                              fontSize: 13,
                              lineHeight: "22px",
                              wordBreak: "break-word",
                            }}
                          >
                            Invitee submitted a new version of this component.
                          </span>
                        }
                      />
                    ))}
                  </div>
                  {renderIconShowComponent()}
                </div>
                {renderButtonSend()}
              </div>
            ) : (
              renderSelectComponent()
            )}
          </div>
        </TippyCustomzie>
      </div>

      {isFormDeleteComponent.value && (
        <ModalDelete
          onCloseModal={() => isFormDeleteComponent.setValue(false)}
          title={`Do you want to delete the component?`}
          content="Invitee will no longer view this component detail and this action can not be undo."
          label={`Confirmation`}
          onSubmit={onDeleteComponentForm}
          titleButton="Delete"
          styleContent={{
            textAlign: "center",
          }}
        />
      )}

      {isSyncComponent.value && (
        <ModalDelete
          onCloseModal={() => isSyncComponent.setValue(false)}
          title={`This action will sync your data with invitee and cannot be undone.`}
          content=""
          label={`Confirmation`}
          onSubmit={onSynComponentInvitee}
          colorYellowButton
          titleButton="Confirm"
          styleTitle={{
            textAlign: "center",
          }}
        />
      )}
      {showSendInvitation.value && (
        <FormSendInvitation
          openModal={true}
          onCloseModal={() => {
            showSendInvitation.setValue(false)
            if (hasSend.value) {
              handleChangeData()
            }
          }}
          invitation={invitation}
          onSubmitSendInvitation={onSubmitSendInvitation}
        />
      )}
      {isLoadingBackupInvitee.value && messageBackups.length ? (
        <FormBackup
          messageBackups={messageBackups}
          currentBackup={currentMessageBackup}
          errorMessageBackup={errorMessageBackup.value}
        />
      ) : null}
    </div>
  )
}
export default InviteesItemCard
