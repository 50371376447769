import { STATUS_INPUT } from "components/Input/types"
import ModalCustom from "components/ModalCustom"
import { useBoolean, useString } from "helpers/hooks"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import { useEffect, useState } from "react"
import {
  FormVendorRequest,
  ProjectBuildInvitessDetail,
  WorkedVendor,
} from "../project-build.type"
import { isValidEmail, umamiTracking } from "helpers/utils"
import {
  postProjectBuildCreateInviteeMiddleware,
  putProjectBuildUpdateInviteeMiddleware,
} from "../api.services"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import ActionFooterModalCard from "components/ModalCustom/ActionFooterModalCard"
import SelectMulti from "components/Select/SelectMulti"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { cloneDeep, trim, uniq } from "lodash"
import { EVENT } from "constants/events"
import { getAddressBookMiddleware } from "pages/address-book/address-book.api"
import { ReactComponent as CloseIcon } from "assets/images/icons/close-icon.svg"
import { closeModal, openModal as customOpenModal } from "reducers/modal"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { EncryptionKeys } from "controllers/EncryptionController"
import { configureStore } from "stores/configureStore"

interface Props {
  handleChangeData: (
    newInvitees?: ProjectBuildInvitessDetail,
    inviteeId?: string,
    newAddedCC?: string[]
  ) => void
  onCloseModal: () => void
  defaultRequest: FormVendorRequest
  openModal: boolean
  idProjectBuild: string
  conversationId: string
}
interface VendorState extends WorkedVendor {
  label: string
  value: string
  custom?: boolean
}
type VendorInputType = "name" | "email"

const FormVendor = (props: Props) => {
  const {
    defaultRequest,
    handleChangeData,
    onCloseModal,
    openModal,
    idProjectBuild,
    conversationId,
  } = props
  const name = useString("")
  const email = useString("")
  const isLoading = useBoolean(false)
  const [workedVendors, setWorkedVendors] = useState<WorkedVendor[]>([])
  const [selectedVendor, setSelectedVendor] = useState<VendorState>({
    name: defaultRequest.invitee.name,
    email: defaultRequest.invitee.email || "",
    label: defaultRequest.invitee.name,
    value: defaultRequest.invitee.email || "",
  })

  const [inputCC, setInputCC] = useState<string>("")

  const [emailsCC, setEmailsCC] = useState<string[]>([])

  useEffect(() => {
    if (!openModal) {
      return
    }
    name.setValue(defaultRequest.invitee.name)
    email.setValue(defaultRequest.invitee.email)
    setEmailsCC(defaultRequest.cc)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultRequest), openModal])

  useEffect(() => {
    if (!openModal) {
      return
    }
    getAddressBookMiddleware({
      page: 1,
      pageSize: 100,
      search: "",
      is_full: true,
    }).then((res) => {
      setWorkedVendors(res.data as WorkedVendor[])
    })
  }, [openModal])

  useEffect(() => {
    name.setValue(selectedVendor.name)
    email.setValue(selectedVendor.email)
  }, [JSON.stringify(selectedVendor)])

  const getVendorState = (key: VendorInputType) => {
    const data = workedVendors.map((item) => {
      return {
        ...item,
        label: `${item.name} - ${item.email}`,
        value: key === "email" ? item.email : item.name,
      }
    })
    if (key === "email" && selectedVendor.email) {
      const existedEmail = data.find(
        (item) => item.value === selectedVendor.email
      )
      if (!existedEmail) {
        return [
          ...data,
          {
            ...selectedVendor,
            label: selectedVendor.email,
            value: selectedVendor.email,
          },
        ]
      }
    }
    if (key === "name" && selectedVendor.name) {
      const existedName = data.find(
        (item) => item.value === selectedVendor.name
      )
      if (!existedName) {
        return [
          ...data,
          {
            ...selectedVendor,
            label: selectedVendor.name,
            value: selectedVendor.name,
          },
        ]
      }
    }
    return data
  }

  const getCCState = () => {
    let data = workedVendors.map((item) => {
      return {
        ...item,
        label: `${item.name} - ${item.email}`,
        value: item.email,
      }
    })
    data = data.filter((item) => !emailsCC.includes(item.value))
    const exists = data.some((user) =>
      user.value.toLowerCase().includes(inputCC.toLowerCase())
    )
    if (!exists && inputCC) {
      data.push({
        label: inputCC,
        value: inputCC,
        email: inputCC,
        name: inputCC,
      })
    }
    return data
  }

  const handleDisableButton = () => {
    return false
  }
  const onSubmit = () => {
    if (!idProjectBuild) {
      return
    }

    if (!email.value && defaultRequest.is_sent) {
      toast(
        <LabelNotificationPage
          messenger="Email address is required!"
          type="error"
        />
      )
      return
    }
    if (email.value && !isValidEmail(trim(email.value))) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.VALIDATE.INVALID_EMAIL}
          type="error"
        />
      )
      return
    }

    isLoading.setValue(true)
    const dataRequest = {
      invitee: {
        name: trim(name.value),
        email: trim(email.value),
      },
      cc: emailsCC,
    }
    if (defaultRequest.invitee.email && !dataRequest.invitee.email) {
      umamiTracking(EVENT.INVITEE.REMOVE)
    }
    if (defaultRequest.id) {
      const addedCC =
        Boolean(defaultRequest.is_sent) &&
        Boolean(defaultRequest.invitee.is_sent)
          ? emailsCC.filter((email) => !defaultRequest.cc.includes(email))
          : []
      if (
        defaultRequest.is_sent &&
        defaultRequest.invitee.email !== dataRequest.invitee.email
      ) {
        configureStore.dispatch(
          customOpenModal({
            type: "Delete",
            props: {
              deleteModal: {
                title: (
                  <p>
                    The invitee{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {defaultRequest.invitee.email}
                    </span>{" "}
                    is currently active. Changing the invitee's email will
                    prevent access to the previously invited conversation data.
                    This action cannot be undone.
                  </p>
                ),
                label: `Warning`,
                content: `Press "Continue" to process`,
                styleTitle: { textAlign: "center" },
                titleButton: "Continue",
                colorYellowButton: true,
                onSubmit: () => {
                  const conversationEncryptionKeys = JSON.parse(
                    localStorage.getItem(
                      EncryptionKeys.conversationEncryptionKeys
                    ) || "{}"
                  )
                  const conversationEncryptionKey =
                    conversationEncryptionKeys[conversationId]
                  if (!conversationEncryptionKey) {
                    toast(
                      <LabelNotificationPage
                        messenger="Conversation key not found"
                        type="error"
                      />
                    )

                    return
                  }
                  const commentLog = activityLogHelper.toEncryptedMessage(
                    ActivityLogType.ChangeInviteeEmailComment,
                    {
                      invitee: defaultRequest.invitee.email,
                      newEmail: dataRequest.invitee.email,
                    },
                    conversationEncryptionKey
                  )
                  const log = activityLogHelper.toEncryptedMessage(
                    ActivityLogType.ChangeInviteeEmail,
                    {
                      invitee: defaultRequest.invitee.email,
                      newEmail: dataRequest.invitee.email,
                    }
                  )
                  putProjectBuildUpdateInviteeMiddleware(
                    defaultRequest.id || "",
                    dataRequest,
                    log,
                    (type: STATUS_RESPONSE, messenger: string) =>
                      onUpdateDataWhenSubmit(type, messenger, addedCC),
                    commentLog
                  )
                },
              },
            },
          })
        )
        return
      }
      putProjectBuildUpdateInviteeMiddleware(
        defaultRequest.id,
        dataRequest,
        "",
        (type: STATUS_RESPONSE, messenger: string) =>
          onUpdateDataWhenSubmit(type, messenger, addedCC)
      )
      return
    }
    postProjectBuildCreateInviteeMiddleware(
      idProjectBuild,
      dataRequest,
      (type: STATUS_RESPONSE, messenger: string) =>
        onUpdateDataWhenSubmit(type, messenger, [])
    )
  }

  const onUpdateDataWhenSubmit = (
    type: STATUS_RESPONSE,
    messenger: string,
    addedCC: string[]
  ) => {
    isLoading.setValue(false)
    toast(<LabelNotificationPage messenger={messenger} type={type} />)
    closeModal()
    onCloseModal()
    if (type === STATUS_RESPONSE.SUCCESS) {
      handleChangeData(undefined, defaultRequest.id, addedCC)
    }
  }

  const handleInputVendorChange =
    (key: VendorInputType) => (inputValue: string) => {
      setSelectedVendor({
        ...selectedVendor,
        [key]: inputValue,
        value: inputValue,
      })
    }

  const renderEditInviteeTab = () => (
    <div className="flex flex-col">
      <FormInputCard
        title="Contact name"
        required
        fontWeightText="font-semibold"
        disabled={defaultRequest.id ? true : false}
      >
        <SelectMulti
          options={getVendorState("name")}
          selectedOption={{
            label: selectedVendor.name,
            value: selectedVendor.name,
          }}
          handleChange={(changedData) => {
            setSelectedVendor(changedData)
          }}
          isMulti={false}
          noOptionsMessage
          handleInputChange={(value, action) => {
            if (action?.action === "input-change") {
              handleInputVendorChange("name")(value)
            }
          }}
          inputValue={selectedVendor.name}
        />
      </FormInputCard>

      <FormInputCard title="Email address" fontWeightText="font-semibold">
        <SelectMulti
          options={getVendorState("email")}
          selectedOption={{
            label: selectedVendor.email,
            value: selectedVendor.email,
          }}
          handleChange={(changedData) => {
            setSelectedVendor(changedData)
          }}
          isMulti={false}
          noOptionsMessage
          handleInputChange={(value, action) => {
            if (action?.action === "input-change") {
              handleInputVendorChange("email")(value)
            }
          }}
          inputValue={selectedVendor.email}
        />
      </FormInputCard>
      <FormInputCard title="CC" fontWeightText="font-semibold">
        <SelectMulti
          options={getCCState()}
          selectedOption={null}
          handleChange={(changedData) => {
            let newSelectedCCs = cloneDeep(emailsCC)
            newSelectedCCs = newSelectedCCs.concat(
              changedData
                .filter((data) =>
                  data.value.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)
                )
                .map((el) => el.value)
            )
            setEmailsCC(uniq(newSelectedCCs))
            setInputCC("")
          }}
          noOptionsMessage
          handleInputChange={(value, action) => {
            if (action?.action === "input-change") {
              setInputCC(value)
            }
          }}
          inputValue={inputCC}
        />
        <div className="flex flex-wrap gap-2 mt-[20px]">
          {emailsCC.map((cc, index) => (
            <div
              className="flex items-center bg-neutral3 gap-2 py-1 px-2 rounded-4 border-[1px] border-stroke "
              key={index}
            >
              <p className="text-[12px] text-black leading-[18px] font-medium">
                {cc}
              </p>
              <CloseIcon
                className="w-4 h-4 cursor-pointer hover-action-icon"
                onClick={() => {
                  setEmailsCC(emailsCC.filter((email) => email !== cc))
                }}
              />
            </div>
          ))}
        </div>
      </FormInputCard>

      <ActionFooterModalCard
        onCloseModal={onCloseModal}
        onSubmit={onSubmit}
        handleDisableButton={handleDisableButton()}
        titleButton={defaultRequest.id ? "Save" : "Add"}
        styleRoot={{
          marginTop: 20,
        }}
      />
    </div>
  )

  return (
    <ModalCustom
      label={`${defaultRequest.id ? "Edit Invitee" : "Add a invitee"}`}
      handleChangeButton={() => onCloseModal}
      bodyChildren={renderEditInviteeTab()}
    />
  )
}
export default FormVendor
