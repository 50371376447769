interface SkeletonConversationMessageProps {
  isMobile: boolean
}
export const SkeletonBuildDetail = (
  props: SkeletonConversationMessageProps
) => {
  const { isMobile } = props
  return (
    <div className="flex flex-1 flex-col h-full overflow-hidden">
      <div
        className="flex flex-col justify-between h-full"
        style={{
          borderLeft: "1px solid #E4E4E4",
        }}
      >
        <div className="min-h-[48px] w-full bg-[#FFFFFF] flex items-center px-[24px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
        </div>
        <div className="min-h-[81px] w-full bg-[#FFFFFF] flex flex-col justify-center px-[24px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
          <div className="flex justify-between items-center mt-[12px]">
            <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[200px]"></div>
            <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[120px]"></div>
          </div>
        </div>
        <div
          className="w-full h-full flex"
          style={{
            borderTop: "1px solid #E4E4E4",
          }}
        >
          <div
            className={`${
              isMobile ? "w-full" : "min-w-[357px]"
            } p-[24px] flex flex-col gap-[24px]`}
          >
            <div className="flex items-center gap-[18px]">
              <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[87px]"></div>
              <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[87px]"></div>
              <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[57px]"></div>
            </div>
            {[1, 2, 3].map((el) => {
              return (
                <div
                  key={el}
                  className="p-[12px] bg-[#FFFFFF] border border-[#E4E4E4] rounded-[6px] animate-pulse dark:bg-gray-700 flex flex-col gap-[21px]"
                >
                  <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[70px]"></div>
                  <div className="flex flex-col gap-[12px]">
                    <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[100px]"></div>
                    <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                  </div>

                  <div className="h-[12px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[70px]"></div>
                </div>
              )
            })}
          </div>
          {isMobile ? null : (
            <div className="bg-[#FFFFFF] w-full h-full flex flex-col justify-between p-[24px]">
              <div className="flex items-center">
                <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[70px] mr-[18px]"></div>
                <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[70px]"></div>
              </div>
              <div role="status" className="space-y-2.5 animate-pulse w-full">
                <div className="flex items-center w-full">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-32"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[480px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full max-w-[400px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[480px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full ">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-32"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[360px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[360px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[560px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full max-w-[300px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full ">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-32"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[560px]">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full ">
                  <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-32"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
                </div>
              </div>
              <div className="h-[120px] bg-[#EFEFEF] w-full rounded-[6px] dark:bg-gray-700"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const SkeletonBuildCard = () => {
  return (
    <div
      className="flex flex-col gap-[12px]"
      style={{
        paddingRight: 24,
      }}
    >
      {[1, 2, 3, 4].map((el) => {
        return (
          <div
            key={el}
            className="p-[12px] bg-[#FFFFFF] border border-[#E4E4E4] rounded-[6px] animate-pulse dark:bg-gray-700 flex flex-col gap-[10px]"
          >
            <div className="h-[15px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[250px]"></div>
            <div className="flex items-center">
              <div className="flex flex-col w-full">
                <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[50%] mb-[3px]"></div>
                <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
              </div>
            </div>
            <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700"></div>
            <div className="flex items-center gap-[10px]">
              <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[40px]"></div>
              <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[50px]"></div>
              <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[70px]"></div>
            </div>
            <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[100px]"></div>
          </div>
        )
      })}
    </div>
  )
}
