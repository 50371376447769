import { useBoolean, useString } from "helpers/hooks"
import { formatDate } from "helpers/utils"
import { useEffect, useMemo, useRef } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { ReactComponent as TimestampIcon } from "assets/images/icons/icon-editor-timestamp.svg"
import { ReactComponent as EditorTextBoldIcon } from "assets/images/icons/icon-editor-bold.svg"
import { ReactComponent as EditorTextItalicIcon } from "assets/images/icons/icon-editor-italic.svg"
import { ReactComponent as EditorTextColorIcon } from "assets/images/icons/icon-editor-text-color.svg"
import { ReactComponent as EditorTextBulletListIcon } from "assets/images/icons/icon-editor-bullet-list.svg"
import { ReactComponent as EditorTextOrderListIcon } from "assets/images/icons/icon-editor-ordered-list.svg"
import DraftToHtml from "draftjs-to-html"

import ImageResize from "quill-image-resize-module-react"
import Tippy from "@tippyjs/react"
import ReactDOMServer from "react-dom/server"
import { isUndefined } from "lodash"
const icons = Quill.import("ui/icons")

window.Quill = Quill
Quill.register("modules/imageResize", ImageResize)
interface Props {
  value: string
  setValue: (value: string) => void
  readOnly?: boolean
  versionId?: string
}

const CustomToolbar = () => {
  return (
    <div
      id="toolbar"
      className="
     rounded-full p-2 flex bg-black-04 gap-1 items-center"
    >
      <div className="">
        <select
          className="ql-header"
          defaultValue={""}
          onChange={(e) => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option selected />
        </select>
      </div>
      <div className="h-[20px] bg-stroke  w-[0.5px]"></div>

      <Tippy placement="top" content={"Bold"} offset={[0, 15]}>
        <button className="ql-bold" />
      </Tippy>
      <Tippy placement="top" content={"Italic"} offset={[0, 15]}>
        <button className="ql-italic" />
      </Tippy>
      <Tippy placement="top" content={"Text color"} offset={[0, 15]}>
        <div>
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option selected />
          </select>
        </div>
      </Tippy>
      <Tippy placement="top" content={"Ordered list"} offset={[0, 15]}>
        <button className="ql-list" value={"ordered"} />
      </Tippy>
      <Tippy placement="top" content={"Bulleted list"} offset={[0, 15]}>
        <button className="ql-list" value={"bullet"} />
      </Tippy>
      <Tippy placement="top" content={"Add timestamp"} offset={[0, 15]}>
        <button className="ql-insertTimestamp">
          <TimestampIcon />
        </button>
      </Tippy>
    </div>
  )
}

const InputEditorDefault2 = (props: Props) => {
  const { setValue, readOnly = false, versionId } = props
  let value = props.value
  try {
    const draftContent = JSON.parse(value)
    if (draftContent) {
      value = DraftToHtml(draftContent)
    }
  } catch (error) {}
  const quillRef = useRef<any>()
  const editorValue = useString(value)
  const isFocus = useBoolean()
  const isInsert = useRef(false)
  const newCursorPosition = useRef(0)

  useEffect(() => {
    editorValue.setValue(value)
  }, [value])

  const insertTimestamp = () => {
    if (isInsert.current) return
    const currentTimestamp = formatDate(new Date())
    const quill = quillRef?.current?.getEditor()
    const insertPos = newCursorPosition.current || 0
    quill.insertText(newCursorPosition.current || 0, currentTimestamp, {
      color: "#FF8C00",
    })
    quill.format("color", "black")
    quill.insertText(insertPos + currentTimestamp.length, " ", {
      color: false,
    })

    quill.setSelection(insertPos + currentTimestamp.length + 1)
    isInsert.current = true
    setTimeout(() => {
      isInsert.current = false
    }, 500)
  }

  const onChangeEditor = (newValue: string) => {
    if (
      newValue?.trim().replace(/[\r\n]/gm, "") ===
      value?.trim().replace(/[\r\n]/gm, "")
    ) {
      return
    }
    if (newValue !== editorValue.value) {
      editorValue.setValue(newValue)
      setValue(newValue)
    }
  }

  const modules = useMemo(() => {
    icons.bold = ReactDOMServer.renderToString(<EditorTextBoldIcon />)
    icons.italic = ReactDOMServer.renderToString(<EditorTextItalicIcon />)
    icons.color = ReactDOMServer.renderToString(<EditorTextColorIcon />)
    icons.list.bullet = ReactDOMServer.renderToString(
      <EditorTextBulletListIcon />
    )
    icons.list.ordered = ReactDOMServer.renderToString(
      <EditorTextOrderListIcon />
    )
    return {
      toolbar: {
        container: "#toolbar",
        handlers: {
          insertTimestamp,
        },
      },
      clipboard: {
        matchVisual: true,
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize"],
      },
    }
  }, [versionId])

  const handleSelectionChange = (range: any) => {
    if (range && !isUndefined(range.index)) {
      newCursorPosition.current = range.index
    }
  }

  return (
    <div className="text-editor relative w-full">
      <div
        className={`${readOnly ? "hidden" : "flex"} items-center pb-2 pr-2 `}
      >
        <CustomToolbar />
      </div>
      <ReactQuill
        key={`${versionId}/editor`}
        className={`w-full px-2 overflow-auto text-black leading-3 rounded-4 border-[1.5px] border-gray-300 rich-editor ${
          isFocus.value ? "active-project-build" : ""
        }`}
        ref={quillRef}
        value={editorValue.value}
        onChange={onChangeEditor}
        onFocus={() => isFocus.setValue(true)}
        onBlur={() => isFocus.setValue(false)}
        onChangeSelection={(range) => handleSelectionChange(range)}
        modules={modules}
        readOnly={readOnly}
        theme="snow"
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          // "image",
          // "imagewithstyle",
          "code",
          "color",
          "background",
          "code-block",
          "align",
          "width",
        ]}
      />
    </div>
  )
}
export default InputEditorDefault2
