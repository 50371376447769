import Tippy from "@tippyjs/react"
import { ReactComponent as IconEditorDownload } from "assets/images/icons/icon-download-file.svg"
import IconEditorDeleteFile from "assets/images/icons/icon-editor-delete-file.svg"
import {
  downloadConversationNoteFile,
  downloadPrivateAsset,
  getPrivateAssetURI,
  removeTimestampInFileName,
} from "helpers/utils"

interface Props {
  index: number
  name: string
  isDownload: boolean
  isDeleted: boolean
  idFile?: string
  urlFile?: string
  styleFileEditorCard?: React.CSSProperties
  conversationId?: string
  versionId?: string
  isDownloadConversationNote?: boolean
  handleDeleteFile: (index: number, idFile?: string) => () => void
  decryptedShareKey?: string
}
const FileEditorCard = (props: Props) => {
  const {
    name,
    index,
    isDownload,
    handleDeleteFile,
    idFile,
    urlFile,
    isDeleted,
    styleFileEditorCard,
    conversationId,
    versionId,
    isDownloadConversationNote,
    decryptedShareKey,
  } = props
  return (
    <div
      className={`${
        isDownload
          ? "grid-card-editor-file"
          : "grid-card-editor-file-no-download"
      } card-editor-file mr-3 mb-3`}
      style={{
        ...styleFileEditorCard,
        maxWidth: 200,
      }}
    >
      <Tippy
        className="custom-tippy-menu-left"
        placement="top"
        content={
          <span
            className="font-normal"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              wordBreak: "break-word",
            }}
          >
            {removeTimestampInFileName(name)}
          </span>
        }
      >
        <div className="flex items-center">
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#111111",
              whiteSpace: "nowrap",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {removeTimestampInFileName(name)}
          </p>
        </div>
      </Tippy>

      <div className="flex items-center justify-end change-color-icon-download-file">
        {isDownload && urlFile ? (
          <span
            onClick={() =>
              isDownloadConversationNote
                ? downloadConversationNoteFile(
                    getPrivateAssetURI(urlFile, {}),
                    true
                  )
                : downloadPrivateAsset(
                    urlFile,
                    conversationId
                      ? { conversation_id: conversationId }
                      : { project_component_history_id: versionId },
                    undefined,
                    undefined,
                    decryptedShareKey
                  )
            }
          >
            <IconEditorDownload
              className=" cursor-pointer"
              style={{
                height: 16,
              }}
            />
          </span>
        ) : null}
        {isDeleted ? (
          <img
            src={IconEditorDeleteFile}
            alt="icon"
            className="cursor-pointer ml-2"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleDeleteFile(index, idFile)()
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default FileEditorCard
