import PageLayout from "pages/layout/PageLayout"
import { useEffect, useRef, useState } from "react"
import { getComponentTypesMiddleware } from "pages/project-component-detail/services"
import { getBuildStatusMiddleware } from "pages/project-component/services/api"
import BuildConversationColumn from "./BuildConversationColumn"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
import { STATUS_BUILD } from "components/Status/types"
import { useFilterBuilds } from "./builds.hook"
import {
  BuildConversationDetail,
  ParamsBuildConversationProps,
  emptyBuildConversationDetail,
} from "../conversations/conversations.type"
import { cloneDeep, compact, isUndefined, remove, toArray } from "lodash"
import {
  useBoolean,
  useSearchParams,
  useNumber,
  useString,
  useWindowSize,
} from "helpers/hooks"
import { ReactComponent as IconNoResult } from "assets/images/icons/icon no-result.svg"
import Button from "components/Button/Button"
import iconNoData from "assets/images/icons/icon-no-data-component.svg"
import { useTippyLayout } from "components/TippyCustomzie"
import { compareDate, searchParams, umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"
import { useAppSelector } from "hooks/useApp"
import { configureStore } from "stores/configureStore"
import { setUnreadBuildAction } from "pages/auth/stores/actions"
import { BuildOverviewPageContext } from "./contexts/BuildOverviewPage.context"
import BuildFilter from "./organisms/BuildFilter"
import { MENU_TAB_BUILD, MENU_TAB_BUILD_KEY } from "./build.type"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useLocation, useParams } from "react-router-dom"

import { getListBuildMiddleware } from "./builds.api"
import BuildDetailColumn from "./BuilDetailColumn"
import { SkeletonBuildCard } from "./molecules/SkeletonBuild"
const BuildOverview = () => {
  const [listBuilds, setListBuilds] = useState<BuildConversationDetail[]>([])
  const [selectedBuild, setSelectedBuild] = useState<BuildConversationDetail>(
    emptyBuildConversationDetail
  )
  const locationParams = useParams<any>()
  const location = useLocation<any>()
  const activeIdBuild = useString()
  const pageHook = useNumber(1)
  const rowsPerPageHook = useNumber(100)
  const isMoreData = useBoolean(true)
  const refBuild = useRef<HTMLDivElement>(null)
  const openNotes = useBoolean(false)
  const showBuildDetailLayout = useBoolean(false)

  const showBuildDetail = useBoolean()
  const {
    activeMenu,
    cpnStatusOptions,
    cpnTypeOptions,
    inputSearch,
    isFilter,
    search,
    isLoading,
    isSearch,
    objSelectStatus,
    objSelectType,
    isShowFavorite,
    selectionRange,
    endDateCreate,
    startDateCreate,
    isCalendarLastUpdate,
    selectionDate,
    dateLastUpdate,
    isApplyFilter,
    setCpnStatusOptions,
    setCpnTypeOptions,
    setObjSelectStatus,
    setObjSelectType,
    onChangeInputSearch,
    onKeyPress,
    onOpenInputSearch,
    onChangeTab,
    onChangeCheckboxFavorite,
    onChangeDate,
    isCalendarRanges,
    onApplySelectionRanges,
    onCancelSelectionRanges,
    onChangeModalSelectRanges,
    onClearInputSelectionRanges,
    onChangeModalDateLastUpdate,
    onChangeDateLastUpdate,
    onApplySelectionDateLastUpdate,
    onCancelSelectionlastUpdate,
    onClearInputSelectionDateLastUpdate,
    handleChangeFilerAll,
    onApplyFilter,
    onOpenModalFiler,
    onClickClearFilterNoResult,
    onRemoveInputSearch,
    rollbackFilter,
    onChangeCheckboxDelete,
    isShowDeleted,
    isShowUnread,
    isShowArchive,
    onChangeCheckBoxArchive,
    onChangeCheckBoxUnread,
    groupOtion,
    onChangeGroupOption,
  } = useFilterBuilds()
  const unreadBuild = useAppSelector((state) => state.unreadBuild)
  const isDeletedBuild = selectedBuild?.status === STATUS_BUILD.DELETED
  const { isMobile } = useWindowSize()
  const { TippyLayout } = useTippyLayout()
  const { searchParams, setSearchParams } = useSearchParams()
  const { tab, selectArchivedFilter } = searchParams
  const collapse = useBoolean(false)
  const isLoadingDefault = useBoolean(true)
  const isLoadingSkeleton = useBoolean(true)

  useEffect(() => {
    if (tab === "my-builds") {
      activeMenu.setValue(MENU_TAB_BUILD.MY_BUILDS)
      getDataDefault("my-builds")
      return
    }
    if (tab === "others") {
      activeMenu.setValue(MENU_TAB_BUILD.OTHERS)
      getDataDefault("others")
      return
    }
    getDataDefault()
    isLoadingDefault.setValue(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (isLoadingDefault.value || !isApplyFilter.value) {
      return
    }

    setListBuilds([])
    isLoading.setValue(true)
    getListBuild(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplyFilter.value])

  useEffect(() => {
    if (isLoadingDefault.value) {
      return
    }
    showBuildDetail.setValue(false)
    isLoading.setValue(true)
    getListBuild(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.value, activeMenu.value])

  useEffect(() => {
    if (isLoadingDefault.value) {
      return
    }
    activeIdBuild.setValue(locationParams.idProjectBuild)
  }, [locationParams.idProjectBuild])

  const changeDataFilter = () => {
    const newTypes = compact(toArray(objSelectType))
    const newStatus = compact(toArray(objSelectStatus))
    const newFilter: any = {}
    if (isShowFavorite.value) {
      newFilter.is_favorite = isShowFavorite.value
    }
    if (isShowUnread.value) {
      newFilter.is_unread = isShowUnread.value
    }
    if (isShowArchive) {
      if (selectArchivedFilter) {
        isShowArchive.setValue(true)
      }
      newFilter.is_archive = selectArchivedFilter || isShowArchive.value
    }
    if (newTypes.length) {
      newFilter.types = newTypes
    }
    if (newStatus.length) {
      newFilter.statuses = newStatus
    }
    if (startDateCreate) {
      newFilter.created_from = startDateCreate
    }
    if (endDateCreate) {
      newFilter.created_to = endDateCreate
    }
    if (dateLastUpdate) {
      newFilter.updated_from = dateLastUpdate
      newFilter.updated_to = dateLastUpdate
    }
    if (search.value) {
      newFilter.name = search.value
    }
    if (groupOtion.value) {
      newFilter.sorted_by = groupOtion.value
    }

    if (isShowDeleted.value) {
      newFilter.is_deleted = isShowDeleted.value ? 1 : 0
      delete newFilter.is_unread
      delete newFilter.is_archive
      delete newFilter.is_favorite
      delete newFilter.name
      delete newFilter.statuses
      delete newFilter.types
    }
    //
    // if (
    //   isLoadingDefault.value &&
    //   locationParams.idProjectBuild &&
    //   location.pathname !== PATH.defaultBuildOverview
    // ) {
    //   newFilter.project_build_id = locationParams.idProjectBuild
    // }
    const urlBuild =
      activeMenu.value === MENU_TAB_BUILD.MY_BUILDS ? "my-builds" : "others"

    return {
      newFilter,
      urlBuild,
    }
  }

  const changeProjectParams = (defaultPage?: number) => {
    const { newFilter, urlBuild } = changeDataFilter()

    const newParams: ParamsBuildConversationProps = {
      page: defaultPage ?? pageHook.value + 1,
      pageSize: rowsPerPageHook.value,
      filter: newFilter,
    }
    return {
      params: newParams,
      urlBuild,
    }
  }

  const getListBuild = async (loading = false, tab?: string) => {
    try {
      isLoadingSkeleton.setValue(true)
      const { params, urlBuild } = changeProjectParams(1)
      const dataRes = await getListBuildMiddleware(tab ? tab : urlBuild, params)
      pageHook.setValue(1)
      isMoreData.setValue(dataRes.length < rowsPerPageHook.value ? false : true)
      if (location.pathname === PATH.defaultBuildOverview) {
        collapse.setValue(false)
      }
      //mobile flow
      if (isMobile) {
        if (location.pathname !== PATH.defaultBuildOverview) {
          if (isLoadingDefault.value) {
            showBuildDetailLayout.setValue(true)
          } else {
            setListBuilds(dataRes)
            isApplyFilter.setValue(false)
            isLoadingSkeleton.setValue(false)
            return
          }
        } else {
          setListBuilds(dataRes)
          isApplyFilter.setValue(false)
          isLoadingSkeleton.setValue(false)
          return
        }
      }
      isLoadingSkeleton.setValue(false)

      //desktop flow
      if (dataRes.length) {
        setListBuilds(dataRes)
      } else {
        setSelectedBuild(emptyBuildConversationDetail)
        setListBuilds([])
        activeIdBuild.setValue("")
        if (!isLoadingDefault.value) {
          pushTo(PATH.defaultBuildOverview)
        }
      }
      isApplyFilter.setValue(false)
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      setListBuilds([])
      isLoadingSkeleton.setValue(false)
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }

  const changeDataObjIsReadBuild = (
    originListBuilds: BuildConversationDetail[],
    projectBuildId: string,
    isDuplicate = false
  ) => {
    const newOriginListBuilds = cloneDeep(originListBuilds)

    const indexBuild = newOriginListBuilds.findIndex(
      (el) => el.id === projectBuildId
    )
    const key =
      activeMenu.value === MENU_TAB_BUILD.MY_BUILDS
        ? "unread_my_build"
        : "unread_others_build"
    if (indexBuild > -1 && newOriginListBuilds[indexBuild].is_read === false) {
      if (!isDuplicate) {
        newOriginListBuilds[indexBuild].is_read = true
      }
      configureStore.dispatch(
        setUnreadBuildAction({
          [key]: unreadBuild[key] > 0 ? unreadBuild[key] - 1 : 0,
        })
      )
    }
    setListBuilds(newOriginListBuilds)
  }

  const getDataDefault = async (tab?: string) => {
    isLoading.setValue(true)
    try {
      await getTypeComponent()
      await getStatusComponent()
      if (isLoadingDefault.value) {
        await getListBuild(true, tab)
      }
      isLoadingDefault.setValue(false)
    } catch (error) {
      setCpnTypeOptions([])
      setCpnStatusOptions([])
    } finally {
      isLoading.setValue(false)
    }
  }
  const getTypeComponent = async () => {
    const dataCpnTypeRes = await getComponentTypesMiddleware()
    setCpnTypeOptions(dataCpnTypeRes)
  }
  const getStatusComponent = async () => {
    const dataCpnStatusRes = await getBuildStatusMiddleware()
    setCpnStatusOptions(dataCpnStatusRes)
  }

  const updateDataWhenChangeFavorite = (dataRes: BuildConversationDetail) => {
    const newListBuilds = cloneDeep(listBuilds)
    const index = newListBuilds.findIndex((el) => el.id === dataRes.id)
    if (index < 0) {
      return
    }
    if (isShowFavorite.value && !dataRes.is_favorite) {
      remove(newListBuilds, (el) => el.id === dataRes.id)
    } else {
      newListBuilds[index] = dataRes
    }
    setListBuilds(newListBuilds)
  }
  const updateDataWhenArchive = (dataRes: BuildConversationDetail) => {
    const newListBuilds = cloneDeep(listBuilds)
    const index = newListBuilds.findIndex((el) => el.id === dataRes.id)
    if (!isShowArchive.value) {
      remove(newListBuilds, (el) => el.id === dataRes.id)
      if (dataRes.id === selectedBuild.id) {
        setSelectedBuild(emptyBuildConversationDetail)
        pushTo(PATH.defaultBuildOverview)
      }
    } else {
      newListBuilds[index] = dataRes
      if (dataRes.id === selectedBuild.id) {
        setSelectedBuild(newListBuilds[index])
      }
    }
    setListBuilds(newListBuilds)
  }
  const updateDataWhenDeleteBuild = (dataRes: BuildConversationDetail) => {
    const newListBuilds = cloneDeep(listBuilds)
    const index = newListBuilds.findIndex((el) => el.id === dataRes.id)
    if (index === -1) return
    remove(newListBuilds, (el) => el.id === dataRes.id)
    if (dataRes.id === selectedBuild.id) {
      showBuildDetail.setValue(false)
      setSelectedBuild(emptyBuildConversationDetail)
      pushTo(PATH.defaultBuildOverview)
    }
    setListBuilds(newListBuilds)
  }
  const updateDataWhenDuplicateBuild = (dataRes: BuildConversationDetail) => {
    listBuilds.unshift(dataRes)
    changeDataObjIsReadBuild(listBuilds, dataRes.id, true)
    // setListBuilds([...listBuilds])
  }
  const updateBuild = (
    buildId: string,
    data: Partial<BuildConversationDetail>
  ) => {
    const newListBuilds = cloneDeep(listBuilds)
    const index = newListBuilds.findIndex((el) => el.id === buildId)
    if (index === -1) return
    newListBuilds[index] = { ...newListBuilds[index], ...data }
    newListBuilds.sort((a, b) => {
      return compareDate(a.updated_at, b.updated_at)
    })

    setListBuilds(newListBuilds)
  }
  const updateSelectedBuildStatus = (status: STATUS_BUILD) => {
    setSelectedBuild({ ...selectedBuild, status })
  }
  const onClickBuildComponent = (dataRes: BuildConversationDetail) => () => {
    if (!dataRes.id) {
      return
    }
    if (dataRes.id === activeIdBuild.value) {
      showBuildDetail.setValue(true)
      pushTo(
        PATH.buildOverview,
        {
          idProject: dataRes.project_id,
          status: dataRes.status,
          idProjectBuild: dataRes.id,
        },
        undefined,
        { tab: MENU_TAB_BUILD_KEY[activeMenu.value] }
      )
      return
    }
    setSelectedBuild(dataRes)
    activeIdBuild.setValue(dataRes.id)
    showBuildDetail.setValue(true)
    pushTo(
      PATH.buildOverview,
      {
        idProject: dataRes.project_id,
        status: dataRes.status,
        idProjectBuild: dataRes.id,
      },
      undefined,
      { tab: MENU_TAB_BUILD_KEY[activeMenu.value] }
    )
    changeDataObjIsReadBuild(listBuilds, dataRes.id)
  }

  const checkShowButtonClearFilter = () => {
    if (isFilter.value) {
      return false
    }
    if (
      isShowFavorite.value ||
      isShowDeleted.value ||
      isShowUnread.value ||
      isShowArchive.value ||
      compact(toArray(objSelectStatus)).length ||
      compact(toArray(objSelectType)).length ||
      startDateCreate ||
      endDateCreate ||
      dateLastUpdate
    ) {
      return true
    }
    return false
  }

  const handleScrollBuild = async () => {
    const offsetHeight = refBuild.current?.offsetHeight
    const scrollHeight = refBuild.current?.scrollHeight
    const scrollTop = refBuild.current?.scrollTop
    if (Number(scrollTop) + Number(offsetHeight) > Number(scrollHeight) - 10) {
      if (!isMoreData.value || !listBuilds.length || isLoading.value) {
        return
      }
      isLoading.setValue(true)
      try {
        const { params, urlBuild } = changeProjectParams()

        const dataRes = await getListBuildMiddleware(urlBuild, params)
        isMoreData.setValue(
          dataRes.length < rowsPerPageHook.value ? false : true
        )
        pageHook.setValue(pageHook.value + 1)
        const newData = cloneDeep(listBuilds).concat(dataRes)
        setListBuilds(newData)
        changeDataObjIsReadBuild(newData, activeIdBuild.value)
        isLoading.setValue(false)
      } catch (error) {
        isLoading.setValue(false)
      }
    }
  }

  const renderBuildDetail = () =>
    isDeletedBuild ? (
      <div className="flex flex-1 flex-col w-full h-full overflow-hidden">
        <div
          className="flex md:hidden p-6"
          onClick={() => {
            showBuildDetailLayout.setValue(false)
          }}
        >
          <ArrowLeftIcon />
        </div>
        <div className="bg-white flex flex-1 items-center justify-center">
          <div className="m-6">
            <img src={iconNoData} alt="icon" />
            <p className="text-center font-semibold text-[#111111] text-[16px] leading-[26px] mt-6">
              The build no longer exists.
              <br />
              Please contact project owner.
            </p>
          </div>
        </div>
      </div>
    ) : (
      <BuildDetailColumn
        isArchived={
          isUndefined(selectedBuild.is_archived)
            ? undefined
            : Boolean(selectedBuild.is_archived)
        }
        showBuildDetailLayout={showBuildDetailLayout}
        collapse={collapse}
      />
    )

  const renderBuildConversation = () => (
    <BuildConversationColumn
      listBuilds={listBuilds}
      updateDataWhenChangeFavorite={updateDataWhenChangeFavorite}
      updateDataWhenArchive={updateDataWhenArchive}
      activeMenu={activeMenu.value}
      onClickBuildComponent={onClickBuildComponent}
      activeIdBuild={activeIdBuild}
      showLayout={showBuildDetailLayout}
      groupOption={groupOtion.value}
      updateDataWhenDeleteBuild={updateDataWhenDeleteBuild}
      updateDataWhenDuplicateBuild={updateDataWhenDuplicateBuild}
    />
  )

  const renderListBuilds = () => {
    if (isLoadingSkeleton.value) {
      return <SkeletonBuildCard />
    }
    if (listBuilds.length) {
      return (
        <div
          className="flex flex-col h-full overflow-auto"
          ref={refBuild}
          onScroll={handleScrollBuild}
        >
          {isMobile ? (
            <TippyLayout
              type="content-screen"
              visible={showBuildDetailLayout.value || openNotes.value}
              mainContent={
                showBuildDetailLayout.value ? renderBuildDetail() : null
              }
              containerClass="build-conversation"
            >
              {renderBuildConversation()}
            </TippyLayout>
          ) : (
            renderBuildConversation()
          )}
        </div>
      )
    }

    return (
      <div className="h-full flex flex-col items-center justify-center">
        <IconNoResult style={{ height: 190 }} />
        <p
          className="font-semibold text-16 leading-26  mt-3"
          style={{
            color: "#111111",
          }}
        >
          {checkShowButtonClearFilter()
            ? "No result found!"
            : "No builds found!"}
        </p>
        {checkShowButtonClearFilter() ? (
          <Button
            title="Clear filter"
            colorBtn="white"
            onClick={onClickClearFilterNoResult}
            sizeBtn="small"
            styleButton={{
              marginTop: 12,
            }}
          />
        ) : null}
      </div>
    )
  }

  return (
    <PageLayout heightHeader={0} minWidth="min-w-[1280px]">
      <BuildOverviewPageContext.Provider
        value={{
          cpnStatusOptions,
          cpnTypeOptions,
          objSelectType,
          objSelectStatus,
          setObjSelectType,
          setObjSelectStatus,
          setBuild: updateBuild,
          setSelectedBuildStatus: updateSelectedBuildStatus,
        }}
      >
        <div className="h-full md:flex">
          <div
            className={`w-full flex transition-all duration-500
            ${
              collapse.value
                ? "md:min-w-0 md:w-0 md:translate-x-[-100%]"
                : "md:min-w-[342px] md:w-[342px]"
            }
            `}
          >
            <div
              className={`flex flex-col flex-1 pl-6 ${
                collapse.value ? "md:pl-0" : ""
              } pt-6 overflow-hidden w-full md:w-[50%]`}
            >
              <BuildFilter
                isSearch={isSearch.value}
                inputSearch={inputSearch.value}
                activeMenu={activeMenu.value}
                onChangeInputSearch={onChangeInputSearch}
                onKeyPress={onKeyPress}
                isFilter={isFilter.value}
                setIsFilter={isFilter.setValue}
                onOpenInputSearch={onOpenInputSearch}
                onChangeTab={onChangeTab}
                isShowFavorite={isShowFavorite.value}
                onChangeCheckboxFavorite={onChangeCheckboxFavorite}
                onChangeCheckboxDelete={onChangeCheckboxDelete}
                isShowDeleted={isShowDeleted.value}
                selectionRange={selectionRange}
                onChangeDate={onChangeDate}
                isCalendarRanges={isCalendarRanges.value}
                onApplySelectionRanges={onApplySelectionRanges}
                onCancelSelectionRanges={onCancelSelectionRanges}
                onChangeModalSelectRanges={onChangeModalSelectRanges}
                endDateCreate={endDateCreate}
                startDateCreate={startDateCreate}
                onClearInputSelectionRanges={onClearInputSelectionRanges}
                isCalendarLastUpdate={isCalendarLastUpdate.value}
                selectionDate={selectionDate}
                onChangeModalDateLastUpdate={onChangeModalDateLastUpdate}
                onChangeDateLastUpdate={onChangeDateLastUpdate}
                onApplySelectionDateLastUpdate={onApplySelectionDateLastUpdate}
                onCancelSelectionlastUpdate={onCancelSelectionlastUpdate}
                onClearInputSelectionDateLastUpdate={
                  onClearInputSelectionDateLastUpdate
                }
                dateLastUpdate={dateLastUpdate}
                handleChangeFilerAll={handleChangeFilerAll}
                onApplyFilter={onApplyFilter}
                onOpenModalFiler={onOpenModalFiler}
                isActiveIconFilter={checkShowButtonClearFilter()}
                onRemoveInputSearch={onRemoveInputSearch}
                rollbackFilter={rollbackFilter}
                onChangeCheckBoxUnread={onChangeCheckBoxUnread}
                isShowUnread={isShowUnread.value}
                isShowArchive={isShowArchive.value}
                onChangeCheckBoxArchive={onChangeCheckBoxArchive}
                unreadByFilter={
                  listBuilds.filter((item) => !item.is_read).length
                }
                groupOption={groupOtion.value}
                onChangeGroupOption={onChangeGroupOption}
              />
              {renderListBuilds()}
            </div>
          </div>

          <div className="hidden md:flex w-full min-w-[50%]">
            {!isLoading.value && renderBuildDetail()}
          </div>
        </div>
      </BuildOverviewPageContext.Provider>
    </PageLayout>
  )
}
export default BuildOverview
