import React, { useEffect, useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import LabelChangeRouter from "./molecules/LabelChangeRouter"
import { PATH } from "constants/path"
import {
  SignUpRequest,
  defaultSignUpRequest,
  TYPE_RESEND_EMAIL,
  CompanyRole,
} from "./types"
import { STATUS_INPUT } from "components/Input/types"
import { changeLabelStatusEmail, umamiTracking } from "helpers/utils"
import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import {
  checkEmailExist,
  getCompanyRoles,
  signUpMiddleware,
} from "./services/api"
import { STATUS_RESPONSE } from "types"
import { pushTo } from "helpers/history"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useChangeLabelStatusInputPasswordAndConfirm } from "./auth.hook"
import FormInputSignUpCard from "./molecules/FormInputSignUpCard"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"

import SelectMulti from "components/Select/SelectMulti"
import { cloneDeep, omit } from "lodash"
import RecoveryModal from "./molecules/RecoveryModal"
import { encryptionController } from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
const SignUp = () => {
  const [request, setRequest] = useState<SignUpRequest>(defaultSignUpRequest)
  const [statusInputEmail, setStatusInputEmail] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })

  const {
    statusInputPassword,
    statusInputConfirm,
    getStatusInputPassword,
    getStatusInputConfirm,
  } = useChangeLabelStatusInputPasswordAndConfirm()
  const { downLoadBackUpKey } = encryptionController()

  const checkbox = useBoolean()
  const isLoading = useBoolean()
  const { isMobile } = useWindowSize()
  const showToolTip = useBoolean()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [cloneCompanyRoles, setCloneCompanyRoles] = useState<CompanyRole[]>([])
  const [selectedRole, setSelectedRole] = useState<CompanyRole>({
    name: "Other",
    id: "other",
  })
  const customRole = useString()
  const openRecoveryModal = useBoolean()

  useEffect(() => {
    getCompanyRoles()
      .then((res) => {
        setCloneCompanyRoles(res)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleChangeInput =
    (
      key:
        | "fullname"
        | "email"
        | "company_name"
        | "password"
        | "confirmedPassword"
    ) =>
    (event) => {
      const newRequest = cloneDeep(request)
      const value = event.target.value
      if (key === "company_name") {
        newRequest.company_name = value
        if (!value) {
          newRequest.company_role = {
            id: "other",
            custom_role: "",
          }
          setSelectedRole({ name: "Other", id: "other" })
        }
      } else {
        newRequest[key] = value
      }
      setRequest(newRequest)
      if (key === "email") {
        setStatusInputEmail(changeLabelStatusEmail(event.target.value))
      }
      if (key === "password") {
        getStatusInputPassword(event.target.value)
      }
      if (key === "confirmedPassword") {
        getStatusInputConfirm(event.target.value, request.password)
      }
    }
  const handleChangeCompanyRole = (data: { value: string; label: string }) => {
    setSelectedRole({
      name: data.label,
      id: data.value,
    })

    let newRequest = cloneDeep(request)
    if (data.value === "other") {
      newRequest.company_role = {
        id: "other",
        custom_role: "",
      }
    } else {
      newRequest.company_role = { id: data.value, custom_role: "" }
    }
    setRequest(newRequest)
  }

  const handleChangeCustomRole = (event) => {
    const newRequest = cloneDeep(request)
    newRequest.company_role = {
      id: "other",
      custom_role: event.target.value,
    }
    setRequest(newRequest)
    customRole.setValue(event.target.value)
  }

  const onClickButton = async () => {
    if (!checkbox.value) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.CHECKBOX_SIGN_UP}
          type="error"
        />
      )
      return
    }
    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }

    const emailExists = await checkEmailExist(request.email)
    if (emailExists) return
    let backupKeyCode = request.backupKeyCode
    if (!backupKeyCode) {
      backupKeyCode = encryptionHelper.createBackupKey()
      setRequest({ ...request, backupKeyCode: backupKeyCode })
    }
    openRecoveryModal.setValue(true)
  }
  const onSubmit = async () => {
    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }
    isLoading.setValue(true)
    configureStore.dispatch(startLoading())
    try {
      const token = await executeRecaptcha(ACTION_RECAPTCHA.SIGN_UP)
      const dataRequest: SignUpRequest = {
        ...omit(request, "confirmedPassword"),
        captcha: token,
      }
      umamiTracking("sign-up")
      signUpMiddleware(dataRequest, (type: STATUS_RESPONSE, messenger) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          pushTo(PATH.confirmEmail, "", {
            type: TYPE_RESEND_EMAIL.VERIFICATION,
            email: request.email,
          })
        } else {
          toast(
            <LabelNotificationPage messenger={messenger ?? ""} type="error" />
          )
        }
      })
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const handleDisableButtonAccount = () => {
    const {
      fullname,
      email,
      password,
      confirmedPassword,
      company_name,
      company_role,
    } = request
    const isEmailError = statusInputEmail.status === STATUS_INPUT.ERROR
    const isPasswordError = statusInputPassword.status === STATUS_INPUT.ERROR

    if (!fullname) return true
    if (!email || isEmailError) return true
    if (!password || isPasswordError) return true
    if (confirmedPassword !== password) return true

    if (company_name) {
      if (!company_role?.id) return true
      if (company_role.id === "other" && !company_role.custom_role) return true
    }

    return false
  }

  const onKeyPressInput = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButtonAccount()) {
        return
      }
      onClickButton()
    }
  }
  return (
    <PageLayoutAuth>
      <div className="h-full w-full flex flex-col p-6">
        <Header title="Sign up to Tracelium" />
        <LabelChangeRouter
          title="Already have an account?"
          label="Sign in"
          path={PATH.login}
        />
        <div className="mt-6">
          <FormInputCard title="Full Name" required>
            <InputDefault
              value={request.fullname}
              onChange={handleChangeInput("fullname")}
              onKeyPress={onKeyPressInput}
            />
          </FormInputCard>
          <FormInputCard title="Email" required>
            <InputDefault
              value={request.email}
              onChange={handleChangeInput("email")}
              status={statusInputEmail.status}
              labelStatus={statusInputEmail.label}
              onKeyPress={onKeyPressInput}
            />
          </FormInputCard>
          <FormInputCard
            title="Password"
            required
            isTippy
            label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
            showToolTip={showToolTip}
            visible={isMobile ? showToolTip.value : undefined}
            onClickOutside={() =>
              isMobile ? showToolTip.setValue(false) : undefined
            }
          >
            <InputDefault
              type="password"
              isPassword
              value={request.password}
              onChange={handleChangeInput("password")}
              onKeyPress={onKeyPressInput}
              status={statusInputPassword.status}
              labelStatus={statusInputPassword.label}
            />
          </FormInputCard>
          <FormInputCard
            title="Confirm password"
            required
            isTippy
            showToolTip={showToolTip}
            visible={isMobile ? showToolTip.value : undefined}
            onClickOutside={() =>
              isMobile ? showToolTip.setValue(false) : undefined
            }
          >
            <InputDefault
              type="password"
              isPassword
              value={request.confirmedPassword}
              onChange={handleChangeInput("confirmedPassword")}
              onKeyPress={onKeyPressInput}
              status={statusInputConfirm.status}
              labelStatus={statusInputConfirm.label}
            />
          </FormInputCard>
          <FormInputCard title="Company " label="(Optional)">
            <InputDefault
              value={request.company_name}
              onChange={handleChangeInput("company_name")}
              onKeyPress={onKeyPressInput}
            />
          </FormInputCard>
          {request.company_name && (
            <>
              <FormInputCard title="Role" required>
                <SelectMulti
                  options={[
                    ...cloneCompanyRoles.map((role) => {
                      return { label: role.name, value: role.id }
                    }),
                    { label: "Other", value: "other" },
                  ]}
                  selectedOption={{
                    label: selectedRole?.name,
                    value: selectedRole?.id,
                  }}
                  handleChange={handleChangeCompanyRole}
                  isMulti={false}
                  noOptionsMessage
                ></SelectMulti>
              </FormInputCard>
              {selectedRole?.id === "other" && (
                <FormInputCard title="Role name" required>
                  <InputDefault
                    value={customRole.value}
                    onChange={handleChangeCustomRole}
                    onKeyPress={onKeyPressInput}
                  />
                </FormInputCard>
              )}
            </>
          )}
        </div>
        <FormInputSignUpCard
          checkbox={checkbox}
          onClickButton={onClickButton}
          handleDisableButton={handleDisableButtonAccount()}
        />
      </div>
      {openRecoveryModal.value && request.backupKeyCode && (
        <RecoveryModal
          handleDisplayCloseIcon={() => () => openRecoveryModal.setValue(false)}
          backUpKey={request.backupKeyCode}
          cancelText="Download"
          onCloseModal={() => {
            downLoadBackUpKey(request.backupKeyCode, request.email)
          }}
          onClickDownLoad={() => {
            downLoadBackUpKey(request.backupKeyCode, request.email)
          }}
          submitText="Continue"
          onClickSubmit={onSubmit}
        />
      )}
    </PageLayoutAuth>
  )
}
export default SignUp
