import * as THREE from "three"
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter"
import occtimportjs from "occt-import-js/dist/occt-import-js"

let occtImportPromise = null
const wasmUrl = "/occt-import-js.wasm"
export const loadOcctImport = async () => {
  if (occtImportPromise) {
    return occtImportPromise
  }
  const occt = occtimportjs({
    locateFile: () => {
      return wasmUrl
    },
  })
  occtImportPromise = occt
  return occtImportPromise
}

const urlForArrayBuffer = (buffer: ArrayBuffer) => {
  const blob = new Blob([buffer], { type: "application/octet-stream" })
  return URL.createObjectURL(blob)
}

export function convertObjectToGlb(obj: any) {
  return new Promise((resolve) => {
    const exporter = new GLTFExporter()
    exporter.parse(
      obj,
      (result) => {
        const url = urlForArrayBuffer(result as ArrayBuffer)
        resolve(url)
      },
      (error) => {
        console.error(error)
      },
      {
        binary: true,
        onlyVisible: false,
        forceIndices: true,
      }
    )
  })
}

export const convertStepToGlBObject = async (fileUrl: string) => {
  const occt = (await loadOcctImport()) as any
  if (!occt) return
  const targetObject = new THREE.Object3D()
  const response = await fetch(fileUrl)
  const buffer = await response.arrayBuffer()
  // read the imported step file
  const fileBuffer = new Uint8Array(buffer)
  const result = occt.ReadStepFile(fileBuffer, null)
  // process the geometries of the result
  for (const resultMesh of result.meshes) {
    let geometry = new THREE.BufferGeometry()

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(resultMesh.attributes.position.array, 3)
    )
    if (resultMesh.attributes.normal) {
      geometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(resultMesh.attributes.normal.array, 3)
      )
    }
    const index = Uint32Array.from(resultMesh.index.array)
    geometry.setIndex(new THREE.BufferAttribute(index, 1))

    let material: any = null
    if (resultMesh.color) {
      const color = new THREE.Color(
        resultMesh.color[0],
        resultMesh.color[1],
        resultMesh.color[2]
      )
      material = new THREE.MeshPhongMaterial({ color })
    }

    if (resultMesh.face_colors) {
      // console.log(resultMesh.face_colors)
      material = new THREE.MeshPhongMaterial({
        vertexColors: true,
      })
      // For vertices
      geometry = geometry.toNonIndexed()
      const faceColors = new Array(
        geometry.attributes.position.array.length
      ).fill(0)
      for (const faceColorGroup of resultMesh.face_colors) {
        const { color, first, last } = faceColorGroup
        for (let i = first; i <= last; i++) {
          faceColors.splice(Math.floor(i * 9), 9, ...color, ...color, ...color)
        }
      }

      geometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(faceColors, 3)
      )
    }

    if (!material) {
      material = new THREE.MeshPhongMaterial({ color: "#c8c8c8" })
    }

    const mesh = new THREE.Mesh(geometry, material)
    targetObject.add(mesh)
  }
  return convertObjectToGlb(targetObject)
}
