import React, { CSSProperties } from "react"

export enum BuildStatusNumber {
  CANCELLED = 1,
  COMMITTED = 2,
  COMPLETED = 3,
  DRAFT = 4,
  IN_PRODUCTION = 5,
  RFQ = 6,
  RFQ_TEST_SETUP = 7,
  DELETED_ATTACHMENT = 8,
  IN_PROGRESS = 9,
  DATA_EXCHANGE = 10,
}

export enum STATUS_BUILD {
  DRAFT = "Draft",
  COMMITTED = "Committed",
  NEXT_RFQ = "Next",
  TEST_SETUP = "Test Setup",
  RFQ = "RFQ",
  NEXT_PRODUCTION = "Next",
  IN_PRODUCTION = "In Production",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  REQUEST_FOR_PRODUCTION = "Request for Production",
  EMPTY = "",
  MERGED = "Merged",
  DELETED = "Deleted",
  IN_PROGRESS = "In Progress",
  SAVE = "Saved",
  UNAPPROVE = "Rejected",
  DATA_EXCHANGE = "Data exchange",
}
export const STATUS_BUILD_VALUE = {
  [BuildStatusNumber.CANCELLED]: STATUS_BUILD.CANCELLED,
  [BuildStatusNumber.COMMITTED]: STATUS_BUILD.COMMITTED,
  [BuildStatusNumber.COMPLETED]: STATUS_BUILD.COMPLETED,
  [BuildStatusNumber.RFQ]: STATUS_BUILD.RFQ,
  [BuildStatusNumber.RFQ_TEST_SETUP]: STATUS_BUILD.TEST_SETUP,
  [BuildStatusNumber.IN_PRODUCTION]: STATUS_BUILD.IN_PRODUCTION,
  [BuildStatusNumber.DRAFT]: STATUS_BUILD.DRAFT,
  [BuildStatusNumber.DATA_EXCHANGE]: STATUS_BUILD.DATA_EXCHANGE,
  [BuildStatusNumber.IN_PROGRESS]: STATUS_BUILD.IN_PROGRESS,
}

export const STATUS_BUILD_SORTED = [
  STATUS_BUILD.DRAFT,
  STATUS_BUILD.COMMITTED,
  STATUS_BUILD.RFQ,
  STATUS_BUILD.TEST_SETUP,
  STATUS_BUILD.IN_PRODUCTION,
  STATUS_BUILD.IN_PROGRESS,
  STATUS_BUILD.COMPLETED,
  STATUS_BUILD.CANCELLED,
  STATUS_BUILD.DELETED,
]

export const BUILD_FINISHED_STATUSES = [
  STATUS_BUILD.COMPLETED,
  STATUS_BUILD.CANCELLED,
]

export const BUILD_NOT_ALLOW_ADD_INVITEE_STATUSES = [
  STATUS_BUILD.COMPLETED,
  STATUS_BUILD.CANCELLED,
  STATUS_BUILD.IN_PRODUCTION,
  STATUS_BUILD.IN_PROGRESS,
]

export enum ProjectComponentStatus {
  Active = 1,
  DeletedAttachment = 2,
}

export interface StatusProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: STATUS_BUILD
  containerStyle?: CSSProperties
  customClass?: string
}
