import ModalDelete from "components/ModalCustom/ModalDelete"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { useBoolean } from "helpers/hooks"
import {
  checkPermissionPage,
  handleCheckUpdateFileComponent,
  messageAndURLRemoveFileComponent,
} from "helpers/utils"
import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import { STATUS_TYPE_FILE_BUILD } from "../build-history.constant"
import { PermissionProjectComponentPage } from "../contexts/PermissionProjectComponentPage.context"
import UpdateFileItem from "../molecules/UpdateFileItem"
import {
  addEncryptedPcbFileMiddleware,
  addPcbFileMiddleware,
  deleteFilePCBTemplateMiddleware,
  uploadAssemblyFileMiddleware,
  uploadAttachmentsMiddleware,
  uploadBOMFileMiddleware,
  uploadStackupFileMiddleware,
} from "../services"
import { InfoFileDetail, ExtendedViewType, FileUrlProp } from "../types"
import FormSubComponentBOM from "./FormSubComponentBOM"
import AttachmentOtherCard from "./AttachmentOtherCard"
import { EncryptionKeys } from "controllers/EncryptionController"
import { isEmpty } from "lodash"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"
interface Props {
  idComponentHistory: string
  handleUpdateDataPCB: (idComponentHistory: string, type?: string) => void
  infoFileGerber: InfoFileDetail
  infoFileBOM: InfoFileDetail
  infoFileStackup: InfoFileDetail
  infoFileAssembly: InfoFileDetail
  listFileUrl: FileUrlProp[]
  detailViewType: ExtendedViewType
  setDetailViewType: (type: ExtendedViewType) => void
  handleUpdateBomFile?: (idHistory: string) => void
  handleDeleteAttachment: (fileId: string) => void
  isDeleted?: boolean
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.idComponentHistory === nextProps.idComponentHistory &&
    prevProps.infoFileGerber === nextProps.infoFileGerber &&
    prevProps.infoFileBOM === nextProps.infoFileBOM &&
    prevProps.infoFileStackup === nextProps.infoFileStackup &&
    prevProps.infoFileAssembly === nextProps.infoFileAssembly &&
    prevProps.detailViewType === nextProps.detailViewType &&
    prevProps.isDeleted === nextProps.isDeleted &&
    prevProps.listFileUrl === nextProps.listFileUrl
  )
}

const UpdateFileBuildCard = React.memo((props: Props) => {
  const {
    idComponentHistory,
    infoFileAssembly,
    infoFileBOM,
    infoFileGerber,
    infoFileStackup,
    listFileUrl,
    handleUpdateDataPCB,
    detailViewType,
    setDetailViewType,
    handleUpdateBomFile,
    handleDeleteAttachment,
    isDeleted,
  } = props
  const {
    archiveProject,
    viewOnlyShare,
    archiveComponent,
    archiveBuild,
    isNoUpdateFilePCB,
  } = useContext(PermissionProjectComponentPage)

  const { workerUploadFile } = useUploadFileWorkerController()

  const isLoading = useBoolean(false)
  const isDelete = useBoolean(false)
  const isFormSubComponent = useBoolean(false)
  const [typeFile, setTypeFile] = useState({
    status: "",
    urlDelete: "",
    messageSuccess: "",
    messageError: "",
  })

  const handleUpdateFileGerber = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        "rar,zip,7z"
      )
    ) {
      return
    }
    isLoading.setValue(true)
    const currentProjectEncryptionKey = localStorage.getItem(
      EncryptionKeys.currentProjectEncryptionKey
    )
    if (isEmpty(currentProjectEncryptionKey)) {
      addPcbFileMiddleware(
        idComponentHistory,
        newFile,
        onChangeDataWhenUpdateFile
      )
    } else {
      if (workerUploadFile) {
        addEncryptedPcbFileMiddleware(
          workerUploadFile,
          idComponentHistory,
          newFile,
          onChangeDataWhenUpdateFile
        )
      }
    }
    setDetailViewType(ExtendedViewType.Gerber)
  }

  const handleUpdateFileBom = async (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        "csv,xls,xlsx",
        "csv|xls|xlsx"
      )
    ) {
      return
    }
    if (!workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    isLoading.setValue(true)
    await uploadBOMFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      newFile,
      (type, message) => {
        if (handleUpdateBomFile) {
          handleUpdateBomFile(idComponentHistory)
        }
        onChangeDataWhenUpdateFile(type, message)
      }
    )
    setDetailViewType(ExtendedViewType.Bom)
  }
  const handleUpdateFileStackup = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        "pdf,png,jpg,jpeg",
        "pdf|png|jpg|jpeg"
      )
    ) {
      return
    }

    if (!workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    isLoading.setValue(true)
    configureStore.dispatch(startLoading())

    uploadStackupFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      newFile,
      onChangeDataWhenUpdateFile
    )
  }
  const handleUpdateFileAssembly = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        ".exe,.bin,.bat",
        "exe|bin|bat",
        true
      )
    ) {
      return
    }
    if (!workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    isLoading.setValue(true)
    configureStore.dispatch(startLoading())
    uploadAssemblyFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      newFile,
      onChangeDataWhenUpdateFile
    )
  }
  const handleAddAttachments = (fileRequest: FormData, _fileErrors: File[]) => {
    if (!workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    configureStore.dispatch(startLoading())
    isLoading.setValue(true)
    uploadAttachmentsMiddleware(
      workerUploadFile,
      idComponentHistory,
      fileRequest,
      (type: STATUS_RESPONSE, messenger: string, iLoadAPI: boolean) => {
        if (type === STATUS_RESPONSE.SUCCESS && iLoadAPI) {
          handleUpdateDataPCB(idComponentHistory)
        }
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
      }
    )
  }

  const onChangeDataWhenUpdateFile = (
    type: STATUS_RESPONSE,
    messenger: string
  ) => {
    if (type === STATUS_RESPONSE.SUCCESS) {
      handleUpdateDataPCB(idComponentHistory)
    }
    isLoading.setValue(false)
    toast(<LabelNotificationPage messenger={messenger} type={type} />)
  }
  const getFileNameAndType = (type: STATUS_TYPE_FILE_BUILD) => {
    let result = {
      pcbType: "gerber",
      fileName: infoFileGerber.name,
    }
    switch (type) {
      case STATUS_TYPE_FILE_BUILD.ASSEMBLY:
        result = {
          pcbType: "assembly",
          fileName: infoFileAssembly.name,
        }
        break
      case STATUS_TYPE_FILE_BUILD.STACKUP:
        result = {
          pcbType: "stackup",
          fileName: infoFileStackup.name,
        }
        break
      case STATUS_TYPE_FILE_BUILD.BOM:
        result = {
          pcbType: "bom",
          fileName: infoFileBOM.name,
        }
        break

      default:
        result = {
          pcbType: "gerber",
          fileName: infoFileGerber.name,
        }
        break
    }
    return result
  }
  const onRemoveFile = (type: STATUS_TYPE_FILE_BUILD) => {
    const { messageError, messageSuccess, urlDelete } =
      messageAndURLRemoveFileComponent(type)
    setTypeFile({
      status: type,
      messageSuccess,
      messageError,
      urlDelete,
    })
    isDelete.setValue(true)
  }
  const onSubmitDeleteFile = () => {
    if (!idComponentHistory) {
      return
    }
    const { pcbType, fileName } = getFileNameAndType(typeFile.status as any)
    isLoading.setValue(true)
    deleteFilePCBTemplateMiddleware(
      idComponentHistory,
      typeFile.urlDelete,
      (type: STATUS_RESPONSE, messenger: string) => {
        if (type === STATUS_RESPONSE.SUCCESS) {
          handleUpdateDataPCB(idComponentHistory, typeFile.status)
          if (handleUpdateBomFile) {
            handleUpdateBomFile(idComponentHistory)
          }
          isDelete.setValue(false)
        }
        isLoading.setValue(false)
        const newMessenger =
          type === STATUS_RESPONSE.SUCCESS
            ? typeFile.messageSuccess
            : typeFile.messageError
        toast(
          <LabelNotificationPage
            messenger={messenger ? messenger : newMessenger}
            type={type}
          />
        )
      },
      fileName,
      pcbType
    )
  }

  const renderModal = () => {
    return (
      <React.Fragment>
        {isDelete.value && (
          <ModalDelete
            onCloseModal={() => isDelete.setValue(false)}
            title={`Are you sure to delete file ${typeFile.status}?`}
            label={`Confirmation`}
            onSubmit={onSubmitDeleteFile}
            titleButton="Delete"
          />
        )}
        {isFormSubComponent.value ? (
          <FormSubComponentBOM
            openModal={isFormSubComponent.value}
            onCloseModal={() => isFormSubComponent.setValue(false)}
            idHitory={idComponentHistory}
            nameBOM={infoFileBOM.name}
          />
        ) : null}
      </React.Fragment>
    )
  }
  return (
    <div>
      <div className="grid grid-cols-box-4-min-324 md:grid-cols-4 gap-2 mr-6 mt-6 pb-2 md:pb-0 overflow-x-auto min-h-[138px]">
        <UpdateFileItem
          title={STATUS_TYPE_FILE_BUILD.GERBER}
          handleUpdateFile={handleUpdateFileGerber}
          containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.GERBER}`}
          file={infoFileGerber.file}
          fileName={infoFileGerber.name}
          onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.GERBER)}
          isAction={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
            component: archiveComponent,
            noUpdateFilePCB: isNoUpdateFilePCB,
            build: archiveBuild,
          })}
          acceptFile=".rar,.zip,.7z"
          detailViewType={detailViewType}
          setDetailViewType={setDetailViewType}
          isDeleted={isDeleted}
          versionId={idComponentHistory}
        />
        <UpdateFileItem
          title={STATUS_TYPE_FILE_BUILD.BOM}
          handleUpdateFile={handleUpdateFileBom}
          containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.BOM}`}
          file={infoFileBOM.file}
          fileName={infoFileBOM.name}
          onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.BOM)}
          isAction={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
            component: archiveComponent,
            noUpdateFilePCB: isNoUpdateFilePCB,
            build: archiveBuild,
          })}
          acceptFile=".csv,.xls,.xlsx"
          detailViewType={detailViewType}
          setDetailViewType={setDetailViewType}
          isDeleted={isDeleted}
          versionId={idComponentHistory}
        />
        <UpdateFileItem
          title={STATUS_TYPE_FILE_BUILD.STACKUP}
          handleUpdateFile={handleUpdateFileStackup}
          containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.STACKUP}`}
          file={infoFileStackup.file}
          fileName={infoFileStackup.name}
          onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.STACKUP)}
          isAction={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
            component: archiveComponent,
            noUpdateFilePCB: isNoUpdateFilePCB,
            build: archiveBuild,
          })}
          acceptFile=".pdf,.png,.jpg,.jpeg"
          isDeleted={isDeleted}
          versionId={idComponentHistory}
        />
        <UpdateFileItem
          title={STATUS_TYPE_FILE_BUILD.ASSEMBLY}
          handleUpdateFile={handleUpdateFileAssembly}
          containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.ASSEMBLY}`}
          file={infoFileAssembly.file}
          fileName={infoFileAssembly.name}
          onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.ASSEMBLY)}
          isAction={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
            component: archiveComponent,
            noUpdateFilePCB: isNoUpdateFilePCB,
            build: archiveBuild,
          })}
          acceptFile="*"
          isDeleted={isDeleted}
          versionId={idComponentHistory}
        />
        {renderModal()}
      </div>
      <AttachmentOtherCard
        listFileUrl={listFileUrl}
        viewOnly={checkPermissionPage({
          project: archiveProject,
          viewShare: viewOnlyShare,
          component: archiveComponent,
          noUpdateFilePCB: isNoUpdateFilePCB,
          build: archiveBuild,
        })}
        typeOther={"pcb"}
        handleUpdateDataWhenDeleteFile={handleDeleteAttachment}
        handleUpdateDataWhenAddFile={handleAddAttachments}
        isDeleted={isDeleted}
        versionId={idComponentHistory}
      />
    </div>
  )
}, isEqualProps)
export default UpdateFileBuildCard
