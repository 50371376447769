import IconFileType from "assets/images/icons/icon-file-type.svg"
import {
  openFileOnNewTab,
  removeTimestampInFileName,
  showTypeFile,
} from "helpers/utils"
import Tippy from "@tippyjs/react"

interface AttachmentFileProps {
  file: string
  filename: string
  project_component_history_id?: string
  conversation_id?: string
}

export default (props: AttachmentFileProps) => {
  const { file, filename, conversation_id, project_component_history_id } =
    props

  return (
    <>
      <Tippy
        className="custom-tippy-menu-left"
        placement="top"
        content={
          <span
            className="hidden md:block font-normal"
            style={{
              fontSize: 13,
              lineHeight: "22px",
            }}
          >
            Click to open file on new tab
          </span>
        }
      >
        <div
          className="relative w-10 cursor-pointer"
          onClick={() => {
            openFileOnNewTab(
              file,
              conversation_id
                ? { conversation_id }
                : { project_component_history_id }
            )
          }}
        >
          <img src={IconFileType} alt="icon" style={{ width: 40 }} />
          <div
            className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"
            style={{
              paddingTop: 10,
            }}
          >
            <p
              className="font-semibold uppercase"
              style={{
                fontSize: 10,
                lineHeight: "14px",
                color: "#FFF",
              }}
            >
              {showTypeFile(filename ?? "N/A")}
            </p>
          </div>
        </div>
      </Tippy>

      <div className="flex items-center cursor-pointer">
        <Tippy
          className="custom-tippy-menu-left"
          placement="top"
          content={
            <span
              className="hidden md:block font-normal"
              style={{
                fontSize: 13,
                lineHeight: "22px",
              }}
            >
              {removeTimestampInFileName(filename)}
            </span>
          }
        >
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#111111",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              openFileOnNewTab(
                file,
                conversation_id
                  ? { conversation_id }
                  : { project_component_history_id }
              )
            }}
          >
            {removeTimestampInFileName(filename)}
          </p>
        </Tippy>
      </div>
    </>
  )
}
