import BackdropCustomize from "components/BackdropCustomize"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { useBoolean } from "helpers/hooks"
import {
  converDataWhenSaveSpecification,
  converSpecifications,
  converSpecificationsRequice,
} from "helpers/utils"
import { cloneDeep, isArray, isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import {
  putInviteeSpecificationHistoryMiddleware,
  putSpecificationHistoryMiddleware,
} from "../services"
import { PartsSpecificationDetail, PCBSpecificationSelection } from "../types"
import SpecificationCard from "./SpecificationCard"

interface Props {
  specifications: PCBSpecificationSelection[]
  partSpecificationRequice: PartsSpecificationDetail[]
  setPartSpecificationRequice?: (newRequice: PartsSpecificationDetail[]) => void
  idComponentHistory: string
  viewOnly: boolean
  handleUpdateSpecifications?: (oldIdComponentHistory: string) => void
  setSpecificationRequired?: (newValue: string[]) => void
  updatedAtComponentDetail?: (newTime: string) => void
  isToggleSpecification: boolean
  conversationId?: string
  isInvitee?: boolean
  decryptedShareKey?: string
  isUploadSpecification: boolean
  isShowErrorSpecification: boolean
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.conversationId === nextProps.conversationId &&
    prevProps.idComponentHistory === nextProps.idComponentHistory &&
    prevProps.viewOnly === nextProps.viewOnly &&
    JSON.stringify(prevProps.specifications) ===
      JSON.stringify(nextProps.specifications) &&
    JSON.stringify(prevProps.partSpecificationRequice) ===
      JSON.stringify(nextProps.partSpecificationRequice) &&
    prevProps.isToggleSpecification === nextProps.isToggleSpecification &&
    prevProps.decryptedShareKey === nextProps.decryptedShareKey &&
    prevProps.isUploadSpecification === nextProps.isUploadSpecification &&
    prevProps.isShowErrorSpecification === nextProps.isShowErrorSpecification
  )
}

const SpecificationViewerCard = React.memo((props: Props) => {
  const {
    specifications,
    idComponentHistory,
    viewOnly,
    handleUpdateSpecifications,
    partSpecificationRequice,
    setPartSpecificationRequice,
    setSpecificationRequired,
    updatedAtComponentDetail,
    conversationId,
    isInvitee,
    decryptedShareKey,
    isUploadSpecification,
    isShowErrorSpecification,
  } = props
  const [wholeData, setWholeData] = useState<any>({})
  const [formData, setFormData] = useState<any>({})
  const [files, setFiles] = useState<any>([])
  const [formDataEditor, setFormDataEditor] = useState<any>({})
  const [formDataEditorFile, setFormDataEditorFile] = useState<any>({})
  const [formDataEditorDeleteFile, setFormDataEditorDeleteFile] = useState<any>(
    {}
  )
  const isLoadingSave = useBoolean()
  const isCreate = useBoolean(true)

  useEffect(() => {
    getSpecification(specifications)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(specifications)])

  const getSpecification = async (
    newSpecifications: PCBSpecificationSelection[]
  ) => {
    const { newFormData, newFormDataEditor, newFormDataEditorFile } =
      converSpecifications(newSpecifications)
    setFormData(newFormData)
    setFormDataEditor(newFormDataEditor)
    setFormDataEditorFile(newFormDataEditorFile)
    if (newSpecifications.length && newSpecifications[0].parts.length) {
      isCreate.setValue(
        newSpecifications[0].parts.filter((el) => el.value).length
          ? false
          : true
      )
    }
  }
  const updateDataForm = (
    type: "formData" | "formEditor" | "formAddFile" | "formDeleteFile",
    field: string,
    newValue: any,
    isRequired: boolean,
    _isEditor?: boolean
  ) => {
    const newFormData = cloneDeep(formData)
    const newFormDataEditor = cloneDeep(formDataEditor)
    const newFormDataEditorFile = cloneDeep(formDataEditorFile)
    const newFormDataEditorDeleteFile = cloneDeep(formDataEditorDeleteFile)
    switch (type) {
      case "formDeleteFile":
        setFormDataEditorDeleteFile({
          ...formDataEditorDeleteFile,
          [field]: newValue,
        })
        newFormDataEditorDeleteFile[field] = newValue
        break
      case "formAddFile":
        setFormDataEditorFile({
          ...formDataEditorFile,
          [field]: newValue,
        })
        newFormDataEditorFile[field] = newValue
        break
      case "formEditor":
        setFormDataEditor({
          ...formDataEditor,
          [field]: newValue,
        })
        newFormDataEditor[field] = newValue
        break
      default:
        setFormData({
          ...formData,
          [field]: newValue,
        })
        newFormData[field] = newValue
        break
    }

    // debounceHandleSaveSpecification(
    //   field,
    //   newValue,
    //   isRequired,
    //   newFormData,
    //   newFormDataEditor,
    //   newFormDataEditorFile,
    //   newFormDataEditorDeleteFile
    // )
    setWholeData({
      field,
      newValue,
      isRequired,
      newFormData,
      newFormDataEditor,
      newFormDataEditorFile,
      newFormDataEditorDeleteFile,
    })
  }

  const handleSaveSpecification = (
    field: string,
    newValue: any,
    isRequired: boolean,
    newFormData: any,
    newFormDataEditor: any,
    newFormDataEditorFile: any,
    newFormDataEditorDeleteFile: any
  ) => {
    if (!idComponentHistory) {
      return
    }
    const input = new FormData()
    const { resultFromData, resultFromDataEditor } =
      converDataWhenSaveSpecification(
        newFormData,
        newFormDataEditor,
        newFormDataEditorFile,
        newFormDataEditorDeleteFile
      )
    const dataRequest = resultFromData.concat(resultFromDataEditor)
    changeSpecificationRequire(isRequired, field, newValue, dataRequest)
    const isLoadingPage = isArray(newValue) && newValue.length ? true : false
    if (isLoadingPage) {
      isLoadingSave.setValue(true)
    }
    if (isCreate.value) {
      if (isInvitee) {
        return putInviteeSpecificationHistoryMiddleware(
          idComponentHistory,
          dataRequest,
          input,
          (type: STATUS_RESPONSE, messenger: string) =>
            handleUpdateDataWhenCallAPI(type, messenger, isLoadingPage),
          conversationId
        )
      }
      return putSpecificationHistoryMiddleware(
        idComponentHistory,
        dataRequest,
        input,
        (type: STATUS_RESPONSE, messenger: string) =>
          handleUpdateDataWhenCallAPI(type, messenger, isLoadingPage)
      )
    }

    if (files[0]) {
      for (let i = 0; i < files.length; i++) {
        input.append("files", files[i])
      }

      // input.append("file", files[0])
    }
    if (isInvitee) {
      return putInviteeSpecificationHistoryMiddleware(
        idComponentHistory,
        dataRequest,
        input,
        (type: STATUS_RESPONSE, messenger: string) =>
          handleUpdateDataWhenCallAPI(type, messenger, isLoadingPage),
        conversationId
      )
    }
    return putSpecificationHistoryMiddleware(
      idComponentHistory,
      dataRequest,
      input,
      (type: STATUS_RESPONSE, messenger: string) =>
        handleUpdateDataWhenCallAPI(type, messenger, isLoadingPage)
    )
  }

  const changeSpecificationRequire = (
    isRequired: boolean,
    field: string,
    newValue: any,
    dataRequest: any
  ) => {
    if (isRequired) {
      const idPartSplit = field.split("_")
      if (idPartSplit.length) {
        const newListRequice = cloneDeep(partSpecificationRequice) || []
        const index = newListRequice.findIndex((el) => el.id === idPartSplit[0])
        const valueDataRequest = dataRequest.find(
          (el) => el.specification_part_id === idPartSplit[0]
        )
        if (
          index > -1 &&
          setPartSpecificationRequice &&
          setSpecificationRequired
        ) {
          newListRequice[index].value = valueDataRequest
            ? valueDataRequest.value
            : newValue
          setPartSpecificationRequice(newListRequice)
          setSpecificationRequired(converSpecificationsRequice(newListRequice))
        }
      }
    }
  }

  const handleUpdateDataWhenCallAPI = (
    type: STATUS_RESPONSE,
    messenger: string,
    isUpdate: boolean
  ) => {
    isLoadingSave.setValue(false)
    if (type === STATUS_RESPONSE.SUCCESS) {
      setFormDataEditorFile({})
      setFormDataEditorDeleteFile({})
      isCreate.setValue(false)
      if (updatedAtComponentDetail) {
        updatedAtComponentDetail(new Date().toISOString())
      }

      if (handleUpdateSpecifications && isUpdate) {
        handleUpdateSpecifications(idComponentHistory)
      }
      return
    }
    toast(<LabelNotificationPage messenger={messenger} type={type} />)
  }
  const renderLoading = () => {
    if (!isLoadingSave.value) {
      return null
    }
    return <BackdropCustomize />
  }
  const handleAddFile = (files: any) => {
    setFiles(files)
  }
  useEffect(() => {
    if (!isEmpty(wholeData) && isUploadSpecification) {
      const handler = setTimeout(() => {
        handleSaveSpecification(
          wholeData.field,
          wholeData.newValue,
          wholeData.isRequired,
          wholeData.newFormData,
          wholeData.newFormDataEditor,
          wholeData.newFormDataEditorFile,
          wholeData.newFormDataEditorDeleteFile
        )
        setFiles([])
      }, 500)

      return () => {
        clearTimeout(handler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholeData, isUploadSpecification])
  return (
    <div>
      <VersionSpecificationContext.Provider
        value={{
          formData,
          formDataEditor,
          formDataEditorFile,
          formDataEditorDeleteFile,
          decryptedShareKey,
          isShowErrorSpecification,
          updateDataForm,
        }}
      >
        <div className="flex flex-col p-3">
          {specifications.map((el, index) => (
            <SpecificationCard
              key={index}
              item={el}
              disabled={viewOnly}
              conversationId={conversationId}
              versionId={idComponentHistory}
              handleAddMultipartFile={handleAddFile}
            />
          ))}
        </div>
      </VersionSpecificationContext.Provider>
      {renderLoading()}
    </div>
  )
}, isEqualProps)

export default SpecificationViewerCard
