import { useBoolean } from "helpers/hooks"
import { downloadEncrypted } from "helpers/utils"
import DotCard from "pages/conversations/atoms/DotCard"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import { BuildHistoryDetail } from "pages/project-component-detail/types"
import TypeComponent from "pages/project-component/molecules/TypeComponent"
import { ProjectComponentDetail } from "pages/project-component/types"
import React from "react"
import { STATUS_BUILD } from "components/Status/types"
import {
  deleteInviteeDraft,
  revertInviteeVersion,
} from "./services/invitee.api"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import FormCreateForInvitee from "components/HistoryTree/Form/FormCreateForInvitee"
import { CONVERSATION_ROLE } from "pages/conversations/conversations.type"
import FormCommitInvitee from "components/HistoryTree/Form/FormCommitInvitee"
import ModalDelete from "components/ModalCustom/ModalDelete"
import Button from "components/Button/Button"
import FormConfirm from "components/HistoryTree/Form/FormConfirm"
interface Props {
  projectComponentDetail: ProjectComponentDetail
  idProjectBuildComponentParam: string
  idConversationParam: string
  isInvitee: boolean
  idProjectComponentHistoryParam: string
  isDownload?: boolean
  nameBOM: string
  isCloseConversation: boolean
  isReadComponent: boolean
  isDeleted?: boolean
  historyDetail: BuildHistoryDetail
  isSentInvite: boolean
  setIsReadComponent: (newIsRead: boolean) => void
  handleUpdateDataWhenChangeHistory: (getFirst?: boolean) => void
  conversationRole: CONVERSATION_ROLE
  commitButtonStage?: {
    isActive: boolean
    tooltipHelper: string
  }
  isDisableEditHistory: boolean
  isDisableRevertHistory: boolean
  extraConditionsToDisableCommitBtn: {
    specificationRequired: string[]
    isUpdatingBom: boolean
  }
  setHistoryDetail: React.Dispatch<React.SetStateAction<BuildHistoryDetail>>
  projectBuildId: string
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.projectComponentDetail === nextProps.projectComponentDetail &&
    prevProps.idProjectBuildComponentParam ===
      nextProps.idProjectBuildComponentParam &&
    prevProps.idConversationParam === nextProps.idConversationParam &&
    prevProps.isInvitee === nextProps.isInvitee &&
    prevProps.idProjectComponentHistoryParam ===
      nextProps.idProjectComponentHistoryParam &&
    prevProps.isDownload === nextProps.isDownload &&
    prevProps.nameBOM === nextProps.nameBOM &&
    prevProps.isCloseConversation === nextProps.isCloseConversation &&
    prevProps.isReadComponent === nextProps.isReadComponent &&
    prevProps.historyDetail === nextProps.historyDetail &&
    prevProps.isDeleted === nextProps.isDeleted &&
    prevProps.isSentInvite === nextProps.isSentInvite &&
    prevProps.projectBuildId === nextProps.projectBuildId &&
    JSON.stringify(prevProps.commitButtonStage) ===
      JSON.stringify(nextProps.commitButtonStage) &&
    JSON.stringify(prevProps.extraConditionsToDisableCommitBtn) ===
      JSON.stringify(nextProps.extraConditionsToDisableCommitBtn)
  )
}
const InfoInviteeComponentDetail = React.memo((props: Props) => {
  const {
    projectComponentDetail,
    idConversationParam,
    idProjectBuildComponentParam,
    // isInvitee,
    idProjectComponentHistoryParam,
    isDownload = true,
    nameBOM,
    // isCloseConversation,
    historyDetail,
    // setIsReadComponent,
    isDeleted,
    // isSentInvite,
    handleUpdateDataWhenChangeHistory,
    conversationRole,
    // commitButtonStage,
    // isDisableEditHistory,
    isDisableRevertHistory,
    // extraConditionsToDisableCommitBtn,
    // projectBuildId,
  } = props

  // const showTippyComment = useBoolean()
  const isLoading = useBoolean()
  const isCommit = useBoolean()
  const isEdit = useBoolean()
  const isDeleteDraft = useBoolean()
  const isRevert = useBoolean()
  // const handleClickTippyComment = (event) => {
  //   event.stopPropagation()
  //   showTippyComment.setValue(!showTippyComment.value)
  //   setIsReadComponent(true)
  // }
  const onDownloadFile = () => {
    if (!idProjectComponentHistoryParam) {
      return
    }
    isLoading.setValue(true)
    downloadEncrypted(
      `/api/project-component/history/${historyDetail.id}/files?conversation_id=${idConversationParam}`
    )
    isLoading.setValue(false)
  }
  // const onCommit = () => {
  //   if (historyDetail.status === STATUS_BUILD.COMMITTED) {
  //     return
  //   }
  //   if (extraConditionsToDisableCommitBtn.specificationRequired?.length) {
  //     toast(
  //       <LabelNotificationPage
  //         messenger={`PCB Specification Selection:${extraConditionsToDisableCommitBtn.specificationRequired?.join(
  //           ", "
  //         )} is required!`}
  //         type="warning"
  //       />
  //     )
  //     return
  //   }
  //   if (extraConditionsToDisableCommitBtn.isUpdatingBom) {
  //     toast(
  //       <LabelNotificationPage
  //         messenger={`Please update BOM data before committing!`}
  //         type="warning"
  //       />
  //     )
  //     return
  //   }
  //   isCommit.setValue(true)
  // }
  // const onEdit = () => {
  //   if (historyDetail.status === STATUS_BUILD.DRAFT || isDisableEditHistory) {
  //     return
  //   }
  //   isEdit.setValue(true)
  // }

  // const onApproved = () => {
  //   if (
  //     historyDetail.approved_status !== VERSION_APPROVED_STATUS.PENDING ||
  //     historyDetail.status === STATUS_BUILD.DRAFT
  //   )
  //     return
  //   approveInviteeVersion(
  //     historyDetail.id,
  //     idConversationParam,
  //     projectComponentDetail.code,
  //     historyDetail.code
  //   )
  //     .then((res) => {
  //       handleUpdateDataWhenChangeHistory()
  //       toast(<LabelNotificationPage messenger={`Success`} type="success" />)
  //     })
  //     .catch((error) => {
  //       toast(
  //         <LabelNotificationPage
  //           messenger={error.response.data?.message || "failed"}
  //           type="error"
  //         />
  //       )
  //     })
  // }
  // const onReject = () => {
  //   if (
  //     historyDetail.approved_status !== VERSION_APPROVED_STATUS.PENDING ||
  //     historyDetail.status === STATUS_BUILD.DRAFT
  //   )
  //     return
  //   unapproveInviteeVersion(
  //     historyDetail.id,
  //     idConversationParam,
  //     projectComponentDetail.code,
  //     historyDetail.code
  //   )
  //     .then((res) => {
  //       handleUpdateDataWhenChangeHistory(true)
  //       toast(<LabelNotificationPage messenger={`Success`} type="success" />)
  //     })
  //     .catch((error) => {
  //       toast(
  //         <LabelNotificationPage
  //           messenger={error.response.data?.message || "failed"}
  //           type="error"
  //         />
  //       )
  //     })
  // }
  const onDeleteDraft = () => {
    if (historyDetail.status !== STATUS_BUILD.DRAFT) return
    isLoading.setValue(true)
    deleteInviteeDraft(historyDetail.id, idConversationParam)
      .then((_res) => {
        toast(<LabelNotificationPage messenger={"Success"} type={"success"} />)
        handleUpdateDataWhenChangeHistory()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response.data?.message}
            type={"error"}
          />
        )
      })
      .finally(() => {
        isLoading.setValue(false)
        isDeleteDraft.setValue(false)
      })
  }
  const onSubmitRevert = (newMessageInput: string) => {
    if (isDisableRevertHistory) return
    isLoading.setValue(true)
    revertInviteeVersion(historyDetail.id, idConversationParam, {
      message: newMessageInput,
    })
      .then((_res) => {
        toast(<LabelNotificationPage messenger={"Success"} type={"success"} />)
        handleUpdateDataWhenChangeHistory()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response.data?.message}
            type={"error"}
          />
        )
      })
      .finally(() => {
        isLoading.setValue(false)
        isRevert.setValue(false)
      })
  }

  // const renderContent = () => (
  //   <CommentCard
  //     isOpen={showTippyComment.value}
  //     onClose={() => showTippyComment.setValue(false)}
  //     idComponent={idProjectBuildComponentParam}
  //     urlComponent={`project-build-component/${idProjectBuildComponentParam}/conversation/${idConversationParam}/comment/get-list`}
  //     titlePage={TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT}
  //     isSendConversation
  //     isSentInvite={isSentInvite}
  //     idConversation={idConversationParam}
  //     isInvitee={isInvitee}
  //     isCloseConversation={isCloseConversation}
  //     projectComponentHistoryId={idProjectComponentHistoryParam}
  //     projectBuildComponentId={idProjectBuildComponentParam}
  //     viewOnly={isDeleted}
  //     projectId={projectComponentDetail.project_id}
  //     projectBuildId={projectBuildId}
  //   />
  // )

  // const renderCommentAction = () => (
  //   <ActionIconComment
  //     handleClickTippyComment={handleClickTippyComment}
  //     isReadComponent={isReadComponent}
  //   />
  // )

  // const renderMessage = () => {
  //   return isMobile ? (
  //     <TippyLayout
  //       visible={showTippyComment.value}
  //       mainContent={renderContent()}
  //     >
  //       {renderCommentAction()}
  //     </TippyLayout>
  //   ) : (
  //     <TippyCustomzie
  //       containerClass="component-card-menu"
  //       placement="bottom-end"
  //       interactive
  //       arrow={false}
  //       animation="scale"
  //       visible={showTippyComment.value}
  //       onClickOutside={() => {
  //         showTippyComment.setValue(modalProps.deleteModal ? true : false)
  //       }}
  //       content={renderContent()}
  //       allowHTML
  //     >
  //       {renderCommentAction()}
  //     </TippyCustomzie>
  //   )
  // }
  const renderButtonDelete = () => {
    if (
      !isDeleted &&
      historyDetail.status === STATUS_BUILD.DRAFT &&
      conversationRole === CONVERSATION_ROLE.INVITEE
    ) {
      return (
        <Button
          title="Delete Draft"
          colorBtn="white"
          onClick={() => {
            isDeleteDraft.setValue(true)
          }}
          styleButton={{
            marginLeft: 8,
          }}
          sizeBtn="small"
        />
      )
    }
    return null
  }

  // const renderInviteeActions = () => {
  //   return (
  //     <div className="flex">
  //       <ActionIconHistory
  //         icon="commit"
  //         tooltip={
  //           commitButtonStage?.isActive ||
  //           historyDetail.status === STATUS_BUILD.COMMITTED
  //             ? "Save"
  //             : commitButtonStage?.tooltipHelper
  //         }
  //         styleIcon={{ width: 28, height: 28 }}
  //         customStyleIcon={{ width: 28, height: 28 }}
  //         onClick={() => {
  //           if (
  //             !commitButtonStage?.isActive &&
  //             historyDetail.status !== STATUS_BUILD.COMMITTED
  //           ) {
  //             return toast(
  //               <LabelNotificationPage
  //                 messenger={commitButtonStage?.tooltipHelper || ""}
  //                 type="warning"
  //               />
  //             )
  //           }
  //           onCommit()
  //         }}
  //         disabled={
  //           historyDetail.status === STATUS_BUILD.COMMITTED ||
  //           !commitButtonStage?.isActive
  //         }
  //       />

  //       <ActionIconHistory
  //         icon="edit"
  //         tooltip={
  //           isDisableEditHistory &&
  //           ![STATUS_BUILD.DRAFT, STATUS_BUILD.SAVE].includes(
  //             historyDetail.status
  //           )
  //             ? "Can only edit on the latest commit."
  //             : "Edit"
  //         }
  //         onClick={onEdit}
  //         disabled={isDisableEditHistory}
  //       />
  //       <ActionIconHistory
  //         icon="revert"
  //         tooltip="Revert"
  //         disabled={isDisableRevertHistory}
  //         onClick={() => {
  //           if (isDisableRevertHistory) return
  //           isRevert.setValue(true)
  //         }}
  //       />
  //     </div>
  //   )
  // }

  // const renderInviterActions = () => {
  //   return (
  //     <div className="flex">
  //       <ActionIconHistory
  //         icon="approve"
  //         customStyleIcon={{ width: 24, height: 24 }}
  //         tooltip={
  //           historyDetail.status === STATUS_BUILD.DRAFT
  //             ? "Cannot approve a draft version"
  //             : historyDetail.approved_status ===
  //               VERSION_APPROVED_STATUS.PENDING
  //             ? "Approve this version"
  //             : "Only the version in Saved status can be approved"
  //         }
  //         disabled={
  //           historyDetail.approved_status !== VERSION_APPROVED_STATUS.PENDING ||
  //           historyDetail.status === STATUS_BUILD.DRAFT
  //         }
  //         onClick={onApproved}
  //       />
  //       <ActionIconHistory
  //         icon="unapprove"
  //         customStyleIcon={{ opacity: 0.7 }}
  //         tooltip={
  //           historyDetail.status === STATUS_BUILD.DRAFT
  //             ? "Cannot reject a draft version"
  //             : historyDetail.approved_status ===
  //               VERSION_APPROVED_STATUS.PENDING
  //             ? "Reject this version"
  //             : "Only the version in Saved status can be disapproved"
  //         }
  //         disabled={
  //           historyDetail.approved_status !== VERSION_APPROVED_STATUS.PENDING ||
  //           historyDetail.status === STATUS_BUILD.DRAFT
  //         }
  //         onClick={onReject}
  //       />
  //     </div>
  //   )
  // }

  return (
    <div
      className="px-6 flex flex-col bg-white"
      style={{
        height: 82,
        minHeight: 82,
        paddingTop: 14,
        borderBottom: "1px solid #E4E4E4",
      }}
    >
      <div
        className="flex items-center"
        style={{
          height: 26,
        }}
      >
        <TypeComponent label={projectComponentDetail.type?.key || ""} />
        <p
          className="font-semibold ml-2"
          style={{
            fontSize: 16,
            lineHeight: "26px",
            color: "#111111",
          }}
        >
          {projectComponentDetail.name}
        </p>
      </div>
      <div
        className="flex items-center justify-between"
        style={{
          height: 32,
        }}
      >
        <div className="flex items-center">
          <p
            className="font-normal"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: "#7A7A7A",
            }}
          >
            Viewing
            <span
              className="font-semibold ml-2"
              style={{
                color: "#333333",
              }}
            >
              {projectComponentDetail.code}
            </span>
          </p>
          {historyDetail ? (
            <React.Fragment>
              <DotCard />
              <span className="font-medium text-[#7A7A7A] text-[13px] leading-[18px]">
                {historyDetail.code}
              </span>
            </React.Fragment>
          ) : null}
          <div className="hidden md:block">{renderButtonDelete()}</div>
        </div>
        {/* action icons */}
        <div className="flex items-center">
          {/* {[CONVERSATION_ROLE.CONTRIBUTOR, CONVERSATION_ROLE.INVITER].includes(
            conversationRole
          ) && renderInviterActions()}
          {conversationRole === CONVERSATION_ROLE.INVITEE &&
            renderInviteeActions()} */}

          {isDownload ? (
            <ActionIconHistory
              icon="download"
              tooltip="Download"
              onClick={onDownloadFile}
              styleIcon={{
                marginRight: 0,
              }}
            />
          ) : null}

          {/* {renderMessage()} */}
        </div>
      </div>

      {isCommit.value && (
        <FormCommitInvitee
          openModal={isCommit.value}
          code={historyDetail.code}
          idComponent={historyDetail.id}
          onCloseModal={() => isCommit.setValue(false)}
          handleUpdateDataWhenCommit={handleUpdateDataWhenChangeHistory}
          conversationId={idConversationParam}
        />
      )}
      {isEdit.value && (
        <FormCreateForInvitee
          openModal={isEdit.value}
          conversationId={idConversationParam}
          idBuildComponent={idProjectBuildComponentParam}
          handleUpdateDataWhenCreate={() =>
            handleUpdateDataWhenChangeHistory(false)
          }
          label={`Preparing new version from ${historyDetail.code}`}
          onCloseModal={() => {
            isEdit.setValue(false)
          }}
          isChangeVersionToDraft={historyDetail.status === STATUS_BUILD.SAVE}
        />
      )}
      {isRevert.value && (
        <FormConfirm
          idHistory={historyDetail.id}
          onCloseModal={() => isRevert.setValue(false)}
          onSubmitButton={onSubmitRevert}
          message={`You are reverting version ${historyDetail.code}.`}
          label="Revert"
        />
      )}
      {isDeleteDraft.value && (
        <ModalDelete
          onCloseModal={() => isDeleteDraft.setValue(false)}
          title={`Are you sure to delete this 
               version
          ?`}
          label={`Confirmation`}
          onSubmit={onDeleteDraft}
          titleButton="Delete"
        />
      )}
    </div>
  )
}, isEqualProps)

export default InfoInviteeComponentDetail
