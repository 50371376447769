import { ReactComponent as IconLock } from "assets/images/icons/icon-lock.svg"
import { isUndefined } from "lodash"
import { useEffect } from "react"
import * as Sentry from "@sentry/react"

interface Props {
  type?: "project" | "build" | "component"
  isOwner?: boolean
  styleRoot?: React.CSSProperties
  conversationCodes?: string[]
  name: string
  id: string
  isEncrypted: boolean
}

export const EncryptedDataWarning = (props: Props) => {
  const { type, isOwner, styleRoot, conversationCodes, name, id, isEncrypted } =
    props
  useEffect(() => {
    const message =
      !isUndefined(conversationCodes) && conversationCodes.length
        ? `This ${conversationCodes.map((el) => el).join(", ")} ${name} is ${
            isEncrypted ? "encrypted" : "not encrypted yet"
          }: ${id}`
        : `This ${type} ${name} is ${
            isEncrypted ? "encrypted" : "not encrypted yet"
          }: ${id}`

    Sentry.captureMessage(message, {
      level: "warning",
    })
  }, [type])

  const renderBody = () => {
    if (!isUndefined(conversationCodes) && conversationCodes.length) {
      return (
        <div className="flex flex-col items-center justify-center mt-[24px]">
          <p className="text-[14px] text-[#EA4545] leading-[24px] mt-[24px] text-center font-semibold mb-[12px]">
            {`This ${conversationCodes.map((el) => el).join(", ")} is ${
              isEncrypted ? "encrypted" : "not encrypted yet"
            }`}
            .
          </p>

          <p className="text-[14px] text-[#111111] leading-[24px]">
            {`Ask the ${
              isOwner ? "Tracelium Admin" : "Owner"
            } for access to view it.`}
          </p>
        </div>
      )
    }
    if (!isUndefined(type)) {
      return (
        <>
          <p className="text-[14px] text-[#EA4545] leading-[24px] mt-[24px] font-semibold mb-[12px]">
            {`This ${type} is ${
              isEncrypted ? "encrypted" : "not encrypted yet"
            }`}
            .
          </p>
          <p className="text-[14px] text-[#111111] leading-[24px]">
            {`Ask the ${
              isOwner ? "Tracelium Admin" : "Owner"
            } for access to view it.`}
          </p>
        </>
      )
    }
    return null
  }

  return (
    <div
      className="h-full"
      style={{
        ...styleRoot,
      }}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <IconLock />
        {renderBody()}
      </div>
    </div>
  )
}
