import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useBoolean, useNumber, useString } from "helpers/hooks"
import { cloneDeep } from "lodash"
import PageLayout from "pages/layout/PageLayout"
import {
  GetUsersInProjectProp,
  ProjectComponentDetail,
} from "pages/project-component/types"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import HeaderProjectSearch from "./organisms/HeaderProjectSearch"
import ProjectCard from "./organisms/ProjectCard"
import {
  archiveProjectMiddleware,
  favoriteProjectMiddleware,
  getObjectSearchMiddleware,
  deleteProjectAttachment,
} from "./services/api"
import {
  defaultProjectDetail,
  ParamsProjectsProps,
  ProjectDetail,
  SEARCH_TAB,
} from "./types"
import { ReactComponent as IconNoResult } from "assets/images/icon-no-results-search.svg"
import { useAppSelector } from "hooks/useApp"
import FormModalActionProjectCard from "./organisms/FormModalActionProjectCard"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import {
  customLocalStorageHandler,
  searchParams,
  umamiTracking,
} from "helpers/utils"
import { EVENT } from "constants/events"
import ProjectSearchTab from "./organisms/ProjectSearchTab"
import ComponentDetailCard from "pages/project-component/organisms/ComponentDetailCard"
import {
  BuildConversationDetail,
  ConversationDetail,
  CustomStatus,
  MENU_TAB_CONVERSATION,
  MENU_TAB_CONVERSATION_BASE_ON_KEY,
} from "pages/conversations/conversations.type"
import BuildConversationCard from "pages/build-overview/molecules/BuildConversationCard"
import ConversationCard from "pages/conversations/molecules/ConversationCard"
import { NAME_LOCALSTORAGE } from "constants/localStorage"

const ProjectSearchPage = () => {
  const searchInput = useString("")
  const originSearchInput = useString("")
  const [arrProject, setArrProject] = useState<ProjectDetail[]>([])
  const isLoading = useBoolean(false)
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const isOpenFormDialog = useBoolean(false)
  const isArchive = useBoolean(false)
  const isShare = useBoolean(false)
  const rowsPerPageHook = useNumber(24)
  const isMoreData = useBoolean(true)
  const ref = useRef<HTMLDivElement>(null)
  const pageHook = useNumber(1)
  const isGetFirst = useBoolean(false)
  const countProjects = useNumber(0)
  const userInfo = useAppSelector((state) => state.userInfo)
  const activeTab = useString(SEARCH_TAB.PROJECT)
  const [generalData, setGeneralData] = useState<{
    projects: ProjectDetail[]
    components: ProjectComponentDetail[]
    builds: BuildConversationDetail[]
    conversations: ConversationDetail[]
  }>({ projects: [], components: [], builds: [], conversations: [] })
  const { handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATIONS
  )
  const handleSetBuildLocalStorage = customLocalStorageHandler(
    NAME_LOCALSTORAGE.BUILD
  ).handleSetLocalStorage
  const query = searchParams("query")
  const location = useLocation<any>()

  useEffect(() => {
    if (!query) {
      pushTo(PATH.projects)
      return
    }
    searchInput.setValue(query)
    originSearchInput.setValue(query)
    getProjects(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, location.state?.searchProject])

  const getProjects = async (newSearch: string) => {
    const params: ParamsProjectsProps = {
      search: newSearch,
      page: 1,
      pageSize: rowsPerPageHook.value,
    }
    if (!newSearch) {
      delete params.search
    }

    isLoading.setValue(true)
    try {
      const dataRes = await getObjectSearchMiddleware(params)
      setGeneralData(dataRes)
      setArrProject(dataRes.projects)
      countProjects.setValue(dataRes.projects.length)
      isMoreData.setValue(
        dataRes.projects.length < rowsPerPageHook.value ? false : true
      )
      isGetFirst.setValue(true)
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
    }
  }
  const handleScroll = async () => {
    if (!isGetFirst.value) {
      return
    }
    const offsetHeight = ref.current?.offsetHeight
    const scrollHeight = ref.current?.scrollHeight
    const scrollTop = ref.current?.scrollTop
    if (Number(scrollTop) + Number(offsetHeight) > Number(scrollHeight) - 10) {
      if (!isMoreData.value || !arrProject.length || isLoading.value) {
        return
      }
      isLoading.setValue(true)
      try {
        const params: ParamsProjectsProps = {
          search: searchInput.value,
          page: pageHook.value + 1,
          pageSize: rowsPerPageHook.value,
        }
        if (!searchInput.value) {
          delete params.search
        }
        const dataRes = await getObjectSearchMiddleware(params)
        isMoreData.setValue(
          dataRes.projects.length < rowsPerPageHook.value ? false : true
        )
        pageHook.setValue(pageHook.value + 1)
        const newData = cloneDeep(arrProject).concat(dataRes.projects)
        setArrProject(newData)
        isLoading.setValue(false)
      } catch (error) {
        isLoading.setValue(false)
      }
    }
  }

  const handleChangeInputSearch = (event) => {
    searchInput.setValue(event.target.value)
  }
  const onRemoveSearch = () => {
    searchInput.setValue("")
    originSearchInput.setValue("")
    pushTo(PATH.projects)
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      umamiTracking(EVENT.PROJECT.SEARCH)
      originSearchInput.setValue(searchInput.value)
      pushTo(PATH.generalSearch, undefined, undefined, {
        query: searchInput.value,
      })
    }
  }
  const onBlurInput = () => {
    searchInput.setValue(originSearchInput.value)
  }
  const handleChangeButton = (
    key: "share" | "edit" | "archive" | "favorite" | "delete_attachment",
    data: ProjectDetail,
    callback: () => void
  ) => {
    setProjectDetail(data)
    switch (key) {
      case "share":
        isShare.setValue(true)
        break
      case "edit":
        isOpenFormDialog.setValue(true)
        break
      case "archive":
        isArchive.setValue(true)
        break
      case "favorite":
        callAPIFavorte(data)
        break
      case "delete_attachment":
        configureStore.dispatch(
          openModal({
            type: "Delete",
            props: {
              deleteModal: {
                title: `When action is confirmed, all attachment files also be removed. This action can not undo.`,
                label: `Warning`,
                content: `Press "Delete" to process`,
                onSubmit: onSubmitDeleteAttachment(data),
                styleTitle: { textAlign: "center" },
                titleButton: "Delete",
              },
            },
          })
        )
        break
    }
    callback()
  }

  const onSubmitDeleteAttachment = (data: ProjectDetail) => () => {
    deleteProjectAttachment(data.id)
      .then(() => {
        toast(
          <LabelNotificationPage
            messenger={"Delete project successfully!"}
            type="success"
          />
        )
        getProjects(searchInput.value)
        closeModal()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={
              error.response?.data?.message || "Delete project failed!"
            }
            type="error"
          />
        )
        closeModal()
      })
  }

  const callAPIFavorte = (data: ProjectDetail) => {
    if (!data.id) {
      return
    }
    isLoading.setValue(true)
    favoriteProjectMiddleware(
      data.id,
      data.name,
      Boolean(data.is_favorite),
      (
        type: STATUS_RESPONSE,
        messenger: string,
        newProject?: ProjectDetail
      ) => {
        if (type === STATUS_RESPONSE.SUCCESS && newProject) {
          const newData = cloneDeep(arrProject)
          const index = newData.findIndex((el) => el.id === data.id)
          newData[index] = newProject
          setArrProject(newData)
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
        isLoading.setValue(false)
      }
    )
  }
  const onArchiveProject = () => {
    if (!projectDetail?.id) {
      return
    }
    isLoading.setValue(true)
    archiveProjectMiddleware(
      projectDetail.id,
      projectDetail.is_archived,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          const newProjects = cloneDeep(arrProject)
          const index = newProjects.findIndex(
            (el) => el.id === projectDetail.id
          )
          if (index > -1) {
            newProjects[index].is_archived =
              projectDetail.is_archived === 1 ? 0 : 1
            setArrProject(newProjects)
          }

          isArchive.setValue(false)
        }
      },
      projectDetail.name
    )
  }
  const onCloseFormShare =
    (newUserRole: GetUsersInProjectProp[], projectId: string) => () => {
      isShare.setValue(false)
      const newProjects = cloneDeep(arrProject)
      const index = newProjects.findIndex((el) => el.id === projectId)
      if (index > -1) {
        newProjects[index].role_users = newUserRole
        setArrProject(newProjects)
      }
    }

  const renderListItems = () => {
    if (activeTab.value === SEARCH_TAB.PROJECT) {
      return renderListProjects()
    }
    if (activeTab.value === SEARCH_TAB.COMPONENT) {
      return renderListComponents()
    }
    if (activeTab.value === SEARCH_TAB.BUILD) {
      return renderListBuilds()
    }
    return renderListConversation()
  }

  const renderBody = () => {
    const totalItems =
      generalData.builds.length +
      generalData.projects.length +
      generalData.components.length +
      generalData.conversations.length
    if (totalItems === 0) {
      if (isLoading.value) {
        return null
      }
      return (
        <div className="h-full flex justify-center items-center">
          <IconNoResult
            style={{
              height: 179,
            }}
          />
        </div>
      )
    }

    return <div className="flex flex-col">{renderListItems()}</div>
  }

  const renderListProjects = () => {
    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-auto-fill-min-310 gap-6 mr-6">
          {arrProject.map((el, index) => {
            return (
              <ProjectCard
                isDiable={true}
                key={index}
                item={el}
                handleChangeButton={handleChangeButton}
                openToNewTab={true}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const renderListComponents = () => {
    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-auto-fill-min-310 gap-6 mr-6">
          {generalData.components.map((el, index) => {
            return (
              <ComponentDetailCard
                key={`${el.id}${index}`}
                item={el}
                isAction={false}
                indexCard={index}
                isOwner={false}
                openToNewTab={true}
              />
            )
          })}
        </div>
      </div>
    )
  }
  const renderListBuilds = () => {
    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-auto-fill-min-310 gap-6 mr-6">
          {generalData.builds.map((el, index) => {
            return (
              <BuildConversationCard
                key={el.id}
                item={el}
                updateDataWhenChangeFavorite={() => {}}
                updateDataWhenArchive={() => {}}
                activeIdBuild={""}
                onClickBuildComponent={(el) => () => {
                  if (el.is_archived)
                    handleSetBuildLocalStorage({ checkboxArchive: true })
                  window.open(
                    `/build/${el.status}/${el.project_id}/${el.id}?tab=${el.tab}`
                  )

                  // pushTo(
                  //   PATH.buildOverview,
                  //   {
                  //     status: el.status,
                  //     idProject: el.project_id,
                  //     idProjectBuild: el.id,
                  //   },
                  //   {
                  //     selectArchivedFilter: el.is_archived,
                  //   },
                  //   {
                  //     tab: el.tab,
                  //   }
                  // )
                }}
                isAction={false}
                updateDataWhenDeleteBuild={() => {}}
                updateDataWhenDuplicateBuild={() => {}}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const renderListConversation = () => {
    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-auto-fill-min-310 gap-6 mr-6">
          {generalData.conversations.map((conversation) => {
            return (
              <ConversationCard
                item={conversation}
                activeConversationId={""}
                updateDataWhenChangeFavorite={function (
                  dataRes: ConversationDetail
                ): void {}}
                updateDataWhenArchive={function (
                  dataRes: ConversationDetail
                ): void {}}
                onClickConversation={(dataRes: ConversationDetail) => () => {
                  handleSetLocalStorage({
                    checkboxArchive:
                      dataRes.is_archived_by_owner || dataRes.is_archived_by_me,
                    tab: MENU_TAB_CONVERSATION_BASE_ON_KEY[dataRes.tab],
                    conversationId: dataRes.id,
                  })
                  // pushTo(PATH.conversations)
                  window.open(`/conversations?conversationId=${dataRes.id}`)
                }}
                updateDataWhenChangeCustomStatus={function (
                  type: "edit" | "delete",
                  customStatus: CustomStatus
                ): void {}}
                updateDataWhenChangeConvoCustomStatus={function (
                  buildId: string,
                  customStatus?: CustomStatus | undefined
                ): void {}}
                activeMenu={MENU_TAB_CONVERSATION.CC}
                isAction={false}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    const totalItems =
      generalData.builds.length +
      generalData.projects.length +
      generalData.components.length +
      generalData.conversations.length
    if (totalItems === 0) {
      return `Your search - ${searchInput.value} - did not match any documents.`
    }
    return (
      <ProjectSearchTab
        activeTab={activeTab.value}
        onChangeTab={(index, lable) => () => {
          activeTab.setValue(lable)
        }}
        count={{
          projects: generalData.projects.length,
          components: generalData.components.length,
          builds: generalData.builds.length,
          conversations: generalData.conversations.length,
        }}
      />
    )
  }
  return (
    <PageLayout
      childrenHeader={
        <HeaderProjectSearch
          search={searchInput.value}
          handleChangeInputSearch={handleChangeInputSearch}
          onKeyPress={onKeyPress}
          onRemoveSearch={onRemoveSearch}
          onBlurInput={onBlurInput}
        />
      }
      minWidth="min-w-[358px]"
      heightHeader={72}
    >
      <div className="flex flex-col h-full">
        <div
          className="pt-3 px-6"
          style={{
            height: 62,
          }}
        >
          {!isLoading.value ? (
            <p
              className="font-semibold text-16 leading-26"
              style={{
                color: " #222222",
              }}
            >
              {renderContent()}
            </p>
          ) : null}
        </div>
        <div
          className="flex flex-col flex-1 pl-6 mb-6"
          ref={ref}
          onScroll={handleScroll}
          style={{
            overflow: "auto",
          }}
        >
          {renderBody()}
        </div>
      </div>
      <FormModalActionProjectCard
        isArchive={isArchive}
        isShare={isShare}
        isLoading={isLoading}
        projectDetail={projectDetail}
        onArchiveProject={onArchiveProject}
        onCloseFormShare={onCloseFormShare}
      />
    </PageLayout>
  )
}
export default ProjectSearchPage
