import React from "react"

export interface GlobalVersionSpecificationContext {
  formData: any
  formDataEditor: any
  formDataEditorFile: any
  formDataEditorDeleteFile: any
  decryptedShareKey?: string
  isShowErrorSpecification: boolean
  updateDataForm: (
    type: "formData" | "formEditor" | "formAddFile" | "formDeleteFile",
    field: string,
    newValue: any,
    isRequired: boolean,
    isEditor?: boolean
  ) => void
}

export const VersionSpecificationContext =
  React.createContext<GlobalVersionSpecificationContext | null>(null)
