import CheckedDefault from "components/Checked/CheckedDefault"
import { SelectedDefaultProp } from "components/Select/types"
import { ProjectComponentStatus } from "components/Status/types"
import { cloneDeep, includes, reduce, sumBy } from "lodash"
import ComponentDetailInBuild from "pages/project-build/organisms/ComponentDetailInBuild "
import ListTypeBuildCard from "pages/project-build/organisms/ListTypeBuildCard"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { getComponentTypesMiddleware } from "pages/project-component-detail/services"
import { getProjectComponentsMiddleware } from "pages/project-component/services/api"
import { Folder, ProjectComponentDetail } from "pages/project-component/types"
import { useEffect, useRef, useState } from "react"

interface Props {
  projectId: string
  projectBuildId: string
  listIdComponentActive: string[]
  buildCode: string
  updateProjectBuild: () => Promise<ProjectComponentBuildDetail>
  openUpdateComponent: boolean
  projectBuildStatus: string
  objActive: Record<string, string>
  setObjActive: (obj: Record<string, string>) => void
}
export const AddComponentInBuildDetail = (props: Props) => {
  const {
    projectId,
    listIdComponentActive,
    openUpdateComponent,
    objActive,
    setObjActive,
  } = props

  const [cpnTypeOptions, setCpnTypeOptions] = useState<SelectedDefaultProp[]>(
    []
  )
  const [folderSelected, setFolderSelected] = useState<SelectedDefaultProp[]>(
    []
  )
  const [cpnTypeSelected, setCpnTypeSelected] = useState<SelectedDefaultProp[]>(
    []
  )
  const [listFolders, setListFolder] = useState<Folder[]>([])

  const [originListComponents, setOriginListComponent] = useState<
    ProjectComponentDetail[]
  >([])

  const [listComponents, setListComponent] = useState<ProjectComponentDetail[]>(
    []
  )
  const isLoadData = useRef(false)

  useEffect(() => {
    if (!projectId || isLoadData.current || !openUpdateComponent) return
    getData()
  }, [projectId, isLoadData.current, openUpdateComponent])

  const filterComponents = (
    components: ProjectComponentDetail[],
    originListIdComponentActive: string[]
  ) => {
    return components.filter(
      (el) =>
        !includes(originListIdComponentActive, el.id) &&
        el.is_valid_to_build &&
        el.status === ProjectComponentStatus.Active
    )
  }

  const getData = async () => {
    try {
      setCpnTypeOptions([])
      setFolderSelected([])
      setCpnTypeSelected([])
      setListFolder([])
      setOriginListComponent([])
      setListComponent([])
      setObjActive({})
      const [dataCpnTypeRes, dataComponentRes] = await Promise.all([
        getComponentTypesMiddleware(),
        getProjectComponentsMiddleware(projectId),
      ])

      isLoadData.current = true

      const newFolders = dataComponentRes.groups.map((group) => ({
        ...group,
        components: filterComponents(group.components, listIdComponentActive),
      }))
      const newComponents = filterComponents(
        dataComponentRes.components,
        listIdComponentActive
      )

      setListFolder(newFolders)
      setOriginListComponent(newComponents)

      onFilterComponent(newFolders, newComponents, [], [])
      setCpnTypeOptions(
        dataCpnTypeRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        }))
      )
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const onChangeTypeSelected = (newSelect: SelectedDefaultProp[]) => {
    onFilterComponent(
      listFolders,
      originListComponents,
      newSelect,
      folderSelected
    )
    setCpnTypeSelected(newSelect)
  }

  const onChangeFolderSelected = (newSelect: SelectedDefaultProp[]) => {
    onFilterComponent(
      listFolders,
      originListComponents,
      cpnTypeSelected,
      newSelect
    )
    setFolderSelected(newSelect)
  }

  const onFilterComponent = (
    folders: Folder[],
    components: ProjectComponentDetail[],
    selectedType: SelectedDefaultProp[],
    selectedFolder: SelectedDefaultProp[]
  ) => {
    const convertFolderSelected = selectedFolder.map((item) => item.value)
    const convertSelected = selectedType.map((item) => Number(item.value))

    const filteredFolders =
      selectedFolder.length === 0
        ? folders
        : folders.filter((folder) => convertFolderSelected.includes(folder.id))

    const folderComponents = filteredFolders.flatMap(
      (folder) => folder.components
    )
    const allComponents =
      selectedFolder.length === 0
        ? [...folderComponents, ...components]
        : folderComponents
    const filterComponents = convertSelected.length
      ? allComponents.filter((el) =>
          convertSelected.includes(Number(el.type.value))
        )
      : allComponents

    setListComponent(filterComponents)
  }

  const onSelectComponent =
    (newIdComponent: string, isValidToBuild: boolean) => () => {
      if (!isValidToBuild) {
        return
      }
      const newObj = cloneDeep(objActive)
      if (newObj[newIdComponent]) {
        delete newObj[newIdComponent]
      } else {
        newObj[newIdComponent] = newIdComponent
      }
      setObjActive(newObj)
    }

  const onChangeSelectedAll = (oldSelected: boolean) => () => {
    let newObjActive = {}

    if (!oldSelected) {
      const newListComponents = cloneDeep(
        listFolders.map((el) => el.components).flat()
      ).concat(cloneDeep(originListComponents))
      newObjActive = reduce(
        newListComponents,
        (newObj, component) => {
          newObj[component.id] = component.id
          return newObj
        },
        {}
      )
    }
    setObjActive(newObjActive)
  }

  const isDisabledButtonAdd = Object.values(objActive).length ? false : true
  const totalComponents =
    sumBy(listFolders, (item) => item.components.length) +
    originListComponents.length

  const selectAll =
    !isDisabledButtonAdd && Object.values(objActive).length === totalComponents

  return (
    <div className="flex flex-col h-full pl-6 pb-2 overflow-auto bg-grayWhite2 md:bg-white">
      <div className="h-full flex flex-col justify-between">
        <ListTypeBuildCard
          cpnTypeOptions={cpnTypeOptions}
          cpnTypeSelected={cpnTypeSelected}
          onChangeTypeSelected={onChangeTypeSelected}
          folderOptions={listFolders.map((folder) => {
            return {
              label: folder.name,
              value: folder.id,
            }
          })}
          folderSelected={folderSelected}
          onChangeFolderSelected={onChangeFolderSelected}
        />
        <div className="grow overflow-auto">
          <div
            className="grid"
            style={{
              marginRight: 24,
              gridTemplateColumns: `repeat(auto-fill,minmax(305px,1fr))`,
              gridGap: 12,
              padding: "5px 0px",
            }}
          >
            {listComponents.map((el, index) => {
              return (
                <ComponentDetailInBuild
                  key={index}
                  item={el}
                  isActive={objActive[el.id] ? true : false}
                  onSelectComponent={onSelectComponent}
                />
              )
            })}
          </div>
        </div>
        {listComponents.length ? (
          <div className="min-h-[64px] flex items-center justify-between pr-6 pt-[24px]">
            <CheckedDefault
              textColor="black"
              checked={selectAll}
              title="Select all"
              onClick={onChangeSelectedAll(selectAll)}
            />
            <div className="hidden md:flex md:items-center">
              <p
                className="font-normal"
                style={{
                  fontSize: 14,
                  color: "#7A7A7A",
                  lineHeight: "24px",
                  marginRight: 24,
                }}
              >
                Selected:
                <span
                  style={{
                    fontWeight: 600,
                    color: "#222222",
                  }}
                >
                  {` ${Object.values(objActive).length} component${
                    Object.values(objActive).length > 1 ? "s" : ""
                  }`}
                </span>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
