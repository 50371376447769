import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface SectionCustomProps {
  containerClass?: string
  childrenClass?: string
  rootRef?: React.RefObject<HTMLDivElement>
}

export const SectionCustom: FC<SectionCustomProps> = ({
  children,
  containerClass,
  childrenClass,
  rootRef,
}) => (
  <div
    className={twMerge(
      "flex flex-col pl-6 grow py-6 md:overflow-hidden",
      containerClass
    )}
  >
    <div
      ref={rootRef}
      className={twMerge(
        "flex flex-col scrollbar-stable overflow-auto h-full",
        childrenClass
      )}
    >
      {children}
    </div>
  </div>
)
