export enum NAME_LOCALSTORAGE {
  CONVERSATIONS = "conversations",
  PROJECT_COMPONENT = "project-component",
  PROJECT = "project",
  BUILD = "builds",
  CONVERSATION_TEXT_INPUT = "tab_conversation_detail",
  ADDITIONAL_INFO_TEXT_INPUT = "additional_info",
  EXTRA_INFO_TEXT_INPUT = "extra_info_",
  COMMENT = "comments",
  PROJECT_BUILD_TAB = "project_build_tab",
}
