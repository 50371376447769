interface SkeletonConversationMessageProps {
  isMobile: boolean
}
export const SkeletonConversationMessage = (
  props: SkeletonConversationMessageProps
) => {
  const { isMobile } = props
  return (
    <div className="bg-[#FFF] h-full w-full flex flex-col items-center justify-between p-[24px]">
      <div className="flex flex-col w-full gap-[12px]">
        <div className="h-[12px] bg-[#EFEFEF] w-full rounded-full dark:bg-gray-700"></div>
        <div className="flex items-center justify-between w-full">
          <div className="h-[12px] bg-[#EFEFEF] w-[20%] rounded-full dark:bg-gray-700"></div>
          <div className="h-[12px] bg-[#EFEFEF] w-[60%] rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="h-[12px] bg-[#EFEFEF] w-[20%] rounded-full dark:bg-gray-700"></div>
          <div className="h-[12px] bg-[#EFEFEF] w-[60%] rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-[12px] bg-[#EFEFEF] w-[50px] rounded-full dark:bg-gray-700"></div>
        <div className="flex items-center w-full max-w-[400px]">
          <div className="h-[70px] bg-[#EFEFEF] rounded-[6px] dark:bg-gray-600 w-[300px]"></div>
          <div className="h-[70px] ms-2 bg-[#EFEFEF] rounded-[6px] dark:bg-gray-700 w-[300px]"></div>
        </div>
      </div>

      <div role="status" className="space-y-2.5 animate-pulse w-full">
        <div className="flex items-center w-full">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-32"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
        </div>
        <div className="flex items-center w-full max-w-[480px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
        </div>
        <div className="flex items-center w-full max-w-[400px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
        </div>
        <div className="flex items-center w-full max-w-[480px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
        </div>
        <div className="flex items-center w-full ">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-32"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
        </div>
        <div className="flex items-center w-full max-w-[360px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
        </div>
        <div className="flex items-center w-full max-w-[360px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
        </div>
        <div className="flex items-center w-full max-w-[560px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
        </div>
        <div className="flex items-center w-full max-w-[300px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-80"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
        </div>
        <div className="flex items-center w-full ">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-32"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
        </div>
        <div className="flex items-center w-full max-w-[560px]">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-full"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
        </div>
        <div className="flex items-center w-full ">
          <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-32"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-600 w-24"></div>
          <div className="h-[12px] ms-2 bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
        </div>
      </div>
      {!isMobile ? (
        <div className="h-[120px] bg-[#EFEFEF] w-full rounded-[6px] dark:bg-gray-700"></div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export const SkeletonConversationCard = () => {
  return (
    <div
      className="flex flex-col gap-[12px]"
      style={{
        paddingRight: 24,
      }}
    >
      {[1, 2, 3, 4].map((el) => {
        return (
          <div
            key={el}
            className="p-[12px] bg-[#FFFFFF] border border-[#E4E4E4] rounded-[6px] animate-pulse dark:bg-gray-700 flex flex-col gap-[10px]"
          >
            <div className="h-[15px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[250px]"></div>
            <div className="flex items-center">
              <svg
                className="w-[30px] h-[30px] text-[#EFEFEF] dark:text-gray-700"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <div className="flex flex-col w-full">
                <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[50%] mb-[3px]"></div>
                <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-full"></div>
              </div>
            </div>
            <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700"></div>
            <div className="flex items-center gap-[10px]">
              <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[40px]"></div>
              <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[50px]"></div>
              <div className="h-[20px] bg-[#EFEFEF] rounded-[4px] dark:bg-gray-700 w-[70px]"></div>
            </div>
            <div className="h-[12px] bg-[#EFEFEF] rounded-full dark:bg-gray-700 w-[100px]"></div>
          </div>
        )
      })}
    </div>
  )
}
