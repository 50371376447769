import Axios, { AxiosResponse } from "axios"
import {
  BuildConversationDetail,
  ParamsBuildConversationProps,
} from "pages/conversations/conversations.type"

export const getListBuildMiddleware = async (
  urlTab: string,
  params?: ParamsBuildConversationProps
) => {
  const response: AxiosResponse<{
    data: BuildConversationDetail[]
  }> = await Axios.get(`/api/conversation/build/${urlTab}`, { params })
  return response.data.data
}

export const updateUnlistedComponentInBuildMiddleware = async (
  projectBuildComponentId: string,
  log: string
) => {
  const response = await Axios.put(
    `/api/project-build/component/${projectBuildComponentId}/unlisted`,
    {
      log,
    }
  )
  return response.data.data
}
