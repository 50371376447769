import { ReactComponent as IconMenuDashboard } from "assets/images/icons/icon-menu-dashboard.svg"
import { ReactComponent as IconMenuProject } from "assets/images/icons/icon-menu-project.svg"
import { ReactComponent as IconMenuConversation } from "assets/images/icons/icon-menu-conversation.svg"
import { ReactComponent as IconMenuSetting } from "assets/images/icons/icon-meu-setting.svg"
import { ReactComponent as IconMenuBuildOverview } from "assets/images/icons/icon-menu-build.svg"
import { ReactComponent as IconAddressBook } from "assets/images/icons/address-book.svg"

import { MenuLeftProps } from "./types"
import { PATH } from "constants/path"

export const MENU_LEFT: MenuLeftProps[] = [
  {
    name: "Dashboard",
    icon: IconMenuDashboard,
    path: PATH.dashboard,
  },
  {
    name: "Projects",
    icon: IconMenuProject,
    path: PATH.projects,
  },
  {
    name: "Builds",
    icon: IconMenuBuildOverview,
    path: PATH.defaultBuildOverview,
  },
  {
    name: "Conversations",
    icon: IconMenuConversation,
    path: PATH.conversations,
  },
  {
    name: "Address book",
    icon: IconAddressBook,
    path: PATH.addressBook,
  },
  {
    name: "Settings",
    icon: IconMenuSetting,
    path: PATH.settings,
  },
]
