import "./style.css"
import ReactPaginate from "react-paginate"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left-black.svg"
import { ReactComponent as ArrowRightIcon } from "assets/images/icons/arrow-right-black.svg"
import { getDisplayRowPagination } from "./helper"
import SelectDefault from "components/Select/SelectDefault"

interface Props {
  queryState: {
    page: number
    limit: number
  }
  total: number
  handleChangePage: (newPage: number, newLimit: number) => void
}
export const Pagination = (props: Props) => {
  const { total, queryState, handleChangePage } = props
  const handlePageClick = (event: { selected: number }) => {
    const newPage = event.selected + 1

    if (!newPage) {
      return
    }
    handleChangePage(newPage, queryState.limit)
  }

  const handleRowsPerPageChange = (data: { value: number; label: string }) => {
    if (data && Number(data.value) !== Number(queryState.limit)) {
      handleChangePage(1, Number(data.value))
    }
  }

  const totalPage = queryState?.limit
    ? Number(total) / Number(queryState.limit)
    : 0
  const pageOptions = [
    { label: "25/page", value: 25 },
    { label: "50/page", value: 50 },
    { label: "100/page", value: 100 },
  ]

  return (
    <div className="pagination-container">
      <ReactPaginate
        previousLabel={<ArrowLeftIcon />}
        nextLabel={<ArrowRightIcon />}
        onPageChange={handlePageClick}
        pageCount={Math.ceil(totalPage)}
        pageClassName={"page-item"}
        breakLabel={"..."}
        activeClassName={"active"}
        forcePage={queryState?.page - 1}
      />
      <div className="flex items-center">
        <p className="text-[#7A7A7A] mr-[12px]">{`${getDisplayRowPagination(
          Number(queryState.page),
          Number(queryState.limit),
          total
        )} of ${total}`}</p>
        <SelectDefault
          options={pageOptions}
          selectedOption={pageOptions.find(
            (el) => el.value === queryState.limit
          )}
          handleChange={handleRowsPerPageChange}
          filed="value"
          rootClasses="h-10 rounded border-select flex items-center bg-[#FFFFFF] "
          controlWidth={95}
          menuPlacement="bottom"
          menuStyle={{
            marginTop: 12,
          }}
        />
      </div>
    </div>
  )
}
