import IconNoDataBuild from "assets/images/icons/icon-no-data-draft-build.svg"
interface Props {
  label?: string
  children?: React.ReactNode
}
const NoDataCard = (props: Props) => {
  const { label, children } = props
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <img
        src={IconNoDataBuild}
        alt="icon"
        style={{
          height: 168,
        }}
      />
      <p
        className="font-normal mt-6"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        {label ?? "Select component to start a build"}
      </p>
      {children}
    </div>
  )
}
export default NoDataCard
