import React from "react"
import Select, {
  components,
  InputActionMeta,
  FocusEventHandler,
} from "react-select"
import { ReactComponent as DownIcon } from "assets/images/icons/icon-multi-select.svg"
import { ReactComponent as IconActiveSelect } from "assets/images/icons/icon-active-select.svg"
import { HashLoader } from "react-spinners"
interface SelectMultiProps {
  options: any[]
  selectedOption: any
  handleChange: (selectedOption: any) => void
  handleCreateSelect?: (newValue: string) => () => void
  handleInputChange?: (inputValue: string, actionMeta?: InputActionMeta) => void
  isLoadingSearch?: boolean
  isMulti?: boolean
  noOptionsMessage?: boolean
  onBlur?: FocusEventHandler
  inputValue?: string
  customMenuList?: React.CSSProperties
  menuPlacement?: "auto" | "bottom" | "top"
}
const SelectMulti = (props: SelectMultiProps) => {
  const {
    options,
    selectedOption,
    isLoadingSearch,
    handleChange,
    handleCreateSelect,
    handleInputChange,
    isMulti = true,
    noOptionsMessage = false,
    onBlur,
    inputValue,
    customMenuList,
    menuPlacement,
  } = props
  const customStyles = {
    control: () => {
      return {
        ...{
          border: "1px solid #E4E4E4",
          borderRadius: 4,
          display: "flex",
          paddingLeft: 12,
          paddingTop: 3,
          paddingBottom: 3,
          minHeight: 44,
          "&:hover": {
            border: " 1px solid #fdbe44",
            boxShadow: "0px 0px 0px 4px rgb(247 172 27 / 10%)",
          },
        },
      }
    },
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #E4E4E4",
      borderRadius: 4,
      boxShadow: "none",
    }),
    menuList: (provided) => ({
      ...provided,
      ...customMenuList,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#F7F7F7",
      borderRadius: 4,
      padding: "4px 8px",
      margin: "0px 8px 0px 0px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "0px !important",
      color: "#7A7A7A",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "18px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#111111",
      marginLeft: 4,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#A2ABBE",
    }),
  }

  const DropdownIndicator = (dropProps) => {
    return (
      <>
        <components.DropdownIndicator {...dropProps}>
          <DownIcon />
        </components.DropdownIndicator>
      </>
    )
  }
  const renderCreateCategory = (originalValue: string) => {
    return (
      <React.Fragment>
        {isLoadingSearch ? (
          <div className="flex justify-center items-center w-full">
            <HashLoader color="#F7AC1B" loading={true} size={28} />
          </div>
        ) : (
          <p
            className="font-normal"
            style={{
              color: "#111111",
              fontSize: 13,
              lineHeight: "22px",
            }}
          >
            <span
              onClick={handleCreateSelect && handleCreateSelect(originalValue)}
              className="hover:underline"
              style={{
                color: "#0A5AF5",
                marginRight: 4,
              }}
            >
              Create
            </span>
            {originalValue}
          </p>
        )}
      </React.Fragment>
    )
  }

  return (
    <Select
      options={options}
      onChange={(e) => {
        handleChange(e)
      }}
      styles={customStyles}
      value={selectedOption}
      isMulti={isMulti}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      menuPlacement={menuPlacement || "auto"}
      className="tracelium-autocomplete"
      placeholder=""
      components={{
        Option: (optionProps) => {
          const data = optionProps.data
          return (
            <div
              className={`min-h-32 flex items-center justify-between px-3 cursor-pointer ${
                optionProps.isSelected ? "" : "hover-text-select"
              }`}
              {...optionProps.innerProps}
            >
              <span
                className="font-normal"
                style={{
                  fontSize: 13,
                  lineHeight: "22px",
                  color: optionProps.isSelected ? "#111111" : "#7A7A7A",
                }}
              >
                {data.label}
              </span>
              {optionProps.isSelected && <IconActiveSelect />}
            </div>
          )
        },
        NoOptionsMessage: noOptionsMessage
          ? () => null
          : (optionProps) => {
              const data = optionProps
              return (
                <div
                  className={`min-h-32 flex items-center justify-between px-3 cursor-pointer relative`}
                  {...optionProps.innerProps}
                >
                  {data.selectProps.inputValue ? (
                    renderCreateCategory(data.selectProps.inputValue)
                  ) : (
                    <p
                      className="font-normal"
                      style={{
                        color: "#111111",
                        fontSize: 13,
                        lineHeight: "22px",
                      }}
                    >
                      No options
                    </p>
                  )}
                </div>
              )
            },
        DropdownIndicator,
      }}
    />
  )
}
export default SelectMulti
