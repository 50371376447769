import { useBoolean, useString } from "helpers/hooks"
import ModalCustom from "components/ModalCustom"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import MultipleInput from "components/Input/MultipleInput"
import Button from "components/Button/Button"
import { STATUS_BUILD } from "components/Status/types"
import { SelectedDefaultProp } from "components/Select/types"
import { useEffect, useState } from "react"
import { postAddBuildMiddleware } from "pages/project-build/api.services"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useAppSelector } from "hooks/useApp"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { EncryptionKeys } from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { addKeyToLocalStorageObject } from "helpers/local_storage"

interface Props {
  onCloseModal: () => void
  isOpenModalNewBuild: boolean
  projectId: string
  RFQStatusStage: SelectedDefaultProp[]
}

const FromNewBuild = (props: Props) => {
  const { onCloseModal, RFQStatusStage, isOpenModalNewBuild, projectId } = props
  const user = useAppSelector((state) => state.userInfo)

  const [valueSelectStatus, setValueSelectStatus] =
    useState<SelectedDefaultProp>({
      label: "",
      value: "",
    })
  const messageInput = useString("")

  useEffect(() => {
    if (!isOpenModalNewBuild || !RFQStatusStage.length) {
      return
    }
    setValueSelectStatus(RFQStatusStage[0])
    messageInput.setValue("")
  }, [isOpenModalNewBuild, JSON.stringify(RFQStatusStage)])

  const onSubmit = () => {
    if (!projectId) {
      return
    }
    const projectKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    const projectKey = projectKeys[projectId]
    const buildAdditionKey = encryptionHelper.createRandomKey()
    const buildAdditionEncryptedKey = encryptionHelper.encrypt(
      projectKey,
      buildAdditionKey
    )
    if (!projectKey) {
      toast(
        <LabelNotificationPage
          messenger="Project key not found!"
          type="error"
        />
      )

      return
    }
    postAddBuildMiddleware(
      {
        project_id: projectId,
        project_component_ids: [],
        status: String(valueSelectStatus.value),
        build_addition_encrypt_key: buildAdditionEncryptedKey,
      },
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          addKeyToLocalStorageObject(
            EncryptionKeys.buildAdditionalInfoEncryptionKeys,
            {
              [dataRes.id]: buildAdditionKey,
            }
          )
          pushTo(
            PATH.buildOverview,
            {
              status: dataRes.status,
              idProject: dataRes.project_id,
              idProjectBuild: dataRes.id,
            },
            undefined,
            { tab: user.id === dataRes.created_by ? "my-builds" : "others" }
          )
        }
      }
    )
  }

  return (
    <ModalCustom
      label="Confirmation"
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <FormInputCard
            title="Select new build status*"
            fontWeightText="font-semibold"
          >
            <div className="flex">
              {RFQStatusStage.map((item, key) => (
                <Button
                  key={key}
                  sizeBtn="small"
                  title={item.label}
                  colorBtn="white"
                  widthBtn={
                    item.label === STATUS_BUILD.DATA_EXCHANGE ? 100 : 80
                  }
                  onClick={() => setValueSelectStatus(item)}
                  styleButton={
                    valueSelectStatus.value === item.value
                      ? {
                          border: "1px solid rgb(86, 160, 18)",
                          boxShadow: "rgb(86 160 18 / 10%) 0px 0px 0px 4px",
                          height: 32,
                          marginRight: 10,
                        }
                      : {
                          backgroundColor: "#F7F7F7",
                          height: 32,
                          marginRight: 10,
                        }
                  }
                />
              ))}
            </div>
          </FormInputCard>
          <div className="flex items-center justify-between mt-8">
            <Button title="Cancel" colorBtn="white" onClick={onCloseModal} />
            <Button title="Confirm" onClick={onSubmit} />
          </div>
        </div>
      }
    />
  )
}

export default FromNewBuild
