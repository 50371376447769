import { useEffect, useState } from "react"

export const useFileMech3DModelWorkerController = () => {
  const [worker, setWorker] = useState<Worker | null>(null)

  useEffect(() => {
    const newWorker = new Worker(
      new URL("./getFileMech3DModelWorker.ts", import.meta.url)
    )

    setWorker(newWorker)

    // Cleanup worker on component unmount
    return () => newWorker.terminate()
  }, [])

  return {
    workerFileMech3D: worker,
  }
}
