export const PATH = {
  home: "/",
  // menu
  mint: "/mint",
  gallery: "/gallery",
  howToConnect: "/how-to-connect",
  components: "/components",
  login: "/login",
  signUp: "/sign-up",
  inviteSentSignUp: "/invitation-create-password",
  forget: "/forget",
  reset: "/reset-password",
  resetSuccess: "/reset-success",
  emailVerification: "/verify",
  signingUpMessage: "/thanks-for-signing-up",
  confirmEmail: "/confirm-email",
  projects: "/projects",
  general: "/generals",
  changePhoneNumber: "/generals/change-phone-number",
  createProject: "/create-project",
  termsAndServices: "/terms-and-conditions",
  policies: "/privacy-policy",
  projectComponent: "/project-detail/:idProject",
  projectSensitiveData: "/project-sensitive",
  shareProjectBuild: "/project-build/:idBuild/share",
  draftBuild: "/draft-build/:idProject/:idProjectBuild",
  committedBuild: "/build-committed/:idProject/:idProjectBuild",
  buildDetail: "/build-detail/:idProjectComponent",
  buildHistory: "/build-history/:idProjectComponent",
  shareBuildDetail:
    "/build-detail/:idProjectComponent/:idProjectBuildComponent/share/histories",
  versionSpecification: "/version-specification/:idVersion",
  shareVersionSpecification: "/version-specification/:idVersion/share",
  shared: "/shared",
  projectComponentPCB:
    "/project/:titlePage/:idProjectComponent/PCB/:idProjectBuildComponent",
  projectComponentBOM:
    "/project/:titlePage/:idProjectComponent/BOM/:idProjectBuildComponent",
  projectComponentOther:
    "/project/:titlePage/:idProjectComponent/:type/:idProjectBuildComponent",
  projectComponentMech:
    "/project/:titlePage/:idProjectComponent/Mechanical/:idProjectBuildComponent",
  folder: "/project-detail/:idProject/folder/:idFolder",
  dashboard: "/dashboard",
  buildsOverview: "/builds",
  buildOverview: "/build/:status/:idProject/:idProjectBuild",
  defaultBuildOverview: "/build",
  conversations: "/conversations",
  settings: "/setting",
  enableEncryption: "/enable-encryption",
  inviteesPCB:
    "/invitees/component/:idProjectComponent/PCB/:idProjectBuildComponent/:idProjectComponentHistory/:idConversation",
  inviteesBOM:
    "/invitees/component/:idProjectComponent/BOM/:idProjectBuildComponent/:idProjectComponentHistory/:idConversation",
  inviteesMechComponent:
    "/invitees/component/:idProjectComponent/Mechanical/:idProjectBuildComponent/:idProjectComponentHistory/:idConversation",
  inviteesOther:
    "/invitees/component/:idProjectComponent/:type/:idProjectBuildComponent/:idProjectComponentHistory/:idConversation",
  inviteesBuildPCB:
    "/invitees/component/:idProjectComponent/PCB/:idProjectBuildComponent/:idConversation",
  inviteesBuildBOM:
    "/invitees/component/:idProjectComponent/BOM/:idProjectBuildComponent/:idConversation",
  inviteesBuildMechComponent:
    "/invitees/component/:idProjectComponent/Mechanical/:idProjectBuildComponent/:idConversation",
  inviteesBuildOther:
    "/invitees/component/:idProjectComponent/:type/:idProjectBuildComponent/:idConversation",
  generalSearch: "/search",
  StorageUsage: "/storage-usage",
  secretChat: "/chat/:idConversation",
  notFound: "/404",
  addressBook: "/address-book",
}
