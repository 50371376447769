import FormCommit from "components/HistoryTree/Form/FormCommit"
import FormConfirm from "components/HistoryTree/Form/FormConfirm"
import FormCreate from "components/HistoryTree/Form/FormCreate"
import ModalDelete from "components/ModalCustom/ModalDelete"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_BUILD } from "components/Status/types"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { checkPermissionPage, umamiTracking } from "helpers/utils"
import TypeComponent from "pages/project-component/molecules/TypeComponent"
import {
  ProjectComponentDetail,
  UpdateProjectComponentRequest,
} from "pages/project-component/types"
import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import { PermissionProjectComponentPage } from "../contexts/PermissionProjectComponentPage.context"
import {
  deleteComponentOrVersionMiddleware,
  postBuildComponentMergeMiddleware,
  postComponentHistoryRevertMiddleware,
  postComponentHistoryTranscendMiddleware,
} from "../services"
import { BuildHistoryDetail, DataErrorWhenEditHistoryTree } from "../types"
import { ReactComponent as IconEditNameComponent } from "assets/images/icons/icon-edit-name-component.svg"
import {
  deleteProjectComponentAttachment,
  getBuildComponentDetailMiddleware,
  postArchiveProjectComponentMiddleware,
  putProjectComponentMiddleware,
} from "pages/project-component/services/api"
import FormRedirectHistoryTree from "components/HistoryTree/Form/FormRedirectHistoryTree"
import { checkDisableButtonHistoryTree } from "../project-component-detail.helper"
import { some } from "lodash"
import { useActionHistoryTree } from "../project-component-detail.hook"
import ListActionHistoryTreeComponentCard from "../molecules/ListActionHistoryTreeComponentCard"
import ListUserWorkingCard from "../molecules/ListUserWorkingCard"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { EVENT } from "constants/events"
import ActionItem from "pages/projects/molecules/ActionItem"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import ActionIconHistory from "../molecules/ActionIconHistory"
import { FormBackup } from "components/FormBackup/FormBackup"
import { MessageBackupType } from "components/FormBackup/type"
import { useAddComponentInInvitee } from "hooks/useAddComponentInInvitee"

import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"
import { EncryptionKeys } from "controllers/EncryptionController"

interface Props {
  projectComponentDetail: ProjectComponentDetail
  projectBuild: ProjectComponentBuildDetail
  historyDetail: BuildHistoryDetail
  histories: BuildHistoryDetail[]
  isNotDownload?: boolean
  isViewLinkedBuild: boolean
  handleUpdateDataWhenChangeHistory: () => void
  onUpdateDataEdit: (item: ProjectComponentDetail) => void
  onViewLinedBuilds: () => void
  specificationRequired?: string[]
  isReadComponent: boolean
  isActiveLinedBuild: boolean
  setIsReadComponent: (newIsRead: boolean) => void
  handleChangeHistoryTreeComment: (originIdHistory: string) => void
  isUpdatingBom?: boolean
  isDeleted?: boolean
  commitButtonStage: {
    isActive: boolean
    tooltipHelper: string
  }
  setArchiveComponent: React.Dispatch<any>
  handleErrorWhenPCBCommit?: () => void
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.projectComponentDetail === nextProps.projectComponentDetail &&
    prevProps.projectBuild === nextProps.projectBuild &&
    prevProps.historyDetail === nextProps.historyDetail &&
    JSON.stringify(prevProps.histories) ===
      JSON.stringify(nextProps.histories) &&
    prevProps.isViewLinkedBuild === nextProps.isViewLinkedBuild &&
    JSON.stringify(prevProps.specificationRequired) ===
      JSON.stringify(nextProps.specificationRequired) &&
    prevProps.isReadComponent === nextProps.isReadComponent &&
    prevProps.isActiveLinedBuild === nextProps.isActiveLinedBuild &&
    prevProps.isUpdatingBom === nextProps.isUpdatingBom &&
    JSON.stringify(prevProps.commitButtonStage) ===
      JSON.stringify(nextProps.commitButtonStage)
  )
}
const InfoBuildDetail = React.memo((props: Props) => {
  const {
    projectComponentDetail,
    historyDetail,
    histories,
    handleUpdateDataWhenChangeHistory,
    onUpdateDataEdit,
    isNotDownload,
    isViewLinkedBuild,
    specificationRequired = [],
    isReadComponent,
    setIsReadComponent,
    onViewLinedBuilds,
    isActiveLinedBuild,
    handleChangeHistoryTreeComment,
    isUpdatingBom = false,
    isDeleted,
    projectBuild,
    commitButtonStage,
    setArchiveComponent,
    handleErrorWhenPCBCommit,
  } = props
  const {
    archiveProject,
    archiveBuild,
    viewOnlyShare,
    archiveComponent,
    isListActionHistory,
    titlePage,
    idProjectBuildComponent,
  } = useContext(PermissionProjectComponentPage)
  const {
    isDisableEditHistory,
    isDisableMergeHistory,
    isDisableRevertHistory,
    isDisableTranscendHistory,
  } = checkDisableButtonHistoryTree(histories, historyDetail, titlePage)
  const {
    errorEditTree,
    isCommit,
    isCreateAndCopyHistory,
    isEditNameComponent,
    isLoading,
    isMerge,
    isRevert,
    isShowModalErrorEditHistory,
    isTranscend,
    isWarningTranscend,
    newNameComponent,
    refInput,
    showTippyComment,
    isDeleteComponent,
    listUserWorkings,
    isWarningCommitUserWorking,
    openModalCommit,
    openModalCreate,
    openModalMerge,
    openModalRevert,
    openModalTranscend,
    disableAllButton,
    onCloseModalErrorEditHistory,
    onSubmitError,
    openFormErrorWhenEditHistoryTree,
    onDownloadFile,
    handleDeleteComponent,
  } = useActionHistoryTree(
    projectComponentDetail,
    historyDetail,
    titlePage,
    viewOnlyShare,
    {
      project: archiveProject,
      component: archiveComponent,
      build: archiveBuild,
    },
    {
      specificationRequired,
      isDisableEditHistory,
      isDisableTranscendHistory,
      isDisableRevertHistory,
      isDisableMergeHistory,
      isUpdatingBom,
    },
    handleErrorWhenPCBCommit
  )
  const showTippySetting = useBoolean()
  const isWarningDelete = useBoolean()

  const handleClickTippyComment = (event) => {
    event.stopPropagation()
    showTippyComment.setValue(!showTippyComment.value)
    if (!showTippyComment.value) {
      umamiTracking(EVENT.COMMENT.VIEW)
    }
    setIsReadComponent(true)
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleBlur()
    }
  }

  const handleChangeInput = (event) => {
    const newValue = event.target.value
    newNameComponent.setValue(newValue)
  }
  const handleBlur = () => {
    if (
      !newNameComponent.value ||
      newNameComponent.value === projectComponentDetail.name
    ) {
      isEditNameComponent.setValue(false)
      newNameComponent.setValue(projectComponentDetail.name)
      return
    }
    const dataRequest: UpdateProjectComponentRequest = {
      name: newNameComponent.value,
      project_id: projectComponentDetail.project_id,
    }
    isLoading.setValue(true)
    putProjectComponentMiddleware(
      projectComponentDetail.id,
      dataRequest,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          onUpdateDataEdit(dataRes)
          isEditNameComponent.setValue(false)
          return
        }
        isEditNameComponent.setValue(false)
        newNameComponent.setValue(projectComponentDetail.name)
      }
    )
  }

  const handleEditNameComponent = () => {
    if (isDeleted) {
      return null
    }
    isEditNameComponent.setValue(true)
    setTimeout(() => {
      refInput.current.focus()
      refInput.current.select()
    }, 0)
  }

  const handleArchivedComponent = () => {
    postArchiveProjectComponentMiddleware(
      projectComponentDetail.id,
      !archiveComponent,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          // onUpdateDataArcvhive(dataRes)
          setArchiveComponent(!archiveComponent)
        }
      }
    )
    isLoading.setValue(true)
    showTippySetting.setValue(false)
  }

  const onSubmitDeleteAttachment = () => {
    if (!projectComponentDetail) {
      return
    }
    if (isDeleted) {
      return
    }
    deleteProjectComponentAttachment(projectComponentDetail.id)
      .then(() => {
        toast(
          <LabelNotificationPage
            messenger={"Delete component successfully!"}
            type="success"
          />
        )
        pushTo(PATH.projectComponent, {
          idProject: projectComponentDetail.project_id,
        })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={
              error.response?.data?.message || "Delete component failed!"
            }
            type="error"
          />
        )
      })
      .finally(() => {
        isWarningDelete.setValue(false)
      })
  }
  const renderIconEditNameComponent = () => {
    if (
      checkPermissionPage({
        project: archiveProject,
        viewShare: viewOnlyShare,
        component: archiveComponent,
        build: archiveBuild,
      }) ||
      isDeleted ||
      //this means that we can only edit component name in component ui, not in build component ui
      idProjectBuildComponent !== "history"
    ) {
      return null
    }
    return (
      <IconEditNameComponent
        className="ml-1 display-icon-edit-name-component cursor-pointer"
        onClick={handleEditNameComponent}
      />
    )
  }
  const renderIconSettingComponent = () => {
    if (
      isDeleted ||
      //this means that we can only edit component name in component ui, not in build component ui
      idProjectBuildComponent !== "history"
    )
      return null
    return (
      <div className="pr-1">
        <TippyCustomzie
          containerClass="component-card-menu"
          placement="bottom-end"
          interactive
          arrow={false}
          animation="scale"
          visible={showTippySetting.value}
          offset={[0, 7]}
          allowHTML
          onClickOutside={() => showTippySetting.setValue(false)}
          content={
            <div
              onClick={() => showTippySetting.setValue(!showTippySetting.value)}
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                width: 100,
              }}
            >
              <ActionItem
                title={!archiveComponent ? "Archive" : "Unarchive"}
                onClick={handleArchivedComponent}
              />
              <ActionItem
                title={"Delete"}
                onClick={() => isWarningDelete.setValue(true)}
              />
            </div>
          }
        >
          <ActionIconHistory
            icon="setting"
            disabled={archiveProject || viewOnlyShare}
            styleIcon={{ marginRight: 0 }}
            tooltip="Setting"
            onClick={() => {
              if (archiveProject) return
              showTippySetting.setValue(true)
            }}
            classIcon="w-[20px] h-[20px]"
          />
        </TippyCustomzie>
      </div>
    )
  }
  return (
    <div className="py-[14px] px-6 flex flex-col bg-white border-b border-[#E4E4E4]">
      <div className="flex items-center justify-between h-[26px] mb-1">
        <div className="flex items-center">
          <TypeComponent label={projectComponentDetail.type?.key || ""} />
          <div className="flex items-center hover-show-icon-edit-name-component ml-2">
            {!isDeleted && isEditNameComponent.value ? (
              <div
                className="relative"
                style={{
                  width: "min-content",
                  height: 26,
                  maxWidth: 600,
                }}
              >
                <span
                  className="font-semibold"
                  style={{
                    visibility: "hidden",
                    whiteSpace: "pre",
                    fontSize: 16,
                    lineHeight: "26px",
                    color: "#111111",
                    left: 0,
                    maxWidth: "100%",
                    display: "inline-block",
                  }}
                >
                  {newNameComponent.value}
                </span>
                <input
                  className="h-26-custom text-base text-white-05 focus:outline-none font-semibold absolute selector-input"
                  style={{
                    width: "100%",
                    left: 0,
                    fontSize: 16,
                    lineHeight: "26px",
                    color: "#111111",
                  }}
                  value={newNameComponent.value}
                  onChange={handleChangeInput}
                  onBlur={handleBlur}
                  onKeyPress={onKeyPress}
                  autoFocus
                  ref={refInput}
                />
              </div>
            ) : (
              <React.Fragment>
                <p
                  className="font-semibold "
                  style={{
                    fontSize: 16,
                    lineHeight: "26px",
                    color: "#111111",
                  }}
                >
                  {projectComponentDetail.name}
                </p>
                {renderIconEditNameComponent()}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <ListUserWorkingCard listUserWorkings={listUserWorkings} />
          {renderIconSettingComponent()}
        </div>
      </div>

      <ListActionHistoryTreeComponentCard
        historyDetail={historyDetail}
        titlePage={titlePage}
        isViewLinkedBuild={isViewLinkedBuild}
        onViewLinedBuilds={onViewLinedBuilds}
        handleDeleteComponent={handleDeleteComponent}
        archiveProject={archiveProject}
        viewOnlyShare={viewOnlyShare}
        archiveComponent={archiveComponent}
        isListActionHistory={isListActionHistory}
        isDisableEditHistory={isDisableEditHistory}
        isDisableRevertHistory={isDisableRevertHistory}
        isDisableMergeHistory={isDisableMergeHistory}
        isDisableTranscendHistory={isDisableTranscendHistory}
        disableAllButton={disableAllButton()}
        openModalCommit={openModalCommit}
        openModalCreate={openModalCreate}
        openModalRevert={openModalRevert}
        openModalMerge={openModalMerge}
        openModalTranscend={openModalTranscend}
        isNotDownload={isNotDownload}
        onDownloadFile={onDownloadFile}
        showTippyComment={showTippyComment}
        idProjectBuildComponent={idProjectBuildComponent}
        projectComponentDetail={projectComponentDetail}
        isReadComponent={isReadComponent}
        handleClickTippyComment={handleClickTippyComment}
        isActiveLinedBuild={isActiveLinedBuild}
        archiveBuild={archiveBuild}
        projectComponentHistoryId={historyDetail.id}
        handleChangeHistoryTree={handleChangeHistoryTreeComment}
        isDeleted={
          isDeleted ||
          (projectBuild.id && projectBuild.status === STATUS_BUILD.DRAFT
            ? true
            : false)
        }
        commitButtonStage={commitButtonStage}
      />

      <FormModal
        isDeleteComponent={isDeleteComponent}
        isCommit={isCommit}
        isCreateAndCopyHistory={isCreateAndCopyHistory}
        isRevert={isRevert}
        isTranscend={isTranscend}
        isWarningTranscend={isWarningTranscend}
        isMerge={isMerge}
        isShowModalErrorEditHistory={isShowModalErrorEditHistory}
        isLoading={isLoading}
        histories={histories}
        historyDetail={historyDetail}
        idProjectBuildComponent={idProjectBuildComponent}
        titlePage={titlePage}
        projectComponentDetail={projectComponentDetail}
        handleUpdateDataWhenChangeHistory={handleUpdateDataWhenChangeHistory}
        openFormErrorWhenEditHistoryTree={openFormErrorWhenEditHistoryTree}
        onCloseModalErrorEditHistory={onCloseModalErrorEditHistory}
        onSubmitError={onSubmitError}
        errorEditTree={errorEditTree}
        isWarningCommitUserWorking={isWarningCommitUserWorking}
      />
      {isWarningDelete.value && (
        <ModalDelete
          onCloseModal={() => isWarningDelete.setValue(false)}
          title={`When action is confirmed, all attachment files also be removed. This action can not undo.
          Press "Delete" to process`}
          content=""
          label={`Warning`}
          onSubmit={onSubmitDeleteAttachment}
          titleButton="OK"
          styleTitle={{
            textAlign: "center",
            // width: 360,
          }}
        />
      )}
    </div>
  )
}, isEqualProps)

export default InfoBuildDetail

interface FormModalProps {
  isDeleteComponent: {
    value: boolean
    setValue: (newIsDeleteComponent: boolean) => void
  }
  isCommit: {
    value: boolean
    setValue: (newIsCommit: boolean) => void
  }
  isCreateAndCopyHistory: {
    value: boolean
    setValue: (newIsCreateAndCopyHistory: boolean) => void
  }
  isRevert: {
    value: boolean
    setValue: (newIsRevert: boolean) => void
  }
  isTranscend: {
    value: boolean
    setValue: (newIsTranscend: boolean) => void
  }
  isMerge: {
    value: boolean
    setValue: (newIsMerge: boolean) => void
  }
  isShowModalErrorEditHistory: {
    value: boolean
    setValue: (newIsShowModalErrorEditHistory: boolean) => void
  }
  isLoading: {
    value: boolean
    setValue: (newIsLoading: boolean) => void
  }
  isWarningCommitUserWorking: {
    value: boolean
    setValue: (newIsLoading: boolean) => void
  }
  isWarningTranscend: {
    value: boolean
    setValue: (newIsLoading: boolean) => void
  }
  histories: BuildHistoryDetail[]
  historyDetail: BuildHistoryDetail
  idProjectBuildComponent: string
  titlePage: string
  projectComponentDetail: ProjectComponentDetail
  handleUpdateDataWhenChangeHistory: () => void
  openFormErrorWhenEditHistoryTree: (
    newData: DataErrorWhenEditHistoryTree
  ) => void
  onCloseModalErrorEditHistory: () => void
  onSubmitError: () => void
  errorEditTree: DataErrorWhenEditHistoryTree
}
const FormModal = (props: FormModalProps) => {
  const {
    isDeleteComponent,
    histories,
    isCommit,
    historyDetail,
    isCreateAndCopyHistory,
    idProjectBuildComponent,
    titlePage,
    projectComponentDetail,
    isRevert,
    isTranscend,
    isWarningTranscend,
    isMerge,
    isShowModalErrorEditHistory,
    isLoading,
    handleUpdateDataWhenChangeHistory,
    openFormErrorWhenEditHistoryTree,
    onCloseModalErrorEditHistory,
    onSubmitError,
    errorEditTree,
    isWarningCommitUserWorking,
  } = props
  const {
    syncNewVersionComponentToInvitee,
    currentMessageBackup,
    errorMessageBackup,
    isLoadingBackupInvitee,
    messageBackups,
  } = useAddComponentInInvitee()

  const onSubmitRevert = (newMessageInput: string, callback: () => void) => {
    postComponentHistoryRevertMiddleware(
      historyDetail.id,
      titlePage,
      {
        message: newMessageInput,
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        callback()
        if (type === STATUS_RESPONSE.SUCCESS) {
          handleUpdateDataWhenChangeHistory()
          isRevert.setValue(false)
        }
      }
    )
  }

  const onSkipWarningTransend = () => {
    isWarningTranscend.setValue(false)
    isTranscend.setValue(true)
  }

  const onSubmitTranscend = (newMessageInput: string, callback: () => void) => {
    postComponentHistoryTranscendMiddleware(
      historyDetail.id,
      {
        message: newMessageInput,
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        callback()
        if (type === STATUS_RESPONSE.SUCCESS) {
          isTranscend.setValue(false)
          pushTo(PATH.projectComponent, {
            idProject: projectComponentDetail.project_id,
          })
        }
      }
    )
  }
  const onSubmitMerge = (newMessageInput: string, callback: () => void) => {
    postBuildComponentMergeMiddleware(
      historyDetail.id,
      {
        message: newMessageInput,
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        callback()
        if (type === STATUS_RESPONSE.SUCCESS) {
          handleUpdateDataWhenChangeHistory()
          isMerge.setValue(false)
        }
      }
    )
  }
  const onDeleteComponentOrVersion = () => {
    if (!projectComponentDetail.id) {
      return
    }
    isLoading.setValue(true)
    deleteComponentOrVersionMiddleware(
      projectComponentDetail.id,
      some(histories, ["status", STATUS_BUILD.COMMITTED])
        ? "version"
        : "component",
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isDeleteComponent.setValue(false)
          if (!some(histories, ["status", STATUS_BUILD.COMMITTED])) {
            pushTo(PATH.projectComponent, {
              idProject: projectComponentDetail.project_id,
            })
          } else {
            handleUpdateDataWhenChangeHistory()
          }
        }
      },
      projectComponentDetail.code
    )
  }
  const onSubmitWarningCommitUserWorking = () => {
    isWarningCommitUserWorking.setValue(false)
    isCommit.setValue(true)
  }
  const syncNewVersion = async () => {
    if (
      historyDetail.project_build_component_id &&
      historyDetail.project_component_id &&
      historyDetail.id
    ) {
      setTimeout(() => {
        configureStore.dispatch(startLoading())
      }, 500)
      const projectBuild = await getBuildComponentDetailMiddleware(
        historyDetail.project_build_component_id
      )
      if (projectBuild) {
        setTimeout(() => {
          configureStore.dispatch(startLoading())
        }, 500)
        await syncNewVersionComponentToInvitee(
          projectBuild.project_id,
          projectBuild.id,
          historyDetail.project_component_id,
          historyDetail.id,
          historyDetail.code
        )
      }
    }
    handleUpdateDataWhenChangeHistory()
  }
  return (
    <React.Fragment>
      {isDeleteComponent.value && (
        <ModalDelete
          onCloseModal={() => isDeleteComponent.setValue(false)}
          title={`Are you sure to delete ${
            some(histories, ["status", STATUS_BUILD.COMMITTED])
              ? "version"
              : "component"
          }?`}
          label={`Confirmation`}
          onSubmit={onDeleteComponentOrVersion}
          titleButton="Delete"
        />
      )}
      {isCommit.value && (
        <FormCommit
          openModal={isCommit.value}
          code={historyDetail.code}
          idComponent={historyDetail.id}
          onCloseModal={() => isCommit.setValue(false)}
          handleUpdateDataWhenCommit={() => {
            syncNewVersion()
          }}
        />
      )}
      {isCreateAndCopyHistory.value && (
        <FormCreate
          openModal={isCreateAndCopyHistory.value}
          idComponent={
            idProjectBuildComponent && idProjectBuildComponent !== "history"
              ? idProjectBuildComponent
              : projectComponentDetail.id
          }
          URLHistory={titlePage}
          onCloseModal={() => isCreateAndCopyHistory.setValue(false)}
          handleUpdateDataWhenCreate={handleUpdateDataWhenChangeHistory}
          label={`Preparing new version from ${historyDetail.code}`}
          openFormErrorWhenEditHistoryTree={openFormErrorWhenEditHistoryTree}
        />
      )}
      {isRevert.value && (
        <FormConfirm
          idHistory={historyDetail.id}
          onCloseModal={() => isRevert.setValue(false)}
          onSubmitButton={onSubmitRevert}
          message={`You are reverting version ${historyDetail.code}.`}
          label="Revert"
        />
      )}
      {isTranscend.value && (
        <FormConfirm
          idHistory={historyDetail.project_component_id}
          onCloseModal={() => isTranscend.setValue(false)}
          onSubmitButton={onSubmitTranscend}
          message={`You are duplicating version ${historyDetail.code}.`}
          label="Duplicate"
          messageUndo="This action will create a new component."
        />
      )}
      {isMerge.value && (
        <FormConfirm
          idHistory={historyDetail.id}
          onCloseModal={() => isMerge.setValue(false)}
          onSubmitButton={onSubmitMerge}
          message={`You are mergeing version ${historyDetail.code}.`}
          label="Merge"
        />
      )}
      {isShowModalErrorEditHistory.value ? (
        <FormRedirectHistoryTree
          onCloseModal={onCloseModalErrorEditHistory}
          titlePage={titlePage}
          errorEditTree={errorEditTree}
          onSubmitError={onSubmitError}
        />
      ) : null}
      {isWarningCommitUserWorking.value && (
        <ModalDelete
          onCloseModal={() => isWarningCommitUserWorking.setValue(false)}
          title={`More than one user are viewing this draft. Please ensure all changes are synced up before proceeding.
If you wish to proceed, please press OK!`}
          content=""
          label={`Warning`}
          onSubmit={onSubmitWarningCommitUserWorking}
          titleButton="OK"
          colorYellowButton
          styleTitle={{
            textAlign: "center",
            // width: 360,
          }}
        />
      )}
      {isWarningTranscend.value && (
        <ModalDelete
          onCloseModal={() => isWarningTranscend.setValue(false)}
          title={`Due to the complexity of our versioning system, when you duplicate a component, the history tree will start over from the beginning. Press OK to proceed.`}
          content=""
          label={`Warning`}
          onSubmit={onSkipWarningTransend}
          titleButton="OK"
          styleTitle={{
            textAlign: "center",
            // width: 360,
          }}
          contentWrapperStyles={{
            paddingTop: 10,
            paddingBottom: 36,
          }}
        />
      )}
      {isLoadingBackupInvitee.value && messageBackups.length ? (
        <FormBackup
          messageBackups={messageBackups}
          currentBackup={currentMessageBackup}
          errorMessageBackup={errorMessageBackup.value}
        />
      ) : null}
    </React.Fragment>
  )
}
