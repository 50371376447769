import Button from "components/Button/Button"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import CheckedDefault from "components/Checked/CheckedDefault"
import { ClearAll, FilterIcon } from "components/Filter"
import { FilterCollapse } from "components/Filter/FilterCollapse"
import { FilterHeader } from "components/Filter/FilterHeader"
import { SelectedDefaultProp } from "components/Select/types"
import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { IUseDefaultValueProps, useBoolean, useWindowSize } from "helpers/hooks"
import { checkPermissionPage, customLocalStorageHandler } from "helpers/utils"
import { isUndefined } from "lodash"
import FormSelectFilter from "pages/conversations/molecules/FormSelectFilter"
import ActionItem from "pages/projects/molecules/ActionItem"
import TabItem from "pages/projects/molecules/TabItem"
import { useContext } from "react"
import { useParams } from "react-router-dom"
import { PermissionProjectPage } from "../contexts/PermissionProjectPage.context"
import { TAB_PROJECT_COMPONENT } from "../project-component.constant"
import { NAME_LOCALSTORAGE } from "constants/localStorage"

interface Props {
  idProject: string
  onApplyFilter: () => void
  showArchive: IUseDefaultValueProps
  showDeletedAttachment: IUseDefaultValueProps
  cpnTypeOptions: SelectedDefaultProp[]
  cpnTypeSelected: SelectedDefaultProp
  setCpnTypeOptions: (cpnTypeSelected: SelectedDefaultProp[]) => void
  summary: {
    components: number
    builds: number
  }
  tab: TAB_PROJECT_COMPONENT
  buildStatusOptions: SelectedDefaultProp[]
  buildStatusSelected: SelectedDefaultProp
  setBuildStatusSelected: (buildStatusSelected: SelectedDefaultProp) => void
  onChangeTab: (newTab: TAB_PROJECT_COMPONENT) => () => void
  onClickNewComponent: () => void
  onClickNewFodler: () => void
  setCpnTypeSelected: (cpnTypeSelected: SelectedDefaultProp) => void
  handleOnSelectCpnType: (event: SelectedDefaultProp) => void
  handleOnSelectBuildStatus: (event: SelectedDefaultProp) => void
  isDeleted?: boolean
  folderName?: string
  onClickModalNewBuild?: () => void
}
const TabProjectComponent = (props: Props) => {
  const {
    onApplyFilter,
    showArchive,
    showDeletedAttachment,
    cpnTypeOptions,
    cpnTypeSelected,
    setCpnTypeSelected,
    summary,
    tab,
    buildStatusOptions,
    buildStatusSelected,
    setBuildStatusSelected,
    idProject,
    onClickNewComponent,
    onClickNewFodler,
    handleOnSelectCpnType,
    onChangeTab,
    handleOnSelectBuildStatus,
    isDeleted,
    onClickModalNewBuild,
  } = props

  const { archiveProject, viewOnlyShare } = useContext(PermissionProjectPage)
  const { isMobile } = useWindowSize()
  const applyFilter = useBoolean(false)
  const showTippyName = useBoolean(false)
  const params = useParams<{
    idProject: string
  }>()
  const idProjectParams = params?.idProject || ""
  const { storageData } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.PROJECT_COMPONENT
  )
  const cookiesProjectComponent = !isUndefined(storageData) ? storageData : []
  const cookieDetail = cookiesProjectComponent.find(
    (el) => el.id === idProjectParams
  )

  const isComponentTab = tab === TAB_PROJECT_COMPONENT.COMPONENT
  const isBuildTab = tab === TAB_PROJECT_COMPONENT.BUILD

  const showComponentFilter = useBoolean()

  const { TippyLayout } = useTippyLayout()

  const onClickDraftBuild = () => {
    if (
      checkPermissionPage({
        project: archiveProject,
        viewShare: viewOnlyShare,
        notificationProject: true,
        notificationViewShare: true,
      })
    ) {
      return
    }
    onClickModalNewBuild?.()
  }

  const onCheckClearAll = () => {
    if (
      !showArchive.value ||
      showDeletedAttachment.value ||
      cpnTypeSelected.value !== "0" ||
      (isBuildTab && buildStatusSelected.value !== "0")
    ) {
      return true
    }

    return false
  }

  /// set back to default value for each state
  const onClearFilter = () => {
    showArchive.setValue(true)
    showDeletedAttachment.setValue(false)
    setBuildStatusSelected({ value: "0", label: "All" })
    setCpnTypeSelected({ value: "0", label: "All" })
  }

  const onShowOnlyDelete = () => {
    if (!showDeletedAttachment.value) {
      setBuildStatusSelected({ value: "-1", label: "All" })
      setCpnTypeSelected({ value: "-1", label: "All" })
      showArchive.setValue(false)
    } else {
      showArchive.setValue(true)
      setBuildStatusSelected({ value: "0", label: "All" })
      setCpnTypeSelected({ value: "0", label: "All" })
    }
    showDeletedAttachment.setValue(!showDeletedAttachment.value)
  }
  const onShowArchive = () => {
    if (!showArchive.value && showDeletedAttachment.value) {
      setBuildStatusSelected({ value: "0", label: "All" })
      setCpnTypeSelected({ value: "0", label: "All" })
    }
    showArchive.setValue(!showArchive.value)
    showDeletedAttachment.setValue(false)
  }

  const onFilterComponentType = (chosenValue: any) => {
    if (showDeletedAttachment.value) {
      handleOnSelectCpnType({
        label: "All",
        value: "-1",
      })
      return
    }
    handleOnSelectCpnType(chosenValue)
  }

  /// get previous value from cookies
  const onSetBackToPreviousValue = () => {
    if (!applyFilter.value) {
      showArchive.setValue(cookieDetail.isArchived)
      showDeletedAttachment.setValue(cookieDetail.isDeleted)
      setCpnTypeSelected(cookieDetail.type)

      if (isBuildTab) {
        setBuildStatusSelected(cookieDetail.status)
      }
    }
  }
  const handleClickTippyName = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archiveProject,
        notificationProject: true,
        notificationViewShare: true,
      }) ||
      isDeleted
    ) {
      return
    }
    showTippyName.setValue(!showTippyName.value)
  }

  /// project filter content on mobile
  const renderProjectFilterDynamic = () => (
    <div>
      <ClearAll onClick={onClearFilter} active={onCheckClearAll()} />

      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        tilteClass="ml-0"
        textColor="black"
        checked={showArchive.value}
        onClick={onShowArchive}
        title="Show Archive"
      />

      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-b-[1px] px-6"
        tilteClass="ml-0"
        textColor="black"
        checked={showDeletedAttachment.value}
        onClick={onShowOnlyDelete}
        title="Show deleted only"
      />

      {isBuildTab ? (
        <FilterCollapse
          label="Status"
          data={buildStatusOptions}
          chosenValue={buildStatusSelected.value}
          onChange={(chosenValue) => {
            handleOnSelectBuildStatus(chosenValue)
          }}
          disabled={showDeletedAttachment.value}
        />
      ) : null}

      <FilterCollapse
        label="Component type"
        data={cpnTypeOptions}
        chosenValue={cpnTypeSelected.value}
        onChange={onFilterComponentType}
        disabled={showDeletedAttachment.value}
      />
    </div>
  )

  /// project filter content on desktop
  const renderComponentFilterContent = () => (
    <div
      className="pt-3.5 pb-3"
      style={{
        background: "#222222",
        borderRadius: 4,
        width: 436,
      }}
    >
      <div className="flex items-center justify-between mb-3 px-3 select-none">
        <p className="hidden md:block font-semibold text-16 leading-26 text-white">
          Filter
        </p>

        <ClearAll onClick={onClearFilter} active={onCheckClearAll()} />
      </div>

      <CheckedDefault
        title="Show Archive"
        className="ml-3 mb-3"
        checked={showArchive.value}
        onClick={onShowArchive}
      />

      <CheckedDefault
        title="Show deleted only"
        className="ml-3"
        checked={showDeletedAttachment.value}
        onClick={onShowOnlyDelete}
      />

      <div className="flex">
        {isBuildTab ? (
          <FormSelectFilter
            title="Status"
            type="single"
            dataSelects={
              buildStatusOptions.map((el) => ({
                ...el,
                key: el.label,
                value: Number(el.value),
              })) as any
            }
            objActiveSelect={Number(buildStatusSelected.value)}
            setObjActiveSelect={(chosenValue) => {
              if (showDeletedAttachment.value) {
                handleOnSelectBuildStatus({
                  label: chosenValue,
                  value: "-1",
                })
                return
              }
              handleOnSelectBuildStatus({
                label: chosenValue,
                value: String(chosenValue),
              })
            }}
          />
        ) : null}

        <FormSelectFilter
          title="Component type"
          type="single"
          containerClass={`${isBuildTab ? "" : "w-full"}`}
          dataSelects={
            cpnTypeOptions.map((el) => ({
              ...el,
              key: el.label,
              value: Number(el.value),
            })) as any
          }
          objActiveSelect={Number(cpnTypeSelected.value)}
          setObjActiveSelect={(chosenValue) => {
            if (showDeletedAttachment.value) {
              handleOnSelectCpnType({
                label: chosenValue,
                value: "-1",
              })
              return
            }
            handleOnSelectCpnType({
              label: chosenValue,
              value: String(chosenValue),
            })
          }}
        />
      </div>

      <div className="flex justify-end mt-3 mr-3">
        <Button
          title="Apply"
          onClick={() => {
            onApplyFilter()
            showComponentFilter.setValue(false)
          }}
          widthBtn={120}
          heightBtn={42}
        />
      </div>
    </div>
  )

  return (
    <div
      className={`flex flex-row items-center ${
        tab === TAB_PROJECT_COMPONENT.FOLDER ? "justify-end" : "justify-between"
      } px-6 mt-6 h-auto md:h-10 gap-y-5 md:gap-y-0`}
    >
      {tab !== TAB_PROJECT_COMPONENT.FOLDER ? (
        <div className="flex items-center">
          <TabItem
            title={`Components (${summary.components})`}
            style={{
              marginRight: 24,
            }}
            isActive={isComponentTab}
            onClick={onChangeTab(TAB_PROJECT_COMPONENT.COMPONENT)}
          />
          <TabItem
            title={`Builds (${summary.builds})`}
            isActive={tab === TAB_PROJECT_COMPONENT.BUILD}
            onClick={onChangeTab(TAB_PROJECT_COMPONENT.BUILD)}
          />
        </div>
      ) : (
        ""
      )}

      <div className="flex items-center">
        {isMobile ? (
          <TippyLayout
            visible={showComponentFilter.value}
            headerContent={
              <FilterHeader
                setVisible={showComponentFilter.setValue}
                onCancel={onSetBackToPreviousValue}
                onApply={() => {
                  onApplyFilter()
                  showComponentFilter.setValue(false)
                }}
              />
            }
            mainContent={renderProjectFilterDynamic()}
          >
            <FilterIcon
              onClick={() => {
                showComponentFilter.setValue(!showComponentFilter.value)
              }}
              activeIcon={onCheckClearAll()}
            />
          </TippyLayout>
        ) : (
          <TippyCustomzie
            containerClass="component-card-menu"
            placement="bottom-end"
            interactive
            arrow={false}
            animation="scale"
            offset={isBuildTab ? [130, 10] : [170, 10]}
            allowHTML
            visible={showComponentFilter.value}
            content={renderComponentFilterContent()}
            onClickOutside={() => {
              onSetBackToPreviousValue()
              /// close filter popup
              showComponentFilter.setValue(false)
            }}
          >
            <FilterIcon
              onClick={() => {
                showComponentFilter.setValue(!showComponentFilter.value)
              }}
              activeIcon={onCheckClearAll()}
            />
          </TippyCustomzie>
        )}

        {!isDeleted && !isMobile ? (
          <div className="ml-[20px]">
            {tab === TAB_PROJECT_COMPONENT.BUILD ? (
              <ButtonHasIcon
                title="New Build"
                disabled={checkPermissionPage({
                  project: archiveProject,
                  viewShare: viewOnlyShare,
                })}
                widthButton={107}
                onClick={onClickDraftBuild}
              />
            ) : tab === TAB_PROJECT_COMPONENT.FOLDER ? (
              <ButtonHasIcon
                title="New Component"
                disabled={checkPermissionPage({
                  project: archiveProject,
                  viewShare: viewOnlyShare,
                })}
                widthButton={149}
                onClick={onClickNewComponent}
              />
            ) : (
              <TippyCustomzie
                disabled={checkPermissionPage({
                  project: archiveProject,
                  viewShare: viewOnlyShare,
                })}
                visible={showTippyName.value}
                containerClass="component-card-menu"
                placement="bottom-start"
                animation="scale"
                offset={[0, 7]}
                allowHTML
                onClickOutside={() => showTippyName.setValue(false)}
                interactive
                content={
                  <div className="bg-[#222222] rounded-4 py-2 w-[117px]">
                    <ActionItem
                      className="hidden md:flex"
                      title="Folder"
                      onClick={() => {
                        showTippyName.setValue(false)
                        onClickNewFodler()
                      }}
                    />
                    <ActionItem
                      className="hidden md:flex"
                      title="Component"
                      onClick={() => {
                        showTippyName.setValue(false)
                        onClickNewComponent()
                      }}
                    />
                  </div>
                }
              >
                <ButtonHasIcon
                  title="Create New"
                  disabled={checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                  })}
                  widthButton={117}
                  onClick={handleClickTippyName}
                />
              </TippyCustomzie>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default TabProjectComponent
