import React, { useContext } from "react"
import {
  arrDoubleInputUnits,
  SpecificationSingleInputCardProps,
} from "../types"
import { showImageUrl, fieldFormDataSpecification } from "helpers/utils"
import InputDefault from "components/Input/InputDefault"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { API_URL } from "config/environments"

const SpecificationSingleInputCard = (
  props: SpecificationSingleInputCardProps
) => {
  const {
    item,
    widthInput,
    unit,
    minInput,
    heightImage,
    widthImage,
    disabled = false,
  } = props
  const context = useContext(VersionSpecificationContext)

  const handleChangeInput = (newIndex: number) => (event) => {
    let newValue = event.target?.value
    if (Number(event.target?.value) < 0) {
      newValue = minInput
    }
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, newIndex),
      newValue,
      Boolean(item.required)
    )
  }

  const handleChangeCheckBox = (newField: string) => () => {
    if (disabled) {
      return
    }
    context?.updateDataForm(
      "formData",
      `${item.id}_unitDoubleInput`,
      newField,
      Boolean(item.required)
    )
  }
  return (
    <div
      className="flex items-center relative"
      style={{
        flexFlow: "row wrap",
      }}
    >
      {item.answers.map((answer, index) => (
        <div className="flex items-center" key={index}>
          <InputDefault
            disabled={disabled}
            placeholder={answer.placeholder}
            value={
              context?.formData[fieldFormDataSpecification(item.id, index)] ??
              ""
            }
            min={minInput}
            onChange={handleChangeInput(index)}
            classCustom={`h-[32px] ${widthInput ?? "w-[80px]"} ${
              context?.isShowErrorSpecification
                ? "border-error-specification"
                : "border-input"
            } bg-white rounded px-4 focus:outline-none mr-2 text-center ${
              context?.formData[fieldFormDataSpecification(item.id, index)]
                ? "active-input-specification"
                : ""
            } `}
          />
          {index === 0 && unit ? (
            <p
              className="mr-2 font-normal"
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
              }}
            >
              {unit}
            </p>
          ) : null}
        </div>
      ))}
      <div
        className="flex items-center"
        style={{
          width: 28,
        }}
      >
        <p
          className="font-normal"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          {context?.formData[`${item.id}_unitDoubleInput`]}
        </p>
      </div>

      <div
        className="flex items-center ml-2 pl-2 h-10 mr-2.5"
        style={{
          borderLeft: "1px solid #E4E4E4",
        }}
      >
        {arrDoubleInputUnits.map((newUnit, index) => (
          <div className="flex items-center mr-2" key={index}>
            <div
              className="h-5 w-5 flex items-center justify-center cursor-pointer"
              style={{
                border:
                  context?.formData[`${item.id}_unitDoubleInput`] ===
                  newUnit.field
                    ? "1px solid #FFFFFF"
                    : "1px solid #E4E4E4",
                backgroundColor:
                  context?.formData[`${item.id}_unitDoubleInput`] ===
                  newUnit.field
                    ? "#FDBE44"
                    : "#FFFFFF",
                borderRadius: "50%",
              }}
              onClick={handleChangeCheckBox(newUnit.field)}
            >
              <div
                className="h-2.5 w-2.5"
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "50%",
                }}
              ></div>
            </div>
            <p
              className="font-normal ml-2"
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
              }}
            >
              {newUnit.field}
            </p>
          </div>
        ))}
      </div>

      {item.image ? (
        <img
          src={showImageUrl(item.image, API_URL)}
          alt="icon"
          className={"mb-2"}
          style={{
            height: heightImage ?? "auto",
            width: widthImage ?? "auto",
          }}
        />
      ) : null}
      {context?.isShowErrorSpecification ? (
        <p
          className="absolute bottom-0 font-semibold"
          style={{
            color: "#EA4545",
            fontSize: 10,
            lineHeight: "14px",
          }}
        >
          This is required
        </p>
      ) : null}
    </div>
  )
}

export default SpecificationSingleInputCard
