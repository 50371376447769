import {
  AddressBookForm,
  AddressBookQueryState,
  AddressBookResponse,
} from "./address-book.type"
import Axios, { AxiosResponse } from "axios"

export const getAddressBookMiddleware = async (
  params?: AddressBookQueryState
) => {
  try {
    const response: AxiosResponse<{
      data: AddressBookResponse[]
      total: number
    }> = await Axios.get(`/api/address-book/get-list`, {
      params,
    })
    return {
      data: response.data.data,
      total: response.data.total,
    }
  } catch (error) {
    return {
      data: [],
      total: 0,
    }
  }
}

export const postAddressBookMiddleware = async (
  dataRequest: AddressBookForm
) => {
  const response: AxiosResponse<{
    data: AddressBookResponse | null
  }> = await Axios.post(`/api/address-book/create`, dataRequest)
  return response.data.data
}

export const putAddressBookMiddleware = async (
  addressBookId: string,
  dataRequest: AddressBookForm
) => {
  const response: AxiosResponse<{
    data: AddressBookResponse | null
  }> = await Axios.put(`/api/address-book/${addressBookId}/update`, dataRequest)
  return response.data.data
}

export const deleteAddressBookMiddleware = async (addressBookId: string) => {
  const response: AxiosResponse<{
    data: AddressBookResponse | null
  }> = await Axios.delete(`/api/address-book/${addressBookId}/delete`)
  return response.data.data
}
