/* eslint-disable react-hooks/exhaustive-deps */
import {
  downloadEncrypted,
  formatDate,
  formatDateForConversationMessage,
} from "helpers/utils"

import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"

import { useEffect, useRef, useState } from "react"
import {
  CONVERSATION_ROLE,
  ConversationDetail,
  ConversationMessagesDetail,
  MENU_TAB_CONVERSATION,
} from "../conversations.type"
import {
  BUILD_INVITEE_STATUS,
  InviteeBuilComponentDetail,
  MENU_TAB_BUILD,
  ProjectBuildAdditionalDetail,
} from "pages/project-build/project-build.type"
import { useAdditionalAndExtraInfoProjectBuild } from "pages/project-build/project-build.hook"
import {
  onRedirectConversationComponent,
  projectBuildLink,
} from "helpers/redirect"
import SwitchButtonDefault from "components/SwitchButton/SwitchButtonDefault"
import { ProjectComponentStatus, STATUS_BUILD } from "components/Status/types"
import {
  IUseDefaultValueProps,
  useBoolean,
  useString,
  useWindowSize,
} from "helpers/hooks"
import LabelHeader, {
  getContentLabelStyleProps,
} from "pages/project-component/molecules/LabelHeader"
import ActivityLogDefault from "components/activity-log/ActivityLogDefault"
import { MODEL_ACTIVITY_LOG } from "components/activity-log/activity-log.type"
import { twMerge } from "tailwind-merge"
import InfoNameAndEmailConversationCard from "../atoms/InfoNameAndEmailConversationCard"
import StatusConversationCard from "../atoms/StatusConversationCard"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import ComponentBuildItemCard2 from "pages/project-build/molecules/ComponentBuildItemCard2"
import TabCoversationDetail from "./TabCoversationDetail"
import AdditionalInfoCard from "pages/project-build/organisms/AdditionalInfoCard"
import MenuTabConversationCard from "../molecules/MenuTabConversationCard"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { getProjectBuildAdditionalInfoMiddleware } from "pages/project-build/api.services"
import { useAppSelector } from "hooks/useApp"
import { isEmpty } from "lodash"
import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import { HeaderContent } from "components/HeaderInfo/HeaderInfo"
import { ReactComponent as ArrowUpIcon } from "assets/images/icons/arrow-up.svg"
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/icon-arrow-down-select-tippy.svg"
import { ReactComponent as CollapseIcon } from "assets/images/icons/collapse-icon.svg"
import { ReactComponent as ExpandIcon } from "assets/images/icons/expand-icon.svg"
import DotCard from "../atoms/DotCard"
import { ReactComponent as IconLock } from "assets/images/icons/icon-lock.svg"
import { getWorkerLocalStorageData } from "workers/utils"

interface Props {
  conversationInfo: ConversationDetail
  conversationMessages: ConversationMessagesDetail
  activeIdConversation: string
  activeIdBuild: string
  activeMenu: string
  isChangeScrollMessageBottom: boolean
  updateWhenAddMessager: (newMessage: string) => void
  getConversationMessages: (
    conversationId: string,
    setConversation: boolean,
    loading: boolean
  ) => Promise<void>
  onScrollMessage: () => void
  isNotificationMessage: boolean
  onChangeNotification: (originNotification: boolean) => () => void
  isConversationUnActive: boolean
  isDeleted?: boolean
  closeLayout: IUseDefaultValueProps
  collapse: IUseDefaultValueProps
  isWarningEncrypted: boolean
}
const ConversationMessagesColumn = (props: Props) => {
  const userInfo = useAppSelector((state) => state.userInfo)
  const {
    conversationMessages,
    conversationInfo,
    activeIdConversation,
    isChangeScrollMessageBottom,
    activeMenu,
    isNotificationMessage,
    isConversationUnActive,
    updateWhenAddMessager,
    onScrollMessage,
    onChangeNotification,
    isDeleted,
    getConversationMessages,
    closeLayout,
    collapse,
    isWarningEncrypted,
  } = props
  const { ref, handleChangeScroll } =
    useAdditionalAndExtraInfoProjectBuild("conversation")
  const { isMobile } = useWindowSize()
  const [additionals, setAdditionals] = useState<
    ProjectBuildAdditionalDetail[]
  >([])
  const activeTab = useString(MENU_TAB_BUILD.CONVERSATION)
  const isLoading = useBoolean()
  const isShowAllComponent = useBoolean(isMobile)
  const { width } = useWindowSize()
  const menuAndConversationTabWidth = 500
  const buildComponentCardWidth = 265

  const maximumComponentsDisplay = Math.floor(
    (width - menuAndConversationTabWidth) / buildComponentCardWidth
  )
  const contentLabelStyleProps = getContentLabelStyleProps(!isMobile)
  const showProjectInfo = useBoolean()
  const { TippyLayout /* closeLayout, openLayout  */ } = useTippyLayout()
  const isToggle = useBoolean()
  const refP = useRef<any>()
  const { currentProjectEncryptionKey } = getWorkerLocalStorageData()

  useEffect(() => {
    if (activeTab.value === MENU_TAB_BUILD.ADDITIONAL) {
      getAdditional(true)
    }
    if (!isChangeScrollMessageBottom) {
      return
    }
    handleChangeScroll()
  }, [
    JSON.stringify(conversationMessages.messages),
    isChangeScrollMessageBottom,
  ])

  useEffect(() => {
    getAdditional(true)
  }, [conversationInfo.id])

  const onChangeTab = (_newIndex: number, newLabel?: string) => () => {
    if (!newLabel) {
      return
    }
    if (activeTab.value === newLabel) {
      return
    }
    activeTab.setValue(newLabel)
    isLoading.setValue(true)
    if (newLabel === MENU_TAB_BUILD.CONVERSATION) {
      getConversationMessages(conversationInfo.id, true, true)
      return
    }
    getAdditional(true)
  }
  const getAdditional = async (loading = false) => {
    const dataAdditionalRes = await getProjectBuildAdditionalInfoMiddleware(
      conversationInfo.project_build_id,
      conversationInfo.id
    )
    setAdditionals(dataAdditionalRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }
  const onExportFile = () => {
    if (!conversationInfo.id) {
      return
    }
    isLoading.setValue(true)
    downloadEncrypted(
      `/api/project-build/${conversationInfo.project_build_id}/files?conversation_id=${conversationInfo.id}`,
      conversationInfo.project_build_id
    )
    isLoading.setValue(false)
  }

  const onClickRootBuildComponent =
    (oldComponent: InviteeBuilComponentDetail) => () => {
      if (isConversationUnActive) return
      onRedirectConversationComponent(
        oldComponent.type_key,
        oldComponent.project_component_id,
        oldComponent.project_build_component_id,
        oldComponent.project_component_history_id,
        activeIdConversation,
        !isConversationUnActive,
        undefined,
        true
      )
    }
  const handleScrollMessage = () => {
    const scrollTop = ref.current?.scrollTop
    if (Number(scrollTop) < 1) {
      onScrollMessage()
      ref.current?.scrollTo(0, 1)
    }
  }

  const renderBuildAndEmailAction = () =>
    !(
      activeMenu === MENU_TAB_CONVERSATION.OTHERS ||
      conversationInfo.is_archived_by_owner
    ) && (
      <div className="flex items-center justify-between ">
        <SwitchButtonDefault
          containerClass="ml-2"
          checked={isNotificationMessage}
          disabled={
            activeMenu === MENU_TAB_CONVERSATION.OTHERS ||
            conversationInfo.is_archived_by_owner
              ? true
              : false
          }
          labelButton="Email notification"
          onChange={onChangeNotification(isNotificationMessage)}
        />
      </div>
    )

  const renderHeader = () => (
    <div className="flex justify-between border-solid border-b-[1px] border-brown h-[48px] pr-6">
      <div className="flex justify-between">
        <LabelHeader
          title="Convo ID:"
          label={`${conversationInfo.code}`}
          isBorderRight={
            conversationInfo.invitee_status !== BUILD_INVITEE_STATUS.DEACTIVATE
          }
        />
        {conversationInfo.invitee_status !==
          BUILD_INVITEE_STATUS.DEACTIVATE && (
          <div className="flex items-center">
            <p className="font-normal leading-[22px] text-[13px]">
              Build status:{" "}
            </p>
            <LabelStatusBuild
              status={
                conversationInfo.project_build_status || STATUS_BUILD.DRAFT
              }
              rootStyle={{
                fontSize: 13,
                lineHeight: "22px",
                marginLeft: "4px",
              }}
            />
          </div>
        )}
      </div>
      <div className="flex items-center">
        <LabelHeader
          title="Project ID:"
          label={`${conversationInfo.project_code}`}
          isBorderRight={true}
          isClick={!isEmpty(conversationInfo.project_id)}
          path={`/project-detail/${conversationInfo.project_id}`}
        />

        <LabelHeader
          title="Build ID:"
          label={`${conversationInfo.project_build_code}`}
          isBorderRight={true}
          isClick={
            !isEmpty(conversationInfo.project_build_id) &&
            (activeMenu === MENU_TAB_CONVERSATION.OTHERS ||
              activeMenu === MENU_TAB_CONVERSATION.FROM_ME)
          }
          path={projectBuildLink(
            conversationInfo.project_id,
            conversationInfo.project_build_id,
            conversationInfo.project_build_status
          )}
          search={{
            tab: conversationInfo.from === userInfo.id ? "my-builds" : "others",
          }}
        />

        <LabelHeader
          title="Created:"
          label={formatDate(conversationInfo.created_at, "DD MMM yyyy")}
          isBorderRight={true}
        />
        <LabelHeader
          title="Last message:"
          label={`${formatDateForConversationMessage(
            conversationInfo.updated_at
          )}`}
          isBorderRight={
            currentProjectEncryptionKey &&
            currentProjectEncryptionKey.length === 64 &&
            conversationMessages.is_owner
              ? true
              : false
          }
        />
        {currentProjectEncryptionKey &&
          currentProjectEncryptionKey.length === 64 &&
          conversationMessages.is_owner && (
            <ActivityLogDefault
              model={MODEL_ACTIVITY_LOG.CONVERSATION_BUILDS}
              idRelation={conversationInfo.id}
            />
          )}
      </div>
    </div>
  )
  const headerMobileContent = () => {
    return (
      <>
        <LabelHeader
          {...contentLabelStyleProps}
          title="Project ID:"
          label={`${conversationInfo.project_code}`}
          isClick={!isEmpty(conversationInfo.project_id)}
          path={`/project-detail/${conversationInfo.project_id}`}
        />

        <LabelHeader
          {...contentLabelStyleProps}
          title="Build ID:"
          label={`${conversationInfo.project_build_code}`}
          isClick={!isEmpty(conversationInfo.project_build_id)}
          path={projectBuildLink(
            conversationInfo.project_id,
            conversationInfo.project_build_id,
            conversationInfo.project_build_status
          )}
          search={{
            tab: conversationInfo.from === userInfo.id ? "my-builds" : "others",
          }}
        />
        <div className="flex items-center justify-between h-[52px] md:h-[13px] w-full border-b-[1px] first-item-border-top md:border-none">
          <div className="flex items-end md:items-center justify-between flex-1">
            <p className="font-normal text-sm md:text-[13px] leading-[22px] whitespace-nowrap overflow-hidden px-6 md:px-0 text-new-black-333333">
              Build status:{" "}
            </p>
            <LabelStatusBuild
              status={
                conversationInfo.project_build_status || STATUS_BUILD.DRAFT
              }
              rootStyle={{
                fontSize: 13,
                lineHeight: "22px",
                marginLeft: "4px",
              }}
              className="px-6"
            />
          </div>
        </div>
        <LabelHeader
          {...contentLabelStyleProps}
          title="Convo ID:"
          label={`${conversationInfo.code}`}
        />

        <LabelHeader
          {...contentLabelStyleProps}
          title="Created:"
          label={formatDate(conversationInfo.created_at, "DD MMM yyyy")}
        />
        <LabelHeader
          {...contentLabelStyleProps}
          title="Last message:"
          label={`${formatDateForConversationMessage(
            conversationInfo.updated_at
          )}`}
        />
      </>
    )
  }
  const renderHeaderMobile = () => (
    <TippyLayout
      visible={showProjectInfo.value}
      headerContent={
        <HeaderContent onClick={() => showProjectInfo.setValue(false)} />
      }
      mainContent={headerMobileContent()}
      allowHTML={true}
    >
      <div className="flex items-center justify-between border-b-[1px] border-brown">
        <div
          className="flex p-2"
          onClick={() => {
            closeLayout.setValue(false)
          }}
        >
          <ArrowLeftIcon />
        </div>
        <div className="flex justify-end md:justify-between items-center h-full px-6 py-3">
          <LabelHeader
            title="Project Info"
            isBorderRight={
              currentProjectEncryptionKey &&
              currentProjectEncryptionKey.length === 64 &&
              conversationMessages.is_owner
                ? true
                : false
            }
            onClickTitle={() => {
              showProjectInfo.setValue(true)
            }}
          />
          {currentProjectEncryptionKey &&
            currentProjectEncryptionKey.length === 64 &&
            conversationMessages.is_owner && (
              <ActivityLogDefault
                model={MODEL_ACTIVITY_LOG.CONVERSATION_BUILDS}
                idRelation={conversationInfo.id}
              />
            )}
        </div>
      </div>
    </TippyLayout>
  )
  const renderParticipantInfo = () => {
    const className =
      "flex flex-col md:mt-3 md:flex-row items-start md:items-center gap-y-3 gap-x-6 md:gap-y-0"
    return (
      <div className={twMerge("justify-between ", className)}>
        <div className="flex flex-col pr-6 border-none gap">
          <InfoNameAndEmailConversationCard
            name={conversationMessages.owner.fullname}
            email={conversationMessages.owner.email}
            label="From:"
          />
          <InfoNameAndEmailConversationCard
            name={conversationMessages.invitee.name}
            email={conversationMessages.invitee.email || "N/A"}
            label="To:"
          />
        </div>
        {/* CC LIST */}
        <div className="h-[44px] ">
          <div className="flex items-center max-w-[400px] overflow-hidden align-top ">
            <p
              className="font-normal text-13 leading-22 color-gray-7a mr-2"
              style={{
                width: 48,
              }}
            >
              {"CC:"}
            </p>
            <div className="flex flex-wrap gap-x-[1px] overflow-hidden ">
              {conversationInfo.cc.length === 0
                ? "N/A"
                : conversationInfo.cc.map((email, index) => (
                    <p
                      className="font-normal text-13 leading-22 color-gray-7a"
                      key={index}
                    >
                      {email}
                      {index !== conversationInfo.cc.length - 1 ? "," : ""}
                    </p>
                  ))}
            </div>
          </div>
        </div>

        <div className="hidden md:flex gap-1 items-center md:border-solid md:border-l-[1px] md:border-[#E4E4E4] md:pr-3">
          <StatusConversationCard status={conversationInfo.status} />
          {!isConversationUnActive && (
            <ActionIconHistory
              icon="download"
              tooltip="Download"
              styleIcon={{
                margin: 0,
              }}
              onClick={onExportFile}
              classIcon="hidden md:flex action-icon-download-conversation"
            />
          )}
        </div>
      </div>
    )
  }

  const renderComponent = () => {
    return (
      <div className="">
        <div className="hidden md:block mb-[6px] mt-4">
          <div
            className="flex gap-4 font-semibold font-body"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              fontStyle: "normal",
            }}
          >
            <p className="text-black">{`Components (${conversationInfo.components.length})`}</p>
            {conversationMessages.components.length >
              maximumComponentsDisplay && (
              <p
                className="text-blue cursor-pointer"
                onClick={() =>
                  isShowAllComponent.setValue(!isShowAllComponent.value)
                }
              >
                {isShowAllComponent.value ? "Show less" : "Show all"}
              </p>
            )}
          </div>
        </div>
        <div className="flex item-center md:flex-wrap gap-3 pb-1 md:pb-0 mb-2 md:mb-0 w-full whitespace-nowrap md:whitespace-normal overflow-x-auto overflow-y-hidden md:overflow-hidden">
          {conversationMessages.components.map((buildComponent, index) => {
            if (!isShowAllComponent.value && index >= maximumComponentsDisplay)
              return
            return (
              <ComponentBuildItemCard2
                key={index}
                component={buildComponent}
                onClickRoot={onClickRootBuildComponent}
                isDeleted={
                  isDeleted ||
                  buildComponent.status ===
                    ProjectComponentStatus.DeletedAttachment
                }
                isDisabled={!!isConversationUnActive}
                tooltip={
                  !buildComponent.is_read_invitee_commit ? (
                    <span
                      className="font-normal"
                      style={{
                        fontSize: 13,
                        lineHeight: "22px",
                        wordBreak: "break-word",
                      }}
                    >
                      Invitee submitted a new version of this component.
                    </span>
                  ) : null
                }
              />
            )
          })}
          {conversationMessages.components.length - maximumComponentsDisplay >
            0 &&
            !isShowAllComponent.value && (
              <div className="flex items-center text-gray w-4 text-[12px] leading-[18px] font-medium text-center">
                +
                {conversationMessages.components.length -
                  maximumComponentsDisplay}
              </div>
            )}
        </div>
      </div>
    )
  }

  const renderConvoDetail = () => {
    return (
      <div>
        {renderParticipantInfo()}
        {renderComponent()}
      </div>
    )
  }
  const renderConvoDetailOnMobile = () => (
    <div className="flex justify-between items-center">
      <div className="flex justify-between items-center gap-2">
        <div className="font-normal text-13 leading-22 color-gray-7a mr-2 max-w-[200px]">
          <div className="overflow-hidden text-ellipsis " ref={refP}>
            <p
              className="font-semibold whitespace-nowrap"
              style={{
                fontSize: 12,
                lineHeight: "20px",
                color: "#333333",
              }}
            >
              {conversationInfo.code}
            </p>{" "}
            <span className="text-black font-semibold">
              {conversationInfo.owner.name}
            </span>{" "}
            to{" "}
            <span className="text-black font-semibold w-full">
              {conversationInfo.invitee.name}
            </span>
          </div>
          <div className="flex items-center">
            <p>Cc to {conversationInfo.cc.length}</p>
            <DotCard styleRoot={{ marginLeft: 8, marginRight: 8 }} />
            <p>{conversationInfo.components?.length || 0} components</p>
          </div>
        </div>
        <div className="" onClick={() => isToggle.setValue(!isToggle.value)}>
          {isToggle.value ? (
            <ArrowDownIcon className="w-[16px] h-[16px]" />
          ) : (
            <ArrowUpIcon className="w-[16px] h-[16px] text-black" />
          )}
        </div>
      </div>
      <div className="pl-2 border-l-[1px] border-[#E4E4E4]">
        <StatusConversationCard status={conversationInfo.status} />
      </div>
    </div>
  )
  const renderTippy = () => (
    <TippyCustomzie
      visible={isToggle.value}
      content={
        <div className="pl-6 pb-4">
          {renderParticipantInfo()}
          {renderComponent()}
        </div>
      }
      containerClass={
        refP.current?.clientHeight === 22
          ? "conversation-mobile-tippy"
          : "conversation-mobile-tippy2"
      }
      placement="bottom-start"
      interactive
      arrow={false}
      animation="scale"
      allowHTML
      onClickOutside={() => {
        isToggle.setValue(false)
      }}
    >
      {renderConvoDetailOnMobile()}
    </TippyCustomzie>
  )
  const renderTab = () => {
    if (activeTab.value === MENU_TAB_BUILD.CONVERSATION) {
      return (
        <TabCoversationDetail
          idConversation={activeIdConversation}
          originMessages={conversationMessages.messages}
          projectId={conversationInfo.project_id}
          // only invitee is able to chat
          isEditorInput={
            !(
              isConversationUnActive ||
              (conversationInfo.role === CONVERSATION_ROLE.VIEWER &&
                //incase invitee is the viewer
                conversationInfo.invitee.user_id !== userInfo.id)
            )
          }
          updateWhenAddMessager={updateWhenAddMessager}
          inviteeUser={conversationMessages.invitee}
          onScrollMessage={handleScrollMessage}
          isChangeScrollMessageBottom={true}
          isDeleted={isDeleted}
          notYetCommented={conversationMessages.is_not_yet_commented_by_users}
          disableButtonEditor={
            conversationInfo.invitee_status === BUILD_INVITEE_STATUS.DEACTIVATE
          }
        />
      )
    }

    return (
      <AdditionalInfoCard
        isLabel={false}
        additionals={additionals}
        idProjectBuild={conversationInfo.project_build_id}
        updateWhenCreateAdditional={() => undefined}
        updateWhenDeleteAdditional={() => undefined}
        isDeleteComment={true}
        isEditorInput={true}
        isDeleted={isDeleted}
        conversationId={conversationInfo.id}
      />
    )
  }

  return (
    <div
      className={`relative flex flex-col flex-1 pb-6 overflow-hidden
       h-[calc(100_*_var(--vh)_-_52px)] md:h-[calc(100_*_var(--vh)_-_0px)]`}
      style={{
        position: "relative",
      }}
    >
      <div
        className="absolute left-[-1px] bottom-[50%] cursor-pointer hidden md:block"
        onClick={() => {
          collapse.setValue(!collapse.value)
        }}
      >
        {collapse.value ? (
          <CollapseIcon className="collapse-icon" />
        ) : (
          <ExpandIcon className="collapse-icon" />
        )}
      </div>
      <div className="h-full flex flex-col justify-between">
        <div
          className={`flex flex-col w-full gap-2 ${
            isToggle.value ? "z-[101]" : ""
          } bg-white pl-6`}
        >
          {isMobile ? renderHeaderMobile() : renderHeader()}
          <div className="pb-3 border-solid border-b-[1px] border-brown">
            {isMobile ? renderTippy() : renderConvoDetail()}
            {/* <div className="block md:hidden">{renderBuildAndEmailAction()}</div> */}
          </div>
        </div>

        <div className="h-full flex flex-col justify-between overflow-auto pl-6 bg-white">
          {/* tab menu */}
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between pr-6">
            <MenuTabConversationCard
              onChangeTab={onChangeTab}
              activeTab={activeTab.value}
              additional={{
                isRead: false,
                count: conversationInfo.additional_count,
              }}
              conversation={{
                count: conversationMessages.total_message,
                isRead: false,
              }}
              isDeactivated={
                conversationInfo.invitee_status ===
                BUILD_INVITEE_STATUS.DEACTIVATE
              }
            />
            <div className="hidden md:block">{renderBuildAndEmailAction()}</div>
          </div>
          <div className="h-full flex flex-col overflow-auto ">
            {/* tab */}
            {renderTab()}
          </div>
        </div>
        <div />
      </div>
      {/* overlay */}
      {isToggle.value && isMobile && (
        <div
          className={`block md:hidden fixed w-[100vw] h-[100vh] `}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 10,
          }}
        ></div>
      )}
      {isWarningEncrypted ? (
        <div className="absolute bottom-0 left-4 right-0 top-12 bg-[#FFFFFF]">
          <div className="flex flex-col items-center justify-center h-full">
            <IconLock />
            <p className="text-[14px] text-[#EA4545] leading-[24px] mt-[24px] font-semibold mb-[12px]">
              This conversation is encrypted.
            </p>
            <p className="text-[14px] text-[#111111] leading-[24px]">
              Ask the owner for access to view it.
            </p>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default ConversationMessagesColumn
