import { encryptionHelper } from "helpers/encryption"
import { isEmpty } from "lodash"
import { IWorkerLocalStorage } from "./type"
import { EncryptionKeys } from "controllers/EncryptionController"
import { getStoreData, Stores } from "services/indexedDB"

const getEncryptionKey = (
  localStorage: IWorkerLocalStorage,
  options: {
    type:
      | "conversation"
      | "conversation_note"
      | "project"
      | "build_additional_info"
    dataType: "string" | "file"
    encryptionKey?: string
    relationId?: string
  }
) => {
  let key = options.encryptionKey
  if (isEmpty(key)) {
    switch (options.type) {
      case "project":
        key = localStorage.currentProjectEncryptionKey
        if (options.relationId) {
          key = localStorage.projectEncryptionKeys[options.relationId]
        }
        break
      case "conversation":
        key = localStorage.currentConversationEncryptionKey
        if (options.relationId) {
          key = localStorage.conversationEncryptionKeys[options.relationId]
        }
        break

      case "conversation_note":
        key = localStorage.userVaultKey
        break
      case "build_additional_info":
        key = ""
        if (options.relationId) {
          key =
            localStorage.buildAdditionalInfoEncryptionKeys[options.relationId]
        }
        break
      default:
        key = ""
        break
    }
  }
  return key
}

export const encryptFile = async (
  localStorageData: IWorkerLocalStorage,
  data: any,
  options: {
    type:
      | "conversation"
      | "conversation_note"
      | "project"
      | "build_additional_info"
    dataType: "string" | "file"
    encryptionKey?: string
    relationId?: string
  }
) => {
  //get key
  const key = getEncryptionKey(localStorageData, options)
  if (!key || isEmpty(key)) {
    return data
  }
  if (options.dataType === "string") {
    return encryptionHelper.encrypt(key, data) as string
  }

  const readFile = await encryptionHelper.readFile(data)
  let fileArrayBuffer = new Uint8Array(readFile as any)
  const temp = encryptionHelper.toWordArray(fileArrayBuffer)
  return encryptionHelper.encrypt(key, temp) as string
}

export const getWorkerLocalStorageData = (): IWorkerLocalStorage => {
  return {
    currentProjectEncryptionKey: localStorage.getItem(
      EncryptionKeys.currentProjectEncryptionKey
    ) as string,
    projectEncryptionKeys: JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    ),
    currentConversationEncryptionKey: localStorage.getItem(
      EncryptionKeys.currentConversationEncryptionKey
    ) as string,
    conversationEncryptionKeys: JSON.parse(
      localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
    ),
    userVaultKey: localStorage.getItem(EncryptionKeys.userVaultKey) as string,
    buildAdditionalInfoEncryptionKeys: JSON.parse(
      localStorage.getItem(EncryptionKeys.buildAdditionalInfoEncryptionKeys) ||
        "{}"
    ),
    userId: localStorage.getItem("user_id") as string,
  }
}

export const encryptContent = async (
  localStorageData: IWorkerLocalStorage,
  content: any,
  contentOption: any
) => {
  return await encryptFile(localStorageData, content, contentOption)
}

export const encryptSVGs = async (
  localStorageData: IWorkerLocalStorage,
  dataSvgs: any[]
) => {
  const svgRequest: { fileName: string; blob: Blob }[] = []
  const svgs = await Promise.all(
    dataSvgs.map(async (svg) => {
      const svgBlob = new Blob([svg.content], { type: "image/svg+xml" })
      const encryptedHtmlFile = await encryptFile(localStorageData, svgBlob, {
        dataType: "file",
        type: "project",
      })
      svgRequest.push({
        blob: new Blob([encryptedHtmlFile], { type: svgBlob.type }),
        fileName: svg.name,
      })
      const { content, ...rest } = svg
      return rest
    })
  )
  return { svgs, svgRequest }
}

export const encryptFiles = async (
  localStorageData: IWorkerLocalStorage,
  files: any[],
  fileOption: any,
  isRawFile: boolean
) => {
  const privateKeyStores = await getStoreData<{
    id: string
    privateKey: string
  }>(Stores.PrivateKeys)
  const privateKeyByUserId =
    privateKeyStores.find((el) => el.id === localStorageData.userId)
      ?.privateKey || ""

  if (!privateKeyByUserId) {
    return { newFileHash: [], attachments: [], fileString: "" }
  }
  const attachments: { fileName: string; blob: Blob }[] = []
  let fileString = ""
  const newFileHash: any = []
  console.log("-------START ENCRYPT FILE-----------")
  for (const [indexFile, file] of files.entries()) {
    const encryptedFile = await encryptFile(
      localStorageData,
      isRawFile ? file : file.file,
      fileOption
    )

    let fileText: any = null
    if (isRawFile) {
      const rawFileBlob = new Blob([file], { type: file.type })
      fileText = await rawFileBlob.text()
    } else {
      fileText = await file.file.text()
    }

    const signatureAndHashFile = encryptionHelper.createSignatureAndHashFile(
      fileText,
      true,
      privateKeyByUserId
    )

    if (signatureAndHashFile.signature) {
      attachments.push({
        blob: new Blob([encryptedFile], {
          type: isRawFile ? file.type : file.file.type,
        }),
        fileName: isRawFile ? file.name : file.file_name,
      })

      fileString =
        fileString +
        `${indexFile > 0 ? ", " : ""} ${isRawFile ? file.name : file.file_name}`
    }
    console.log("Encrypted file", isRawFile ? file.name : file.file_name)
    if (isRawFile) {
      newFileHash.push({
        file_name: file.name,
        file_hash: signatureAndHashFile.signature,
      })
    } else {
      newFileHash.push({
        ...file,
        file_hash: signatureAndHashFile.signature,
      })
    }
  }
  console.log("Total files", attachments.length)
  console.log("-------END ENCRYPT FILE-----------")

  return {
    newFileHash: newFileHash.filter((el) => el.file_hash),
    attachments,
    fileString,
  }
}

export const toUint8Array = (data: any) => {
  const dataArray = new Uint8Array(data.sigBytes)
  for (let i = 0x0; i < data.sigBytes; i++) {
    dataArray[i] = (data.words[i >>> 0x2] >>> (0x18 - (i % 0x4) * 0x8)) & 0xff
  }
  return new Uint8Array(dataArray)
}
