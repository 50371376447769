import { useRef, useEffect, useState } from "react"
import { includes, isNull } from "lodash"
import * as THREE from "three"
import { TDSLoader } from "three/examples/jsm/loaders/TDSLoader"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls"
import TreeSTLLoader from "three-stl-loader"
import Button from "components/Button/Button"
import {
  convertObjectToGlb,
  loadOcctImport,
} from "components/ThreeDModels/CustomLoaders/StepLoader"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { ACTION_WORKER } from "workers/type"
import { MESSENGER_NOTIFICATION } from "constants/messenger"

interface ThreeDModelProps {
  url: string
  fileName?: string
  workerFileMech3D?: Worker | null
  extraParams?: { [key: string]: any }
  decryptedShareKey?: string
}

type LoaderProps = (
  renderer: THREE.WebGLRenderer,
  fileUrl: string,
  aspect: number,
  result?: any
) => Promise<{
  camera: THREE.PerspectiveCamera
  reqAnimation: number
  resetCamera: () => void
}>

const ViewerMaxHeight = 500

export const Mech3DModel = ({
  url,
  fileName,
  workerFileMech3D,
  extraParams,
  decryptedShareKey,
}: ThreeDModelProps) => {
  const [data, setData] = useState<any>(null)
  const loader = getObjectLoader(fileName || url)
  const refContainer = useRef<any>(null)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [renderer, setRenderer] = useState<any>()
  const [camera, setCamera] = useState<THREE.PerspectiveCamera>()
  const [reqAnimation, setReqAnimation] = useState<number>()
  const [resetCamera, setResetCamera] = useState<any>(undefined)
  const get3DFile = async () => {
    if (!workerFileMech3D) {
      toast(
        <LabelNotificationPage messenger="Worker not available" type="error" />
      )
      return
    }

    const token = localStorage.getItem("access_token")
    const tokenType = localStorage.getItem("token_type") || "Bearer"
    const projectKey = localStorage.getItem("currentProjectEncryptionKey")
    const newKey = decryptedShareKey || projectKey
    if (!newKey || !token) {
      toast(<LabelNotificationPage messenger="Key not found." type="warning" />)
      return
    }
    toast(
      <LabelNotificationPage
        messenger="System busy. Please wait.."
        type="warning"
      />
    )
    workerFileMech3D.postMessage({
      action: ACTION_WORKER.START_LOAD_FILE,
      payload: {
        fileURL: url,
        accessToken: `${tokenType} ${token}`,
        encryptKey: newKey,
        extraParams,
      },
    })

    workerFileMech3D.onmessage = async function (e) {
      if (e.data.action === ACTION_WORKER.FINISH_LOAD_FILE) {
        const { url, isError } = e.data.payload
        if (isError || !url) {
          setErrorMessage(MESSENGER_NOTIFICATION.LOAD_FILE_ERROR)
          return
        }
        setData(url as string)
      }
    }
  }

  useEffect(() => {
    if (!url || !refContainer.current) return
    get3DFile()
  }, [url])

  useEffect(() => {
    if (!camera || !renderer) {
      return
    }
    const resize = () => {
      const { current: container } = refContainer
      if (!camera || !container) return
      const newScW = container.clientWidth
      const newScH = newScW > ViewerMaxHeight ? ViewerMaxHeight : newScW
      camera.aspect = newScW / newScH
      camera.updateProjectionMatrix()
      renderer.setSize(newScW, newScH)
    }
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [camera, renderer])

  useEffect(() => {
    if (!data) {
      return
    }
    getData3DModel()
  }, [data])

  const getData3DModel = async () => {
    const { current: container } = refContainer
    if (container && !renderer) {
      let containerDOM = container
      let scW = containerDOM.clientWidth
      let scH = ViewerMaxHeight
      while (scW === 0) {
        containerDOM = containerDOM.parentElement
        scW = containerDOM.clientWidth
      }

      if (scW <= ViewerMaxHeight) {
        scH = scW
      }
      const aspect = scW / scH
      const newRenderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      })
      newRenderer.setPixelRatio(window.devicePixelRatio)
      newRenderer.setSize(scW, scH)
      newRenderer.outputEncoding = THREE.sRGBEncoding
      container.appendChild(newRenderer.domElement)
      setRenderer(newRenderer)
      if (includes(["stp", "step"], loader.extension)) {
        const occt = (await loadOcctImport()) as any

        if (!occt) {
          setErrorMessage(MESSENGER_NOTIFICATION.LOAD_FILE_ERROR)
          return
        }
        const response = await fetch(data)
        const buffer = await response.arrayBuffer()
        // read the imported step file
        const fileBuffer = new Uint8Array(buffer)
        if (workerFileMech3D) {
          workerFileMech3D.postMessage({
            action: ACTION_WORKER.START_READ_FILE,
            payload: {
              fileBuffer,
            },
          })

          workerFileMech3D.onmessage = async function (e) {
            if (e.data.action === ACTION_WORKER.FINISH_READ_FILE) {
              const { result } = e.data.payload
              if (!result) {
                setErrorMessage(MESSENGER_NOTIFICATION.LOAD_FILE_ERROR)
                return
              }
              loadStepModel(newRenderer, data, aspect, result)
                .then((result) => {
                  update3DWhenLoadFile(result)
                })
                .catch((error) => {
                  console.log(error)
                  setLoading(false)
                  setErrorMessage(MESSENGER_NOTIFICATION.LOAD_FILE_ERROR)
                })
            }
          }
        }
        return
      }
      const loaderModel = loader.loaderModel
      if (loaderModel) {
        loaderModel(newRenderer, data, aspect, workerFileMech3D)
          .then((result) => {
            update3DWhenLoadFile(result)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
            setErrorMessage(MESSENGER_NOTIFICATION.LOAD_FILE_ERROR)
          })
      }

      // remove event listener
      return () => {
        if (reqAnimation) {
          cancelAnimationFrame(reqAnimation)
        }
        newRenderer.dispose()
      }
    }
  }

  const update3DWhenLoadFile = (result: {
    camera: THREE.PerspectiveCamera
    reqAnimation: number
    resetCamera: () => void
  }) => {
    setCamera(() => result.camera)
    setReqAnimation(() => result.reqAnimation)
    setResetCamera(() => result.resetCamera)
    setLoading(false)
  }

  return (
    <div
      className="w-full h-[0] relative"
      style={{ paddingTop: `min(${ViewerMaxHeight}px, 100%)` }}
    >
      <div className="w-full h-full absolute left-3 top-0" ref={refContainer}>
        {isNull(loader.loaderModel) ? (
          <span className="font-semibold text-14 leading-24 color-gray-7a flex w-full h-full justify-center items-center">
            The 3D viewer currently supports .stl, .3ds, .obj, .stp, .step only
          </span>
        ) : loading ? (
          <span className="font-semibold text-14 leading-24 color-gray-7a flex w-full h-full justify-center items-center">
            Loading...
          </span>
        ) : errorMessage.length > 0 ? (
          <span className="absolute top-0 font-semibold text-14 leading-24 color-gray-7a flex w-full h-full justify-center items-center">
            {errorMessage}
          </span>
        ) : null}
      </div>
      <div className="3d-controls absolute right-0 top-0">
        <Button
          title="Reset View"
          colorBtn="white"
          sizeBtn="small"
          disabled={
            isNull(loader.loaderModel) || loading || errorMessage.length
              ? true
              : false
          }
          onClick={resetCamera}
        />
      </div>
    </div>
  )
}

const getFileExtension = (filename: string) => {
  const pattern = "^.+\\.([^.]+)$"
  const ext = new RegExp(pattern).exec(filename)
  return ext == null ? "" : ext[1]
}

const fitCameraToSelection = (
  camera: any,
  controls: any,
  object: any,
  fitOffset = 2.2
) => {
  const size = new THREE.Vector3()
  const center = new THREE.Vector3()
  const box = new THREE.Box3()
  box.makeEmpty()
  box.expandByObject(object)

  box.getSize(size)
  box.getCenter(center)

  const maxSize = Math.max(size.x, size.y, size.z)
  const fitHeightDistance =
    maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360))
  const fitWidthDistance = fitHeightDistance / camera.aspect
  const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance)

  const direction = controls.target
    .clone()
    .sub(camera.position)
    .normalize()
    .multiplyScalar(distance)

  controls.maxDistance = distance * 10
  controls.target.copy(center)

  camera.near = distance / 100
  camera.far = distance * 100
  camera.updateProjectionMatrix()

  camera.position.copy(controls.target).sub(direction)

  controls.update()
}

const loadSTLModel: LoaderProps = (renderer, fileUrl, aspect) => {
  return new Promise((resolve, reject) => {
    const STLLoader = TreeSTLLoader(THREE)
    const loader = new STLLoader()
    const scene = new THREE.Scene()
    loader.load(
      fileUrl,
      (geometry: any) => {
        const material = new THREE.MeshMatcapMaterial({
          color: 0xffeedd,
        })
        const object = new THREE.Mesh(geometry, material)

        object.geometry.computeVertexNormals()
        object.geometry.center()
        scene.add(object)
        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2
        /// light
        const ambientLight = new THREE.AmbientLight(0xcccccc, 1)
        scene.add(ambientLight)
        const controls = new OrbitControls(camera, renderer.domElement)
        ////
        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }
        const resetCamera = () => {
          return fitCameraToSelection(camera, controls, object)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error: Error) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const load3DSModel: LoaderProps = (renderer, fileUrl, aspect) => {
  return new Promise((resolve, reject) => {
    const loader = new TDSLoader()
    const scene = new THREE.Scene()
    loader.load(
      fileUrl,
      (object) => {
        scene.add(object)
        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2

        /// light
        scene.add(new THREE.HemisphereLight())
        const directionalLight = new THREE.DirectionalLight(0xffeedd)
        directionalLight.position.set(0, 0, 2)
        scene.add(directionalLight)

        /// controls
        const controls = new TrackballControls(camera, renderer.domElement)
        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material.specular.setScalar(0.1)
          }
        })

        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }

        const resetCamera = () => {
          fitCameraToSelection(camera, controls, object)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const loadObjModel: LoaderProps = (renderer, fileUrl, aspect) => {
  return new Promise((resolve, reject) => {
    const loader = new OBJLoader()
    const scene = new THREE.Scene()
    loader.load(
      fileUrl,
      (object) => {
        scene.add(object)
        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2

        /// light
        const ambientLight = new THREE.AmbientLight(0xffeedd, 0.2)
        scene.add(ambientLight)

        const pointLight = new THREE.PointLight(0xffffff, 0.6)
        camera.add(pointLight)
        scene.add(camera)
        /// controls
        const controls = new TrackballControls(camera, renderer.domElement)

        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }
        const resetCamera = () => {
          fitCameraToSelection(camera, controls, object)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const loadStepModel: LoaderProps = async (
  renderer,
  _fileUrl,
  aspect,
  result
) => {
  const targetObject = new THREE.Object3D()
  // process the geometries of the result
  for (const resultMesh of result.meshes) {
    let geometry = new THREE.BufferGeometry()

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(resultMesh.attributes.position.array, 3)
    )
    if (resultMesh.attributes.normal) {
      geometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(resultMesh.attributes.normal.array, 3)
      )
    }
    const index = Uint32Array.from(resultMesh.index.array)
    geometry.setIndex(new THREE.BufferAttribute(index, 1))

    let material: any = null
    if (resultMesh.color) {
      const color = new THREE.Color(
        resultMesh.color[0],
        resultMesh.color[1],
        resultMesh.color[2]
      )
      material = new THREE.MeshPhongMaterial({ color })
    }

    if (resultMesh.face_colors) {
      material = new THREE.MeshPhongMaterial({
        vertexColors: true,
      })
      // For vertices
      geometry = geometry.toNonIndexed()
      const faceColors = new Array(
        geometry.attributes.position.array.length
      ).fill(0)
      for (const faceColorGroup of resultMesh.face_colors) {
        const { color, first, last } = faceColorGroup
        for (let i = first; i <= last; i++) {
          faceColors.splice(Math.floor(i * 9), 9, ...color, ...color, ...color)
        }
      }

      geometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(faceColors, 3)
      )
    }

    if (!material) {
      material = new THREE.MeshPhongMaterial({ color: "#c8c8c8" })
    }

    const mesh = new THREE.Mesh(geometry, material)
    targetObject.add(mesh)
  }

  const glbObject: any = await convertObjectToGlb(targetObject)

  if (!glbObject) {
    return Promise.reject("Error")
  }
  const loader = new GLTFLoader()
  const scene = new THREE.Scene()
  renderer.toneMapping = THREE.ACESFilmicToneMapping
  renderer.toneMappingExposure = 1
  return new Promise((resolve, reject) => {
    loader.load(
      glbObject,
      (gltf) => {
        scene.add(gltf.scene)
        gltf.scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.castShadow = true
            child.receiveShadow = true
          }
        })

        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2

        // /// light
        // /// light
        const ambientLight = new THREE.AmbientLight(0xffeedd, 1)
        scene.add(ambientLight)
        //
        const pointLight = new THREE.PointLight(0xffffff, 0.6)
        camera.add(pointLight)
        scene.add(camera)

        const controls = new OrbitControls(camera, renderer.domElement)
        ////
        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }

        const resetCamera = () => {
          return fitCameraToSelection(camera, controls, gltf.scene)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error) => reject(error)
    )
  })
}

const loaderMapping: { [key: string]: LoaderProps } = {
  stl: loadSTLModel,
  "3ds": load3DSModel,
  obj: loadObjModel,
  stp: loadStepModel,
  step: loadStepModel,
}

export const getObjectLoader = (url: string) => {
  const fileExtension = getFileExtension(url).toLocaleLowerCase()
  const loader = loaderMapping[fileExtension] || null
  return {
    extension: fileExtension,
    loaderModel: loader,
  }
}
