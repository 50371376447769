import React from "react"
import { TypographyProps } from "./Typography/types"
import Typography from "components/Typography"
import { getFirstLetterInName, showImageUrl } from "helpers/utils"
import { API_URL } from "config/environments"

interface ItemAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  avatar?: string
  name?: string
  containerclass?: string
  content?: string
  textcolor?: TypographyProps["textColor"]
  contentsize?: string
  width?: string
  height?: string
  justOneLetter?: boolean
  styleAvatarURL?: React.CSSProperties
}
export const Avatar = (props: ItemAvatarProps) => {
  const {
    avatar,
    containerclass,
    name,
    content,
    textcolor = "white",
    contentsize,
    width,
    height,
    justOneLetter,
    styleAvatarURL,
    ...rootProps
  } = props
  const newContent = content
    ? content
    : getFirstLetterInName(name || "N/A", true)
  const newURLAvatar =
    avatar && avatar.includes("public/") ? API_URL : undefined
  return (
    <div
      className={`${
        !avatar ? "bg-orange" : "bg-white"
      }  rounded-50% flex items-center justify-center ${
        !avatar ? "border-white border" : "border-[#e6e6e6]"
      } ${containerclass}`}
      {...rootProps}
      style={{
        width: width || "24px",
        height: height || "24px",
      }}
    >
      {!avatar ? (
        <Typography
          textColor={textcolor}
          textClass="leading-1.125-custom font-semibold"
          style={{ fontSize: contentsize || "10px", fontWeight: 500 }}
        >
          {newContent}
        </Typography>
      ) : (
        <img
          src={showImageUrl(avatar, newURLAvatar)}
          alt="icon"
          style={{
            width: width || "100%",
            height: height || "100%",
            ...styleAvatarURL,
          }}
          className={`rounded-50% object-cover`}
        />
      )}
    </div>
  )
}
