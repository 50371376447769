import { useString } from "helpers/hooks"
import ModalCustom from "components/ModalCustom"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import Button from "components/Button/Button"
import InputDefault from "components/Input/InputDefault"
import { useEffect, useState } from "react"
import { STATUS_INPUT } from "components/Input/types"
import { changeLabelStatusEmail } from "helpers/utils"
import { AddressBookForm, AddressBookResponse } from "../address-book.type"
import {
  postAddressBookMiddleware,
  putAddressBookMiddleware,
} from "../address-book.api"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"

interface Props {
  onCloseModal: () => void
  openModal: boolean
  dataSelected: AddressBookResponse
  handleWhenSubmit: (response: AddressBookResponse) => void
}

export const FormAddressBookModal = (props: Props) => {
  const { onCloseModal, openModal, dataSelected, handleWhenSubmit } = props
  const name = useString("")
  const email = useString("")

  const [statusInputEmail, setStatusInputEmail] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })

  useEffect(() => {
    if (!openModal) {
      return
    }
    name.setValue(dataSelected.name)
    email.setValue(dataSelected.email)
  }, [openModal, dataSelected.id])

  const handleChangeInput = (key: "name" | "email") => (event) => {
    const value = event.target.value
    if (key === "name") {
      name.setValue(value)
      return
    }
    setStatusInputEmail(changeLabelStatusEmail(event.target.value))
    email.setValue(value)
  }

  const onKeyPressInput = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onSubmit()
    }
  }

  const onSubmit = async () => {
    const dataRequest: AddressBookForm = {
      name: name.value,
      email: email.value,
    }
    if (dataSelected.id) {
      try {
        const response = await putAddressBookMiddleware(
          dataSelected.id,
          dataRequest
        )
        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.PUT_ADDRESS_BOOK_SUCCESS}
            type="success"
          />
        )

        if (response) {
          handleWhenSubmit(response)
        }
      } catch (error: any) {
        toast(
          <LabelNotificationPage
            messenger={
              error.response?.data?.message ??
              MESSENGER_NOTIFICATION.PUT_ADDRESS_BOOK_ERROR
            }
            type="error"
          />
        )
      }
      return
    }
    try {
      const response = await postAddressBookMiddleware(dataRequest)
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.POST_ADDRESS_BOOK_SUCCESS}
          type="success"
        />
      )

      if (response) {
        handleWhenSubmit(response)
      }
    } catch (error: any) {
      console.log(error)
      toast(
        <LabelNotificationPage
          messenger={
            error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_ADDRESS_BOOK_ERROR
          }
          type="error"
        />
      )
    }
  }

  const handleDisableButton = (): boolean => {
    const isEmailError = statusInputEmail.status === STATUS_INPUT.ERROR
    if (isEmailError) {
      return true
    }
    if (name.value.trim() === "" || email.value.trim() === "") {
      return true
    }
    return false
  }

  return (
    <ModalCustom
      label={`${dataSelected.id ? "Edit" : "Create new"} contact`}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <FormInputCard title="Name" required fontWeightText="font-semibold">
            <InputDefault
              value={name.value}
              onChange={handleChangeInput("name")}
              onKeyPress={onKeyPressInput}
              maxLength={255}
            />
          </FormInputCard>
          <FormInputCard title="Email" required fontWeightText="font-semibold">
            <InputDefault
              value={email.value}
              onChange={handleChangeInput("email")}
              status={statusInputEmail.status}
              labelStatus={statusInputEmail.label}
              onKeyPress={onKeyPressInput}
              maxLength={255}
            />
          </FormInputCard>
          <div className="flex items-center justify-between">
            <Button title="Cancel" colorBtn="white" onClick={onCloseModal} />
            <Button
              title={dataSelected.id ? "Save" : "Create"}
              disabled={handleDisableButton()}
              onClick={onSubmit}
            />
          </div>
        </div>
      }
    />
  )
}
