import TabItem from "components/Tab/TabItem"
import { MENU_TAB_BUILD } from "../project-build.type"
interface Props {
  onChangeTab: (index: number, label?: string) => () => void
  activeTab: string
  additional: {
    isRead: boolean
    count: number
  }
  extra: {
    isRead: boolean
    count: number
  }
  invitees: {
    isRead: boolean
    count: number
  }
  isInvitee: boolean
}
const MenuTabBuildCard = (props: Props) => {
  const { activeTab, additional, extra, invitees, isInvitee, onChangeTab } =
    props
  const styleTab = () => {
    return {
      height: "auto",
      borderBottom: "none",
      display: "flex",
    }
  }
  return (
    <div className="min-h-[24px] md:min-h-[48px] mt-6 md:mt-0 mb-6 md:mb-[10px] flex items-center overflow-x-auto overflow-y-hidden whitespace-nowrap">
      <TabItem
        index={0}
        label={MENU_TAB_BUILD.COMPONENT}
        active={activeTab === MENU_TAB_BUILD.COMPONENT}
        handleClickTabItem={onChangeTab}
        className="flex items-center md:hidden min-h-[24px] border-0"
      />
      <TabItem
        index={2}
        label={MENU_TAB_BUILD.ADDITIONAL}
        count={additional.count}
        active={activeTab === MENU_TAB_BUILD.ADDITIONAL}
        handleClickTabItem={onChangeTab}
        styleTabRoot={styleTab()}
        isRead={additional.isRead}
        className="min-h-[24px]"
      />
      {isInvitee ? (
        <TabItem
          index={1}
          label={MENU_TAB_BUILD.INVITEES}
          active={activeTab === MENU_TAB_BUILD.INVITEES}
          handleClickTabItem={onChangeTab}
          styleTabRoot={styleTab()}
          isRead={invitees.isRead}
          count={invitees.count}
          className="min-h-[24px]"
        />
      ) : null}
    </div>
  )
}
export default MenuTabBuildCard
