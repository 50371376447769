import { STATUS_BUILD } from "components/Status/types"
import {
  IUseDefaultValueProps,
  useBoolean,
  useString,
  useWindowSize,
} from "helpers/hooks"
import { getProjectBuildMiddleware } from "pages/project-build/api.services"
import {
  ProjectComponentBuildDetail,
  emptyProjectBuildDetail,
} from "pages/project-build/project-build.type"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import BuildDetail from "./organisms/BuildDetail"
import BuildDraft from "./organisms/BuildDraft"
import { ReactComponent as CollapseIcon } from "assets/images/icons/collapse-icon.svg"
import { ReactComponent as ExpandIcon } from "assets/images/icons/expand-icon.svg"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
import iconNoData from "assets/images/icons/icon-no-data-component.svg"
import { SkeletonBuildDetail } from "./molecules/SkeletonBuild"

interface Props {
  isArchived: boolean | undefined
  showBuildDetailLayout: IUseDefaultValueProps
  collapse: IUseDefaultValueProps
}
const BuildDetailColumn = (props: Props) => {
  const { isMobile } = useWindowSize()
  const { isArchived, showBuildDetailLayout, collapse } = props
  const params = useParams<{
    status: string
    idProject: string
    idProjectBuild: string
  }>()
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const updatedAtBuild = useString("")
  const idProjectBuildParams = params?.idProjectBuild || ""
  const isLoadingSkeletonBuild = useBoolean(false)

  useEffect(() => {
    getProjectBuild(true)
  }, [params?.idProjectBuild, params.idProject])

  const getProjectBuild = async (isSkeleton = false) => {
    if (!idProjectBuildParams) {
      setProjectBuild(emptyProjectBuildDetail)
      return emptyProjectBuildDetail
    } else {
      if (isSkeleton) {
        isLoadingSkeletonBuild.setValue(true)
      }
      const dataRes = await getProjectBuildMiddleware(idProjectBuildParams)
      if (dataRes?.id) {
        setProjectBuild(dataRes)
        isLoadingSkeletonBuild.setValue(false)
        return dataRes
      }
      return emptyProjectBuildDetail
    }
  }
  const onUpdateDataBuild = (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => {
    setProjectBuild({ ...projectBuild, ...updatedData })
    updatedAtBuild.setValue(new Date().toISOString())
  }
  if (isLoadingSkeletonBuild.value) {
    return <SkeletonBuildDetail isMobile={isMobile} />
  }
  if (projectBuild.id) {
    return (
      <div className={`relative w-full`}>
        <div
          className="absolute left-[-1px] bottom-[50%] cursor-pointer hidden md:block"
          style={{
            zIndex: 10,
          }}
          onClick={() => {
            collapse.setValue(!collapse.value)
          }}
        >
          {collapse.value ? (
            <CollapseIcon className="collapse-icon" />
          ) : (
            <ExpandIcon className="collapse-icon" />
          )}
        </div>
        {projectBuild.status === STATUS_BUILD.DRAFT ? (
          <BuildDraft
            onUpdateDataEditNameBuild={onUpdateDataBuild}
            projectBuild={projectBuild}
            showBuildDetailLayout={showBuildDetailLayout}
            isArchived={isArchived}
            updatedAtBuild={updatedAtBuild.value}
            updateProjectBuild={getProjectBuild}
          />
        ) : (
          <BuildDetail
            onUpdateDataEditNameBuild={onUpdateDataBuild}
            projectBuild={projectBuild}
            showBuildDetailLayout={showBuildDetailLayout}
            isArchived={isArchived}
            updatedAtBuild={updatedAtBuild.value}
            updateProjectBuild={getProjectBuild}
            isLoadingSkeletonBuild={isLoadingSkeletonBuild.value}
          />
        )}
      </div>
    )
  }
  return (
    <div className="flex flex-1 flex-col h-full overflow-hidden">
      <div
        className="flex md:hidden p-6"
        onClick={() => {
          showBuildDetailLayout.setValue(false)
        }}
      >
        <ArrowLeftIcon />
      </div>
      <div className="bg-white flex flex-1 items-center justify-center">
        <div className="m-6">
          <img src={iconNoData} alt="icon" />
        </div>
      </div>
    </div>
  )
}
export default BuildDetailColumn
