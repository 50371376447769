import { REACT_APP_API_URL_IMAGE, API_URL } from "config/environments"
import isMobilePhone from "validator/lib/isMobilePhone"
import isEmail from "validator/lib/isEmail"
import { toast } from "react-toastify"
import { STATUS_INPUT } from "components/Input/types"
import { isValid, parseISO } from "date-fns"
import moment from "moment"
import { instanceExternalUrl } from "services/axios"
import { ID_LAYERS, ID_INNER_COPPER } from "constants/index"
import {
  arrDoubleInputUnits,
  BuildHistoryDetail,
  PartsSpecificationDetail,
  PCBSpecificationSelection,
  TableColumnBOMDetail,
  TYPE_PARTS_SPECIFICATION,
} from "pages/project-component-detail/types"
import { ProjectComponentDetail } from "pages/project-component/types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { STATUS_BUILD } from "components/Status/types"
import { SelectedDefaultProp } from "components/Select/types"
import draftToHtmlPuri from "draftjs-to-html"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { pushTo } from "./history"
import { PATH } from "constants/path"
import { FormDate, VERSION_APPROVED_STATUS } from "types"
import { STATUS_TYPE_FILE_BUILD } from "pages/project-component-detail/build-history.constant"
import { convertToRaw, EditorState } from "draft-js"
import {
  isEqual,
  uniq,
  lowerCase,
  endsWith,
  isFunction,
  head,
  isEmpty,
  forEach,
} from "lodash"
import { configureStore } from "stores/configureStore"
import { startLoading, closeLoading } from "reducers/loading"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { encryptionHelper } from "./encryption"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import JSZip from "jszip"
import Axios, { AxiosResponse } from "axios"
import {
  getFileHashKeyMiddleware,
  updateFailedFileMiddleware,
} from "pages/projects/services/api"
import { getStoreData, Stores } from "services/indexedDB"
import forge from "node-forge"
import Joi from "joi"
import JoiPhoneNumber from "joi-phone-number"

// Extend Joi with the phone number extension
const JoiPhone = Joi.extend(JoiPhoneNumber)

/* eslint-disable no-useless-escape */

export const getFieldValue = (
  field:
    | {
        raw?: string
        snippet?: string
      }
    | string
): string => {
  return typeof field === "string" ? field : field?.snippet || field?.raw || ""
}

export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl"
export const getScreenSize = (width: number) => {
  switch (true) {
    case width < 600:
      return "xs"
    case width < 1024:
      return "sm"
    case width < 1440:
      return "md"
    case width < 1920:
      return "lg"
    default:
      return "xl"
  }
}

export const getTopicColDisplayNumber = (width: number) => {
  switch (true) {
    case width < 800:
      return 2
    case width < 1024:
      return 4
    case width < 1200:
      return 5
    default:
      return 6
  }
}

export const getScreenSpacing = (width: number) => {
  switch (true) {
    case width < 600:
      return 2
    case width < 1444:
      return 3
    default:
      return 4
  }
}

export const searchParams = (sent?: string) => {
  const url = new URLSearchParams(window.location.search)
  if (sent && url.has(sent)) {
    return url.get(sent)
  } else {
    return null
  }
}

export const isValidEmail = (email: string): boolean => {
  return isEmail(email)
}

export const isValidPhone = (phone: string): boolean => {
  if (!phone) {
    return false
  }
  const phoneNumber = JoiPhone.string()
    .phoneNumber({
      strict: true,
    })
    .required()
  const validation = phoneNumber.validate(`+${phone}`)
  if (validation.error) {
    return false
  }
  return true
}

export const getFirstLetterInName = (name: string, justOne = false) => {
  if (!name) {
    return ""
  }
  const splitedName = name.split(" ")
  if (justOne || splitedName.length === 1) {
    return splitedName[0]?.charAt(0)
  }
  return (
    splitedName[0]?.charAt(0) + splitedName[splitedName.length - 1]?.charAt(0)
  )
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export function getBufferFromBase64(base64String: string) {
  const binaryString = atob(base64String)
  const byteArray = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i)
  }
  return byteArray.buffer
}

export function showImageUrl(url: string, apiURl?: string) {
  try {
    const urlInfo = new URL(url)
    return urlInfo.href
  } catch {
    return `${apiURl ?? REACT_APP_API_URL_IMAGE}${url}`
  }
}

export function convertUrlBase64(url: string) {
  return url.replace(/^(.+\;base64,)/, "")
}

export function getMessengerError(
  messenger: string,
  type: "error" | "success"
) {
  toast[type](messenger)
}

export function validationPassword(password: string) {
  return REGEX_PASSWORD.test(password)
}

export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#^()+=~`{}|/:;'"<>[,.-])[A-Za-z\d@$!%*?&_#^()+=~`{}|/:;'"<>[,.-]{8,}$/

export function changeValueSlidePassword(password: string) {
  if (!password) {
    return 0
  }
  let newSlide = 0
  if (password.length < 8) {
    newSlide = 1
  } else if (password.length < 10 && REGEX_PASSWORD.test(password)) {
    newSlide = 2
  } else if (password.length >= 10 && REGEX_PASSWORD.test(password)) {
    newSlide = 3
  } else {
    newSlide = 1
  }
  return newSlide
}

export function changeLabelStatusEmail(email: string) {
  const newStatus = {
    status: STATUS_INPUT.DEFAULT,
    label: "",
  }
  if (email) {
    newStatus.status = isValidEmail(email)
      ? STATUS_INPUT.VALID
      : STATUS_INPUT.ERROR
    newStatus.label = isValidEmail(email)
      ? "Email is valid!"
      : "Email incorrect format!"
  }
  return newStatus
}

export function isValidDate(date) {
  if (
    !date ||
    String(new Date(date)) === "Invalid Date" ||
    !isValid(parseISO(new Date(date).toISOString()))
  ) {
    return false
  }
  return true
}

const checkTypeDate = (date: FormDate) => {
  let typedDate = date
  if (typeof date === "string" && isValidDate(date.replace(" ", "T"))) {
    typedDate = date.replace(" ", "T")
  }
  return typedDate
}

export const formatDate = (date: FormDate, typeFormat?: string) => {
  if (!date) {
    return ""
  }
  const timezone = localStorage.getItem("timezone") || "+0000"
  return moment(new Date(checkTypeDate(date)))
    .clone()
    .utcOffset(timezone)
    .format(typeFormat ? typeFormat : "DD MMM yyyy, HH:mm:ss A")
}

export const formatDurationInHuman = (date: string | number | Date) => {
  if (date === "0000-00-00 00:00:00") {
    return "N/A"
  }
  const timezone = localStorage.getItem("timezone") || "+0000"
  return moment(new Date(checkTypeDate(date)))
    .clone()
    .utcOffset(timezone)
    .startOf("seconds")
    .fromNow()
}
export const formatDateForConversationMessage = (
  date: string | number | Date
) => {
  const timezone = localStorage.getItem("timezone") || "+0000"

  const currentTime = moment().clone().utcOffset(timezone)
  const inputTime = moment(date).clone().utcOffset(timezone)
  const daysDifference = currentTime.diff(inputTime, "days")
  if (daysDifference > 7) return formatDate(date, "DD MMM yyyy")
  return formatDurationInHuman(date)
}

export const compareDate = (time1: string, time2: string) => {
  const moment1 = moment(time1)
  const moment2 = moment(time2)
  return moment2.diff(moment1, "seconds")
}

export const fieldFormDataSpecification = (start: string, end: number) => {
  return start + "_" + end
}

export const getPrivateAssetURI = (
  assetPath: string,
  extraParams?: any,
  isInvitee = false
) => {
  let fileURL = `${API_URL}/api/assets/${encodeURIComponent(assetPath)}`
  if (extraParams?.conversation_id) {
    fileURL = `${API_URL}/api/invitee/assets/${encodeURIComponent(assetPath)}`
  }
  if (extraParams) {
    const queryString = new URLSearchParams(extraParams).toString()
    if (queryString) {
      fileURL += `?${queryString}`
    }
  }
  return fileURL
}
export const getProjectBackupAssetURI = (assetPath: string) => {
  let fileURL = `${API_URL}/api/assets/project-backup/${encodeURIComponent(
    assetPath
  )}`

  return fileURL
}

function isBrowserSupportView(filePath: string) {
  // Check if the string is a file path
  if (!filePath.includes("/")) {
    return false
  }
  // Get the file extension
  const extension = filePath.split(".")?.pop()?.toLowerCase() ?? "N/A"
  // Check if the extension is an image or pdf
  return [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "pdf",
    "webp",
    "json",
    "xml",
    "txt",
    "html",
    "css",
    "txt",
    "js",
  ].includes(extension)
}

export const openFileOnNewTab = async (
  assetPath: string,
  extraParams: { [key: string]: any }
) => {
  let fileURL = getPrivateAssetURI(assetPath, extraParams)
  configureStore.dispatch(startLoading())
  const isBrowserView = isBrowserSupportView(assetPath)
  if (!isBrowserView) {
    return downloadFile(fileURL, undefined, true)
  }
  const url = (await downloadFile(fileURL, undefined, true, true)) as string
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("target", "_blank")
  document.body.appendChild(link)
  link.click()
  link?.parentNode?.removeChild(link)
  closeLoading()
}

export const downloadPrivateAsset = (
  assetPath: string,
  extraParams?: { [key: string]: any },
  fileName?: string,
  blobOnly?: boolean,
  decryptedShareKey?: string
) => {
  const fileURL = getPrivateAssetURI(assetPath, extraParams)
  //
  return downloadFile(
    fileURL,
    fileName,
    true,
    blobOnly,
    undefined,
    assetPath,
    extraParams,
    decryptedShareKey
  )
}
export const downloadPrivateAssetForInvitee = (
  assetPath: string,
  extraParams?: { [key: string]: any },
  fileName?: string,
  blobOnly?: boolean,
  decryptedShareKey?: string
) => {
  //
  const fileURL = getPrivateAssetURI(assetPath, extraParams, true)
  return downloadFile(
    fileURL,
    fileName,
    true,
    blobOnly,
    undefined,
    assetPath,
    undefined,
    decryptedShareKey
  )
}
export const getParameterByName = (name: string, url: string) => {
  name = name.replace(/[\[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return undefined
  if (!results[2]) return undefined
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}
export const downloadEncrypted = async (apiURL: string, buildId = "") => {
  configureStore.dispatch(startLoading())
  const response: AxiosResponse = await Axios.get(apiURL)

  const zip = new JSZip()
  let url: any
  const conversationId = getParameterByName("conversation_id", apiURL)
  const privateKey: any = await getPrivateKeyByUserId()

  await Promise.all(
    response.data.data
      .filter((file) => !isEmpty(file.file))
      .map(async (file) => {
        const isAdditionalInfo =
          file.keyToFolder === "additional_info" && buildId
        if (!file.buffer && file.buffer !== "") {
          let decryptedShareKey: string | undefined = undefined
          if (file.shareKey) {
            try {
              decryptedShareKey = privateKey.decrypt(file.shareKey, "RSA-OAEP")
            } catch (error) {}
          }
          const params = {}
          if (conversationId) {
            params["conversation_id"] = conversationId
          }
          if (isAdditionalInfo) {
            params["project_build_id"] = buildId
          }
          let blob: any = await getFileContentFromEnrypted(
            getPrivateAssetURI(file.file, params),
            "",
            true,
            undefined,
            undefined,
            decryptedShareKey,
            isAdditionalInfo ? buildId : undefined
          )
          if (typeof blob === "string") {
            blob = b64toBlob(blob)
          }
          const fileName = file.file.split("/").slice(-1)[0]
          zip.file(
            `${file.keyToFolder}/${removeTimestampInFileName(fileName)}`,
            blob
          )
        } else if (file.buffer) {
          let fileContent = file.buffer
          if (file.messages) {
            file.messages.forEach((message) => {
              const rawMessage = encryptionController().decryptUnknowMessage(
                message.message,
                file.conversationId,
                file.projectId,
                isAdditionalInfo ? buildId : undefined
              )

              fileContent = fileContent.replace(message.uniqueKey, rawMessage)
            })
          }
          if (
            (file.file.split("/").slice(-1)[0] as string).search("txt") !==
              -1 ||
            (file.file.split("/").slice(-1)[0] as string).search("csv") !== -1
          ) {
            const blob = new Blob([fileContent])
            const fileNameBuffer = file.file.split("/").slice(-1)[0]

            zip.file(
              `${file.keyToFolder}/${removeTimestampInFileName(
                fileNameBuffer
              )}`,
              blob
            )
          }
        }
      })
  )
  zip.generateAsync({ type: "blob" }).then(function (content) {
    // see FileSaver.js
    url = window.URL.createObjectURL(
      new Blob([content], {
        type: "application/zip",
      })
    )
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", response.data.name)
    document.body.appendChild(link)
    link.click()
    link?.parentNode?.removeChild(link)
    closeLoading()
  })
}
export const downloadFile = async (
  fileURL: string,
  fileName?: string,
  isAuth?: boolean,
  blobOnly?: boolean,
  idConversationParam?: string,
  fileKey?: string,
  extraParams?: { [key: string]: any },
  decryptedShareKey?: string
) => {
  const query = new URLSearchParams(window.location.search)
  const token =
    localStorage.getItem("access_token") || query.get("access_token") || ""
  const tokenType = localStorage.getItem("token_type") || "Bearer"
  configureStore.dispatch(startLoading())
  console.log("-------START DOWNLOAD FILE-----------")
  return instanceExternalUrl
    .get(fileURL, {
      responseType: "blob",
      headers: {
        Authorization: isAuth ? `${tokenType} ${token}` : "",
      },
      params: { conversation_id: idConversationParam },
    })
    .then(async (response: any) => {
      let downloadFileName = fileName
      const contentDisposition = response.headers["content-disposition"]
      if (contentDisposition) {
        const fileNames = contentDisposition.match(/filename=".*"/g)
        if (!isEmpty(fileNames)) {
          const headerFileName = head(fileNames) as string
          downloadFileName = headerFileName.substring(
            10,
            headerFileName.length - 1
          )
        }
      }
      if (!downloadFileName) {
        throw new Error("Something went wrong!")
      }
      let data = response.data
      let dataToDownload = data
      let fileText = ""
      /***
       * Check file content
       */
      const isEncrypted: "0" | "1" = response.headers["encrypted"]
      const encryptionOrigin:
        | "project"
        | "conversation"
        | "build_additional_info" = response.headers["encryption-origin"]

      if (isEncrypted === "1") {
        const temp = await response.data.text()
        data = decryptedShareKey
          ? await encryptionController().decrypt(temp, {
              dataType: "file",
              type: "component-shared",
              encryptionKey: decryptedShareKey,
            })
          : await encryptionController().decrypt(temp, {
              dataType: "file",
              type: encryptionOrigin,
              relationId: extraParams?.["project_build_id"],
            })
        dataToDownload = encryptionHelper.toUint8Array(data)
        const newBlob = new Blob([dataToDownload])
        fileText = await newBlob.text()
      }
      if (fileKey) {
        const fileHashKeyFromServer = await getFileHashKeyMiddleware(fileKey)
        let isError = false
        if (
          fileHashKeyFromServer.hash_key &&
          fileHashKeyFromServer.public_key
        ) {
          const isVerifiedSignature = encryptionHelper.checkVerifiedSignature(
            fileHashKeyFromServer.public_key,
            fileHashKeyFromServer.hash_key,
            fileText
          )
          if (isVerifiedSignature) {
            console.log(
              "Signature",
              fileHashKeyFromServer.hash_key,
              `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
              "//SUCCESS//"
            )
          } else {
            console.log(
              "Signature",
              fileHashKeyFromServer.hash_key,
              `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
              "//FAILED//"
            )
            isError = true
          }
        } else {
          console.log(
            "Signature",
            fileHashKeyFromServer.hash_key,
            `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            "//FAILED//"
          )
          isError = true
        }
        console.log("-------END DOWNLOAD FILE-----------")

        if (isError) {
          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.INCORRECT_FILE_CONTENT}
              type="error"
            />
          )
          return
        }
      }

      const url = window.URL.createObjectURL(
        new Blob([dataToDownload], {
          type: response.headers["content-type"],
        })
      )
      if (blobOnly) {
        return url
      }

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", removeTimestampInFileName(downloadFileName))
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      closeLoading()
    })
    .catch((err) => {
      console.log(err)
      closeLoading()
      if (blobOnly) {
        return false
      }
      if (
        err.response?.data?.type === "application/json" &&
        isFunction(err.response?.data?.text)
      ) {
        err.response.data.text().then((responseText: string) => {
          const errorJSON = JSON.parse(responseText)
          toast(
            <LabelNotificationPage
              messenger={
                errorJSON.message || MESSENGER_NOTIFICATION.GENERAL.UKNOWN_ERROR
              }
              type="error"
            />
          )
        })
      } else {
        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.GENERAL.UKNOWN_ERROR}
            type="error"
          />
        )
      }
    })
}

// Removes the last numeric part before the extension
//   _ → Matches an underscore before the number.
// (\d+) → Captures the last sequence of digits (the timestamp).
// (?=\.[^.]+$) → Ensures the number is immediately before the last dot (.) and extension (e.g., .png, .docs).
export const removeTimestampInFileName = (fileName: string) => {
  return fileName.replace(/_(\d+)(?=\.[^.]+$)/, "")
}

export const getContentFromConversationNoteFile = (
  fileURL: string,
  isAuth?: boolean,
  resultType?: "base64" | "blob"
) =>
  new Promise(async (resolve) => {
    const query = new URLSearchParams(window.location.search)
    const token =
      localStorage.getItem("access_token") || query.get("access_token") || ""
    const tokenType = localStorage.getItem("token_type") || "Bearer"
    configureStore.dispatch(startLoading())
    return instanceExternalUrl
      .get(fileURL, {
        responseType: "blob",
        headers: {
          Authorization: isAuth ? `${tokenType} ${token}` : "",
        },
      })
      .then(async (response: any) => {
        let data = response.data
        let dataToDownload = data
        const temp = await response.data.text()
        const encryptionKey =
          localStorage.getItem(EncryptionKeys.userVaultKey) || ""
        if (encryptionKey) {
          data = await encryptionController().decrypt(temp, {
            dataType: "file",
            type: "conversation_note",
            encryptionKey,
          })
          dataToDownload = encryptionHelper.toUint8Array(data)
          const newBlob = new Blob([dataToDownload], {
            type: response.headers["content-type"],
          })
          return resolve(
            resultType === "blob" ? newBlob : await blobToBase64(newBlob)
          )
        }
        return resolve(resultType === "blob" ? data : await blobToBase64(data))
      })
      .catch((error) => {
        console.log(error)
        resolve("")
      })
  })
export const downloadConversationNoteFile = (
  fileURL: string,
  isAuth?: boolean
) => {
  const query = new URLSearchParams(window.location.search)
  const token =
    localStorage.getItem("access_token") || query.get("access_token") || ""
  const tokenType = localStorage.getItem("token_type") || "Bearer"
  configureStore.dispatch(startLoading())
  return instanceExternalUrl
    .get(fileURL, {
      responseType: "blob",
      headers: {
        Authorization: isAuth ? `${tokenType} ${token}` : "",
      },
    })
    .then(async (response: any) => {
      let downloadFileName = ""
      const contentDisposition = response.headers["content-disposition"]
      if (contentDisposition) {
        const fileNames = contentDisposition.match(/filename=".*"/g)
        if (!isEmpty(fileNames)) {
          const headerFileName = head(fileNames) as string
          downloadFileName = headerFileName.substring(
            10,
            headerFileName.length - 1
          )
        }
      }
      let data = response.data
      let dataToDownload = data
      const temp = await response.data.text()
      const encryptionKey =
        localStorage.getItem(EncryptionKeys.userVaultKey) || ""
      if (encryptionKey) {
        data = await encryptionController().decrypt(temp, {
          dataType: "file",
          type: "conversation_note",
          encryptionKey,
        })
        dataToDownload = encryptionHelper.toUint8Array(data)
        const newBlob = new Blob([dataToDownload], {
          type: response.headers["content-type"],
        })
        dataToDownload = newBlob
      }
      const url = window.URL.createObjectURL(
        new Blob([dataToDownload], {
          type: response.headers["content-type"],
        })
      )

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", removeTimestampInFileName(downloadFileName))
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      closeLoading()
    })
    .catch((error) => {
      console.log(error)
    })
}
export const getFileContentFromEnrypted = (
  fileURL: string,
  fileName?: string,
  isAuth?: boolean,
  idConversationParam?: string,
  options?: {
    resutlType: "base64" | "blob"
  },
  decryptedShareKey?: string,
  project_build_id?: string
) =>
  new Promise(async (resolve) => {
    const query = new URLSearchParams(window.location.search)
    const token =
      localStorage.getItem("access_token") || query.get("access_token") || ""
    const tokenType = localStorage.getItem("token_type") || "Bearer"
    configureStore.dispatch(startLoading())
    return instanceExternalUrl
      .get(fileURL, {
        responseType: "blob",
        headers: {
          Authorization: isAuth ? `${tokenType} ${token}` : "",
        },
        params: { conversation_id: idConversationParam },
      })
      .then(async (response: any) => {
        let downloadFileName = fileName
        const contentDisposition = response.headers["content-disposition"]
        if (contentDisposition) {
          const fileNames = contentDisposition.match(/filename=".*"/g)
          if (!isEmpty(fileNames)) {
            const headerFileName = head(fileNames) as string
            downloadFileName = headerFileName.substring(
              10,
              headerFileName.length - 1
            )
          }
        }
        if (!downloadFileName) {
          throw new Error("Something went wrong!")
        }
        let data = response.data

        let dataToDownload = data
        /***
         * Check file content
         */
        const isEncrypted: "0" | "1" = response.headers["encrypted"]
        const encryptionOrigin:
          | "project"
          | "conversation"
          | "build_additional_info" = response.headers["encryption-origin"]
        let relationId: string | undefined = undefined
        if (encryptionOrigin === "conversation" && idConversationParam) {
          relationId = idConversationParam
        }
        if (encryptionOrigin === "build_additional_info" && project_build_id) {
          relationId = project_build_id
        }

        if (isEncrypted === "1") {
          const temp = await response.data.text()
          data = await encryptionController().decrypt(
            temp,
            decryptedShareKey
              ? {
                  type: "component-shared",
                  dataType: "file",
                  encryptionKey: decryptedShareKey,
                }
              : {
                  type: encryptionOrigin,
                  dataType: "file",
                  relationId: relationId,
                }
          )

          dataToDownload = encryptionHelper.toUint8Array(data)
        }
        if (options?.resutlType === "base64") {
          const blob = new Blob([dataToDownload], {
            type: response.headers["content-type"],
          })
          return resolve(blobToBase64(blob))
        } else if (options?.resutlType === "blob") {
          const blob = new Blob([dataToDownload], {
            type: response.headers["content-type"],
          })
          return resolve(URL.createObjectURL(blob))
        }
        resolve(dataToDownload)
      })
      .catch((err) => {
        resolve(new Blob([]))
        console.log("err: ", err)
      })
  })
export const getFileContentFromEnryptedWithSpecifiedKey = (
  projectId: string,
  fileURL: string,
  conversationId?: string,
  project_build_id?: string,
  isAuth?: boolean,
  idConversationParam?: string
) =>
  new Promise(async (resolve) => {
    const query = new URLSearchParams(window.location.search)
    const token =
      localStorage.getItem("access_token") || query.get("access_token") || ""
    const tokenType = localStorage.getItem("token_type") || "Bearer"
    configureStore.dispatch(startLoading())
    return instanceExternalUrl
      .get(fileURL, {
        responseType: "blob",
        headers: {
          Authorization: isAuth ? `${tokenType} ${token}` : "",
        },
        params: { conversation_id: idConversationParam },
      })
      .then(async (response: any) => {
        let data = response.data

        let dataToDownload = data
        /***
         * Check file content
         */
        const isEncrypted: "0" | "1" = response.headers["encrypted"]
        const encryptionOrigin:
          | "project"
          | "conversation"
          | "build_additional_info" = response.headers["encryption-origin"]
        let relationId = projectId
        if (encryptionOrigin === "conversation" && conversationId) {
          relationId = conversationId
        }
        if (encryptionOrigin === "build_additional_info" && project_build_id) {
          relationId = project_build_id
        }
        if (isEncrypted === "1") {
          const temp = await response.data.text()
          data = await encryptionController().decrypt(temp, {
            type: encryptionOrigin,
            dataType: "file",
            relationId: relationId,
          })
          dataToDownload = encryptionHelper.toUint8Array(data)
        }

        return resolve(dataToDownload)
      })
      .catch((err) => {
        const parsedError = JSON.parse(JSON.stringify(err))
        if (parsedError.status === 403) {
          return resolve("failed by 403")
        }
        console.log("err: ", err)
        resolve(new Blob([]))
      })
  })
export const getFileContent = (
  fileURL: string,
  isAuth?: boolean
): Promise<{
  content: Blob
  content_type: string
}> =>
  new Promise(async (resolve) => {
    const query = new URLSearchParams(window.location.search)
    const token =
      localStorage.getItem("access_token") || query.get("access_token") || ""
    const tokenType = localStorage.getItem("token_type") || "Bearer"
    configureStore.dispatch(startLoading())
    return instanceExternalUrl
      .get(fileURL, {
        responseType: "blob",
        headers: {
          Authorization: isAuth ? `${tokenType} ${token}` : "",
        },
      })
      .then(async (response: any) => {
        resolve({
          content: response.data,
          content_type: response.headers["content-type"],
        })
      })
      .catch((err) => {
        resolve({
          content: new Blob([]),
          content_type: "",
        })
        console.log("err: ", "File not found", err)
      })
  })

export const isTypeFile = (fileType: string, type?: string) => {
  const regFile = RegExp(`^.*.(${type ?? "rar|zip|7z"})$`)
  return regFile.test(fileType)
}

export const roundNumber = (num: number, decimal: number) => {
  return Number(num.toFixed(decimal))
}

export const restUsersNumber = (usersTotal: number, displayNumber) => {
  if (usersTotal - displayNumber >= 99) {
    return 99
  }
  return usersTotal - displayNumber
}

export const checkEmptyDataEditor = (dataEditor: string) => {
  if (dataEditor && JSON.parse(dataEditor)?.blocks[0]?.text) {
    return true
  }
  return false
}

export const convertTagsToSuggrestions = (tags) => {
  return tags.map((tag, index) => ({ id: String(index), name: tag }))
}

export const filterSpecifications = (
  specifications: PCBSpecificationSelection[],
  formData: any
): {
  newSpecification: PCBSpecificationSelection[]
  innerCopper: {
    idInForm: string
    defaultValue: string
  }
} => {
  const layers = specifications[0]?.parts?.find(
    (spec) => spec?.id === ID_LAYERS
  )
  const innerCopper = specifications[0]?.parts?.find(
    (spec) => spec?.id === ID_INNER_COPPER
  )
  if (innerCopper && layers && formData) {
    const idLayers = fieldFormDataSpecification(layers.id, layers.sequence)
    const index = layers.answers.findIndex(
      (answer) => answer.name === formData[idLayers]
    )
    if (index === 0 || index === 1) {
      const currentSpecifications = [...specifications]
      const newParts = currentSpecifications[0].parts.filter(
        (part) => part.id !== ID_INNER_COPPER
      )
      currentSpecifications[0] = {
        ...currentSpecifications[0],
        parts: newParts,
      }
      return {
        newSpecification: currentSpecifications,
        innerCopper: {
          idInForm: fieldFormDataSpecification(
            ID_INNER_COPPER,
            innerCopper.sequence
          ),
          defaultValue: "",
        },
      }
    }
  }
  return {
    newSpecification: specifications,
    innerCopper: {
      idInForm: ID_INNER_COPPER + "_" + innerCopper?.sequence,
      defaultValue:
        innerCopper?.value?.split("_")?.[0] ||
        innerCopper?.answers?.[0].name ||
        "",
    },
  }
}

export const componentsSelected = (array: ProjectComponentDetail[]) => {
  return array.filter((cpn) => cpn.checkbox === true).length
}

export const showTypeFile = (fileName: string) => {
  const arr = fileName.split(".")
  if (arr.length > 1) {
    return arr[arr.length - 1]
  }
  return ""
}

export const addAlphaColor = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

export const checkPermissionPage = (item: {
  project?: boolean
  notificationProject?: boolean
  viewShare?: boolean
  notificationViewShare?: boolean
  component?: boolean
  notificationComponent?: boolean
  noUpdateFilePCB?: boolean
  notificationNoUpdateFilePCB?: boolean
  build?: boolean
  notificationBuild?: boolean
}) => {
  const {
    project = false,
    notificationProject = false,
    component = false,
    notificationComponent = false,
    noUpdateFilePCB = false,
    notificationNoUpdateFilePCB = false,
    build = false,
    notificationBuild = false,
    viewShare = false,
    notificationViewShare = false,
  } = item
  if (project) {
    if (notificationProject) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.PERMISSION_PROJECT}
          type="warning"
        />
      )
    }
    return project
  }
  if (component) {
    if (notificationComponent) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.PERMISSION_COMPONENT}
          type="warning"
        />
      )
    }
    return component
  }
  if (noUpdateFilePCB) {
    if (notificationNoUpdateFilePCB) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.PERMISSION_NO_UPDATE_FILE_PCB}
          type="warning"
        />
      )
    }
    return noUpdateFilePCB
  }
  if (build) {
    if (notificationBuild) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.PERMISSION_BUILD}
          type="warning"
        />
      )
    }
    return build
  }
  if (viewShare) {
    if (notificationViewShare) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.VIEW_ONLY}
          type="warning"
        />
      )
    }
    return viewShare
  }
  return false
}

export const newStepStatusBuild = (
  oldStatus: string,
  listStatus: SelectedDefaultProp[]
) => {
  let newStatus = ""
  let message = ""

  if (oldStatus === STATUS_BUILD.DRAFT) {
    newStatus = STATUS_BUILD.COMMITTED
    message = "Committed build"
  }
  if (oldStatus === STATUS_BUILD.COMMITTED) {
    newStatus = STATUS_BUILD.NEXT_RFQ
    message = "RFQ build"
  }
  if (
    oldStatus === STATUS_BUILD.NEXT_RFQ ||
    oldStatus === STATUS_BUILD.TEST_SETUP ||
    oldStatus === STATUS_BUILD.RFQ ||
    oldStatus === STATUS_BUILD.DATA_EXCHANGE
  ) {
    newStatus = STATUS_BUILD.NEXT_PRODUCTION
    message = "In production"
  }
  const statusDetail = listStatus.find((el) => {
    const label = lowerCase(el.label)
    if (message === "RFQ build") {
      return label === "test setup"
    }
    if (message === "In production") {
      return label === "in production"
    }
    return label === lowerCase(newStatus)
  })

  if (statusDetail) {
    return {
      ...statusDetail,
      message,
    }
  }
  return {
    label: "",
    value: "",
    message,
  }
}
export const parseDraftToHtml = (description: string) => {
  return draftToHtmlPuri(JSON.parse(description))
    .replaceAll("<p></p>", '<p style="display: inline-block"></p>')
    .replaceAll(
      `<ol>`,
      `<ol style="list-style-type: decimal; padding-left: 15px;">`
    )
    .replaceAll(
      `<ul>`,
      `<ul style="list-style-type: disc; padding-left: 15px;">`
    )
    .replaceAll("background-color: rgb(255,255,255);", "")
}

export const onRedirectInviteeUser = (
  idUserInfo: string,
  idInviteeUser: string,
  typeComponent: string,
  oldIdProjectComponent: string,
  oldIdProjectBuildComponent: string,
  oldProjectComponentHistory: string,
  oldIdConversation: string
) => {
  if (idUserInfo === idInviteeUser) {
    switch (typeComponent) {
      case TYPE_PROJECT_COMPONENT.PCB:
        pushTo(PATH.inviteesPCB, {
          idProjectComponent: oldIdProjectComponent,
          idProjectBuildComponent: oldIdProjectBuildComponent,
          idProjectComponentHistory: oldProjectComponentHistory,
          idConversation: oldIdConversation,
        })
        break
      case TYPE_PROJECT_COMPONENT.BOM:
        pushTo(PATH.inviteesBOM, {
          idProjectComponent: oldIdProjectComponent,
          idProjectBuildComponent: oldIdProjectBuildComponent,
          idProjectComponentHistory: oldProjectComponentHistory,
          idConversation: oldIdConversation,
        })
        break
      default:
        pushTo(PATH.inviteesOther, {
          type: typeComponent,
          idProjectComponent: oldIdProjectComponent,
          idProjectBuildComponent: oldIdProjectBuildComponent,
          idProjectComponentHistory: oldProjectComponentHistory,
          idConversation: oldIdConversation,
        })
        break
    }
    return
  }
  switch (typeComponent) {
    case TYPE_PROJECT_COMPONENT.PCB:
      return pushTo(PATH.projectComponentPCB, {
        idProjectComponent: oldIdProjectComponent,
        titlePage: "build-component",
        idProjectBuildComponent: oldIdProjectBuildComponent,
      })
    case TYPE_PROJECT_COMPONENT.BOM:
      return pushTo(PATH.projectComponentBOM, {
        idProjectComponent: oldIdProjectComponent,
        titlePage: "build-component",
        idProjectBuildComponent: oldIdProjectBuildComponent,
      })
    default:
      return pushTo(PATH.projectComponentOther, {
        idProjectComponent: oldIdProjectComponent,
        type: typeComponent,
        titlePage: "build-component",
        idProjectBuildComponent: oldIdProjectBuildComponent,
      })
  }
}
export const handleCheckUpdateFileComponent = (
  oldIdComponentHistory: string,
  nameFile: string,
  typeMessage: string,
  typeCheck?: string,
  isNotAllow?: boolean
) => {
  if (!oldIdComponentHistory) {
    return true
  }
  const result = isTypeFile(nameFile.toLowerCase(), typeCheck)
  if ((isNotAllow && result) || (!isNotAllow && !result)) {
    toast(
      <LabelNotificationPage
        messenger={`${
          isNotAllow
            ? MESSENGER_NOTIFICATION.FILE_NOT_ALLOW
            : MESSENGER_NOTIFICATION.UPDATE_FILE_PCB_ERROR
        } ${typeMessage}`}
        type="warning"
      />
    )
    return true
  }

  return false
}

export const messageAndURLRemoveFileComponent = (
  oldType: STATUS_TYPE_FILE_BUILD
) => {
  let urlDelete = "delete-gerber-file"
  let messageSuccess = MESSENGER_NOTIFICATION.DELETE_GERBER_FILE_SUCCESS
  let messageError = MESSENGER_NOTIFICATION.DELETE_GERBER_FILE_ERROR
  switch (oldType) {
    case STATUS_TYPE_FILE_BUILD.BOM:
      urlDelete = "delete-bom-file"
      messageSuccess = MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS
      messageError = MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR
      break
    case STATUS_TYPE_FILE_BUILD.STACKUP:
      urlDelete = "delete-stackup-file"
      messageSuccess = MESSENGER_NOTIFICATION.DELETE_STACKUP_FILE_SUCCESS
      messageError = MESSENGER_NOTIFICATION.DELETE_STACKUP_FILE_ERROR

      break
    case STATUS_TYPE_FILE_BUILD.ASSEMBLY:
      urlDelete = "delete-assembly-file"
      messageSuccess = MESSENGER_NOTIFICATION.DELETE_ASSEMBLY_FILE_SUCCESS
      messageError = MESSENGER_NOTIFICATION.DELETE_ASSEMBLY_FILE_ERROR
      break
  }
  return {
    urlDelete,
    messageSuccess,
    messageError,
  }
}

export const converSpecifications = (
  oldSpecifications: PCBSpecificationSelection[]
) => {
  const newFormData = {}
  const newFormDataEditor = {}
  const newFormDataEditorFile = {}
  const listValueIdRequiced = {}
  oldSpecifications.forEach((el) => {
    if (el.parts.length) {
      el.parts.forEach((part) => {
        if (part.value) {
          if (part.required === 1) {
            converDataPartRequiredExsit(part, listValueIdRequiced, el)
          }
          converDataPartTypeExsit(part, newFormData, newFormDataEditor)
        } else {
          if (part.required === 1) {
            converDataPartRequiredNoExsit(part, listValueIdRequiced, el)
          }
          converDataPartTypeNoExsit(
            part,
            newFormData,
            newFormDataEditor,
            newFormDataEditorFile
          )
        }
      })
    }
  })
  return {
    newFormData,
    newFormDataEditor,
    newFormDataEditorFile,
    listValueIdRequiced,
  }
}

const converDataPartRequiredExsit = (
  part: PartsSpecificationDetail,
  listValueIdRequiced: any,
  oldEl: PCBSpecificationSelection
) => {
  if (
    part.type === TYPE_PARTS_SPECIFICATION.DOUBLE_INPUT &&
    part.answers &&
    part.answers.length
  ) {
    part.answers.forEach((_answersDoubleInput, index) => {
      listValueIdRequiced[`${oldEl.name}: ${part.name}_${part.id}_${index}`] =
        part.id
    })
  } else {
    listValueIdRequiced[`${oldEl.name}: ${part.name}_${part.id}_0`] = part.id
  }
}

const converDataPartTypeExsit = (
  part: PartsSpecificationDetail,
  newFormData: any,
  newFormDataEditor: any
) => {
  switch (part.type) {
    case TYPE_PARTS_SPECIFICATION.SINGLE_TWO_LINES_CHECK:
    case TYPE_PARTS_SPECIFICATION.SELECT:
    case TYPE_PARTS_SPECIFICATION.SINGLE_INPUT:
    case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK_IMAGE:
    case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK_OR_INPUT:
    case TYPE_PARTS_SPECIFICATION.SINGLE_IMAGE_CHECK:
    case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK:
      const arrSplitValue = part.value.split("_")
      if (arrSplitValue.length) {
        newFormData[fieldFormDataSpecification(part.id, arrSplitValue[1])] =
          arrSplitValue[0]
      }
      break
    case TYPE_PARTS_SPECIFICATION.MULTIPLE_CHECK:
      const arrSplitMultipleValue = part.value.split(",")
      arrSplitMultipleValue.forEach((elMultiple: string) => {
        const newValueMultiple = elMultiple.split("_")
        if (newValueMultiple.length) {
          newFormData[
            fieldFormDataSpecification(part.id, Number(newValueMultiple[1]))
          ] = newValueMultiple[0]
        }
      })
      break
    case TYPE_PARTS_SPECIFICATION.CHECKBOX:
      const arrSplitCheckbox = part.value.split("_")
      if (arrSplitCheckbox.length) {
        newFormData[fieldFormDataSpecification(part.id, arrSplitCheckbox[1])] =
          arrSplitCheckbox[0] === "true" ? true : false
      }
      break
    case TYPE_PARTS_SPECIFICATION.DOUBLE_INPUT:
      converDataPartTypeDoubleInputExsit(part, newFormData)
      break
    case TYPE_PARTS_SPECIFICATION.RADIO_BUTTON:
      converDataPartTypeRadioButtonExsit(part, newFormData)
      break
    case TYPE_PARTS_SPECIFICATION.EDITOR:
      converDataPartTypeEditotExsit(part, newFormDataEditor)
      break
    default:
      break
  }
}

const converDataPartTypeEditotExsit = (
  part: PartsSpecificationDetail,
  newFormDataEditor: any
) => {
  const arrSplitValueEditor = part.value.split("_editor_")
  if (arrSplitValueEditor.length) {
    newFormDataEditor[part.id + "_editor_" + arrSplitValueEditor[1]] =
      arrSplitValueEditor[0]
  }
}
const converDataPartTypeRadioButtonExsit = (
  part: PartsSpecificationDetail,
  newFormData: any
) => {
  const arrSplitRadioButton = part.value.split(",")
  if (arrSplitRadioButton.length) {
    arrSplitRadioButton.forEach((radio) => {
      const newArrRadio = radio.split("_")
      if (newArrRadio.length) {
        newFormData[fieldFormDataSpecification(part.id, newArrRadio[1])] =
          newArrRadio[0] === "true" ? true : false
      }
    })
  }
}
const converDataPartTypeDoubleInputExsit = (
  part: PartsSpecificationDetail,
  newFormData: any
) => {
  const arrSplitDouble = part.value.split(",")
  if (arrSplitDouble.length) {
    arrSplitDouble.forEach((double) => {
      const newArrDouble = double.split("_")
      if (newArrDouble.length) {
        newFormData[fieldFormDataSpecification(part.id, newArrDouble[1])] =
          newArrDouble[0]
      }
    })
  }
}
const converDataPartRequiredNoExsit = (
  part: PartsSpecificationDetail,
  listValueIdRequiced: any,
  oldEl: PCBSpecificationSelection
) => {
  if (
    part.type === TYPE_PARTS_SPECIFICATION.DOUBLE_INPUT &&
    part.answers &&
    part.answers.length
  ) {
    part.answers.forEach((_answersDoubleInput, index) => {
      listValueIdRequiced[`${oldEl.name}: ${part.name}_${part.id}_${index}`] =
        ""
    })
  } else {
    listValueIdRequiced[`${oldEl.name}: ${part.name}_${part.id}_0`] = ""
  }
}

const converDataPartTypeNoExsit = (
  part: PartsSpecificationDetail,
  newFormData: any,
  newFormDataEditor: any,
  newFormDataEditorFile: any
) => {
  switch (part.type) {
    case TYPE_PARTS_SPECIFICATION.SINGLE_TWO_LINES_CHECK:
    case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK_IMAGE:
    case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK_OR_INPUT:
    case TYPE_PARTS_SPECIFICATION.SINGLE_IMAGE_CHECK:
    case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK:
      if (part.answers && part.answers.length) {
        newFormData[fieldFormDataSpecification(part.id, part.sequence)] =
          part.answers[0].name
      }
      break
    case TYPE_PARTS_SPECIFICATION.SELECT:
      if (part.answers && part.answers.length) {
        newFormData[fieldFormDataSpecification(part.id, part.sequence)] =
          part.answers[0].id
      }
      break
    case TYPE_PARTS_SPECIFICATION.MULTIPLE_CHECK:
      if (part.answers && part.answers.length) {
        part.answers.forEach((answer) => {
          newFormData[fieldFormDataSpecification(part.id, answer.sequence)] = ""
        })
      }
      break
    case TYPE_PARTS_SPECIFICATION.RADIO_BUTTON:
      converDataPartTypeRadioButtonNoExsit(part, newFormData)
      break
    case TYPE_PARTS_SPECIFICATION.SINGLE_INPUT:
    case TYPE_PARTS_SPECIFICATION.DOUBLE_INPUT:
      converDataPartTypeDoubleInputNoExsit(part, newFormData)
      break
    case TYPE_PARTS_SPECIFICATION.CHECKBOX:
      newFormData[fieldFormDataSpecification(part.id, part.sequence)] = true
      break
    case TYPE_PARTS_SPECIFICATION.EDITOR:
      converDataPartTypeEditotNoExsit(
        part,
        newFormDataEditor,
        newFormDataEditorFile
      )
      break
    default:
      if (part.parts && part.parts.length) {
        converDataPartTypeAdditionalNoExsit(part, newFormData)
      }
      break
  }
}
const converDataPartTypeAdditionalNoExsit = (
  part: PartsSpecificationDetail,
  newFormData: any
) => {
  const partSelects = part.parts.filter(
    (newSelect) => newSelect.type === "select"
  )
  const partCheckbox = part.parts.filter(
    (newCheckbox) => newCheckbox.type === "checkbox"
  )
  const partSingleInput = part.parts.filter(
    (newSingle) => newSingle.type === "single input"
  )
  partSelects.forEach((partSelect) => {
    if (partSelect.value) {
      const arrSplitPartChild = partSelect.value.split("_")
      if (arrSplitPartChild.length) {
        newFormData[
          fieldFormDataSpecification(partSelect.id, arrSplitPartChild[1])
        ] = arrSplitPartChild[0]
      }
    } else {
      newFormData[
        fieldFormDataSpecification(partSelect.id, partSelect.sequence)
      ] = partSelect.answers[0].id
    }
  })

  partSingleInput.forEach((singleInput) => {
    if (singleInput.value) {
      const arrSplitSingleInput = singleInput.value.split("_")
      if (arrSplitSingleInput.length) {
        newFormData[
          fieldFormDataSpecification(singleInput.id, arrSplitSingleInput[1])
        ] = arrSplitSingleInput[0]
      }
    } else {
      newFormData[
        fieldFormDataSpecification(singleInput.id, singleInput.sequence)
      ] = ""
    }
  })

  partCheckbox.forEach((checkbox) => {
    if (checkbox.value) {
      const arrSplitCheckbox = checkbox.value.split("_")
      if (arrSplitCheckbox.length) {
        newFormData[
          fieldFormDataSpecification(checkbox.id, arrSplitCheckbox[1])
        ] = arrSplitCheckbox[0] === "true" ? true : false
      }
    } else {
      newFormData[fieldFormDataSpecification(checkbox.id, checkbox.sequence)] =
        false
    }
  })
}
const converDataPartTypeEditotNoExsit = (
  part: PartsSpecificationDetail,
  newFormDataEditor: any,
  newFormDataEditorFile: any
) => {
  newFormDataEditor[part.id + "_editor_" + part.sequence] = JSON.stringify(
    convertToRaw(EditorState.createEmpty().getCurrentContent())
  )
  newFormDataEditorFile[fieldFormDataSpecification(part.id, part.sequence)] = []
}
const converDataPartTypeDoubleInputNoExsit = (
  part: PartsSpecificationDetail,
  newFormData: any
) => {
  if (part.answers && part.answers.length) {
    part.answers.forEach((_answer, index) => {
      newFormData[fieldFormDataSpecification(part.id, index)] = ""
    })
    newFormData[`${part.id}_unitDoubleInput`] = arrDoubleInputUnits[0].field
  } else {
    newFormData[fieldFormDataSpecification(part.id, 0)] = ""
  }
}
const converDataPartTypeRadioButtonNoExsit = (
  part: PartsSpecificationDetail,
  newFormData: any
) => {
  if (part.answers && part.answers.length) {
    newFormData[fieldFormDataSpecification(part.id, 0)] = true
  }
}

export const converDataWhenSaveSpecification = (
  formData: any,
  formDataEditor: any,
  formDataEditorFile: any,
  formDataEditorDeleteFile: any
) => {
  const resultFromData: any[] = Object.keys(formData).reduce(
    (prev: any, current: any) => {
      const arr = current.split("_")
      const itemExisted = prev.find((el) => el.specification_part_id === arr[0])
      if (itemExisted) {
        itemExisted.value += `,${formData[current]}_${arr[1]}`
      } else {
        prev.push({
          specification_part_id: arr[0],
          value: `${formData[current]}_${arr[1]}`,
        })
      }
      return prev
    },
    []
  )
  const resultFromDataEditor: any[] = Object.keys(formDataEditor).reduce(
    (prev: any, current: any) => {
      const arr = current.split("_editor_")
      const itemExisted = prev.find((el) => el.specification_part_id === arr[0])
      if (itemExisted) {
        itemExisted.value += `,${formDataEditor[current]}_editor_${arr[1]}`
      } else {
        const newFile =
          formDataEditorFile[fieldFormDataSpecification(arr[0], arr[1])]
        const fileDeleteIds = formDataEditorDeleteFile[arr[0]]
        prev.push({
          specification_part_id: arr[0],
          value: `${formDataEditor[current]}_editor_${arr[1]}`,
          files: newFile,
          file_delete_ids: fileDeleteIds,
        })
      }
      return prev
    },
    []
  )
  return {
    resultFromData,
    resultFromDataEditor,
  }
}
export const handleDisableButtonSaveSpecification = (
  formDataIdRequiced: any
) => {
  const newFomDataIdRequiced: string[] = []
  Object.keys(formDataIdRequiced).forEach((key) => {
    const arrRequicedSplit = key.split("_")
    if (!formDataIdRequiced[key]) {
      newFomDataIdRequiced.push(arrRequicedSplit[0])
    }
  })
  if (newFomDataIdRequiced.length) {
    uniq(newFomDataIdRequiced).forEach((requiced) => {
      toast(
        <LabelNotificationPage
          messenger={`${requiced} is requiced!`}
          type="error"
        />
      )
    })
  }
  return newFomDataIdRequiced.length ? true : false
}

export const onDisableButtonSubmitSpecification = (
  formData: any,
  originFormData: any,
  formDataEditor: any,
  originFormDataEditor: any,
  formDataEditorFile: any,
  formDataEditorDeleteFile: any
) => {
  const newEditorFile: any = Object.values(formDataEditorFile)
  if (newEditorFile.length && newEditorFile[0]?.length) {
    return false
  }
  if (Object.values(formDataEditorDeleteFile).length) {
    return false
  }
  if (
    isEqual(formData, originFormData) &&
    isEqual(formDataEditor, originFormDataEditor)
  ) {
    return true
  }
  return false
}

export const converDataAdditionalBOMComponent = (additionalJson: any) => {
  const newHeaderAddColumn: string[] = Object.keys(additionalJson[0])
  const newDataBodyAddition: any[] = []
  newHeaderAddColumn.forEach((newHeader) => {
    const childrenAdditional = additionalJson.map((el) => {
      return {
        [newHeader]: el[newHeader],
        [`origin-${newHeader}`]: el[newHeader],
      }
    })
    newDataBodyAddition.push({
      field: newHeader,
      originField: newHeader,
      rowChildrens: childrenAdditional,
    })
  })
  return {
    newHeaderAddColumn,
    newDataBodyAddition,
  }
}

export const converNameHeaderTableAdditional = (oldKey: string) => {
  if (!oldKey) {
    return {
      sliceKey: "",
      newKey: "",
    }
  }
  const sliceKey = oldKey ? oldKey.slice(-9) : ""
  return {
    sliceKey,
    newKey: oldKey.replace(sliceKey, ""),
  }
}

export const converDataTableColumnBOM = (newData: TableColumnBOMDetail[]) => {
  return newData.map((el) => {
    return {
      ...el,
      key: el.key.concat(el.idColumn),
    }
  })
}

export const converSpecificationsRequice = (
  dataPartsRequice: PartsSpecificationDetail[]
) => {
  const listRequice: string[] = []
  dataPartsRequice.forEach((el) => {
    if (el.type === TYPE_PARTS_SPECIFICATION.DOUBLE_INPUT) {
      converSpecificationsDoubleInputRequice(el, listRequice)
    } else if (!el.value) {
      listRequice.push(el.name)
    }
  })
  return listRequice
}

const converSpecificationsDoubleInputRequice = (
  el: PartsSpecificationDetail,
  listRequice: string[]
) => {
  const newNameArr: string[] = []
  if (el.value) {
    const valueSplit = el.value.split(",")
    if (valueSplit.length > 2) {
      const valueLength = valueSplit[0].split("_")
      const valueWidth = valueSplit[1].split("_")
      if (valueLength.length && !valueLength[0]) {
        newNameArr.push("Length")
      }
      if (valueWidth.length && !valueWidth[0]) {
        newNameArr.push("Width")
      }
    }
  } else {
    newNameArr.push("Length")
    newNameArr.push("Width")
  }
  if (newNameArr.length) {
    listRequice.push(`${el.name}:${newNameArr.join(",")}`)
  }
}

export const findHistoryTreeDefault = (
  newDataRes: BuildHistoryDetail[],
  findItemCommit: boolean,
  location: any
) => {
  let idHistory = newDataRes[0].id
  let newHistoryDetail = newDataRes[0]
  if (
    findItemCommit ||
    newHistoryDetail.approved_status === VERSION_APPROVED_STATUS.UNAPPROVED
  ) {
    if (location.state && location.state.idHistoryTreeState) {
      let historyCommitCode: BuildHistoryDetail | undefined
      historyCommitCode = newDataRes.find(
        (el) => el.id === location.state.idHistoryTreeState
      )
      if (historyCommitCode) {
        idHistory = historyCommitCode.id
        newHistoryDetail = historyCommitCode
      }
    } else {
      const historyCommit = newDataRes.find(
        (el) =>
          (el.status === STATUS_BUILD.SAVE ||
            el.status === STATUS_BUILD.COMMITTED ||
            el.status === STATUS_BUILD.MERGED) &&
          el.approved_status !== VERSION_APPROVED_STATUS.UNAPPROVED
      )
      if (historyCommit) {
        idHistory = historyCommit.id
        newHistoryDetail = historyCommit
      }
    }
  }
  return {
    idHistory,
    newHistoryDetail,
  }
}

export const findWithRegex = (
  str: string,
  regex: RegExp,
  allMatch: boolean = false,
  index = 0
) => {
  let finalRegex = regex
  const regexString = regex.toString()
  /// if it's not a global match => convert to global match
  if (!endsWith(regexString, "g")) {
    finalRegex = new RegExp(`${regexString}g`)
  }
  /// result will be null | string[]
  const result = str.match(finalRegex)
  if (result) {
    /// get all match return string[]
    if (allMatch) {
      return result
    }
    try {
      /// try to get data from index
      return result[index] || ""
    } catch {
      return ""
    }
  }
  return allMatch ? [] : ""
}

export const onActiveSelect = (option, fieldActive, activeSelect) =>
  option[fieldActive] === activeSelect

export const umamiTracking = (eventName: string) => {
  if (typeof window.umami !== "undefined") {
    const userId = localStorage.getItem("user_id") || ""
    window.umami.track(eventName, { user_id: userId })
  }
}

export const customLocalStorageHandler = (name: NAME_LOCALSTORAGE) => {
  const handleSetLocalStorage = (newData: any) => {
    localStorage.setItem(name, JSON.stringify(newData))
  }
  const storageDataString = localStorage.getItem(name)
  const data = storageDataString ? JSON.parse(storageDataString) : undefined
  return {
    storageData: data,
    handleSetLocalStorage,
  }
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}
export function removeImageBySrc(htmlString: string, srcToRemove: string) {
  // Create a regular expression to match the img tag with the specified src
  const regex = new RegExp('<img[^>]*src="' + srcToRemove + '"[^>]*>', "g")

  // Remove the matched img tag from the HTML string
  htmlString = htmlString.replace(regex, "")
  return htmlString
}
export const b64toBlob = (base64: string, type?: string) => {
  try {
    let binaryData = atob(base64)

    // Create a Uint8Array from the binary data
    let uint8Array = new Uint8Array(binaryData.length)
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i)
    }

    // Create a Blob from the Uint8Array
    let blob = new Blob([uint8Array], {
      type: type || "application/octet-stream",
    })
    return blob
  } catch (error) {
    return false
  }
}
export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export const validateBase64 = (value: string) => {
  const regex = new RegExp(
    "/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/"
  )

  return regex.test(value)
}

export const validateEncryptedFile = async (
  rawHash: string,
  file: {
    file_key: string
    conversation_id?: string
    project_build_id?: string
  },
  projectId: string
) => {
  console.log("-------START VALIDATE ENCRYPTED FILE-----------")
  console.log("File name URL:", file.file_key)
  let encryptedFileContentBlob: any =
    await getFileContentFromEnryptedWithSpecifiedKey(
      projectId,
      getPrivateAssetURI(
        file.file_key,
        file.project_build_id
          ? {
              project_build_id: file.project_build_id,
            }
          : file.conversation_id
          ? { conversation_id: file.conversation_id }
          : {}
      ),
      file.conversation_id,
      file.project_build_id,
      true
    )
  if (encryptedFileContentBlob === "failed by 403") {
    return
  }
  let fileText = ""
  if (typeof encryptedFileContentBlob === "string") {
    encryptedFileContentBlob = b64toBlob(encryptedFileContentBlob)
    fileText = !encryptedFileContentBlob
      ? new Blob([])
      : await encryptedFileContentBlob.text()
  } else {
    encryptedFileContentBlob = new Blob([encryptedFileContentBlob])
    fileText = await encryptedFileContentBlob.text()
  }
  const fileHashKeyFromServer = await getFileHashKeyMiddleware(file.file_key)
  if (
    fileHashKeyFromServer.hash_key === rawHash &&
    fileHashKeyFromServer.public_key
  ) {
    const isVerifiedSignature = encryptionHelper.checkVerifiedSignature(
      fileHashKeyFromServer.public_key,
      fileHashKeyFromServer.hash_key,
      fileText
    )
    if (isVerifiedSignature) {
      console.log(
        "Signature",
        fileHashKeyFromServer.hash_key,
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        "//SUCCESS//"
      )
    } else {
      console.log(
        "Signature",
        rawHash,
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        "//FAILED//"
      )
      await updateFailedFileMiddleware(file.file_key)
    }
  } else {
    console.log(
      "Signature",
      rawHash,
      `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      "//FAILED//"
    )
    await updateFailedFileMiddleware(file.file_key)
  }
  console.log("-------END VALIDATE ENCRYPTED FILE-----------")

  return `\n${file.file_key},${rawHash},${fileHashKeyFromServer.hash_key},${
    rawHash === fileHashKeyFromServer.hash_key
  }, ${moment().format("YYYY-MM-DD HH:mm:ss")}`
}
export const validateConversationNoteFile = async (
  rawHash: string,
  file: {
    file_key: string
  }
) => {
  let encryptedFileContentBlob: any = await getContentFromConversationNoteFile(
    getPrivateAssetURI(file.file_key, {}),
    true,
    "blob"
  )
  if (encryptedFileContentBlob === "failed by 403") {
    return
  }
  console.log("-------START VALIDATE ENCRYPTED CONVERSATION FILE-----------")

  const fileText = await encryptedFileContentBlob.text()
  const fileHashKeyFromServer = await getFileHashKeyMiddleware(file.file_key)
  if (
    fileHashKeyFromServer.hash_key === rawHash &&
    fileHashKeyFromServer.public_key
  ) {
    const isVerifiedSignature = encryptionHelper.checkVerifiedSignature(
      fileHashKeyFromServer.public_key,
      fileHashKeyFromServer.hash_key,
      fileText
    )
    if (isVerifiedSignature) {
      console.log(
        "Signature",
        fileHashKeyFromServer.hash_key,
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        "//SUCCESS//"
      )
    } else {
      console.log(
        "Signature",
        rawHash,
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        "//FAILED//"
      )
      await updateFailedFileMiddleware(file.file_key)
    }
  } else {
    console.log(
      "Signature",
      rawHash,
      `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      "//FAILED//"
    )
    await updateFailedFileMiddleware(file.file_key)
  }
  console.log("-------END VALIDATE ENCRYPTED CONVERSATION FILE-----------")
  return `\n${file.file_key},${rawHash},${fileHashKeyFromServer.hash_key},${
    rawHash === fileHashKeyFromServer.hash_key
  }, ${moment().format("YYYY-MM-DD HH:mm:ss")}`
}

export const base64ToFile = async (
  base64: string,
  filename: string,
  mimeType: string
) => {
  const byteString = atob(base64.split(",")[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeType })
  return new File([blob], filename, { type: mimeType })
}

export const fileArrayToFileList = (files: File[]): FileList => {
  const dataTransfer = new DataTransfer()
  forEach(files, (file) => dataTransfer.items.add(file))
  return dataTransfer.files
}

export const processImageFiles = async (
  imageFiles: File[]
): Promise<{ name: string; imageUrl: string }[]> => {
  const newURLFiles: { name: string; imageUrl: string }[] = []

  for (const file of imageFiles) {
    const imageUrl = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target?.result) {
          const blob = new Blob([e.target.result], { type: file.type })
          const url = URL.createObjectURL(blob)
          resolve(url)
        } else {
          reject(new Error("Failed to read file"))
        }
      }
      reader.onerror = () => reject(new Error("FileReader error"))
      reader.readAsArrayBuffer(file)
    })

    newURLFiles.push({
      name: file.name,
      imageUrl,
    })
  }

  return newURLFiles
}

export const readLinkS3AndCreateFile = async (
  fileKey: string,
  projectComponentHistoryId: string,
  sharedKey: string,
  checkHash: boolean = true,
  historyFromId?: string,
  userVaultKey?: string,
  userId?: string,
  conversation_id?: string
): Promise<any> => {
  const fileURL = getPrivateAssetURI(
    fileKey,
    conversation_id
      ? { conversation_id }
      : { project_component_history_id: projectComponentHistoryId }
  )

  const token = localStorage.getItem("access_token") || ""
  const tokenType = localStorage.getItem("token_type") || "Bearer"

  try {
    // Fetch file as a blob
    const response = await instanceExternalUrl.get(fileURL, {
      responseType: "blob",
      headers: { Authorization: `${tokenType} ${token}` },
    })

    if (response.headers["encrypted"] === "0") return null

    // Read text data from blob
    let fileText = (await response.data.text()) as any

    // Decrypt file
    let decryptedData = await encryptionController().decrypt(
      fileText,
      userVaultKey
        ? {
            dataType: "file",
            type: "component-shared",
            encryptionKey: sharedKey,
          }
        : { dataType: "file", type: "project" }
    )

    // Free up memory
    fileText = null
    let dataToDownload = encryptionHelper.toUint8Array(decryptedData) as any
    decryptedData = null // Free memory
    let newBlob = new Blob([dataToDownload]) as any
    dataToDownload = null // Free memory

    let finalFileText = await newBlob.text()
    const fileHashKeyFromServer = await getFileHashKeyMiddleware(fileKey)
    if (fileHashKeyFromServer.hash_key && fileHashKeyFromServer.public_key) {
      const isVerifiedSignature = encryptionHelper.checkVerifiedSignature(
        fileHashKeyFromServer.public_key,
        fileHashKeyFromServer.hash_key,
        finalFileText
      )

      if (!isVerifiedSignature) {
        console.log(
          "Signature",
          fileHashKeyFromServer.hash_key,
          moment().format("YYYY-MM-DD HH:mm:ss"),
          "//FAILED//"
        )
        return null
      }
    }

    // Fetch private key
    const privateKeyByUserId = (await getPrivateKeyByUserId(false)) as string
    if (!privateKeyByUserId) {
      toast(
        <LabelNotificationPage
          messenger={"Private key not found!"}
          type={"error"}
        />
      )
      return null
    }

    // Create signature
    const signatureAndHashFile = encryptionHelper.createSignatureAndHashFile(
      finalFileText,
      true,
      privateKeyByUserId
    )
    finalFileText = null // Free memory
    if (!signatureAndHashFile.signature) return null

    let newFileName = userVaultKey
      ? fileKey.replace(
          /^([a-f0-9-]+)\/share\//,
          `${userId}/share-${historyFromId}/`
        )
      : fileKey.replace(
          /^([a-f0-9-]+)\//,
          `$1/share-${projectComponentHistoryId}/`
        )

    if (userVaultKey && userId) {
      newFileName = newFileName.replace(newFileName.split("/")[0], userId)
    }

    // Convert to File object
    const file = new File([newBlob], newFileName, {
      type: response.headers["content-type"],
    })
    newBlob = null // Free memory
    finalFileText = null // Free memory

    // Encrypt final file
    const encryptedFile = await encryptionController().encrypt(file, {
      dataType: "file",
      type: "component-shared",
      encryptionKey: userVaultKey || sharedKey,
    })

    return {
      blob: new Blob([encryptedFile], { type: file.type }),
      file_hash: signatureAndHashFile.signature,
      file_name: newFileName,
    }
  } catch (err) {
    console.error(err)
    return null
  }
}
export const readLinkS3AndCreateFileConversationMessage = async (
  fileKey: string,
  userVaultKey: string,
  conversation_id: string,
  userId: string
): Promise<any> => {
  const fileURL = getPrivateAssetURI(fileKey, {
    conversation_id: conversation_id,
  })
  const token = localStorage.getItem("access_token") || ""
  const tokenType = localStorage.getItem("token_type") || "Bearer"
  try {
    const response = await instanceExternalUrl.get(fileURL, {
      responseType: "blob",
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    })
    let data = response.data
    let dataToDownload = data
    let fileText = ""
    const isEncrypted = response.headers["encrypted"]
    if (isEncrypted === "0") {
      return null
    }

    const temp = await response.data.text()
    data = await encryptionController().decrypt(temp, {
      dataType: "file",
      type: "conversation",
    })

    dataToDownload = encryptionHelper.toUint8Array(data)
    const newBlob = new Blob([dataToDownload])
    fileText = await newBlob.text()
    const fileHashKeyFromServer = await getFileHashKeyMiddleware(fileKey)
    if (fileHashKeyFromServer.hash_key && fileHashKeyFromServer.public_key) {
      const isVerifiedSignature = encryptionHelper.checkVerifiedSignature(
        fileHashKeyFromServer.public_key,
        fileHashKeyFromServer.hash_key,
        fileText
      )
      if (isVerifiedSignature) {
        console.log(
          "Signature",
          fileHashKeyFromServer.hash_key,
          `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          "//SUCCESS//"
        )
      } else {
        console.log(
          "Signature",
          fileHashKeyFromServer.hash_key,
          `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          "//FAILED//"
        )
        return null
      }
    } else {
      console.log(
        "Signature",
        fileHashKeyFromServer.hash_key,
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        "//FAILED//"
      )
      return null
    }

    const privateKeyByUserId = (await getPrivateKeyByUserId(false)) as string
    if (!privateKeyByUserId) {
      toast(
        <LabelNotificationPage
          messenger={"Private key not found!"}
          type={"error"}
        />
      )
      return null
    }

    const signatureAndHashFile = encryptionHelper.createSignatureAndHashFile(
      fileText,
      true,
      privateKeyByUserId
    )
    if (!signatureAndHashFile.signature) {
      console.log(
        "Not createting signature file",
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        "//FAILED//"
      )
      return null
    }

    let newFileName = fileKey
    if (userVaultKey && userId) {
      newFileName = newFileName.replace(newFileName.split("/")[0], userId)
    }
    const file = new File([newBlob], newFileName, {
      type: response.headers["content-type"],
    })

    const encryptedFile = await encryptionController().encrypt(file, {
      dataType: "file",
      type: "conversation",
      encryptionKey: userVaultKey,
    })

    return {
      blob: new Blob([encryptedFile], {
        type: file.type,
      }),
      file_hash: signatureAndHashFile.signature,
      file_name: newFileName,
    }
  } catch (err) {
    console.log(err)
    return null
  }
}

export const getPrivateKeyByUserId = async (isPrivatePem = true) => {
  const userId = localStorage.getItem("user_id")
  const privateKeyStores = await getStoreData<{
    id: string
    privateKey: string
  }>(Stores.PrivateKeys)
  const privateKeyByUserId =
    privateKeyStores.find((el) => el.id === userId)?.privateKey || ""
  if (isPrivatePem) {
    return forge.pki.privateKeyFromPem(privateKeyByUserId)
  }
  return privateKeyByUserId
}

export const getDifferenceInDays = (date: string) => {
  const timezone = localStorage.getItem("timezone") || "+0000"
  // Current date in timezone
  const now = moment().utcOffset(timezone).startOf("day")

  // Target date in timezone
  const targetDate = moment(date).utcOffset(timezone).startOf("day")

  // Calculate the difference in days
  const daysDifference = targetDate.diff(now, "days")
  if (daysDifference > 7) {
    return 7
  }
  if (daysDifference <= 0) {
    return 1
  }

  return daysDifference
}

export const replaceImagesWithText = (
  value: string,
  files: { key: string; file_name: string }[]
): string => {
  return value.replace(/<img src="(.*?)">/g, (_, key) => {
    const file = files.find((f) => f.key === key)
    return file ? `<br>[${file.file_name}]<br>` : ""
  })
}

export const getNextTitleButtonBuild = (status: STATUS_BUILD) => {
  switch (status) {
    case STATUS_BUILD.DRAFT:
      return "Build"
    default:
      return "Next"
  }
}
