import Button from "components/Button/Button"
import FormConfirm from "components/HistoryTree/Form/FormConfirm"
import FormLoading from "components/FormLoading/FormLoading"
import ModalDelete from "components/ModalCustom/ModalDelete"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { SelectedDefaultProp } from "components/Select/types"
import { STATUS_BUILD } from "components/Status/types"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import {
  checkPermissionPage,
  downloadEncrypted,
  getNextTitleButtonBuild,
  newStepStatusBuild,
  umamiTracking,
} from "helpers/utils"
import { compact, includes, isEmpty } from "lodash"
import DotCard from "pages/conversations/atoms/DotCard"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import React, { useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  deleteProjectBuildMiddleware,
  postProjectBuildStatusMiddleware,
  putProjectBuildNameMiddleware,
} from "../api.services"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import ChangeStatusBuildCard from "../molecules/ChangeStatusBuildCard"
import FormInProduction from "../molecules/FormInProduction"
import NameBuildCard from "../molecules/NameBuildCard"
import { ProjectComponentBuildDetail } from "../project-build.type"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import { EVENT } from "constants/events"
import { useAppSelector } from "hooks/useApp"
import { BuildOverviewPageContext } from "pages/build-overview/contexts/BuildOverviewPage.context"

interface Props {
  projectBuild: ProjectComponentBuildDetail
  isDeleteBuild?: boolean
  onUpdateDataEditNameBuild: (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => void
  showButtonUpdateComponent?: boolean
}
const InfoDraftBuildCard = (props: Props) => {
  const {
    projectBuild,
    isDeleteBuild = false,
    onUpdateDataEditNameBuild,
    showButtonUpdateComponent,
  } = props
  const { archiveProject, archiveBuild, listStatus, viewOnlyShare } =
    useContext(PermissionProjectBuildPage)
  const { setBuild, setSelectedBuildStatus } = useContext(
    BuildOverviewPageContext
  )
  const { isMobile } = useWindowSize()
  const RFQStatusStage = compact(
    [STATUS_BUILD.TEST_SETUP, STATUS_BUILD.DATA_EXCHANGE, STATUS_BUILD.RFQ].map(
      (item) => {
        return listStatus.find((el) => el.label === item)
      }
    )
  )

  const refInput = useRef<any>(null)
  const isLoading = useBoolean(false)
  const isLoadingForm = useBoolean()
  const isFormDeleteBuild = useBoolean()
  const nextStepStatus = newStepStatusBuild(projectBuild.status, listStatus)
  const isFormInProduction = useBoolean(false)
  const isOpenSelectStatus = useBoolean(false)
  const isEditNameBuild = useBoolean(false)
  const openModalConfirmStatus = useBoolean(false)
  const openModalNextStage = useBoolean(false)
  const newNameBuild = useString("")

  const user = useAppSelector((state) => state.userInfo)
  useEffect(() => {
    if (!projectBuild.id) {
      return
    }
    newNameBuild.setValue(projectBuild.name)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBuild])

  const isSelectStatus = includes(
    [
      STATUS_BUILD.IN_PRODUCTION,
      STATUS_BUILD.IN_PROGRESS,
      STATUS_BUILD.CANCELLED,
      STATUS_BUILD.COMPLETED,
      STATUS_BUILD.RFQ,
      STATUS_BUILD.TEST_SETUP,
      STATUS_BUILD.DATA_EXCHANGE,
    ],
    projectBuild.status
  )

  const [valueSelectStatus, setValueSelectStatus] =
    useState<SelectedDefaultProp>({
      label: "",
      value: "",
    })
  const handleBuild = () => {
    if (!projectBuild.id) {
      return
    }

    if (nextStepStatus.label === STATUS_BUILD.TEST_SETUP) {
      setValueSelectStatus(nextStepStatus)
      openModalNextStage.setValue(true)
      return
    }
    if (nextStepStatus.label === STATUS_BUILD.IN_PRODUCTION) {
      isFormInProduction.setValue(true)
      return
    }
    isLoadingForm.setValue(true)
    postProjectBuildStatusMiddleware(
      projectBuild.id,
      {
        status: String(nextStepStatus.value),
        reason: nextStepStatus.label,
      },

      nextStepStatus.message ? nextStepStatus.message : "",
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        isLoadingForm.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          setBuild(dataRes.id, {
            status: dataRes.status,
            updated_at: Date().toString(),
          })
          if (nextStepStatus.label === STATUS_BUILD.COMMITTED) {
            onUpdateDataEditNameBuild({
              ...projectBuild,
              status: dataRes.status,
            })
          }
          setSelectedBuildStatus(dataRes.status)
          pushTo(
            PATH.buildOverview,
            {
              status: dataRes.status,
              idProject: dataRes.project_id,
              idProjectBuild: dataRes.id,
            },
            undefined,
            { tab: user.id === dataRes.created_by ? "my-builds" : "others" }
          )
        }
      },
      projectBuild.status
    )
  }
  const onExportFile = () => {
    if (!projectBuild.id) {
      return
    }
    isLoading.setValue(true)

    downloadEncrypted(
      `/api/project-build/${projectBuild.id}/files`,
      projectBuild.id
    )
    umamiTracking(EVENT.BUILD.DOWNLOAD)
    isLoading.setValue(false)
  }

  const onDeleteBuildDraft = () => {
    isLoading.setValue(true)
    deleteProjectBuildMiddleware(
      projectBuild.id,
      projectBuild.name,
      projectBuild.code,
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          pushTo(PATH.projectComponent, {
            idProject: projectBuild.project_id,
          })
        }
      }
    )
  }
  const handleOpenSelectStatus = () => {
    if (!isSelectStatus || viewOnlyShare) {
      return
    }
    isOpenSelectStatus.setValue(!isOpenSelectStatus.value)
  }
  const handleChangeStatus = (newStatus: SelectedDefaultProp) => () => {
    setValueSelectStatus(newStatus)
    openModalConfirmStatus.setValue(true)
    isOpenSelectStatus.setValue(false)
  }
  const onSubmitChangeStatus = (
    newMessageInput: string,
    callback: () => void
  ) => {
    if (!projectBuild.id) {
      return
    }
    if (newMessageInput === "") {
      toast(
        <LabelNotificationPage
          messenger={"Message is required!"}
          type={"error"}
        />
      )
      return
    }
    postProjectBuildStatusMiddleware(
      projectBuild.id,
      {
        status: valueSelectStatus.value,
        reason: newMessageInput,
      },

      "Build status updated",
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        callback()
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          openModalConfirmStatus.setValue(false)
          setBuild(dataRes.id, {
            status: dataRes.status,
            updated_at: Date().toString(),
          })
          onUpdateDataEditNameBuild({ status: dataRes.status })
          pushTo(
            PATH.buildOverview,
            {
              status: dataRes.status,
              idProject: dataRes.project_id,
              idProjectBuild: dataRes.id,
            },
            undefined,
            { tab: user.id === dataRes.created_by ? "my-builds" : "others" }
          )
        }
      },
      projectBuild.status
    )
  }
  const onSubmitChangeStage = (
    newMessageInput: string,
    callback: () => void
  ) => {
    if (!projectBuild.id) {
      return
    }
    if (!newMessageInput) {
      return toast(
        <LabelNotificationPage
          messenger={"Reason is required!"}
          type={"error"}
        />
      )
    }
    openModalNextStage.setValue(false)
    isLoadingForm.setValue(true)
    postProjectBuildStatusMiddleware(
      projectBuild.id,
      {
        status: String(valueSelectStatus.value),
        reason: newMessageInput,
      },
      nextStepStatus.message ? nextStepStatus.message : "",
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        callback()
        isLoadingForm.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          setBuild(dataRes.id, { status: dataRes.status })
          onUpdateDataEditNameBuild({ status: dataRes.status })
          pushTo(
            PATH.buildOverview,
            {
              status: dataRes.status,
              idProject: dataRes.project_id,
              idProjectBuild: dataRes.id,
            },
            undefined,
            { tab: user.id === dataRes.created_by ? "my-builds" : "others" }
          )
        }
      },
      projectBuild.status
    )
  }
  const handleChangeInput = (event) => {
    const newValue = event.target.value
    newNameBuild.setValue(newValue)
  }

  const handleBlur = () => {
    if (!projectBuild.id) {
      return
    }
    if (!newNameBuild.value || newNameBuild.value === projectBuild.name) {
      isEditNameBuild.setValue(false)
      newNameBuild.setValue(projectBuild.name)
      return
    }
    isLoading.setValue(true)
    putProjectBuildNameMiddleware(
      projectBuild.id,
      {
        name: newNameBuild.value,
      },
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          onUpdateDataEditNameBuild({
            name: newNameBuild.value,
            updated_at: dataRes.updated_at,
          })
          isEditNameBuild.setValue(false)
          return
        }
        isEditNameBuild.setValue(false)
        newNameBuild.setValue(projectBuild.name)
      }
    )
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleBlur()
    }
  }
  const onChangeEditNameBuild = () => {
    isEditNameBuild.setValue(true)
    setTimeout(() => {
      refInput.current.focus()
      refInput.current.select()
    }, 0)
  }

  const renderButtonDeleteBuild = () => {
    if (
      checkPermissionPage({
        project: archiveProject,
        build: archiveBuild,
        viewShare: viewOnlyShare,
      }) ||
      !projectBuild.id ||
      !isDeleteBuild
    ) {
      return null
    }
    return (
      <Button
        title="Delete Draft"
        colorBtn="white"
        sizeBtn="small"
        onClick={() => isFormDeleteBuild.setValue(true)}
        styleButton={{
          marginLeft: 8,
        }}
      />
    )
  }
  return (
    <div className="bg-white flex flex-col px-6 py-2">
      <NameBuildCard
        isEditNameBuild={isEditNameBuild.value}
        newNameBuild={newNameBuild.value}
        projectBuildName={projectBuild.name}
        handleChangeInput={handleChangeInput}
        handleBlur={handleBlur}
        refInput={refInput}
        onKeyPress={onKeyPress}
        archiveProject={archiveProject}
        archiveBuild={archiveBuild}
        viewOnlyShare={viewOnlyShare}
        idProjectBuild={projectBuild.id}
        onChangeEditNameBuild={onChangeEditNameBuild}
      />

      <div className="flex justify-between h-7 mt-[11px]">
        <div className="flex items-center">
          <p
            className="font-semibold"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: "#333333",
            }}
          >
            {projectBuild.code
              ? projectBuild.code
              : `BD-${new Date().getFullYear()}`}
          </p>
          <DotCard />

          <div className="flex items-center">
            <ChangeStatusBuildCard
              isOpenSelectStatus={isOpenSelectStatus}
              listStatus={listStatus}
              projectBuild={projectBuild}
              handleChangeStatus={handleChangeStatus}
              handleOpenSelectStatus={handleOpenSelectStatus}
              isSelectStatus={viewOnlyShare ? false : isSelectStatus}
            />
            {renderButtonDeleteBuild()}
          </div>
        </div>
        <div className="flex items-center">
          {!includes(
            [
              STATUS_BUILD.IN_PRODUCTION,
              STATUS_BUILD.IN_PROGRESS,
              STATUS_BUILD.CANCELLED,
              STATUS_BUILD.COMPLETED,
              STATUS_BUILD.DELETED,
            ],
            projectBuild.status
          ) &&
          !showButtonUpdateComponent &&
          !checkPermissionPage({
            project: archiveProject,
            build: archiveBuild,
            viewShare: viewOnlyShare,
          }) ? (
            <Button
              title={getNextTitleButtonBuild(projectBuild.status)}
              sizeBtn="small"
              onClick={handleBuild}
              disabled={!projectBuild.id || isEmpty(projectBuild.components)}
              isDisabledBtn
              widthBtn={isMobile ? 80 : 128}
            />
          ) : null}
          {projectBuild.status !== STATUS_BUILD.DELETED && projectBuild.id ? (
            <React.Fragment>
              <ActionIconHistory
                classIcon="hidden md:block"
                icon="download"
                tooltip="Export"
                styleIcon={{
                  marginLeft: 12,
                  marginRight: 0,
                }}
                onClick={onExportFile}
              />
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {isFormDeleteBuild.value && (
        <ModalDelete
          onCloseModal={() => isFormDeleteBuild.setValue(false)}
          title={`Are you sure to delete ${projectBuild.name}?`}
          label={`Confirmation`}
          onSubmit={onDeleteBuildDraft}
          titleButton="Delete"
        />
      )}
      {openModalConfirmStatus.value && (
        <FormConfirm
          idHistory={projectBuild.id}
          onCloseModal={() => openModalConfirmStatus.setValue(false)}
          onSubmitButton={onSubmitChangeStatus}
          message={`You are changing the status for the build.`}
          label="Confirmation"
          messageUndo=""
        />
      )}
      {openModalNextStage.value && (
        <FormConfirm
          idHistory={projectBuild.id}
          onCloseModal={() => openModalNextStage.setValue(false)}
          onSubmitButton={onSubmitChangeStage}
          message={`You are changing the status for the build.`}
          label="Confirmation"
          messageUndo=""
          extraTopForm={
            <div className="pb-3">
              <FormInputCard
                title="Select build status*"
                fontWeightText="font-semibold"
              >
                <div className="flex">
                  {RFQStatusStage.map((item, key) => (
                    <Button
                      key={key}
                      sizeBtn="small"
                      title={item.label}
                      colorBtn="white"
                      widthBtn={
                        item.label === STATUS_BUILD.DATA_EXCHANGE ? 100 : 80
                      }
                      onClick={() => setValueSelectStatus(item)}
                      styleButton={
                        valueSelectStatus.value === item.value
                          ? {
                              border: "1px solid rgb(86, 160, 18)",
                              boxShadow: "rgb(86 160 18 / 10%) 0px 0px 0px 4px",
                              height: 32,
                              marginRight: 10,
                            }
                          : {
                              backgroundColor: "#F7F7F7",
                              height: 32,
                              marginRight: 10,
                            }
                      }
                    />
                  ))}
                </div>
              </FormInputCard>
            </div>
          }
        />
      )}
      {isFormInProduction.value ? (
        <FormInProduction
          onCloseModal={() => isFormInProduction.setValue(false)}
          openModal={isFormInProduction.value}
          components={projectBuild.components.filter(
            (el) => !el.is_build_component_archived
          )}
          productBuild={projectBuild}
          onUpdateDataEditNameBuild={onUpdateDataEditNameBuild}
          listStatus={listStatus.filter((item) => {
            return (
              item.label === STATUS_BUILD.IN_PRODUCTION ||
              item.label === STATUS_BUILD.IN_PROGRESS
            )
          })}
        />
      ) : null}
      {isLoadingForm.value ? <FormLoading label="Processing..." /> : null}
    </div>
  )
}
export default InfoDraftBuildCard
