/* eslint-disable react-hooks/exhaustive-deps */
import { BuildHistoryDetail } from "../../pages/project-component-detail/types"
import {
  Gitgraph,
  templateExtend,
  TemplateName,
  Orientation,
} from "@gitgraph/react"
import React, { useEffect, useRef, useState } from "react"
import { cloneDeep, isUndefined, reverse } from "lodash"
import { useBoolean, useNumber } from "helpers/hooks"
import { arrTreeColors } from "./history-tree.constant"
import {
  converHistoryTreeGitGraph,
  spacingAndLineWidthTree,
} from "./history-tree.utils"
import InfoCommitCard from "./organisms/InfoCommitCard"
import ActionSoftCard from "./organisms/ActionSoftCard"
import InfoBranchCard from "./organisms/InfoBranchCard"
import StatusCard from "./organisms/StatusCard"
import DotCard from "pages/conversations/atoms/DotCard"
import { formatDate } from "helpers/utils"
import CustomCollapse from "pages/project-component-detail/molecules/CustomCollapse"
import { SpecificationHeader } from "components/specification/SpecificationHeader"
import { VERSION_APPROVED_STATUS } from "types"
interface Props {
  histories: BuildHistoryDetail[]
  typeComponent: string
  historyDetail: BuildHistoryDetail
  handleChangeHistoryDetail: (
    newHistoryDetail: BuildHistoryDetail
  ) => () => void
  titlePage: string
  isShowErrorSpecification?: boolean
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.historyDetail === nextProps.historyDetail &&
    JSON.stringify(prevProps.histories) ===
      JSON.stringify(nextProps.histories) &&
    prevProps.handleChangeHistoryDetail ===
      nextProps.handleChangeHistoryDetail &&
    prevProps.titlePage === nextProps.titlePage &&
    prevProps.typeComponent === nextProps.typeComponent &&
    prevProps.isShowErrorSpecification === nextProps.isShowErrorSpecification
  )
}
const HistoryTreeCard = React.memo((props: Props) => {
  const {
    histories,
    historyDetail,
    typeComponent,
    handleChangeHistoryDetail,
    titlePage,
    isShowErrorSpecification,
  } = props
  const [historyTrees, setHistoryTrees] = useState<BuildHistoryDetail[]>([])
  const [reverseHistoryTrees, setReverseHistoryTrees] = useState<
    BuildHistoryDetail[]
  >([])
  const redHistoryTree = useRef<HTMLDivElement>(null)
  const [orientationTree, setOrientationTree] = useState<
    Orientation | undefined
  >(undefined)
  const maxWidthSVGDots = useNumber(30)
  const reloadTree = useBoolean()
  const isToggle = useBoolean(true)
  const options = {
    template: templateExtend(TemplateName.Metro, {
      colors: arrTreeColors,
      branch: {
        label: {
          display: false,
        },
        lineWidth: spacingAndLineWidthTree(histories.length).lineWidth,
        spacing: spacingAndLineWidthTree(histories.length).spacing,
      },
      commit: {
        message: {
          displayAuthor: false,
          displayHash: false,
          display: false,
        },
        spacing: 24,
        dot: {
          size: 5,
        },
        hasTooltipInCompactMode: true,
      },
    }),
  }
  useEffect(() => {
    setHistoryTrees(cloneDeep(histories))
    setReverseHistoryTrees(reverse(cloneDeep(histories)))
  }, [JSON.stringify(histories)])

  useEffect(() => {
    if (!isShowErrorSpecification) {
      return
    }
    isToggle.setValue(false)
  }, [isShowErrorSpecification])

  const handleChangeOrderByHistory =
    (newOrientation: Orientation | undefined) => () => {
      setOrientationTree(newOrientation)
    }
  const renderHistoryTree = () => {
    return (
      <div
        className="absolute left-0 right-0 bottom-0"
        ref={redHistoryTree}
        id="gitgrah"
        style={{ top: -3, width: maxWidthSVGDots.value }}
      >
        <Gitgraph
          key={`${JSON.stringify(
            reverseHistoryTrees
          )} ${orientationTree} ${reloadTree}`}
          options={{
            ...options,
            orientation: orientationTree,
          }}
        >
          {(gitgraph) => {
            const objBranchTree = {}
            converHistoryTreeGitGraph(
              reverseHistoryTrees,
              objBranchTree,
              gitgraph
            )
            const lengthDots = Object.keys(objBranchTree).length - 1
            // default width 1 dots
            let widthTdHistoryTree = 30
            if (lengthDots > 0) {
              widthTdHistoryTree =
                30 +
                lengthDots *
                  spacingAndLineWidthTree(reverseHistoryTrees.length).spacing
            }
            maxWidthSVGDots.setValue(widthTdHistoryTree)
          }}
        </Gitgraph>
      </div>
    )
  }
  const renderBackgroundColorTree = (oldHistory: BuildHistoryDetail) => {
    if (historyDetail.id === oldHistory.id) {
      return "#F1F1F1"
    }
    return "#FFFFFF"
  }
  const newHistoryTrees = isUndefined(orientationTree)
    ? historyTrees
    : reverseHistoryTrees
  const renderBody = () => {
    return (
      <div
        className="bg-white py-3 mr-6 flex flex-col"
        style={{
          border: "1px solid #E4E4E4",
          borderTop: "0px",
          borderRadius: 4,
        }}
      >
        <ActionSoftCard
          orientationTree={orientationTree}
          handleChangeOrderByHistory={handleChangeOrderByHistory}
        />
        <div className="flex items-start justify-between overflow-auto h-[400px]">
          {newHistoryTrees.length ? (
            <table className="w-full">
              <tbody>
                {newHistoryTrees.map((history, index) => (
                  <tr
                    key={index}
                    id={`row-${
                      Boolean(history.is_merged) &&
                      history.from_build_component_id
                        ? history.parent_id
                        : history.project_build_component_id
                    }`}
                    className={`${
                      history.approved_status !==
                      VERSION_APPROVED_STATUS.UNAPPROVED
                        ? "hover-item-history-tree cursor-pointer"
                        : "bg-delete-version"
                    } whitespace-nowrap`}
                    style={{
                      backgroundColor: renderBackgroundColorTree(history),
                    }}
                    onClick={() => {
                      if (
                        history.approved_status ===
                        VERSION_APPROVED_STATUS.UNAPPROVED
                      )
                        return
                      handleChangeHistoryDetail(history)()
                    }}
                  >
                    <td
                      className="h-6 relative"
                      style={{
                        minWidth: maxWidthSVGDots.value,
                        width: maxWidthSVGDots.value,
                        maxWidth: maxWidthSVGDots.value,
                        padding: 0,
                      }}
                    >
                      {index === 0 ? renderHistoryTree() : null}
                    </td>
                    <td className="h-6 w-[170px] min-w-[170px]">
                      <InfoBranchCard history={history} />
                    </td>
                    <td className="h-6 w-[100px] min-w-[100px]">
                      <StatusCard history={history} />
                    </td>
                    <td className="h-6">
                      <div className="flex">
                        <InfoCommitCard
                          titlePage={titlePage}
                          history={history}
                          histories={histories}
                          typeComponent={typeComponent}
                        />
                      </div>
                    </td>
                    <td
                      className="px-3 h-6 bg-tree-user relative md:sticky right-0 "
                      style={{
                        backgroundColor: renderBackgroundColorTree(history),
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="flex items-center justify-end">
                        <p
                          className="font-semibold whitespace-nowrap"
                          style={{
                            fontSize: 13,
                            lineHeight: "22px",
                            color: "#111111",
                          }}
                        >
                          {history.user?.name}
                        </p>
                        <DotCard />
                        <p
                          className="font-normal whitespace-nowrap"
                          style={{
                            fontSize: 13,
                            lineHeight: "22px",
                            color: "#7A7A7A",
                            minWidth: 155,
                          }}
                        >
                          {formatDate(
                            history?.updated_at || history?.created_at,
                            "DD MMM yyyy - HH:mm A"
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    )
  }
  return (
    <>
      <CustomCollapse
        // changeHeaderBorder={false}
        buttonClass="border-0"
        isToggle={isToggle.value}
        isDisableToggle={false}
        header={
          <SpecificationHeader
            title={"History"}
            isExpanded={isToggle.value}
            className="mr-6"
          />
        }
        handleChangeToggle={(newToggle: boolean) => {
          isToggle.setValue(newToggle)
          setTimeout(() => {
            reloadTree.setValue(!reloadTree.value)
          }, 100)
        }}
        childrenBody={renderBody()}
      />
    </>
  )
}, isEqualProps)
export default HistoryTreeCard
