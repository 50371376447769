import React, { useState, useEffect } from "react"
import { useBoolean, useString } from "helpers/hooks"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "../molecules/FormInputCard"
import {
  CompanyRole,
  InformationUserRequest,
  defaultInformationUserRequest,
} from "../types"
import { cloneDeep, isEqual } from "lodash"
import { useAppSelector } from "hooks/useApp"
import { umamiTracking } from "helpers/utils"
import {
  updateInformationMiddleware,
  getUserMiddleware,
  getCompanyRoles,
} from "../services/api"
import { STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import InputPhone from "components/Input/InputPhone"
import Button from "components/Button/Button"
import { EVENT } from "constants/events"
import SelectMulti from "components/Select/SelectMulti"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"

const InformationUserCard = () => {
  const userInfo = useAppSelector((state) => state.userInfo)

  const isLoading = useBoolean()
  const [request, setRequest] = useState<InformationUserRequest>(
    defaultInformationUserRequest
  )
  const avatarInput = useString("")
  const [companyRoles, setCompanyRoles] = useState<CompanyRole[]>([])
  const [cloneCompanyRoles, setCloneCompanyRoles] = useState<CompanyRole[]>([])
  const [selectedRole, setSelectedRole] = useState<CompanyRole>()
  const customRole = useString()

  useEffect(() => {
    if (!userInfo.id) {
      return
    }
    getDefaultData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  const getDefaultData = async () => {
    const companyRoles = await getCompanyRoles()

    setCompanyRoles(companyRoles)
    setCloneCompanyRoles(companyRoles)
    setRequest({
      ...request,
      company_name: userInfo.company_name || "",
      company_role: {
        id: userInfo.company_role_id ? userInfo.company_role_id : "other",
        custom_role: userInfo.company_role_custom || "",
      },
      fullname: userInfo.fullname,
      address: userInfo.address || "",
    })
    if (userInfo.company_role_id) {
      setSelectedRole(
        companyRoles.find((item) => item.id === userInfo.company_role_id)
      )
    } else {
      setSelectedRole({ name: "Other", id: "other" })
    }
    customRole.setValue(userInfo.company_role_custom)
    avatarInput.setValue("")
  }

  const handleChangeInput =
    (key: "fullname" | "company_name" | "address" | "email" | "company_role") =>
    (event) => {
      const newRequest = cloneDeep(request)
      const value = event.target.value
      if (key === "company_name") {
        newRequest.company_name = value
        if (!value) {
          newRequest.company_role = {
            id: "other",
            custom_role: "",
          }
          setSelectedRole({ name: "Other", id: "other" })
        }
      } else {
        newRequest[key] = value
      }
      setRequest(newRequest)
    }

  const handleChangePhoneNumber = (_value) => {
    setRequest({
      ...request,
    })
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButtonSave()) {
        return
      }
      onClickButtonSave()
    }
  }

  const isRequestDataChanged = (request, userInfo) => {
    const dataNewRequest = {
      fullname: request.fullname.trim(),
      company_name: request.company_name?.trim(),
      address: request.address?.trim(),
    }

    const dataOld = {
      fullname: userInfo.fullname.trim(),
      company_name: userInfo.company_name ? userInfo.company_name.trim() : "",
      address: userInfo.address ? userInfo.address.trim() : "",
    }

    return !isEqual(dataNewRequest, dataOld)
  }

  const handleDisableButtonSave = () => {
    if (!request.fullname) {
      return true
    }

    if (
      request.company_role?.id !== "other" &&
      request.company_role?.id !== userInfo.company_role_id
    ) {
      return false
    }

    if (request.company_role?.id === "other" && request.company_name) {
      if (!request.company_role?.custom_role) {
        return true
      }

      if (
        (request.company_role?.custom_role || "") !==
        (userInfo.company_role_custom || "")
      ) {
        return false
      }
    }
    if (isRequestDataChanged(request, userInfo)) {
      return false
    }
    return true
  }

  const handleDisableButtonCancel = () => {
    if (request.company_role?.id !== (userInfo.company_role_id || "other")) {
      return false
    }

    if (request.company_role?.id === "other") {
      if (
        (request.company_role?.custom_role || "") !==
        (userInfo.company_role_custom || "")
      ) {
        return false
      }
    }
    return !isRequestDataChanged(request, userInfo)
  }

  const onClickButtonSave = () => {
    umamiTracking(EVENT.UPDATE_PROFILE)
    if (!request.fullname) {
      return
    }
    const dataRequest = cloneDeep(request)
    delete dataRequest.avatar
    isLoading.setValue(true)
    updateInformationMiddleware(dataRequest, (type, messenger) => {
      if (type === STATUS_RESPONSE.SUCCESS) {
        getUserMiddleware(() => {
          isLoading.setValue(false)
        })

        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.UPDATING_INFORMATION_SUCCESS}
            type="success"
          />
        )
      } else {
        isLoading.setValue(false)
        toast(
          <LabelNotificationPage messenger={messenger ?? ""} type="error" />
        )
      }
    })
  }
  const handleCancel = () => {
    setRequest({
      ...request,
      company_name: userInfo.company_name ? userInfo.company_name : "",
      fullname: userInfo.fullname,
      address: userInfo.address ? userInfo.address : "",
      company_role: {
        id: userInfo.company_role_id ? userInfo.company_role_id : "other",
        custom_role: userInfo.company_role_custom,
      },
    })
    if (userInfo.company_role_id) {
      setSelectedRole(
        companyRoles.find((item) => item.id === userInfo.company_role_id)
      )
    } else {
      setSelectedRole({ name: "Other", id: "other" })
    }
    customRole.setValue(userInfo.company_role_custom)
  }

  const handleChangeCustomRole = (event) => {
    const newRequest = cloneDeep(request)
    newRequest.company_role = {
      id: "other",
      custom_role: event.target.value,
    }
    setRequest(newRequest)
    customRole.setValue(event.target.value)
  }

  const handleChangeCompanyRole = (data: { value: string; label: string }) => {
    setSelectedRole({
      name: data.label,
      id: data.value,
    })

    let newRequest = cloneDeep(request)
    if (data.value === "other") {
      newRequest.company_role = {
        id: "other",
        custom_role: "",
      }
      customRole.setValue("")
    } else {
      newRequest.company_role = { id: data.value, custom_role: "" }
    }
    setRequest(newRequest)
  }

  const onRedirectChangePhoneNumber = () => {
    pushTo(PATH.changePhoneNumber, undefined, { tab: 0 })
  }

  return (
    <div className="flex flex-col">
      <FormInputCard title="Full Name" required>
        <InputDefault
          value={request.fullname}
          onChange={handleChangeInput("fullname")}
          onKeyPress={onKeyPress}
        />
      </FormInputCard>
      <FormInputCard title="Email address" required>
        <InputDefault
          value={userInfo.email}
          onChange={handleChangeInput("email")}
          disabled
        />
      </FormInputCard>
      <FormInputCard title="Phone Number" customClass="relative mb-3">
        <InputPhone
          value={userInfo.phone_number}
          setValue={handleChangePhoneNumber}
          onEnterKeyPress={onKeyPress}
          disabled
          inputStyle={{
            color: "transparent",
          }}
        />
        <p className="font-normal text-[14px] leading-[24px] text-[#111111] absolute top-[35px] left-[63px]">
          {userInfo.phone_number}
        </p>
        <p
          className="font-normal text-[14px] leading-[24px] text-[#0A5AF5] absolute top-[-1px] right-0 cursor-pointer hover:underline"
          onClick={onRedirectChangePhoneNumber}
        >
          Change
        </p>
      </FormInputCard>
      <FormInputCard title="Company">
        <InputDefault
          value={request.company_name}
          onChange={handleChangeInput("company_name")}
          onKeyPress={onKeyPress}
        />
      </FormInputCard>
      {request.company_name ? (
        <React.Fragment>
          <FormInputCard title="Role" required>
            <SelectMulti
              options={[
                ...cloneCompanyRoles.map((role) => {
                  return { label: role.name, value: role.id }
                }),
                { label: "Other", value: "other" },
              ]}
              selectedOption={{
                label: selectedRole?.name,
                value: selectedRole?.id,
              }}
              handleChange={handleChangeCompanyRole}
              isMulti={false}
              noOptionsMessage
              menuPlacement="top"
            ></SelectMulti>
          </FormInputCard>
          {selectedRole?.id === "other" && (
            <FormInputCard title="Role name" required>
              <InputDefault
                value={customRole.value}
                onChange={handleChangeCustomRole}
              />
            </FormInputCard>
          )}
        </React.Fragment>
      ) : null}

      <FormInputCard title="Address">
        <InputDefault
          value={request.address}
          onChange={handleChangeInput("address")}
          onKeyPress={onKeyPress}
        />
      </FormInputCard>
      <div className="flex items-center justify-between mt-3 w-full">
        <Button
          title="Cancel"
          colorBtn="white"
          onClick={handleCancel}
          widthBtn={"48%"}
          disabled={handleDisableButtonCancel()}
        />
        <Button
          title="Save"
          widthBtn={"48%"}
          onClick={onClickButtonSave}
          disabled={handleDisableButtonSave()}
          isDisabledBtn
        />
      </div>
    </div>
  )
}
export default InformationUserCard
