import ModalCustom from "components/ModalCustom"
import { ReactComponent as IconGoogleAuthen } from "assets/images/icons/google-authen.svg"
import Button from "components/Button/Button"

interface Props {
  onSetUpGoogleAuthenticator: () => void
}
export const WarningEnhanceAuthenticationModal = (props: Props) => {
  const { onSetUpGoogleAuthenticator } = props

  const renderHeader = () => {
    return (
      <div className="flex">
        <IconGoogleAuthen />
        <p className="ml-[8px] text-[16px] text-[#222222] font-semibold">
          Enhance security with two-factor authentication
        </p>
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div className="flex flex-col">
        <p className="text-[14px] text-[#222222] leading-[24px]">
          Two-factor authentication provides an extra layer of security by
          requiring a code during login to confirm it’s you. The option of
          receiving authentication code sent to your email is enabled by
          default.
        </p>
        <p className="text-[14px] text-[#222222] leading-[24px] mt-[12px]">
          We recommend enabling the option to get authentication code from
          Google Authenticator for extra protection.
        </p>
        <div className="mt-[32px]">
          <Button
            widthBtn={"100%"}
            title="Set up Google Authenticator"
            onClick={onSetUpGoogleAuthenticator}
          />
        </div>
      </div>
    )
  }
  return (
    <ModalCustom
      label="Enhance security with two-factor authentication"
      widthModal={400}
      styleHeaderModal={{
        height: "auto",
        paddingTop: 12,
        alignItems: "start",
      }}
      renderHeader={renderHeader()}
      bodyChildren={renderBody()}
    />
  )
}
