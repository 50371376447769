import PageLayout from "pages/layout/PageLayout"
import ConversationFilter from "./organisms/ConversationFilter"
import { useEffect, useRef, useState } from "react"
import { getComponentTypesMiddleware } from "pages/project-component-detail/services"
import { getBuildStatusMiddleware } from "pages/project-component/services/api"
import { ConversationsPageContext } from "./contexts/ConversationsPage.context"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
import { STATUS_BUILD } from "components/Status/types"
import { useFilterConversations } from "./conversations.hook"
import {
  getConversationMessagesMiddleware,
  getCustomStatusesMiddleware,
  getListConversationMiddleware,
  getNotificationConversationMiddleware,
  postNotificationConversationMiddleware,
} from "./conversations.api"
import {
  BuildConversationDetail,
  ConversationMessagesDetail,
  emptyConversationMessagesDetail,
  ParamsBuildConversationProps,
  CustomStatus,
  ConversationDetail,
  emptyConversationDetail,
  MENU_TAB_KEY,
  UNREAD_KEY_BY_TAB,
  MENU_TAB_CONVERSATION_BASE_ON_KEY,
} from "./conversations.type"
import {
  cloneDeep,
  compact,
  isUndefined,
  orderBy,
  remove,
  toArray,
} from "lodash"
import {
  useBoolean,
  useSearchParams,
  useNumber,
  useString,
  useWindowSize,
} from "helpers/hooks"
import ConversationMessagesColumn from "./organisms/ConversationMessagesColumn"
import { ReactComponent as IconNoResult } from "assets/images/icons/icon no-result.svg"
import Button from "components/Button/Button"
import iconNoData from "assets/images/icons/icon-no-data-component.svg"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useTippyLayout } from "components/TippyCustomzie"
import { umamiTracking, customLocalStorageHandler } from "helpers/utils"
import { EVENT } from "constants/events"
import { useAppSelector } from "hooks/useApp"
import { configureStore } from "stores/configureStore"
import { setUnreadConversationAction } from "pages/auth/stores/actions"
import ConversationColumn from "./organisms/ConversationColunm"
import { useLocation } from "react-router-dom"
import NoteColumn from "./organisms/NoteColumn"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { Stores, getStoreData } from "services/indexedDB"
import { FileEditorDetail } from "pages/project-component-detail/types"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"

import { acceptedSharingConversationMiddleware } from "pages/projects/services/api"
import * as Sentry from "@sentry/react"
import {
  SkeletonConversationCard,
  SkeletonConversationMessage,
} from "./molecules/SkeletonConversation"

const Conversations = () => {
  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATIONS
  )

  const cookiesConversations = !isUndefined(storageData) ? storageData : {}

  const [listBuilds, setListBuilds] = useState<BuildConversationDetail[]>([])
  const [listConversation, setListConversation] = useState<
    ConversationDetail[]
  >([])
  const [selectedConversation, setSelectedConversation] =
    useState<ConversationDetail>(emptyConversationDetail)
  const showBuildInviteeLayout = useBoolean()
  const showConversationDetailLayout = useBoolean(false)
  const activeIdBuild = useString()
  const [conversationMessages, setConversationMessages] =
    useState<ConversationMessagesDetail>(emptyConversationMessagesDetail)
  const pageHook = useNumber(1)
  const rowsPerPageHook = useNumber(100)
  const isMoreData = useBoolean(true)
  const refBuild = useRef<HTMLDivElement>(null)
  const pageMessageHook = useNumber(1)
  const rowsMessagePerPageHook = useNumber(50)
  const isMoreDataMessage = useBoolean(true)
  const isLoadingMoreDataMessage = useBoolean(false)
  const isChangeScrollMessageBottom = useBoolean(true)
  const isNotificationMessage = useBoolean(false)
  const openNotes = useBoolean(false)
  const location = useLocation<any>()
  const {
    activeMenu,
    cpnStatusOptions,
    cpnTypeOptions,
    inputSearch,
    isFilter,
    search,
    isLoading,
    isSearch,
    objSelectStatus,
    objSelectType,
    isShowFavorite,
    selectionRange,
    endDateCreate,
    startDateCreate,
    isCalendarLastUpdate,
    selectionDate,
    dateLastUpdate,
    isApplyFilter,
    setCpnStatusOptions,
    setCpnTypeOptions,
    setObjSelectStatus,
    setObjSelectType,
    onChangeInputSearch,
    onKeyPress,
    onOpenInputSearch,
    onChangeTab,
    onChangeCheckboxFavorite,
    onChangeDate,
    isCalendarRanges,
    onApplySelectionRanges,
    onCancelSelectionRanges,
    onChangeModalSelectRanges,
    onClearInputSelectionRanges,
    onChangeModalDateLastUpdate,
    onChangeDateLastUpdate,
    onApplySelectionDateLastUpdate,
    onCancelSelectionlastUpdate,
    onClearInputSelectionDateLastUpdate,
    handleChangeFilerAll,
    onApplyFilter,
    onOpenModalFiler,
    onClickClearFilterNoResult,
    onRemoveInputSearch,
    rollbackFilter,
    onChangeCheckboxDelete,
    isShowDeleted,
    isShowUnread,
    isShowArchive,
    isShowDraft,
    onChangeCheckBoxArchive,
    onChangeCheckBoxUnread,
    customStatuses,
    setCustomStatuses,
    setObjSelectCustomStatus,
    objSelectCustomStatus,
    groupOtion,
    onChangeGroupOption,
    onChangeCheckBoxDraft,
  } = useFilterConversations()

  const unreadConversation = useAppSelector((state) => state.unreadConversation)
  const { isMobile } = useWindowSize()
  const { TippyLayout } = useTippyLayout()
  const isLoadingDefault = useBoolean(true)
  const isSavingNote = useBoolean()

  // const tabQuery = searchParams("tab")
  const { searchParams, setSearchParams } = useSearchParams()
  const { tab, conversationId } = searchParams
  const conversationIdQuery = conversationId
  const collapse = useBoolean(false)
  const isWarningEncrypted = useBoolean(false)
  const isConversationUnActive = useBoolean(false)
  const isLoadSkeleton = useBoolean(true)
  const isLoadSkeletonMessage = useBoolean(true)

  useEffect(() => {
    if (tab && conversationIdQuery) {
      activeMenu.setValue(MENU_TAB_CONVERSATION_BASE_ON_KEY[tab])
      getDataDefault(tab)
      return
    }
    getDataDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (isLoadingDefault.value || !isApplyFilter.value) {
      return
    }
    setConversationMessages(emptyConversationMessagesDetail)
    isLoading.setValue(true)
    getListConversation(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplyFilter.value, groupOtion.value])

  useEffect(() => {
    if (isLoadingDefault.value) {
      return
    }
    setConversationMessages(emptyConversationMessagesDetail)
    isLoading.setValue(true)
    getListConversation(true)
    openNotes.setValue(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.value, activeMenu.value])

  useEffect(() => {
    if (
      isSavingNote.value ||
      isLoadingDefault.value ||
      !conversationIdQuery ||
      (selectedConversation.id === conversationIdQuery && !isMobile) ||
      (selectedConversation.id === conversationIdQuery &&
        isMobile &&
        !showConversationDetailLayout.value)
    ) {
      return
    }
    showConversationDetailLayout.setValue(true)
    const newListConversation = cloneDeep(listConversation)
    const index = newListConversation.findIndex(
      (el) => el.id === conversationIdQuery
    )
    if (index <= -1) {
      setSearchParams({ idConversation: undefined })
      return
    }
    changeDataObjIsReadConversation(newListConversation, index)
    isLoading.setValue(true)
    if (
      newListConversation[index].project_build_status !== STATUS_BUILD.DELETED
    ) {
      getConversationMessages(newListConversation[index].id, true, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationIdQuery, showConversationDetailLayout.value])

  const changeDataFilter = () => {
    const newTypes = compact(toArray(objSelectType))
    const newStatus = compact(toArray(objSelectStatus))
    const newCustomStatus = compact(toArray(objSelectCustomStatus))

    let newFilter: any = {}
    if (isShowFavorite.value) {
      newFilter.is_favorite = isShowFavorite.value
    }
    if (isShowUnread.value) {
      newFilter.is_unread = isShowUnread.value
    }
    if (isShowArchive) {
      newFilter.is_archive = isShowArchive.value
    }
    if (newTypes.length) {
      newFilter.types = newTypes
    }
    if (newStatus.length) {
      newFilter.statuses = newStatus
    }
    if (newCustomStatus) {
      newFilter.custom_statuses = newCustomStatus
    }
    if (startDateCreate) {
      newFilter.created_from = startDateCreate
    }
    if (endDateCreate) {
      newFilter.created_to = endDateCreate
    }
    if (dateLastUpdate) {
      newFilter.updated_from = dateLastUpdate
      newFilter.updated_to = dateLastUpdate
    }
    if (search.value) {
      newFilter.name = search.value
    }

    if (isShowDeleted.value) {
      newFilter.is_deleted = isShowDeleted.value ? 1 : 0
      delete newFilter.is_unread
      delete newFilter.is_archive
      delete newFilter.is_favorite
      delete newFilter.name
      delete newFilter.statuses
      delete newFilter.types
    }
    if (groupOtion.value) {
      newFilter.sorted_by = groupOtion.value
    }
    const urlConversation =
      MENU_TAB_KEY[activeMenu.value] || MENU_TAB_KEY["To me"]

    if (isLoadingDefault.value && conversationIdQuery) {
      newFilter.conversation_id = conversationIdQuery
      // collapse.setValue(true)
    }
    return {
      newFilter,
      urlConversation,
    }
  }
  const changeProjectParams = (defaultPage?: number) => {
    const { newFilter, urlConversation } = changeDataFilter()

    const newParams: ParamsBuildConversationProps = {
      page: defaultPage ?? pageHook.value + 1,
      pageSize: rowsPerPageHook.value,
      filter: newFilter,
    }
    return {
      params: newParams,
      urlConversation,
    }
  }
  const getListConversation = async (loading = false, tab?: string) => {
    try {
      isLoadSkeleton.setValue(true)
      isLoadSkeletonMessage.setValue(true)
      const { params, urlConversation } = changeProjectParams(1)
      let conversations = await getListConversationMiddleware(
        tab ? tab : urlConversation,
        params
      )
      const draftAttachmentDB = await getStoreData<{
        id: string
        listFile: FileEditorDetail[]
        text: string
      }>(Stores.Conversations)
      conversations = conversations.map((item) => {
        const draftData = draftAttachmentDB.find(
          (attachment) => item.id === attachment.id
        )
        return {
          ...item,
          draftMessage:
            draftData?.text === "<p><br></p>" ? "" : draftData?.text,
          draftAttachments: draftData?.listFile ? draftData.listFile.length : 0,
        }
      }) as ConversationDetail[]

      if (isShowDraft.value) {
        conversations = conversations.filter(
          (item) => item.draftMessage || item.draftAttachments
        )
      }

      const conversationId =
        conversationIdQuery ||
        location.state?.conversationId ||
        cookiesConversations?.conversationId
      setListConversation(conversations)
      if (!conversations.length) {
        isLoadSkeletonMessage.setValue(false)
      }
      isLoadSkeleton.setValue(false)
      pageHook.setValue(1)
      const found =
        conversations.find((item) => item.id === conversationId) ||
        conversations[0]
      if (found) encryptionController().setCurrentProjectKey(found.project_id)
      ///on mobile flow
      if (isMobile) {
        let selectedIndex = -1
        //flow for redirect from build -> convo
        if (
          isLoadingDefault &&
          (conversationIdQuery || location.state?.conversationId)
        ) {
          const convoId = conversationIdQuery || location.state?.conversationId
          selectedIndex = conversations.findIndex((el) => el.id === convoId)
        }

        if (selectedIndex >= 0 && isLoadingDefault.value) {
          changeDataObjIsReadConversation(
            conversations,
            selectedIndex > -1 ? selectedIndex : 0
          )
          await getConversationMessages(
            selectedIndex > -1 ? conversationId : conversations[0].id,
            true,
            true
          )
          setSearchParams({
            conversationId:
              selectedIndex > -1 ? conversationId : conversations[0].id,
          })
          showConversationDetailLayout.setValue(true)
        } else {
          setSelectedConversation(emptyConversationDetail)
          setConversationMessages(emptyConversationMessagesDetail)
          setSearchParams({
            conversationId: undefined,
          })
        }
        isApplyFilter.setValue(false)
        return
      }
      // on desktop flow
      isMoreData.setValue(
        conversations.length < rowsPerPageHook.value ? false : true
      )
      if (conversations.length) {
        // get conversation messages
        const index = conversations.findIndex((el) => el.id === conversationId)
        changeDataObjIsReadConversation(conversations, index > -1 ? index : 0)
        await getConversationMessages(
          index > -1 ? conversationId : conversations[0].id,
          true
        )
        setSearchParams({
          conversationId: index > -1 ? conversationId : conversations[0].id,
        })
      } else {
        setSelectedConversation(emptyConversationDetail)
        setConversationMessages(emptyConversationMessagesDetail)
        setListConversation([])
        setSearchParams({
          conversationId: undefined,
        })
      }
      isApplyFilter.setValue(false)
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      console.log(error)
      setListBuilds([])
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const changeDataObjIsReadConversation = (
    originConversationList: ConversationDetail[],
    index: number
  ) => {
    const oldSelectedConvoStatus = originConversationList[index].is_read
    // change selected convo to read
    originConversationList[index].is_read = true
    const newUnreadConversations = originConversationList.filter(
      (conversation) => !conversation.is_read
    ).length

    const key = UNREAD_KEY_BY_TAB[activeMenu.value]

    if (newUnreadConversations >= unreadConversation[key]) {
      configureStore.dispatch(
        setUnreadConversationAction({
          [key]: newUnreadConversations,
        })
      )
    } else {
      configureStore.dispatch(
        setUnreadConversationAction({
          [key]: oldSelectedConvoStatus
            ? unreadConversation[key]
            : unreadConversation[key] - 1,
        })
      )
    }
    setSelectedConversation(originConversationList[index])
    setListConversation(originConversationList)
  }

  const getDataDefault = async (tab?: string) => {
    isLoading.setValue(true)
    try {
      await getTypeComponent()
      await getStatusComponent()
      await getCustomStatus()
      if (isLoadingDefault.value) {
        await getListConversation(false, tab)
      }
      isLoadingDefault.setValue(false)
    } catch (error) {
      setCpnTypeOptions([])
      setCpnStatusOptions([])
      setCustomStatuses([])
    } finally {
      isLoading.setValue(false)
    }
  }
  const getTypeComponent = async () => {
    const dataCpnTypeRes = await getComponentTypesMiddleware()
    setCpnTypeOptions(dataCpnTypeRes)
  }
  const getStatusComponent = async () => {
    const dataCpnStatusRes = await getBuildStatusMiddleware()
    setCpnStatusOptions(dataCpnStatusRes)
  }
  const getCustomStatus = async () => {
    const data = await getCustomStatusesMiddleware()
    setCustomStatuses(data.statuses)
  }
  const updateDataWhenChangeFavorite = (dataRes: ConversationDetail) => {
    const newListConversation = cloneDeep(listConversation)
    const index = newListConversation.findIndex((el) => el.id === dataRes.id)
    if (index < 0) {
      return
    }
    if (isShowFavorite.value) {
      if (dataRes.is_favorite) {
        remove(newListConversation, (el) => el.id === dataRes.id)
        if (dataRes.id === selectedConversation.id) {
          setConversationMessages(emptyConversationMessagesDetail)
          setSelectedConversation(emptyConversationDetail)
        }
      }
    } else {
      dataRes.is_favorite = !dataRes.is_favorite
      newListConversation[index] = dataRes
    }
    setListConversation(newListConversation)
  }
  const updateDataWhenArchive = (dataRes: ConversationDetail) => {
    const newListConversation = cloneDeep(listConversation)
    const index = newListConversation.findIndex((el) => el.id === dataRes.id)
    if (!isShowArchive.value && dataRes.is_archived_by_me) {
      remove(newListConversation, (el) => el.id === dataRes.id)
      if (dataRes.id === selectedConversation.id) {
        setConversationMessages(emptyConversationMessagesDetail)
        setSelectedConversation(emptyConversationDetail)
      }
    } else {
      newListConversation[index] = dataRes
      if (dataRes.id === selectedConversation.id) {
        setSelectedConversation(newListConversation[index])
      }
    }
    setListConversation(newListConversation)
  }
  const updateDataWhenChangeCustomStatus = (
    type: "edit" | "delete",
    customStatus: CustomStatus
  ) => {
    const newListConversation = cloneDeep(listConversation)
    newListConversation.forEach((conversation) => {
      if (conversation.custom_status?.id === customStatus.id) {
        conversation.custom_status = undefined
        if (type === "edit") {
          conversation.custom_status = customStatus
        }
      }
    })
    setListConversation(newListConversation)
  }
  const updateDataWhenChangeConvoCustomStatus = (
    conversationId: string,
    customStatus?: CustomStatus
  ) => {
    const newListConversation = cloneDeep(listConversation)
    const index = newListConversation.findIndex(
      (el) => el.id === conversationId
    )
    if (index < 0) {
      return
    }
    if (newListConversation[index].custom_status?.id === customStatus?.id) {
      return
    }
    newListConversation[index].custom_status = customStatus
    setListConversation(newListConversation)
  }
  const onClickConversation = (newItem: ConversationDetail) => () => {
    if (newItem.id === conversationIdQuery) {
      if (isMobile) {
        isLoadSkeletonMessage.setValue(true)
        showConversationDetailLayout.setValue(true)
      }
      return
    }
    setSearchParams({ conversationId: newItem.id })
    isLoadSkeletonMessage.setValue(true)
    encryptionController().setCurrentProjectKey(newItem.project_id)
  }
  const updateWhenAddMessager = async (newMessage: string) => {
    const newConversationList = cloneDeep(listConversation)
    const index = newConversationList.findIndex(
      (el) => el.id === selectedConversation.id
    )
    if (index > -1) {
      newConversationList[index].message = {
        content: newMessage,
        created_at: new Date().toISOString(),
        owner: "",
      }
      newConversationList[index].draftAttachments = 0
      newConversationList[index].draftMessage = ""
      newConversationList[index].updated_at = new Date().toISOString()
      selectedConversation.updated_at = new Date().toISOString()
      await getConversationMessages(selectedConversation.id, true, true)
      setSelectedConversation({ ...selectedConversation })
      setListConversation(newConversationList)
    }
  }
  const getConversationMessages = async (
    conversationId: string,
    setConversation = false,
    loading = false
  ) => {
    const params = {
      page: 1,
      pageSize: rowsMessagePerPageHook.value,
    }
    const dataRes = await getConversationMessagesMiddleware(
      conversationId,
      params
    )
    const isWarningEncrypted = setWarningEncrypted(
      dataRes.conversation_code || "",
      conversationId
    )

    if (!dataRes.invitee.accepted && !dataRes.is_owner && !isWarningEncrypted) {
      await acceptedSharingConversationMiddleware(conversationId)
    }
    await getNotificationConversation(conversationId)
    isConversationUnActive.setValue(dataRes.is_conversation_un_active || false)

    setConversationMessages({
      ...dataRes,
      components: dataRes.components,
      messages: orderBy(dataRes.messages, "created_at", "asc"),
    })
    isLoadSkeletonMessage.setValue(false)
    isMoreDataMessage.setValue(
      dataRes.components.length < rowsMessagePerPageHook.value ? false : true
    )
    pageMessageHook.setValue(1)
    isChangeScrollMessageBottom.setValue(true)
    if (setConversation) {
      handleUpdateConversationCookies(conversationId)
    }
    if (loading) {
      isLoading.setValue(false)
    }
  }

  const setWarningEncrypted = (
    conversationCode: string,
    conversationId: string
  ) => {
    const conversationEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
    )
    if (
      !conversationEncryptionKeys[conversationId] ||
      conversationEncryptionKeys[conversationId].length !== 64
    ) {
      const messageSentry = `This conversation ID: ${conversationCode} is encrypted: ${conversationId}`
      Sentry.captureMessage(messageSentry, {
        level: "warning",
      })
      isWarningEncrypted.setValue(true)
      return true
    }
    isWarningEncrypted.setValue(false)
    return false
  }
  const getNotificationConversation = async (conversationId: string) => {
    const dataRes = await getNotificationConversationMiddleware(conversationId)
    isNotificationMessage.setValue(dataRes.is_notified)
  }

  const onScrollMessage = async () => {
    if (
      !isMoreDataMessage.value ||
      !conversationMessages.messages.length ||
      isLoadingMoreDataMessage.value
    ) {
      return
    }
    isChangeScrollMessageBottom.setValue(false)
    isLoadingMoreDataMessage.setValue(true)
    const params = {
      page: pageMessageHook.value + 1,
      pageSize: rowsMessagePerPageHook.value,
    }
    const dataRes = await getConversationMessagesMiddleware(
      selectedConversation.id,
      params
    )
    const newConversationMessage = cloneDeep(conversationMessages)
    newConversationMessage.messages = orderBy(
      dataRes.messages,
      "created_at",
      "asc"
    ).concat(conversationMessages.messages)
    setConversationMessages(newConversationMessage)
    pageMessageHook.setValue(pageMessageHook.value + 1)
    isMoreDataMessage.setValue(
      dataRes.messages.length < rowsMessagePerPageHook.value ? false : true
    )
    isLoadingMoreDataMessage.setValue(false)
  }
  const checkShowButtonClearFilter = () => {
    if (isFilter.value) {
      return false
    }
    if (
      isShowFavorite.value ||
      isShowDeleted.value ||
      isShowUnread.value ||
      isShowArchive.value ||
      isShowDraft.value ||
      compact(toArray(objSelectStatus)).length ||
      compact(toArray(objSelectType)).length ||
      compact(toArray(objSelectCustomStatus)).length ||
      startDateCreate ||
      endDateCreate ||
      dateLastUpdate
    ) {
      return true
    }
    return false
  }
  const handleUpdateConversationCookies = (conversationId: string) => {
    const newCookies = !isUndefined(storageData) ? cloneDeep(storageData) : {}
    newCookies.conversationId = conversationId
    handleSetLocalStorage(newCookies)
  }
  const handleScrollBuild = async () => {
    const offsetHeight = refBuild.current?.offsetHeight
    const scrollHeight = refBuild.current?.scrollHeight
    const scrollTop = refBuild.current?.scrollTop
    if (Number(scrollTop) + Number(offsetHeight) > Number(scrollHeight) - 10) {
      if (!isMoreData.value || !listConversation.length || isLoading.value) {
        return
      }
      isLoading.setValue(true)
      try {
        const { params, urlConversation } = changeProjectParams()

        const dataRes = await getListConversationMiddleware(
          urlConversation,
          params
        )
        isMoreData.setValue(
          dataRes.length < rowsPerPageHook.value ? false : true
        )
        pageHook.setValue(pageHook.value + 1)
        const newData = cloneDeep(listConversation).concat(dataRes)
        setListConversation(newData)
        // changeDataObjIsReadBuild(newData, listInvitees, activeIdBuild.value)
        isLoading.setValue(false)
      } catch (error) {
        isLoading.setValue(false)
      }
    }
  }
  const onChangeNotification = (originNotification: boolean) => () => {
    if (!selectedConversation.id && selectedConversation.is_archived_by_owner) {
      return
    }
    if (isNotificationMessage.value) {
      umamiTracking(EVENT.CONVERSATION.DISABLE)
    } else {
      umamiTracking(EVENT.CONVERSATION.ENABLE)
    }
    isNotificationMessage.setValue(!originNotification)
    postNotificationConversationMiddleware(
      selectedConversation.id,
      isNotificationMessage.value ? "off" : "on",
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.ERROR) {
          isNotificationMessage.setValue(originNotification)
        }
      }
    )
  }
  const updateCurrentConvoInConvoList = (
    conversationId: string,
    data: Partial<ConversationDetail>
  ) => {
    const newConversationList = cloneDeep(listConversation)
    const currentConvoIndex = newConversationList.findIndex(
      (item) => item.id === conversationId
    )
    if (currentConvoIndex > -1) {
      const newData = {
        ...newConversationList[currentConvoIndex],
        ...data,
      }
      newConversationList[currentConvoIndex] = newData
      setListConversation(newConversationList)
    }
  }
  const renderConversationDetail = () => {
    if (isLoadSkeletonMessage.value) {
      return <SkeletonConversationMessage isMobile={isMobile} />
    }
    if (selectedConversation.project_build_status === STATUS_BUILD.DELETED) {
      return (
        <div className="flex flex-1 flex-col h-full overflow-hidden">
          <div
            className="flex md:hidden p-6"
            onClick={() => {
              showConversationDetailLayout.setValue(false)
            }}
          >
            <ArrowLeftIcon />
          </div>
          <div className="bg-white flex flex-1 items-center justify-center">
            <div className="m-6 flex justify-center flex-col">
              <img src={iconNoData} alt="icon" />
              <p className="text-center font-semibold text-[#111111] text-[16px] leading-[26px] mt-6">
                The conversation is no longer exists.
                <br />
                Please contact project owner.
              </p>
            </div>
          </div>
        </div>
      )
    }

    if (selectedConversation.id) {
      return (
        <ConversationMessagesColumn
          conversationInfo={selectedConversation}
          closeLayout={showConversationDetailLayout}
          conversationMessages={conversationMessages}
          activeIdConversation={selectedConversation.id}
          activeIdBuild={activeIdBuild.value}
          updateWhenAddMessager={updateWhenAddMessager}
          activeMenu={activeMenu.value}
          onScrollMessage={onScrollMessage}
          isChangeScrollMessageBottom={isChangeScrollMessageBottom.value}
          isNotificationMessage={isNotificationMessage.value}
          onChangeNotification={onChangeNotification}
          isConversationUnActive={isConversationUnActive.value}
          getConversationMessages={getConversationMessages}
          collapse={collapse}
          isWarningEncrypted={isWarningEncrypted.value}
        />
      )
    }

    return (
      <div className="flex flex-1 flex-col h-full overflow-hidden">
        <div
          className="flex md:hidden p-6"
          onClick={() => {
            showConversationDetailLayout.setValue(false)
          }}
        >
          <ArrowLeftIcon />
        </div>
        <div className="bg-white flex flex-1 items-center justify-center">
          <div className="m-6">
            <img src={iconNoData} alt="icon" />
            <p className="font-semibold text-[#111111] text-[16px] leading-[26px] mt-6">
              No conversation selected!
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderConversations = () => {
    return (
      <ConversationColumn
        listConversation={listConversation}
        updateDataWhenChangeFavorite={updateDataWhenChangeFavorite}
        updateDataWhenArchive={updateDataWhenArchive}
        activeMenu={activeMenu.value}
        onClickConversation={onClickConversation}
        activeConversationId={selectedConversation.id}
        showLayout={showBuildInviteeLayout}
        updateDataWhenChangeCustomStatus={updateDataWhenChangeCustomStatus}
        updateDataWhenChangeConvoCustomStatus={
          updateDataWhenChangeConvoCustomStatus
        }
        openNotes={openNotes}
        groupOtion={groupOtion.value}
      />
    )
  }

  const renderListConversations = () => {
    if (isLoadSkeleton.value) {
      return <SkeletonConversationCard />
    }
    if (listConversation.length) {
      return (
        <div
          className="flex flex-col h-full overflow-auto"
          ref={refBuild}
          onScroll={handleScrollBuild}
        >
          {isMobile ? (
            <TippyLayout
              type="content-screen"
              visible={showConversationDetailLayout.value || openNotes.value}
              mainContent={
                openNotes.value
                  ? renderNotesEditor()
                  : showConversationDetailLayout.value
                  ? renderConversationDetail()
                  : null
              }
              containerClass="build-conversation"
            >
              {renderConversations()}
            </TippyLayout>
          ) : (
            renderConversations()
          )}
        </div>
      )
    }

    return (
      <div className="h-full flex flex-col items-center justify-center">
        <IconNoResult style={{ height: 190 }} />
        <p
          className="font-semibold text-16 leading-26  mt-3"
          style={{
            color: "#111111",
          }}
        >
          {checkShowButtonClearFilter()
            ? "No result found!"
            : "No conversations found!"}
        </p>
        {checkShowButtonClearFilter() ? (
          <Button
            title="Clear filter"
            colorBtn="white"
            onClick={onClickClearFilterNoResult}
            sizeBtn="small"
            styleButton={{
              marginTop: 12,
            }}
          />
        ) : null}
      </div>
    )
  }
  const renderNotesEditor = () => {
    if (!selectedConversation.id) {
      return
    }
    return (
      <NoteColumn
        key={selectedConversation.id}
        activeConversationId={selectedConversation.id}
        closeNote={() => {
          showConversationDetailLayout.setValue(false)
          openNotes.setValue(false)
        }}
        isSavingNote={isSavingNote}
      />
    )
  }
  return (
    <PageLayout heightHeader={0} minWidth="min-w-[1280px]">
      <ConversationsPageContext.Provider
        value={{
          cpnStatusOptions,
          cpnTypeOptions,
          objSelectType,
          objSelectStatus,
          setObjSelectType,
          setObjSelectStatus,
          customStatuses,
          setCustomStatuses,
          objSelectCustomStatus,
          setObjSelectCustomStatus,
          updateCurrentConvoInConvoList,
        }}
      >
        <div className="flex h-full">
          <div
            className={`w-full flex transition-all duration-500
            ${
              collapse.value
                ? "md:min-w-0 md:w-0 md:translate-x-[-100%]"
                : "md:min-w-[342px] md:w-[342px]"
            }
            `}
          >
            <div
              className={`flex flex-col flex-1 pl-6 ${
                collapse.value ? "md:pl-0" : ""
              } pt-6 overflow-hidden w-full md:w-[50%]`}
            >
              <ConversationFilter
                isSearch={isSearch.value}
                inputSearch={inputSearch.value}
                activeMenu={activeMenu.value}
                onChangeInputSearch={onChangeInputSearch}
                onKeyPress={onKeyPress}
                isFilter={isFilter.value}
                setIsFilter={isFilter.setValue}
                onOpenInputSearch={onOpenInputSearch}
                onChangeTab={(newTab: string) => () => {
                  if (isLoadSkeleton.value) {
                    return
                  }
                  onChangeTab(newTab)()
                }}
                isShowFavorite={isShowFavorite.value}
                onChangeCheckboxFavorite={onChangeCheckboxFavorite}
                onChangeCheckboxDelete={onChangeCheckboxDelete}
                isShowDeleted={isShowDeleted.value}
                isShowDraft={isShowDraft.value}
                onChangeCheckBoxDraft={onChangeCheckBoxDraft}
                selectionRange={selectionRange}
                onChangeDate={onChangeDate}
                isCalendarRanges={isCalendarRanges.value}
                onApplySelectionRanges={onApplySelectionRanges}
                onCancelSelectionRanges={onCancelSelectionRanges}
                onChangeModalSelectRanges={onChangeModalSelectRanges}
                endDateCreate={endDateCreate}
                startDateCreate={startDateCreate}
                onClearInputSelectionRanges={onClearInputSelectionRanges}
                isCalendarLastUpdate={isCalendarLastUpdate.value}
                selectionDate={selectionDate}
                onChangeModalDateLastUpdate={onChangeModalDateLastUpdate}
                onChangeDateLastUpdate={onChangeDateLastUpdate}
                onApplySelectionDateLastUpdate={onApplySelectionDateLastUpdate}
                onCancelSelectionlastUpdate={onCancelSelectionlastUpdate}
                onClearInputSelectionDateLastUpdate={
                  onClearInputSelectionDateLastUpdate
                }
                dateLastUpdate={dateLastUpdate}
                handleChangeFilerAll={handleChangeFilerAll}
                onApplyFilter={onApplyFilter}
                onOpenModalFiler={onOpenModalFiler}
                isActiveIconFilter={checkShowButtonClearFilter()}
                onRemoveInputSearch={onRemoveInputSearch}
                rollbackFilter={rollbackFilter}
                onChangeCheckBoxUnread={onChangeCheckBoxUnread}
                isShowUnread={isShowUnread.value}
                isShowArchive={isShowArchive.value}
                onChangeCheckBoxArchive={onChangeCheckBoxArchive}
                unreadByFilter={
                  listBuilds.filter((item) => !item.is_read).length
                }
                onChangeGroupOption={onChangeGroupOption}
                groupOption={groupOtion.value}
              />
              {renderListConversations()}
            </div>
          </div>
          <div className="hidden md:flex w-full min-w-[50%]">
            {openNotes.value ? renderNotesEditor() : renderConversationDetail()}
          </div>
        </div>
      </ConversationsPageContext.Provider>
    </PageLayout>
  )
}
export default Conversations
