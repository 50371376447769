import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import {
  createEncryptionKeysMiddleware,
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  IUseDefaultValueProps,
  useBoolean,
  useString,
  useWindowSize,
} from "helpers/hooks"
import { getBuildStatusMiddleware } from "../../project-component/services/api"
import Header from "../../project-build/organisms/ProjectBuildHeader"
// import Header from " /organisms/ProjectBuildHeader"
import InfoDraftBuildCard from "../../project-build/organisms/InfoDraftBuildCard"
import {
  MENU_TAB_BUILD,
  ProjectBuildAdditionalDetail,
  ProjectBuildExtraDetail,
  ProjectBuildInvitessDetail,
  ProjectComponentBuildDetail,
} from "../../project-build/project-build.type"
import {
  deleteProjectBuildInviteeAddComponentMiddleware,
  getProjectBuildAdditionalInfoMiddleware,
  getProjectBuildInviteeMiddleware,
  postProjectBuildComponentSyncMiddleware,
  postProjectBuildSendInviteeMiddleware,
  putProjectBuildMiddleware,
} from "../../project-build/api.services"
import { PermissionProjectBuildPage } from "../../project-build/contexts/PermissionProjectBuildPage.context"
import ComponentBuildSyncCard from "../../project-build/organisms/ComponentBuildSyncCard"
import { SelectedDefaultProp } from "components/Select/types"
import MenuTabBuildCard from "../../project-build/molecules/MenuTabBuildCard"
import AdditionalInfoCard from "../../project-build/organisms/AdditionalInfoCard"
import { cloneDeep, includes, isUndefined, remove } from "lodash"
import { STATUS_BUILD } from "components/Status/types"
import InviteesInfoCard from "../../project-build/organisms/InviteesInfoCard"
import { checkPermissionPage, customLocalStorageHandler } from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { KeyEncryptionType, STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
import { useAddComponentInInvitee } from "hooks/useAddComponentInInvitee"
import { FormBackup } from "components/FormBackup/FormBackup"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import forge from "node-forge"
import { useShareInvitation } from "hooks/useShareInvitation"
import { getWorkerLocalStorageData } from "workers/utils"
import { EncryptedDataWarning } from "components/EncryptedDataWarning"
import { encryptionHelper } from "helpers/encryption"
import { generateDataKeyPairMiddleware } from "pages/auth/services/api"
import { AddComponentInBuildDetail } from "../molecules/AddComponentInBuildDetail"
import { updateUnlistedComponentInBuildMiddleware } from "../builds.api"
import { configureStore } from "stores/configureStore"
import { closeModal, openModal } from "reducers/modal"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import Button from "components/Button/Button"
import ButtonHasIcon from "components/Button/ButtonHasIcon"

interface Props {
  projectBuild: ProjectComponentBuildDetail
  isArchived: boolean | undefined
  showBuildDetailLayout: IUseDefaultValueProps
  onUpdateDataEditNameBuild: (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => void
  updatedAtBuild: string
  updateProjectBuild: () => Promise<ProjectComponentBuildDetail>
  isLoadingSkeletonBuild: boolean
}

const BuildDetail = (props: Props) => {
  const params = useParams<{
    status: string
    idProject: string
    idProjectBuild: string
  }>()
  const {
    showBuildDetailLayout,
    isArchived,
    onUpdateDataEditNameBuild,
    projectBuild,
    updatedAtBuild,
    isLoadingSkeletonBuild,
    updateProjectBuild,
  } = props
  const {
    syncNewVersionComponentToInvitee,
    currentMessageBackup,
    errorMessageBackup,
    isLoadingBackupInvitee,
    messageBackups,
  } = useAddComponentInInvitee()

  const { acceptedSharingProject } = useShareInvitation()

  const idProjectParams = params?.idProject || ""
  const idProjectBuildParams = params?.idProjectBuild || ""
  const idStatusBuildParams = params?.status || ""
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const isLoading = useBoolean()
  // const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
  //   emptyProjectBuildDetail
  // )
  const archiveProject = useBoolean(true)
  const viewOnlyShare = useBoolean(true)
  const archiveBuild = useBoolean(false)
  const [listStatus, setListStatus] = useState<SelectedDefaultProp[]>([])
  const { isMobile } = useWindowSize()
  const activeTab = useString(
    isMobile ? MENU_TAB_BUILD.COMPONENT : MENU_TAB_BUILD.ADDITIONAL
  )
  const [additionals, setAdditionals] = useState<
    ProjectBuildAdditionalDetail[]
  >([])
  const [extras, setExtras] = useState<ProjectBuildExtraDetail[]>([])
  const [invitess, setInvitess] = useState<ProjectBuildInvitessDetail[]>([])
  const isSyncComponent = useBoolean(false)
  const projectBuildComponentSyncId = useString("")
  // const sizeWindow = useWindowSize()
  const isDeleted = projectBuild.status === STATUS_BUILD.DELETED
  const { projectEncryptionKeys, userId } = getWorkerLocalStorageData()
  const openUpdateComponent = useBoolean(false)
  const projectBuildTabLocal = customLocalStorageHandler(
    NAME_LOCALSTORAGE.PROJECT_BUILD_TAB
  )
  const activeTabLocal = projectBuildTabLocal.storageData?.[projectBuild.id]
  const [selectAddComponent, setSelectAddComponent] = useState<
    Record<string, string>
  >({})

  useEffect(() => {
    if (!idProjectParams) {
      // pushTo(PATH.projects)
      return
    }
    getProjectDetail()
    return () => {
      openUpdateComponent.setValue(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProjectParams, idProjectBuildParams, idStatusBuildParams])

  useEffect(() => {
    if (!activeTabLocal) {
      activeTab.setValue(
        isMobile ? MENU_TAB_BUILD.COMPONENT : MENU_TAB_BUILD.ADDITIONAL
      )
      return
    }
    activeTab.setValue(activeTabLocal)
  }, [activeTabLocal])

  const getProjectDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataProjectRoleRes = await getProjectRoleMiddleware(idProjectParams)
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        idProjectParams
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      viewOnlyShare.setValue(
        dataProjectRoleRes.role === STATUS_PROJECT_ROLE.VIEWER
      )
      setProjectDetail(dataProjectDetailRes)
      await acceptedSharingProject(dataProjectDetailRes)
      await getProjectBuild(idProjectBuildParams)
      openUpdateComponent.setValue(false)
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }
  const getProjectBuild = async (idBuild: string, loading = false) => {
    try {
      archiveBuild.setValue(Boolean(projectBuild.is_archived))
      const dataBuildStatusRes = await getBuildStatusMiddleware()
      setListStatus([
        ...dataBuildStatusRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        })),
      ])

      if (projectBuild.status !== STATUS_BUILD.COMMITTED) {
        await getInvitees(idBuild)
      }
      await getAdditional(idBuild)
      // await getExtra(idBuild)

      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
      pushTo(PATH.projectComponent, {
        idProject: idProjectParams,
      })
    }
  }
  const getAdditional = async (idBuild: string, loading = false) => {
    const dataAdditionalRes = await getProjectBuildAdditionalInfoMiddleware(
      idBuild
    )
    setAdditionals(dataAdditionalRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }

  const getInvitees = async (
    idBuild: string,
    loading = false,
    inviteeId?: string,
    newAddedCC?: string[]
  ) => {
    const dataInvitees = await getProjectBuildInviteeMiddleware(idBuild)
    setInvitess(dataInvitees)
    if (loading) {
      isLoading.setValue(false)
    }
    if (inviteeId && !isUndefined(newAddedCC) && newAddedCC.length) {
      const inviteeDefault = dataInvitees.find((el) => el.id === inviteeId)
      if (inviteeDefault) {
        automaticallySendNewCC(inviteeDefault, newAddedCC, inviteeId)
      }
    }
  }

  const automaticallySendNewCC = async (
    inviteeDefault: ProjectBuildInvitessDetail,
    newAddedCC: string[],
    inviteeId: string
  ) => {
    const publicKeyCCs =
      !isUndefined(inviteeDefault.publicKeyCCs) &&
      inviteeDefault.publicKeyCCs.length
        ? inviteeDefault.publicKeyCCs.filter((el) =>
            includes(newAddedCC, el.email)
          )
        : []
    if (publicKeyCCs.length) {
      const generateDataKeyPairUsers: {
        publicKey: string
        encryptedPrivateKey: string
        userId: string
      }[] = []

      const newPublicKeyCCs: {
        publicKey: string
        userId: string
        email: string
      }[] = []

      for (const cc of publicKeyCCs) {
        let originPublicKey = cc.publicKey
        if (!originPublicKey) {
          const dataKeyPairCC = await generateDataKeyPairMiddleware()
          originPublicKey = dataKeyPairCC.publicKey
          generateDataKeyPairUsers.push({
            publicKey: dataKeyPairCC.publicKey,
            encryptedPrivateKey: dataKeyPairCC.encryptedPrivateKey,
            userId: cc.user_id,
          })
        }
        newPublicKeyCCs.push({
          publicKey: originPublicKey,
          userId: cc.user_id,
          email: cc.email,
        })
      }

      postProjectBuildSendInviteeMiddleware(
        inviteeId,
        "Send",
        async (type: STATUS_RESPONSE, _messenger: string) => {
          if (type === STATUS_RESPONSE.SUCCESS && newPublicKeyCCs.length) {
            const conversationEncryptionKeys = JSON.parse(
              localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) ||
                "{}"
            )
            const buildAdditionalInfoEncryptionKeys = JSON.parse(
              localStorage.getItem(
                EncryptionKeys.buildAdditionalInfoEncryptionKeys
              ) || "{}"
            )
            const projectKeys = JSON.parse(
              localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
            )
            const projectKey = projectKeys[idProjectParams]

            const components = inviteeDefault.project_build_components.filter(
              (el) =>
                el.componentShareKey && el.project_component_history_share_id
            )
            const keys: {
              encrypted_key: string
              model: string
              relation_id: string
              type: KeyEncryptionType
              user_id: string
            }[] = []

            const conversationKeyItem =
              conversationEncryptionKeys[inviteeDefault.conversation_id]
            const buildAdEncryptionKeyItem =
              buildAdditionalInfoEncryptionKeys[inviteeDefault.project_build_id]
            if (conversationKeyItem && buildAdEncryptionKeyItem && projectKey) {
              for (const keyCC of newPublicKeyCCs) {
                const publicKeyCCFrom = forge.pki.publicKeyFromPem(
                  keyCC.publicKey
                )
                const buildCCEncryptionKey = publicKeyCCFrom.encrypt(
                  buildAdEncryptionKeyItem as string,
                  "RSA-OAEP"
                )
                keys.push({
                  encrypted_key: buildCCEncryptionKey,
                  model: "build_additional_infos",
                  relation_id: inviteeDefault.project_build_id,
                  type: KeyEncryptionType.ModuleKey,
                  user_id: keyCC.userId,
                })
                const conversationCCEncryptionKey = publicKeyCCFrom.encrypt(
                  conversationKeyItem as string,
                  "RSA-OAEP"
                )
                keys.push({
                  encrypted_key: conversationCCEncryptionKey,
                  model: "conversations",
                  relation_id: inviteeDefault.conversation_id,
                  type: KeyEncryptionType.ModuleKey,
                  user_id: keyCC.userId,
                })
                if (components.length) {
                  for (const component of components) {
                    const sharedKey = await encryptionController().decrypt(
                      component.componentShareKey,
                      {
                        dataType: "string",
                        type: "component-shared",
                        encryptionKey: projectKey,
                      }
                    )

                    const componentCCEncryptionKey = publicKeyCCFrom.encrypt(
                      sharedKey,
                      "RSA-OAEP"
                    )
                    keys.push({
                      encrypted_key: componentCCEncryptionKey,
                      model: "project_build_components",
                      relation_id:
                        component.project_component_history_share_id || "",
                      type: KeyEncryptionType.ComponentShareKey,
                      user_id: keyCC.userId,
                    })
                  }
                }
              }
            }
            toast(
              <LabelNotificationPage
                messenger={`Send invite:${newPublicKeyCCs
                  .map((el) => el.email)
                  .join(", ")}  successfully!`}
                type={type}
              />
            )

            if (keys.length) {
              await createEncryptionKeysMiddleware(keys)
              getInvitees(projectBuild.id, true)
            }
          }
        },
        newPublicKeyCCs.map((el) => el.email),
        generateDataKeyPairUsers
      )
    }
  }
  const onChangeTab = (_newIndex: number, newLabel?: string) => () => {
    if (!newLabel) {
      return
    }
    if (activeTab.value === newLabel) {
      return
    }
    if (newLabel !== MENU_TAB_BUILD.COMPONENT) {
      projectBuildTabLocal.handleSetLocalStorage({
        ...projectBuildTabLocal.storageData,
        [projectBuild.id]: newLabel,
      })
    }

    activeTab.setValue(newLabel)
    isLoading.setValue(true)
    switch (newLabel) {
      case MENU_TAB_BUILD.INVITEES:
        getInvitees(projectBuild.id, true)
        break
      case MENU_TAB_BUILD.ADDITIONAL:
        getAdditional(projectBuild.id, true)
        break
      // default:
      //   getExtra(projectBuild.id, true)
      //   break
    }
  }
  const updateWhenCreateAdditional = () => {
    isLoading.setValue(true)
    getAdditional(projectBuild.id, true)
  }
  const updateWhenDeleteAdditional = (oldAdditional: string) => {
    const newAdditionals = cloneDeep(additionals)
    remove(newAdditionals, (el) => el.id === oldAdditional)
    setAdditionals(newAdditionals)
  }

  const handleChangeDataVendor = async (
    _newInvitees?: ProjectBuildInvitessDetail,
    inviteeId?: string,
    newAddedCC?: string[]
  ) => {
    isLoading.setValue(true)
    await getInvitees(projectBuild.id, true, inviteeId, newAddedCC)
    // await updateProjectBuild()
  }

  const handleUnListedComponent = async (
    projectBuildComponentId: string,
    componentCode: string,
    projectComponentId: string,
    version: string,
    newArchive: boolean
  ) => {
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: newArchive
              ? `When the component ${componentCode}/${version} is unlisted, it will be removed from all conversation instances used within build ${projectBuild.code}. Furthermore, any associated shared components and shared keys will also be deleted.`
              : `Are you sure you want to restore this component ${componentCode}/${version}?`,
            label: `Warning`,
            content: newArchive
              ? `Would you like to proceed?`
              : `This action cannot be undone`,
            styleTitle: { textAlign: "center" },
            titleButton: newArchive ? "Proceed" : "Restore",
            colorYellowButton: !newArchive,
            onSubmit: async () => {
              try {
                await updateUnlistedComponentInBuildMiddleware(
                  projectBuildComponentId,
                  activityLogHelper.toEncryptedMessage(
                    newArchive
                      ? ActivityLogType.UnlistedComponent
                      : ActivityLogType.RestoreComponent,
                    {
                      componentCode: `${componentCode}/${version}`,
                      buildCode: projectBuild.code,
                    }
                  )
                )

                await updateProjectBuild()
                const newInvitess = cloneDeep(invitess)
                if (newArchive) {
                  for (const el of newInvitess) {
                    const componentExist = el.project_build_components.find(
                      (elComponent) =>
                        elComponent.project_component_id === projectComponentId
                    )
                    if (componentExist) {
                      await deleteProjectBuildInviteeAddComponentMiddleware(
                        componentExist.id,
                        `${componentExist.code}/${componentExist.version}`,
                        el.conversation_id
                      )
                    }
                  }
                  toast(
                    <LabelNotificationPage
                      messenger={`Updated ${
                        newArchive ? "unlisted" : "restore"
                      } component successfully!`}
                      type="success"
                    />
                  )
                  closeModal()
                  await getInvitees(projectBuild.id, true)
                } else {
                  closeModal()
                }
              } catch (error) {
                toast(
                  <LabelNotificationPage
                    messenger={`Updated ${
                      newArchive ? "unlisted" : "restore"
                    } component failed`}
                    type="error"
                  />
                )
              }
            },
          },
        },
      })
    )
  }
  const renderComponentBuildSyncCard = () => (
    <div className="pr-6 h-full md:pr-0 ">
      <ComponentBuildSyncCard
        projectBuild={projectBuild}
        gridCols={"grid-cols-1"}
        onSyncDataComponent={onSyncDataComponent}
        handleUnListedComponent={handleUnListedComponent}
        openUpdateComponent={openUpdateComponent}
      />
    </div>
  )

  const renderTab = () => {
    const finalActiveTab =
      activeTab.value === MENU_TAB_BUILD.INVITEES &&
      projectBuild.status === STATUS_BUILD.COMMITTED
        ? MENU_TAB_BUILD.ADDITIONAL
        : activeTab.value
    switch (finalActiveTab) {
      case MENU_TAB_BUILD.COMPONENT:
        return renderComponentBuildSyncCard()
      case MENU_TAB_BUILD.INVITEES:
        return (
          <InviteesInfoCard
            idProjectBuild={projectBuild.id}
            handleChangeData={handleChangeDataVendor}
            invitess={invitess}
            sumComponent={
              projectBuild.components.filter(
                (el) => !el.is_build_component_archived
              ).length
            }
            isDeleted={isDeleted}
          />
        )
      default:
        return (
          <AdditionalInfoCard
            additionals={additionals}
            idProjectBuild={projectBuild.id}
            updateWhenCreateAdditional={updateWhenCreateAdditional}
            updateWhenDeleteAdditional={updateWhenDeleteAdditional}
            isEditorInput={checkPermissionPage({
              project: archiveProject.value,
              build: archiveBuild.value,
              viewShare: viewOnlyShare.value,
            })}
            isLabel
            isDeleteComment={checkPermissionPage({
              project: archiveProject.value,
              build: archiveBuild.value,
              viewShare: viewOnlyShare.value,
            })}
            isDeleted={isDeleted}
          />
        )
    }
  }

  const onSyncDataComponent = (newItem: ProjectComponentDetail) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    isSyncComponent.setValue(true)
    projectBuildComponentSyncId.setValue(newItem.project_build_component_id)
  }
  const onSynComponent = () => {
    if (!projectBuildComponentSyncId.value) {
      return
    }
    isLoading.setValue(true)
    const component = projectBuild.components.find(
      (item) =>
        item.project_build_component_id === projectBuildComponentSyncId.value
    ) as any

    postProjectBuildComponentSyncMiddleware(
      projectBuildComponentSyncId.value,
      async (type: STATUS_RESPONSE, messenger: string, data: any) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isSyncComponent.setValue(false)
          if (data.id && component) {
            await syncNewVersionComponentToInvitee(
              projectBuild.project_id,
              projectBuild.id,
              component.id,
              data.id,
              `${data.code}`,
              false
            )
          }
          await updateProjectBuild()
          getProjectBuild(idProjectBuildParams, true)
        } else {
          isLoading.setValue(false)
        }
      },

      component?.code,
      component?.main_version
    )
  }

  const getButtonNameAddOrUpdateComponent = () => {
    const hasComponents = projectBuild.components.length > 0
    const isUpdateOpen = openUpdateComponent.value

    if (isUpdateOpen) {
      return hasComponents ? "Close update components" : "Close add components"
    }

    return hasComponents ? "Update components" : "Add components"
  }

  const handleAddComponentInBuild = () => {
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `Build ${projectBuild.code} is currently status ${projectBuild.status}. This action may affect the build and cannot be undone`,
            label: `Warning`,
            content: `Are you sure you want to continue?`,
            styleTitle: { textAlign: "center" },
            titleButton: "Continue",
            colorYellowButton: true,
            onSubmit: () => {
              putProjectBuildMiddleware(
                projectBuild.id,
                {
                  project_component_ids: Object.values(selectAddComponent),
                },
                async (type: STATUS_RESPONSE, messenger: string) => {
                  if (type === STATUS_RESPONSE.SUCCESS) {
                    toast(
                      <LabelNotificationPage
                        messenger={messenger}
                        type={type}
                      />
                    )
                    closeModal()
                    openUpdateComponent.setValue(false)
                    setSelectAddComponent({})
                    await updateProjectBuild()
                  } else {
                    toast(
                      <LabelNotificationPage
                        messenger={messenger}
                        type={type}
                      />
                    )
                    closeModal()
                  }
                }
              )
            },
          },
        },
      })
    )
  }

  const renderButtonUpdateComponent = () => {
    const hasSelectedComponents = Object.values(selectAddComponent).length > 0
    if (hasSelectedComponents) {
      return (
        <ButtonHasIcon
          title="Add selected and close"
          onClick={handleAddComponentInBuild}
          widthButton={309}
          showIcon={false}
          heightButton={35}
        />
      )
    }
    return (
      <Button
        title={getButtonNameAddOrUpdateComponent()}
        onClick={() => openUpdateComponent.setValue(!openUpdateComponent.value)}
        sizeBtn="small"
        colorBtn={openUpdateComponent.value ? "white" : "yellow"}
        isDisabledBtn
        widthBtn={309}
        heightBtn={35}
      />
    )
  }

  const renderLayout = () => {
    if (!projectBuild.id || projectBuild.id !== idProjectBuildParams) {
      return null
    }
    const projectKey = projectEncryptionKeys[projectBuild.project_id]
    if (!projectKey) {
      return (
        <EncryptedDataWarning
          type="project"
          isOwner={projectDetail.created_by === userId}
          name={`"${projectBuild.project_name}"`}
          id={projectBuild.project_id}
          isEncrypted={projectBuild.is_encrypted_project || false}
        />
      )
    }
    const decryptedBuildKey = encryptionHelper.decrypt(
      projectKey,
      projectBuild?.buildKey?.encryptedKey
    )
    if (
      !decryptedBuildKey ||
      String(decryptedBuildKey).length !== 64 ||
      decryptedBuildKey === projectBuild?.buildKey?.encryptedKey
    ) {
      return (
        <EncryptedDataWarning
          type="build"
          isOwner={projectBuild.created_by === userId}
          name={`ID: ${projectBuild.code}`}
          id={projectBuild.id}
          isEncrypted={projectBuild.is_encrypted_project || false}
        />
      )
    }
    const conversationKeys = !isUndefined(projectBuild.conversationKeys)
      ? projectBuild.conversationKeys
      : []

    const decryptedConversationKeys = conversationKeys.map(
      (conversationKey) => {
        let isDecrypted = false
        if (conversationKey.encryptedKey) {
          const decrypted = encryptionHelper.decrypt(
            projectKey,
            conversationKey.encryptedKey
          )
          if (
            decrypted !== conversationKey.encryptedKey &&
            String(decrypted).length === 64
          ) {
            isDecrypted = true
          }
        }

        return {
          conversationCode: conversationKey.conversationCode,
          isDecrypted,
        }
      }
    )
    const notDecryptedConversationKeys = decryptedConversationKeys.filter(
      (el) => !el.isDecrypted
    )
    if (notDecryptedConversationKeys.length) {
      return (
        <EncryptedDataWarning
          name={`in build ID: ${projectBuild.code}`}
          id={projectBuild.id}
          conversationCodes={notDecryptedConversationKeys.map(
            (el) => el.conversationCode
          )}
          isEncrypted={projectBuild.is_encrypted_project || false}
        />
      )
    }
    return (
      <div className=" h-full flex flex-col overflow-auto">
        <InfoDraftBuildCard
          projectBuild={projectBuild}
          onUpdateDataEditNameBuild={onUpdateDataEditNameBuild}
          showButtonUpdateComponent={openUpdateComponent.value}
        />
        <div
          className="block md:grid h-full overflow-auto"
          style={{
            borderTop: "1px solid #E4E4E4",
            gridTemplateColumns: "358px calc(100% - 358px)",
          }}
        >
          <div
            className={`border-r border-[#E4E4E4] relative overflow-hidden ${
              isMobile ? "" : "pb-6"
            }`}
            style={{
              paddingTop: isMobile
                ? 0
                : includes(
                    [
                      STATUS_BUILD.COMPLETED,
                      STATUS_BUILD.CANCELLED,
                      STATUS_BUILD.DELETED,
                    ],
                    projectBuild.status
                  )
                ? 24
                : 55,
            }}
          >
            <div className="hidden md:block pl-6 pr-6 h-full overflow-auto">
              {renderComponentBuildSyncCard()}
            </div>
            <div className="absolute top-[6px] left-[24px]">
              {!isMobile &&
              !includes(
                [
                  STATUS_BUILD.CANCELLED,
                  STATUS_BUILD.COMPLETED,
                  STATUS_BUILD.DELETED,
                ],
                projectBuild.status
              ) &&
              !checkPermissionPage({
                project: archiveProject.value,
                build: archiveBuild.value,
                viewShare: viewOnlyShare.value,
              })
                ? renderButtonUpdateComponent()
                : null}
            </div>
          </div>
          {openUpdateComponent.value ? (
            <AddComponentInBuildDetail
              projectId={projectBuild.project_id}
              projectBuildId={projectBuild.id}
              listIdComponentActive={projectBuild.components.map((el) => el.id)}
              updateProjectBuild={updateProjectBuild}
              buildCode={projectBuild.code}
              openUpdateComponent={openUpdateComponent.value}
              projectBuildStatus={projectBuild.status}
              objActive={selectAddComponent}
              setObjActive={setSelectAddComponent}
            />
          ) : (
            <div className="flex flex-col h-full pl-6 pb-2 overflow-auto bg-grayWhite2 md:bg-white">
              <MenuTabBuildCard
                activeTab={
                  activeTab.value === MENU_TAB_BUILD.INVITEES &&
                  projectBuild.status === STATUS_BUILD.COMMITTED
                    ? MENU_TAB_BUILD.ADDITIONAL
                    : activeTab.value
                }
                onChangeTab={onChangeTab}
                additional={{
                  isRead: !projectBuild.is_read_additional_info
                    ? projectBuild.is_read_additional_info
                    : false,
                  count: additionals.length,
                }}
                extra={{
                  isRead: !projectBuild.is_read_extra_info
                    ? projectBuild.is_read_extra_info
                    : false,
                  count: extras.length,
                }}
                isInvitee={
                  projectBuild.status !== STATUS_BUILD.COMMITTED ? true : false
                }
                invitees={{
                  count: invitess.length,
                  isRead: false,
                }}
              />
              {renderTab()}
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <PermissionProjectBuildPage.Provider
      value={{
        archiveProject: archiveProject.value,
        archiveBuild: isUndefined(isArchived)
          ? Boolean(projectBuild.is_archived)
          : isArchived,
        viewOnlyShare: viewOnlyShare.value,
        listStatus,
        status: projectBuild.status,
        tabMenu: activeTab.value,
        projectBuild: projectBuild,
      }}
    >
      <div className="flex flex-col w-full border-l border-[#E4E4E4] h-[calc(100_*_var(--vh)_-_52px)] md:h-[calc(100_*_var(--vh)_-_0px)]">
        <div className="min-h-[48px] flex justify-between items-center bg-white">
          <div
            className="flex md:hidden p-6 "
            onClick={() => {
              showBuildDetailLayout.setValue(false)
            }}
          >
            <ArrowLeftIcon />
          </div>
          <div className="md:w-full h-full">
            <Header
              projectDetail={projectDetail}
              projectBuild={projectBuild}
              updatedAtBuild={updatedAtBuild}
            />
          </div>
        </div>
        {renderLayout()}
      </div>

      {isSyncComponent.value && (
        <ModalDelete
          onCloseModal={() => isSyncComponent.setValue(false)}
          title={`Update to the latest version from the master branch and cannot be undone.`}
          content=""
          label={`Confirmation`}
          onSubmit={onSynComponent}
          colorYellowButton
          titleButton="Confirm"
          styleTitle={{
            textAlign: "center",
          }}
        />
      )}
      {isLoadingBackupInvitee.value && messageBackups.length ? (
        <FormBackup
          messageBackups={messageBackups}
          currentBackup={currentMessageBackup}
          errorMessageBackup={errorMessageBackup.value}
        />
      ) : null}
    </PermissionProjectBuildPage.Provider>
    // </PageLayout>
  )
}

export default BuildDetail
