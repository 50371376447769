import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BUILD_FINISHED_STATUSES,
} from "components/Status/types"
import { checkPermissionPage } from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { useContext } from "react"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import NoDataCard from "../molecules/NoDataCard"
import { useSelectComponentInProjectBuild } from "../project-build.hook"
import { MENU_TAB_BUILD } from "../project-build.type"
import ComponentDetailInBuild from "./ComponentDetailInBuild "
import { orderBy } from "lodash"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import { useWindowSize } from "helpers/hooks"
import Button from "components/Button/Button"

interface Props {
  projectBuild: ProjectComponentBuildDetail
  gridCols: string
  onSyncDataComponent: (newItem: ProjectComponentDetail) => (event) => void
  onReShareKeyComponent?: (newItem: ProjectComponentDetail) => (event) => void
  handleUnListedComponent?: (
    projectBuildComponentId: string,
    componentCode: string,
    projectComponentId: string,
    version: string,
    newArchive: boolean
  ) => void
  openUpdateComponent: {
    value: boolean
    setValue: (value: boolean) => void
  }
}
const ComponentBuildSyncCard = (props: Props) => {
  const {
    projectBuild,
    gridCols,
    onSyncDataComponent,
    onReShareKeyComponent,
    handleUnListedComponent,
    openUpdateComponent,
  } = props
  const { components } = projectBuild
  const { archiveProject, archiveBuild, viewOnlyShare, status, tabMenu } =
    useContext(PermissionProjectBuildPage)
  const { onSelectComponent } = useSelectComponentInProjectBuild()
  const isDeleted = projectBuild.status === STATUS_BUILD.DELETED
  return (
    <div className="h-full w-full">
      {components.length ? (
        <div
          className={`grid ${gridCols} py-0 md:py-[5px] px-0`}
          style={{
            gridGap: 24,
          }}
        >
          {orderBy(components, "is_build_component_archived", "asc").map(
            (el, index) => {
              return (
                <ComponentDetailInBuild
                  key={index}
                  item={el}
                  isHoverCard
                  isSync
                  onSyncData={onSyncDataComponent}
                  onReShareKey={onReShareKeyComponent}
                  isDeleted={
                    isDeleted ||
                    el.status === ProjectComponentStatus.DeletedAttachment
                  }
                  isShowInvitee={
                    BUILD_FINISHED_STATUSES.includes(status as any) &&
                    tabMenu === MENU_TAB_BUILD.INVITEES
                  }
                  isShowReShare={
                    projectBuild.status !== STATUS_BUILD.COMMITTED
                      ? true
                      : false
                  }
                  onSelectComponent={onSelectComponent}
                  isShowStatus
                  isView={
                    checkPermissionPage({
                      project: archiveProject,
                      build: archiveBuild,
                      viewShare: viewOnlyShare,
                    }) || BUILD_FINISHED_STATUSES.includes(status as any)
                  }
                  canSync={el.can_sync}
                  isUpdateComponent={openUpdateComponent.value}
                  handleUnListedComponent={handleUnListedComponent}
                />
              )
            }
          )}
        </div>
      ) : (
        <NoDataCard
          label=""
          // children={
          //   !checkPermissionPage({
          //     project: archiveProject,
          //     viewShare: viewOnlyShare,
          //   }) ? (
          //     <>
          //       {openUpdateComponent.value ? (
          //         <Button
          //           title="Close add components"
          //           onClick={() => openUpdateComponent.setValue(false)}
          //           sizeBtn="large"
          //           colorBtn="red"
          //           heightBtn={40}
          //           isDisabledBtn
          //           widthBtn={200}
          //         />
          //       ) : (
          //         <ButtonHasIcon
          //           title="Add components"
          //           widthButton={200}
          //           onClick={() => openUpdateComponent.setValue(true)}
          //         />
          //       )}
          //     </>
          //   ) : null
          // }
        />
      )}
    </div>
  )
}

export default ComponentBuildSyncCard
