import React, { useContext } from "react"
import {
  checkPermissionPage,
  formatDateForConversationMessage,
  getDifferenceInDays,
} from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import TypeComponent from "pages/project-component/molecules/TypeComponent"
import { ReactComponent as IconChecked } from "assets/images/icons/icon-checked-build.svg"
import { ReactComponent as IconCheckedActive } from "assets/images/icons/icon-checked-build-active.svg"
import { ReactComponent as IconRemove } from "assets/images/icons/icon-remove-component-build.svg"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import { STATUS_BUILD } from "components/Status/types"
import DotCard from "pages/conversations/atoms/DotCard"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { isUndefined } from "lodash"

interface Props {
  item: ProjectComponentDetail
  isActive?: boolean
  onSelectComponent?: (
    id: string,
    isValidToBuild: boolean,
    idProjectBuildComponent: string,
    typeKey: string,
    idProjectComponentHistory: string
  ) => (event) => void
  isRemove?: boolean
  onRemoveComponent?: (idProjectBuildComponent: string) => (event) => void
  isHoverCard?: boolean
  isSync?: boolean
  isDeleted?: boolean
  onSyncData?: (newItem: ProjectComponentDetail) => (event) => void
  onReShareKey?: (newItem: ProjectComponentDetail) => (event) => void
  isView?: boolean
  isShowInvitee?: boolean
  isShowStatus?: boolean
  canSync?: boolean
  isShowReShare?: boolean
  isUpdateComponent?: boolean
  handleUnListedComponent?: (
    projectBuildComponentId: string,
    componentCode: string,
    projectComponentId: string,
    version: string,
    newArchive: boolean
  ) => void
}
const ComponentDetailInBuild = (props: Props) => {
  const {
    item,
    isActive,
    isRemove,
    isHoverCard,
    isSync,
    isDeleted,
    isView = false,
    onSelectComponent,
    onRemoveComponent,
    onSyncData,
    isShowInvitee,
    isShowStatus = false,
    canSync,
    isUpdateComponent,
    handleUnListedComponent,
  } = props
  const { archiveProject, archiveBuild } = useContext(
    PermissionProjectBuildPage
  )
  const renderIcon = () => {
    if (isView || isDeleted) {
      return null
    }
    if (isUpdateComponent) {
      if (
        !isUndefined(handleUnListedComponent) &&
        item.project_build_component_id
      ) {
        return (
          <ActionIconHistory
            icon={item.is_build_component_archived ? "revert" : "close-modal"}
            tooltip={item.is_build_component_archived ? "Restore" : "Unlist"}
            customStyleIcon={{
              width: 24,
              height: 24,
            }}
            classIcon="restore-icon"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleUnListedComponent(
                item.project_build_component_id || "",
                item.code,
                item.project_component_id || "",
                item.version || "",
                !item.is_build_component_archived || false
              )
            }}
            styleIcon={{
              marginRight: 0,
            }}
          />
        )
      }
    }

    if (isRemove) {
      if (
        checkPermissionPage({
          project: archiveProject,
          build: archiveBuild,
        })
      ) {
        return
      }
      return (
        <ActionIconHistory
          icon="close-modal"
          tooltip="Delete"
          customStyleIcon={{
            width: 24,
            height: 24,
          }}
          onClick={
            onRemoveComponent
              ? onRemoveComponent(item.project_build_component_id ?? "")
              : undefined
          }
          styleIcon={{
            marginRight: 0,
          }}
        />
        // <IconRemove
        //   onClick={
        //     onRemoveComponent
        //       ? onRemoveComponent(item.project_build_component_id ?? "")
        //       : undefined
        //   }
        // />
      )
    }
    if (isSync && !item.is_build_component_archived) {
      if (
        checkPermissionPage({
          project: archiveProject,
          build: archiveBuild,
        })
      ) {
        return
      }
      return (
        <ActionIconHistory
          icon="system-data"
          tooltip={
            canSync
              ? "Update to the latest version from the master branch"
              : "The component has already been updated to the most recent version."
          }
          customStyleIcon={{
            width: 24,
            height: 24,
          }}
          disabled={!canSync}
          onClick={
            onSyncData && canSync
              ? onSyncData(item)
              : (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }
          }
          styleIcon={{
            marginRight: 0,
          }}
        />
      )
    }
    if (!item.is_valid_to_build) {
      return null
    }
    if (isActive) {
      return <IconCheckedActive />
    }
    return <IconChecked />
  }
  const classHoverCard = () => {
    if (isHoverCard) {
      return true
    }
    return item.is_valid_to_build
  }
  const renderInfoInvitee = () => {
    if (isShowInvitee && item.invitee?.email) {
      return (
        <div className="flex flex-col">
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: item.is_build_component_archived ? "#C7C7C7" : "#111111",
            }}
          >
            {item.invitee.name}
          </p>
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: item.is_build_component_archived ? "#C7C7C7" : "#7A7A7A",
            }}
          >
            {item.invitee.email}
          </p>
        </div>
      )
    }
    return (
      <div className="flex">
        <p
          className="font-medium text-time"
          style={{
            color: item.is_build_component_archived ? "#C7C7C7" : "#7A7A7A",
            fontSize: 12,
            lineHeight: "18px",
          }}
        >
          {item?.updated_at
            ? `Updated ${formatDateForConversationMessage(item.updated_at)}`
            : "No update"}
        </p>
      </div>
    )
  }

  return (
    <div
      onClick={
        onSelectComponent && !isDeleted && !isUpdateComponent
          ? onSelectComponent(
              item.id,
              item.is_valid_to_build,
              item.project_build_component_id ?? "",
              item.type.key,
              item.project_component_history_id
            )
          : undefined
      }
      className={`rounded-md px-3 pt-[9px] ${
        item.is_build_component_archived ? "bg-delete-element" : ""
      } ${
        classHoverCard() && !isDeleted ? "hover-component-detail-in-build" : ""
      } ${
        isDeleted
          ? " bg-neutral3 archived-project-component"
          : "bg-white cursor-pointer"
      }`}
      style={{
        border: `1px solid ${isActive ? "#56a012" : "#E4E4E4"}`,
        boxShadow: isActive ? "0px 0px 0px 4px rgb(86 160 18 / 10%)" : "none",
      }}
    >
      <div className="flex justify-between mb-[21px]">
        <TypeComponent label={item.type?.key || ""} />
        {renderIcon()}
      </div>
      <div className="flex items-center">
        <p
          className={`font-medium text-code text-xs ${
            item.is_build_component_archived ? "text-[#C7C7C7]" : "text-brown"
          }`}
        >
          {item.code}
        </p>
        {item.version ? (
          <React.Fragment>
            <div
              style={{
                width: 4,
                height: 4,
                backgroundColor: item.is_build_component_archived
                  ? "#C7C7C7"
                  : "#7A7A7A",
                borderRadius: "50%",
                margin: "0px 4px",
              }}
            />
            <p
              className="font-medium text-code"
              style={{
                fontSize: 12,
                color: item.is_build_component_archived ? "#C7C7C7" : "#7A7A7A",
                lineHeight: "18px",
              }}
            >
              {item.version}
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="flex items-center mt-1">
        <p
          className="font-semibold text-name"
          style={{
            fontSize: 14,
            color: item.is_build_component_archived ? "#C7C7C7" : "#111111",
            lineHeight: "24px",
          }}
        >
          {item.name}
        </p>
        {item.last_version_status &&
        isShowStatus &&
        item.last_version_status === STATUS_BUILD.DRAFT ? (
          <React.Fragment>
            <DotCard />
            <LabelStatusBuild status={item.last_version_status} />
          </React.Fragment>
        ) : null}
      </div>
      <div
        className="flex flex-col"
        style={
          isShowInvitee && item.invitee?.email
            ? {
                marginTop: 18,
                marginBottom: 4,
              }
            : {
                marginTop: 25,
                paddingBottom: 15,
              }
        }
      >
        <div className="flex justify-between items-center w-full">
          {renderInfoInvitee()}
        </div>
      </div>
    </div>
  )
}
export default ComponentDetailInBuild
